import React from 'react';
import { Row, Col, Layout } from 'antd';

import LeftSideBar from 'src/pages/DCache/LeftSideBar';
import RightToolsBar from 'src/pages/DCache/RightToolsBar';

const { Content, Sider } = Layout;

export default class DCache extends React.Component {
  render() {
    return (
      <Layout hasSider={true} style={{backgroundColor: 'white'}}>
        <Sider theme='light' width='240' style={{width: '20vw'}}>
          <LeftSideBar />
        </Sider>

        <Row justify='end' wrap={false} style={{width: '90vw'}}>
          <Col flex='1 1 auto'>
            <Content style={{ marginLeft: 15 }}>
              Placeholder
            </Content>
          </Col>
          <Col flex='0 0 auto'>
            <Sider theme='light' width='140' style={{marginLeft:8}}>
              <RightToolsBar />
            </Sider>
          </Col>
        </Row>
      </Layout>
    )
  }
}