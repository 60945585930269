import React from "react";
import { isEmpty } from "lodash";
import { Button, Card, Tabs, Row, Col, Badge } from "antd";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";

import ServerTable from "src/pages/DevOps/ServerTable";
import MachineInfo from "src/pages/DevOps/MachineInfo";
import Monitoring from "src/pages/DevOps/Monitoring";
import PropertyPlus from "src/pages/DevOps/PropertyPlus";
import ServerInfo from "src/pages/DevOps/ServerInfo";
import ServerOperationHistory from "src/pages/DevOps/ServerOperationHistory";
import Xterm from "src/pages/DevOps/Xterm";
import TaskManager from "src/pages/DevOps/LeftSideTaskManager";
import DebugInterface from "src/pages/DevOps/DebuggerBlack";
import "./style/common.less";
const { TabPane } = Tabs;

interface IProps {
  tab?: string;
  preference: ActionType.IPreference;
  haveOperatePerm?: boolean;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  isRollout?: boolean;

  depotID?: number;
  pausedItems?: Map<string, number>;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  taskManagerDot?: boolean;

  changeTab: (currentTab: string) => void;

  expandTerminal: (expand: boolean) => void;

  setTaskManagerDot: (taskManagerDot: boolean) => void;
  // expandTable: (expand: boolean) => void;
}

const RightContent: React.FC<IProps> = (props) => {
  const xtermRef = React.useRef<Xterm>(null);

  const historyRef = React.useRef<any>(null);

  const [currTab, setCurrTab] = React.useState<string>("log");
  // 容器节点点击登陆之后，设置xterm的podIP
  const execClicked = (podIP: string, clusterName: string) => {
    // 调用登录至容器
    props.expandTerminal(true);

    if (xtermRef.current) xtermRef.current.exec(podIP, clusterName);
  };
  // 监听是否展开xterm的值
  React.useEffect(() => {
    // 控制台展开时，自动调整xterm的大小
    if (props.preference.expandTerminal) {
      setTimeout(() => {
        if (xtermRef.current) xtermRef.current.fit();
      }, 100);
    }
  }, [props.preference.expandTerminal]);

  React.useEffect(() => {
    setCurrTab("log");
  }, [
    props.selectedTarsApplication,
    props.selectedTarsSetName,
    props.selectedTarsServer,
  ]);

  return (
    <div className="mangePane">
      {props.selectedTarsServer && !isEmpty(props.selectedTarsServer) && (
        <Tabs
          size="large"
          activeKey={props.tab}
          animated={false}
          onChange={(key) => props.changeTab(key)}
          destroyInactiveTabPane
        >
          <TabPane tab="服务管理" key="devops" style={{ width: "100%" }}>
            {/* 中间的节点容器列表 */}
            <ServerTable execClicked={execClicked} historyRef={historyRef} />
            {/* 下面的日志以及控制台的面板 */}
            <Card bordered={false} size="small" style={{ width: "100%" }}>
              <Row>
                <Col
                  span={24}
                  flex="1 1 auto"
                  style={{
                    display: props.preference.expandTerminal ? "block" : "none",
                  }}
                >
                  <Xterm ref={xtermRef} />
                </Col>
              </Row>
              <Row>
                <Col span={24} flex={"1 1 auto"}>
                  <Tabs
                    tabBarExtraContent={{
                      right: (
                        <>
                          <Button
                            size="small"
                            type="dashed"
                            className="tool-btn"
                            disabled={!props.haveOperatePerm}
                            onClick={() =>
                              props.expandTerminal(
                                !props.preference.expandTerminal
                              )
                            }
                          >
                            {props.preference.expandTerminal
                              ? "隐藏shell控制台"
                              : "打开shell控制台"}
                          </Button>
                        </>
                      ),
                    }}
                    activeKey={currTab}
                    onChange={(activeKey: string) => {
                      setCurrTab(activeKey);
                      if (activeKey === "task") {
                        props.setTaskManagerDot(false);
                      }
                    }}
                  >
                    <Tabs.TabPane tab="日志" key="log">
                      <ServerOperationHistory
                        onRef={historyRef}
                        isRollout={!!props?.isRollout}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <>
                          <Badge dot={props.taskManagerDot}>操作任务</Badge>
                        </>
                      }
                      key="task"
                    >
                      {currTab === "task" && <TaskManager />}
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
            </Card>
          </TabPane>

          <TabPane tab="机器信息" key="machine-info">
            <div style={{ width: "78vw" }}>
              <MachineInfo />
            </div>
          </TabPane>

          <TabPane tab="容器监控" key="container-monitoring">
            <div style={{ width: "78vw" }}>
              <Monitoring kind="container" />
            </div>
          </TabPane>

          <TabPane tab="被调监控" key="slave-stat">
            <div style={{ width: "78vw" }}>
              <Monitoring kind="stat" />
            </div>
          </TabPane>

          <TabPane tab="特性监控" key="property">
            <div style={{ width: "78vw" }}>
              <Monitoring kind="property" />
            </div>
          </TabPane>
          <TabPane tab="特性对比" key="property_compare">
            <div style={{ width: "78vw" }}>
              <Monitoring kind="property_compare" />
            </div>
          </TabPane>

          <TabPane tab="主调监控" key="master-stat">
            <div style={{ width: "78vw" }}>
              <Monitoring kind="master-stat" />
            </div>
          </TabPane>

          <TabPane tab="PP特性监控" key="property-plus">
            <div style={{ width: "78vw" }}>
              <PropertyPlus />
            </div>
          </TabPane>
          {props.tarsServerItems?.length && (
            <TabPane tab="接口调试" key="debugInterface">
              <div style={{ width: "85vw" }}>
                <DebugInterface />
              </div>
            </TabPane>
          )}
          <TabPane tab="服务信息" key="server-info">
            <div style={{ width: "78vw" }}>
              <ServerInfo />
            </div>
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  tab: state.tarsReducer.tab,
  preference: state.tarsReducer.preference,

  haveOperatePerm: state.tarsReducer.selectedTarsPerm === "W",
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,

  depotID: state.tarsReducer.depotID,
  pausedItems: state.tarsReducer.pausedItems,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  taskManagerDot: state.tarsReducer.taskManagerDot,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  changeTab: (currentTab: string) => dispatch(Actions.changeTab(currentTab)),

  expandTerminal: (expand: boolean) => dispatch(Actions.expandTerminal(expand)),
  setTaskManagerDot: (taskManagerDot: boolean) =>
    dispatch(Actions.setTaskManagerDot(taskManagerDot)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RightContent);
