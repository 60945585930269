import React from "react";
import { isEmpty } from "lodash";
import { Table, Button, Modal } from "antd";
import { EyeTwoTone } from "@ant-design/icons";

import { ColumnsType } from "antd/es/table";

import * as fetch from "src/fetch";
import DeletePodButton from "src/pages/Common/DeletePodButton";

interface IPod {
  resourceName: string;
  resource: string;
  podName: string;
  podIP: string;
  settingState: string;
  presentState: string;
  runningCount: number;
}

interface IProps {
  currentUser?: string;
  machineIP: string;
  clusterName: string;
}

interface IState {
  visible: boolean;
  dataSource: Array<IPod>;
}

export default class ViewPodButton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      visible: false,
      dataSource: [],
    };
  }

  fetchData(machineIP: string) {
    this.setState({ visible: false });
    fetch
      .fetchListMachinePods({
        clusterName: this.props.clusterName,
        machineIP,
      })
      .then((data) => {
        this.setState({ dataSource: data.items, visible: true });
      })
      .catch((_) => {});
  }

  fetchDeletePod(podName: string, clusterName: string) {
    fetch
      .fetchDeletePod({
        clusterName,
        podName,
        userName: this.props.currentUser || "",
      })
      .then(() => {
        this.setState({ visible: false, dataSource: [] });
      })
      .catch((_) => {});
  }

  render() {
    const wrapState = (s: string) => {
      switch (s) {
        case "active":
          return "Active";
        case "activating":
          return "Activating";
        case "prepare":
          return "Prepare";
        case "ready":
          return "Ready";
        case "inactive":
          return "Off";
        case "deactivating":
          return "Deactivating";
      }
      if (!isEmpty(s)) s = s[0].toUpperCase() + s.substring(1);
      return s;
    };
    const wrapStateColor = (s: string) => {
      switch (s) {
        case "active":
          return "green";
        case "activating":
          return "red";
        case "prepare":
          return "blue";
        case "ready":
          return "hotpink";
        case "inactive":
          return "red";
        case "deactivating":
          return "red";
        case "Running":
          return "green";
        case "Pending":
          return "red";
      }
      return "black";
    };

    const columns: ColumnsType<IPod> = [
      { title: "IP", dataIndex: "podIP", width: 100, align: "center" },
      { title: "规格", dataIndex: "resource", width: 100, align: "center" },
      {
        title: "资源名称",
        dataIndex: "resourceName",
        width: 200,
        align: "center",
        ellipsis: true,
      },
      {
        title: "容器名",
        dataIndex: "podName",
        width: 300,
        align: "center",
        ellipsis: true,
      },
      {
        title: "预期",
        dataIndex: "settingState",
        width: 100,
        align: "center",
        render: (text: string) => (
          <span style={{ color: wrapStateColor(text) }}>{wrapState(text)}</span>
        ),
      },
      {
        title: "当前",
        dataIndex: "presentState",
        width: 100,
        align: "center",
        ellipsis: true,
        render: (text: string) => (
          <span style={{ color: wrapStateColor(text) }}>{wrapState(text)}</span>
        ),
      },
      {
        title: "预期数量",
        dataIndex: "runningCount",
        width: 100,
        align: "center",
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 100,
        align: "center",
        render: (text: string, record: IPod) => (
          <DeletePodButton
            currentUser={this.props.currentUser}
            clusterName={this.props.clusterName}
            podName={record.podName}
            onOK={() => this.setState({ visible: false, dataSource: [] })}
          />
        ),
      },
    ];

    return (
      <>
        <Button
          style={{ padding: "0 8px" }}
          size="small"
          className="tool-btn"
          onClick={() => this.fetchData(this.props.machineIP)}
        >
          <EyeTwoTone />
          查看
        </Button>

        <Modal
          title={<div style={{ textAlign: "center" }}>节点上的容器</div>}
          width="80vw"
          visible={this.state.visible}
          footer={null}
          onCancel={() => this.setState({ dataSource: [], visible: false })}
        >
          <Table
            size="small"
            rowKey="podName"
            pagination={false}
            columns={columns}
            dataSource={this.state.dataSource}
          />
        </Modal>
      </>
    );
  }
}
