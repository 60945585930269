import React from "react";
import { Alert, Button, Input } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import Marquee from "react-fast-marquee";

interface IProps {
  fetchData: () => void;
  onSearchIp?: (ip: string) => void;
}

export default class ExpandNodePoolTitle extends React.Component<IProps> {
  render() {
    return (
      <span>
        <Alert
          message={
            <Marquee pauseOnHover gradient={false} direction="right">
              阿里云SDK扩容成功会报错,
              报错可以忽略，机器上线一般需要等5~10分钟。
              {Array.from(Array(100)).map((_, index) => (
                <div key={index}>&nbsp;</div>
              ))}
            </Marquee>
          }
          type="warning"
          showIcon
        />

        <div style={{ marginTop: 8, textAlign: "center" }}>
          {this.props.onSearchIp && (
            <Input
              style={{
                width: 200,
              }}
              placeholder="模糊搜索的IP"
              onChange={(e) => {
                (this.props.onSearchIp as any)(e.target.value);
              }}
            />
          )}
          <Button
            size="small"
            type="dashed"
            className="tool-btn"
            style={{ marginLeft: 20 }}
            onClick={() => this.props.fetchData()}
          >
            <ReloadOutlined style={{ color: "green", fontSize: 15 }} />
            刷新
          </Button>
        </div>
      </span>
    );
  }
}
