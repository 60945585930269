export const config = {
  comments: {
    lineComment: "#",
  },
  brackets: [
    ["{", "}"],
    ["[", "]"],
    ["(", ")"],
  ],
  autoClosingPairs: [
    { open: "{", close: "}" },
    { open: "[", close: "]" },
    { open: "(", close: ")" },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
  ],
  surroundingPairs: [
    { open: "{", close: "}" },
    { open: "[", close: "]" },
    { open: "(", close: ")" },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
  ],
};

export const language = {
  defaultToken: "",
  tokenPostfix: ".toml",

  // we include these common regular expressions
  escapes:
    /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

  // The main tokenizer for our languages
  tokenizer: {
    root: [
      // sections
      [/^\[[^\]]*\]/, "metatag"],

      { include: "@comments" },
      { include: "@tables" },
      { include: "@keys_double_quotes" },  // 新增规则
      { include: "@keys" },
      { include: "@dataTypes" },

      // whitespace
      { include: "@whitespace" },

      { include: "@float" },
      { include: "@integer" },

      // strings: recover on non-terminated strings
      [/"([^"\\]|\\.)*$/, "string.invalid"], // non-teminated string
      [/'([^'\\]|\\.)*$/, "string.invalid"], // non-teminated string
      [/"/, "string", '@string."'],
      [/'/, "string", "@string.'"],
      { include: "@dateTimeWithTz" },
      { include: "@dateTime" },
      { include: "@date" },
      { include: "@float" },
      { include: "@integer" },
      { include: "@boolean" },
      { include: "@string" },
    ],

    array: [{ include: "@comments" }, { include: "@dataTypes" }],

    boolean: [[/(?<!\w)(true|false)(?!\w)/, "constant.other.boolean.toml"]],

    comments: [
      [
        /\s*((#).*)$/,
        {
          cases: {
            $1: "comment.line.number-sign.toml",
            $2: "punctuation.definition.comment.toml",
          },
        },
      ],
    ],

    dataTypes: [


      { include: "@dateTimeWithTz" },
      { include: "@dateTime" },
      { include: "@date" },
      { include: "@float" },
      { include: "@integer" },
      { include: "@boolean" },
    ],

    dateTimeWithTz: [
      [
        /(?<!\w)(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2}))(?!\w)/,
        "constant.other.datetime-with-timezone.toml",
      ],
    ],

    dateTime: [
      [
        /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?)/,
        "constant.other.datatime.toml",
      ],
    ],

    date: [[/(\d{4}-\d{2}-\d{2})/, "constant.other.date.toml"]],

    float: [
      [
        /(?<!\w)([+-]?(0|([1-9](([0-9]|_[0-9])+)?))(?:(?:\.(0|([1-9](([0-9]|_[0-9])+)?)))?[eE][+-]?[1-9]_?[0-9]*|(?:\.[0-9_]*)))(?!\w)/,
        "constant.numeric.float.toml",
      ],
    ],

    integer: [
      [
        /(?<!\w)((?:[+-]?(0|([1-9](([0-9]|_[0-9])+)?))))(?!\w)/,
        "constant.numeric.integer.toml",
      ],
    ],

    keys: [[/(^\w+)(\s*)(=)/, ["key", "", "delimiter"]]],
    keys_double_quotes: [[/"[^"]*"\s*(?==)/, "key"]],  // 新增规则
    whitespace: [[/[ \t\r\n]+/, ""]],

    string: [
      [/[^\\"']+/, "string"],
      [/@escapes/, "string.escape"],
      [/\\./, "string.escape.invalid"],
      [
        /["']/,
        {
          cases: {
            "$#==$S2": { token: "string", next: "@pop" },
            "@default": "string",
          },
        },
      ],
    ],

    tables: [[/^\[\\[^\]]*\]/, "punctuation.definition.table.toml"]],
 
  },
};
