import React from "react";
import { isEmpty } from "lodash";
import {
  Divider,
  Space,
  Descriptions,
  Spin,
  Empty,
  Form,
  Button,
  AutoComplete,
  Result,
  TreeSelect,
  Input,
  InputNumber,
  FormInstance,
  Alert,
  Tag,
  Select,
  Switch,
  Row,
  Col,
  notification,
  PageHeader,
  message,
} from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { DataNode } from "rc-tree-select/lib/interface";
import {
  PlusCircleOutlined,
  DeleteTwoTone,
  LikeFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { NavigateFunction } from "react-router-dom";
import "./index.less";

import * as fetch from "../../fetch";
import * as FetchType from "../..//fetch/types";
import { pickClusterName } from "../..//utils/Picker";
import PodQosClassTooltip from "../..//pages/Common/PodQosClassTooltip";
import SetSelect from "./components/SetSelect";

const { webApi } = fetch;
const FormItem = Form.Item;
const FormList = Form.List;
const DescriptionItem = Descriptions.Item;
const SelectOption = Select.Option;

const { TextArea } = Input;

interface IProps {
  currentUser?: string;
  navigator: NavigateFunction;
  admin: boolean | undefined;
}

interface IDeployOptionSet {
  idcName: string;
  tarsApplications: Array<string>;
  tarsSets: Array<string>;
  tarsServers: Array<string>;
  tarsServerTypes: Array<string>;
  tarsServerTemplates: Array<string>;
  nodeResources: Array<FetchType.INodeResource>;
  nodeRequests: Array<FetchType.INodeRequest>;
  grayLogicNodePoolID: number;
  antiAffinities: Array<FetchType.IAntiAffinity>;
  podQOSClasses: Array<string>;
  serviceAccounts: Array<string>;
  costJobCenters: Array<string>;
  costJobs: Array<string>;
  costApps: Array<string>;
}

interface IState {
  loading: boolean;
  enableSet: boolean;
  options: Map<string, IDeployOptionSet>;

  adapters: Array<FetchType.IAdapter>;
  selectedResourceID?: number;
  searchOptions?: Array<{ value: string }>;

  tarsSetName?: string;
  clusterName?: string;
  logLimit: boolean;
  disableSchedule: boolean;
  sharedStorage?: boolean;
  selfAntiAffinity: boolean;
  antiAffinities: Array<string>;
  treeData: Array<DataNode>;

  inputVisible: boolean;
  inputEnvName: string;
  inputEnvValue: string;
  envs: Map<string, string>;
  userList: {
    value: string;
    label: string;
  }[];
  customApplicationName: string;
  currSelectMem: string;
}

interface IUserInfo {
  uid: string;
  username: string;
  avatar: string;
}

interface IPerson {
  apprvoer: string;
  developer: string[];
  visitor: string[];
  first_alarm: string;
  second_alarm: string;
  third_alarm: string;
}

class CreateServer extends React.Component<IProps, IState> {
  private createForm: React.RefObject<FormInstance>;
  initialState(): IState {
    return {
      loading: false,
      enableSet: false,
      options: new Map(),
      adapters: [],
      selectedResourceID: undefined,
      searchOptions: undefined,
      tarsSetName: undefined,
      clusterName: undefined,
      logLimit: true,
      disableSchedule: false,
      sharedStorage: undefined,
      selfAntiAffinity: false,
      treeData: [],
      antiAffinities: [],
      inputVisible: false,
      inputEnvName: "",
      inputEnvValue: "",
      envs: new Map(),
      userList: [],
      customApplicationName: "",
      currSelectMem: "",
    };
  }

  defaultAdapter(): FetchType.IAdapter {
    return {
      tarsServantObj: "",
      protocol: "tars",
      threadNum: 5,
      maxConn: 200000,
      maxQueue: 10000,
      maxQueueTimeout: 60000,
    };
  }

  constructor(props: IProps) {
    super(props);
    this.createForm = React.createRef<FormInstance>();
    this.state = {
      ...this.initialState(),
      adapters: [this.defaultAdapter()],
    };
  }

  onAntiAffinitiesSelect(node: any) {
    let antiAffinities = this.state.antiAffinities;
    antiAffinities.push(node.resourceName);
    this.setState({ antiAffinities });
  }

  onAntiAffinitiesDeselect(node: any) {
    let antiAffinities = this.state.antiAffinities;
    antiAffinities = antiAffinities.filter(
      (item) => item !== node.resourceName
    );
    this.setState({ antiAffinities });
  }

  fetchData() {
    this.setState({ loading: true });
    fetch
      .fetchGetDeployOptionSet()
      .then((data) => {
        let treeData: Array<DataNode> = [];
        let options = new Map<string, IDeployOptionSet>();
        for (const i in data.items) {
          const item = data.items[i];
          options.set(i, item);
        }
        const clusterName = pickClusterName(Array.from(options.keys()));
        const option = options.get(clusterName);
        if (option) {
          treeData = fetch.buildSelectDataNodesFromMemory(
            option.antiAffinities
          );
        }
        this.setState({ options, clusterName, treeData, loading: false });
      })
      .catch((_) => {});
  }

  fetchUserList() {
    webApi.get<IUserInfo[]>("user/list").then((res) =>
      this.setState({
        userList: res.map((item) => ({
          value: item.uid,
          label: item.username,
        })),
      })
    );
  }

  componentDidMount() {
    this.fetchData();
    this.fetchUserList();
  }

  onInputEnvConfirm() {
    if (isEmpty(this.state.inputEnvName) || isEmpty(this.state.inputEnvValue)) {
      this.setState({
        inputEnvName: "",
        inputEnvValue: "",
        inputVisible: false,
      });
      return;
    }

    let reg = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/g;
    if (!reg.test(this.state.inputEnvName)) {
      notification.destroy();
      notification.error({
        message: "环境变量错误",
        description: "环境变量名称只能包含字母、数字、下划线",
        duration: 2,
      });
      return;
    }

    let envs = this.state.envs;
    envs.set(this.state.inputEnvName, this.state.inputEnvValue);
    this.setState({
      envs,
      inputEnvName: "",
      inputEnvValue: "",
      inputVisible: false,
    });
  }

  onInputEnvCancel() {
    this.setState({ inputEnvName: "", inputEnvValue: "", inputVisible: false });
  }

  saveServerPerson(server: string, person: IPerson) {
    webApi.post<IPerson>("server/person/create", {
      server: server,
      ...person,
    });
  }

  async onSubmit() {
    try {
      if (!this.createForm.current) return;

      const fields = await this.createForm.current.validateFields();
      const resourceParts = fields.requestResource.toString().split("x");

      const logLimitMB = this.state.logLimit ? fields.logLimitMB : 0;
      const logLimitDays = this.state.logLimit ? fields.logLimitDays : 0;

      let antiAffinities: Array<FetchType.IAntiAffinityReq> = [];
      if (this.state.selfAntiAffinity) {
        antiAffinities.push({ resourceName: "", isSelf: 1 });
      }

      for (const antiAffinity of this.state.antiAffinities) {
        if (antiAffinity.length > 0) {
          antiAffinities.push({ resourceName: antiAffinity, isSelf: 0 });
        }
      }

      let obj: FetchType.ICreateServerReq = {
        clusterName: fields.clusterName,
        tarsIDCName: fields.idcName,
        tarsApplication: fields.tarsApplication,
        tarsSetName: fields.tarsSet || "",
        tarsServerName: fields.tarsServer,
        tarsServerType: fields.tarsServerType,
        tarsTemplateName: fields.tarsServerTemplate,
        asyncThreadNum: fields.asyncThread,
        replicas: fields.replicas,
        logicNodePoolId: fields.nodeResource,
        serviceAccount: fields.serviceAccount,
        costApps: fields.costApps,
        costJobCenters: fields.costJobCenters,
        costJob: fields.costJob,
        resource: {
          cpu: parseInt(resourceParts[0]),
          mem: parseInt(resourceParts[1]),
        },
        adapters: fields.adapters,
        statefulset: fields.statefulset ? 1 : 0,
        autoScaling: fields.autoscaling ? 1 : 0,
        masterSlave: fields.masterSlave ? 1 : 0,
        sharedStorageSubPaths: !isEmpty(fields.sharedStorageSubPath)
          ? [fields.sharedStorageSubPath]
          : [],
        logLimitMB,
        logLimitDays,
        antiAffinities,
        envs: Object.fromEntries(this.state.envs.entries()),
        podQOSClass: fields.podQOSClass,
        userName: this.props.currentUser || "",
        disableSchedule: Number(fields.disableSchedule),
      };

      const person: IPerson = {
        apprvoer: fields.apprvoer,
        developer: fields.developer,
        first_alarm: fields.first_alarm,
        second_alarm: fields.second_alarm,
        third_alarm: fields.third_alarm,
        visitor: fields.visitor,
      };

      if (
        person.first_alarm === person.second_alarm ||
        person.first_alarm === person.third_alarm ||
        (person.second_alarm && person.second_alarm === person.third_alarm)
      ) {
        message.warn("服务告警人不能出现重复");
        return;
      }

      await Promise.all([
        this.saveServerPerson(
          `${obj.tarsApplication}.${obj.tarsServerName}`,
          person
        ),
        fetch
          .fetchCreateServer(obj)
          .then((data) => {
            notification.success({
              message: "服务创建成功",
              description: `稍后在服务列表页刷新\n服务名:${
                fields.tarsApplication + "." + fields.tarsServer
              }\n资源名:${
                data.resourceName
              }\n服务上线一般需要等1~2分钟,机器资源不足时需要等5~10分钟`,
              duration: 30,
              style: { width: 500, whiteSpace: "pre-line" },
            });
          })
          .catch((_) => {}),
      ]);
    } catch {}
  }

  render() {
    const tarsServerTypeTemplateMap = new Map<string, string>([
      ["tars_cpp", "tars.default"],
      ["tars_nodejs", "tars.default"],
      ["tars_go", "tars.go.default"],
      ["tars_java", "tars.tarsjava.default"],
    ]);

    let idcName: string = "";
    let tarsApplications = Array<string>();
    let tarsServerTypes = Array<string>();
    let tarsServerTemplates = Array<string>();
    let tarsSets = Array<string>();
    let nodeResources = Array<FetchType.INodeResource>();
    let nodeRequests = Array<FetchType.INodeRequest>();
    let nodeCPUResources = Array<number>();
    let podQOSClasses = Array<string>();
    // servcie账户类型
    let serviceAccounts = Array<string>();

    // 花费类型
    let costJobCenters = Array<string>();

    // 服务类型
    let costJobs = Array<string>();
    let costApps = Array<string>();

    let tarsServers = Array<string>();
    // 灰度固定的 sourceid

    const clusters = Array.from(this.state.options.keys());

    // 选择了集群的情况下, 显示集群对应的选项
    const option = this.state.options.get(this.state.clusterName || "");

    if (option) {
      idcName = option.idcName;
      tarsApplications = option.tarsApplications;
      tarsServerTypes = option.tarsServerTypes;
      tarsServerTemplates = option.tarsServerTemplates;
      tarsSets = option.tarsSets;
      tarsServers = option.tarsServers;
      nodeResources = option.nodeResources;
      //  过滤选中的资源
      nodeRequests = option.nodeRequests
        .filter(
          (x) =>
            this.state.selectedResourceID &&
            x.logicNodePoolIDs.indexOf(this.state.selectedResourceID) !== -1
        )
        .sort((a, b) => a.cpu - b.cpu);
      nodeCPUResources = [...new Set(nodeRequests.map((item) => item.cpu))];
      podQOSClasses = option.podQOSClasses;
      serviceAccounts = option.serviceAccounts || [];
      costJobCenters = option.costJobCenters || [];
      costJobs = option.costJobs || [];
      costApps = option.costApps || [];
    }
    // 资源排序
    nodeResources = nodeResources.sort((a, b) => {
      return a.logicNodePoolID - b.logicNodePoolID;
    });

    // 应用新增
    const handleAddApplicationName = () => {
      const { customApplicationName } = this.state;
      if (customApplicationName) {
        if (
          customApplicationName.length > 1 &&
          customApplicationName.length < 10
        ) {
          if (/^[a-zA-Z_$][a-zA-Z0-9_$]*$/.test(customApplicationName)) {
            // @ts-ignore
            option.tarsApplications.push(customApplicationName);
            //
            this.setState({
              options: this.state.options,
              customApplicationName: "",
            });
          } else {
            message.warn("应用名命名不符合规范");
          }
        } else {
          message.warn("应用名长度需要在 1~10个字符数量之间");
        }
      }
    };

    return (
      <>
        <PageHeader
          title="创建服务"
          onBack={() => {
            this.props.navigator(-1);
          }}
        />
        {!this.state.loading && (
          <Form
            className="tars-create-server"
            name="createForm"
            ref={this.createForm}
            validateTrigger="onBlur"
            initialValues={{
              idcName,
              clusterName: this.state.clusterName,
              podQOSClass: podQOSClasses.length > 0 ? podQOSClasses[0] : null,
              replicas: 1,
              asyncThread: 4,
              sharedStorage: this.state.sharedStorage ? true : false,
              logLimit: true,
              logLimitMB: 1024,
              logLimitDays: 1,

              statefulset: false,
              autoscaling: false,
              masterSlave: false,
              selfAntiAffinity: false,
              serviceAccount:
                serviceAccounts.length > 0 ? serviceAccounts[0] : null,
              disableSchedule: false,
            }}
          >
            <Descriptions bordered>
              <DescriptionItem label="集群">
                <FormItem name="clusterName" className="desc-item">
                  <Select
                    placeholder="选集群"
                    value={this.state.clusterName}
                    onChange={(clusterName) => {
                      if (this.createForm.current) {
                        let idcNameToSet = "";
                        const optionToSet = this.state.options.get(
                          clusterName || ""
                        );
                        if (optionToSet) {
                          idcNameToSet = optionToSet.idcName;
                        }
                        this.createForm.current.resetFields();
                        this.createForm.current.setFieldsValue({
                          clusterName,
                          idcName: idcNameToSet,
                        });
                      }
                      this.setState({
                        clusterName,
                        inputEnvName: "",
                        inputEnvValue: "",
                        inputVisible: false,
                        envs: new Map(),
                      });
                    }}
                  >
                    {clusters.map((clusterName, index) => (
                      <SelectOption key={index} value={clusterName}>
                        {clusterName}
                      </SelectOption>
                    ))}
                  </Select>
                </FormItem>
              </DescriptionItem>

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem label="应用" span={2}>
                  <FormItem
                    name="tarsApplication"
                    className="desc-item"
                    rules={[{ required: true, message: "没选应用" }]}
                  >
                    <Select
                      placeholder="选应用"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space style={{ padding: "0 8px 4px" }}>
                            <Input
                              placeholder="Please enter item"
                              value={this.state.customApplicationName}
                              onChange={(val) =>
                                this.setState({
                                  customApplicationName: val.target.value,
                                })
                              }
                            />
                            <Button
                              type="primary"
                              icon={<PlusOutlined style={{ color: "#fff" }} />}
                              onClick={handleAddApplicationName}
                            >
                              新增应用
                            </Button>
                          </Space>
                        </>
                      )}
                    >
                      {tarsApplications.map((tarsApplication, index) => (
                        <SelectOption key={index} value={tarsApplication}>
                          {tarsApplication}
                        </SelectOption>
                      ))}
                    </Select>
                  </FormItem>
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem label="服务类型">
                  <FormItem
                    name="tarsServerType"
                    className="desc-item"
                    rules={[{ required: true, message: "没选服务类型" }]}
                  >
                    <Select
                      placeholder="选服务类型"
                      onChange={(tarsServerType) => {
                        if (this.createForm.current) {
                          this.createForm.current.setFieldsValue({
                            tarsServerTemplate:
                              tarsServerTypeTemplateMap.get(tarsServerType),
                          });
                        }
                      }}
                    >
                      {tarsServerTypes.map((tarsServerType, index) => (
                        <SelectOption key={index} value={tarsServerType}>
                          {tarsServerType}
                        </SelectOption>
                      ))}
                    </Select>
                  </FormItem>
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem label="服务模板配置" span={2}>
                  <FormItem
                    name="tarsServerTemplate"
                    className="desc-item"
                    rules={[{ required: true, message: "没选服务模板" }]}
                  >
                    <Select placeholder="选服务模板">
                      {tarsServerTemplates.map((tarsServerTemplate, index) => (
                        <SelectOption key={index} value={tarsServerTemplate}>
                          {tarsServerTemplate}
                        </SelectOption>
                      ))}
                    </Select>
                  </FormItem>
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem label="IDC">
                  <FormItem
                    name="idcName"
                    className="desc-item"
                    valuePropName="children"
                  >
                    <div />
                  </FormItem>
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem label="Set名" span={2}>
                  <FormItem name="tarsSet" className="desc-item">
                    <SetSelect
                      placeholder="选Set分组"
                      onChange={(tarsSetName) => this.setState({ tarsSetName })}
                      options={tarsSets.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </FormItem>
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem label="部署集" span={3}>
                  <FormItem
                    name="nodeResource"
                    className="desc-item"
                    rules={[{ required: true, message: "没选部署集" }]}
                  >
                    <Select
                      placeholder="选部署集"
                      size="large"
                      dropdownMatchSelectWidth={false}
                      style={{ width: "65vw" }}
                      onChange={(selectedResourceID) =>
                        this.setState({ selectedResourceID, currSelectMem: "" })
                      }
                    >
                      {nodeResources.map((nodeResource, index) => (
                        <SelectOption
                          key={index}
                          value={nodeResource.logicNodePoolID}
                        >
                          <Tag color="blue" style={{ marginLeft: 5 }}>
                            {nodeResource.description}
                          </Tag>

                          {nodeResource.nodeSelectorTerms.map(
                            (nodeSelectorTerm, index) => (
                              <Tag key={index} color="green">
                                {nodeSelectorTerm.constraintKey}=
                                {nodeSelectorTerm.constraintValue}
                              </Tag>
                            )
                          )}
                        </SelectOption>
                      ))}
                    </Select>
                  </FormItem>
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem
                  label="分配资源"
                  span={
                    this.state.selectedResourceID && !isEmpty(nodeRequests)
                      ? undefined
                      : 3
                  }
                >
                  {this.state.selectedResourceID && !isEmpty(nodeRequests) && (
                    <Row>
                      <Col span={10}>
                        <FormItem
                          name="requestResource"
                          className="desc-item"
                          wrapperCol={{ span: 24 }}
                          label="CPU Request"
                          rules={[{ required: true, message: "没选分配资源" }]}
                          style={{ width: "100%" }}
                        >
                          <Select
                            placeholder={"CPU Request"}
                            optionLabelProp="label"
                            dropdownMatchSelectWidth={false}
                            onChange={(value) => {
                              // const mem=value.split("x")[1];
                              this.setState({
                                currSelectMem: value,
                              });
                            }}
                          >
                            {nodeRequests.map((requestResource, index) => (
                              <SelectOption
                                key={index}
                                value={
                                  requestResource.cpu.toString() +
                                  "x" +
                                  requestResource.mem.toString()
                                }
                                label={<>{requestResource.cpu / 1000}核</>}
                              >
                                <span style={{ marginLeft: 5 }}>
                                  {requestResource.cpu / 1000}核
                                </span>
                              </SelectOption>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                      <Col span={10} offset={1}>
                        <FormItem
                          className="desc-item"
                          wrapperCol={{ span: 24 }}
                          label="Memory Limit"
                          style={{ width: "100%" }}
                        >
                          <Select
                            placeholder={"Memory Limit"}
                            optionLabelProp="label"
                            dropdownMatchSelectWidth={false}
                            disabled
                            value={this.state.currSelectMem}
                            className="select-mem"
                          >
                            {nodeRequests.map((requestResource, index) => (
                              <SelectOption
                                key={index}
                                value={
                                  requestResource.cpu.toString() +
                                  "x" +
                                  requestResource.mem.toString()
                                }
                                label={<> {requestResource.mem}Mi</>}
                              >
                                <span style={{ marginLeft: 5 }}>
                                  {requestResource.mem}Mi
                                </span>
                              </SelectOption>
                            ))}
                          </Select>
                        </FormItem>
                      </Col>
                    </Row>
                  )}

                  {!this.state.selectedResourceID &&
                    !isEmpty(nodeResources) && (
                      <Alert
                        message="先选部署集"
                        type="info"
                        showIcon
                        style={{ width: "30vw" }}
                      />
                    )}

                  {this.state.selectedResourceID && isEmpty(nodeRequests) && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{ height: 30 }}
                    />
                  )}
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) &&
                !isEmpty(nodeResources) &&
                this.state.selectedResourceID &&
                !isEmpty(nodeRequests) && (
                  <DescriptionItem label="服务优先级" span={2}>
                    <FormItem
                      name="podQOSClass"
                      className="desc-item"
                      label="QoS"
                      tooltip={<PodQosClassTooltip />}
                    >
                      <Select style={{ width: "100%" }}>
                        {podQOSClasses.map((podQOSClass, index) => (
                          <SelectOption key={index} value={podQOSClass}>
                            {podQOSClass}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormItem>
                  </DescriptionItem>
                )}

              {!isEmpty(nodeResources) && !isEmpty(nodeRequests) && (
                <DescriptionItem
                  label="服务名"
                  span={3}
                  style={{ height: 100 }}
                >
                  <FormItem
                    name="tarsServer"
                    className="desc-item"
                    rules={[
                      {
                        required: true,
                        message: "服务名格式错误",
                        pattern: /[a-zA-Z$_][a-zA-Z\d_]*/g,
                      },
                    ]}
                  >
                    <AutoComplete
                      options={this.state.searchOptions}
                      style={{ width: "30vw" }}
                      placeholder="搜索或添加.."
                      onSearch={(searchText) => {
                        let searchOptions: Array<{ value: string }> = [];
                        if (searchText.length >= 4) {
                          searchOptions = tarsServers
                            .filter(
                              (tarsServer) =>
                                tarsServer
                                  .toLowerCase()
                                  .indexOf(searchText.toLowerCase()) > -1
                            )
                            .map((value) => ({ value }));
                        }
                        this.setState({ searchOptions });
                      }}
                    />
                  </FormItem>
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem
                  label="服务配置"
                  span={3}
                  style={{ height: 100 }}
                >
                  <FormList
                    name="adapters"
                    initialValue={this.state.adapters}
                    children={(fields) => (
                      <>
                        {fields.map((field) => (
                          <div key={field.key}>
                            {
                              <Row>
                                <Col className="obj-item">
                                  <FormItem
                                    name={[field.name, "tarsServantObj"]}
                                    label="ServantObj"
                                    rules={[{ required: true }]}
                                    style={{ textAlign: "center" }}
                                  >
                                    <Input placeholder="eg: HelloObj" />
                                  </FormItem>
                                </Col>

                                <Col className="obj-item">
                                  <FormItem
                                    name={[field.name, "protocol"]}
                                    label="协议类型"
                                  >
                                    <Select
                                      style={{ width: 90 }}
                                      dropdownClassName="tool-btn"
                                      onChange={(value) => {
                                        if (value === "not_tars") {
                                          notification.info({
                                            message: "会自动创建与k8s同名的svc",
                                            description:
                                              "外网访问ingress需要配置域名指向svc",
                                            duration: 15,
                                          });
                                        }
                                      }}
                                    >
                                      <SelectOption key="tars" value="tars">
                                        TARS
                                      </SelectOption>
                                      <SelectOption
                                        key="not_tars"
                                        value="not_tars"
                                      >
                                        HTTP
                                      </SelectOption>
                                    </Select>
                                  </FormItem>
                                </Col>

                                <Col className="obj-item">
                                  <FormItem
                                    name={[field.name, "threadNum"]}
                                    label="线程数"
                                  >
                                    <InputNumber min={1} />
                                  </FormItem>
                                </Col>

                                <Col className="obj-item">
                                  <FormItem
                                    name={[field.name, "maxConn"]}
                                    label="最大连接"
                                  >
                                    <InputNumber min={1} />
                                  </FormItem>
                                </Col>

                                <Col className="obj-item">
                                  <FormItem
                                    name={[field.name, "maxQueue"]}
                                    label="队列长度"
                                  >
                                    <InputNumber min={1} />
                                  </FormItem>
                                </Col>

                                <Col className="obj-item">
                                  <FormItem
                                    name={[field.name, "maxQueueTimeout"]}
                                    label="队列超时ms"
                                  >
                                    <InputNumber min={1} />
                                  </FormItem>
                                </Col>

                                {field.key ===
                                  this.state.adapters.length - 1 && (
                                  <Col className="obj-item">
                                    <FormItem>
                                      <Button
                                        type="dashed"
                                        size="small"
                                        className="tool-btn"
                                        onClick={() => {
                                          let adapters = this.state.adapters;
                                          adapters.push(this.defaultAdapter());
                                          this.setState({ adapters });
                                          if (this.createForm.current)
                                            this.createForm.current.setFieldsValue(
                                              { adapters }
                                            );
                                        }}
                                      >
                                        <PlusCircleOutlined /> 新增一行
                                      </Button>
                                      {field.key !== 0 && (
                                        <Button
                                          type="dashed"
                                          size="small"
                                          className="tool-btn obj-item"
                                          onClick={() => {
                                            let adapters = this.state.adapters;
                                            adapters.splice(field.key, 1);
                                            this.setState({ adapters });
                                            if (this.createForm.current)
                                              this.createForm.current.setFieldsValue(
                                                { adapters }
                                              );
                                          }}
                                        >
                                          <DeleteTwoTone twoToneColor="#DC143C" />{" "}
                                          删除一行
                                        </Button>
                                      )}
                                    </FormItem>
                                  </Col>
                                )}
                              </Row>
                            }
                          </div>
                        ))}
                      </>
                    )}
                  />
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem label="自定义配置" span={3}>
                  <Row justify="space-between">
                    <Col>
                      <FormItem name="replicas" label="节点数">
                        <InputNumber min={1} />
                      </FormItem>
                    </Col>

                    <Col>
                      <FormItem name="asyncThread" label="异步线程">
                        <InputNumber min={1} />
                      </FormItem>
                    </Col>

                    <Col>
                      <FormItem
                        name="statefulset"
                        label="有状态服务"
                        valuePropName="checked"
                      >
                        <Switch size="small" />
                      </FormItem>
                    </Col>

                    <Col>
                      <FormItem
                        name="autoscaling"
                        label="自动伸缩"
                        valuePropName="checked"
                      >
                        <Switch size="small" />
                      </FormItem>
                    </Col>

                    <Col>
                      <FormItem
                        name="masterSlave"
                        label="主备模式"
                        valuePropName="checked"
                      >
                        <Switch
                          size="small"
                          onChange={(checked) => {
                            if (checked) {
                              notification.destroy();
                              notification.warning({
                                message: "主备模式只有一台机器接受流量",
                                description: "需要处理masterChanged命令",
                                duration: 15,
                              });
                            }
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col>
                      <FormItem
                        name="disableSchedule"
                        label="禁止调度"
                        valuePropName="checked"
                      >
                        <Switch
                          size="small"
                          onChange={(checked) => {
                            if (checked) {
                              notification.destroy();
                              notification.warning({
                                message:
                                  "打开后，集群将不再主动调度该服务所有节点，请谨慎打开！！！",
                                duration: 15,
                              });
                            }
                          }}
                        />
                      </FormItem>
                    </Col>
                    <Col>
                      <FormItem name="sharedStorageSubPath" label="共享存储">
                        <Input
                          placeholder="dir子路径.."
                          addonBefore={
                            <Switch
                              size="small"
                              onChange={(checked) => {
                                if (checked) {
                                  notification.destroy();
                                  notification.warning({
                                    message: `服务共享目录将挂载至/data/${"{dir}"}`,
                                    description:
                                      "不同服务填同一dir会挂载相同路劲",
                                    duration: 15,
                                  });
                                }
                                this.setState({ sharedStorage: checked });
                              }}
                            />
                          }
                          style={{ width: 200 }}
                          disabled={this.state.sharedStorage ? false : true}
                        />
                      </FormItem>
                    </Col>

                    <Col>
                      <>
                        日志清理:{" "}
                        <FormItem
                          name="logLimit"
                          valuePropName="checked"
                          noStyle
                        >
                          <Switch
                            size="small"
                            defaultChecked
                            onChange={(checked) =>
                              this.setState({ logLimit: checked })
                            }
                          />
                        </FormItem>
                      </>
                      <>
                        最大:{" "}
                        <FormItem name="logLimitMB" noStyle>
                          <InputNumber
                            min={1}
                            disabled={this.state.logLimit ? false : true}
                          />
                        </FormItem>
                        MB
                      </>
                      <>
                        最多:{" "}
                        <FormItem name="logLimitDays" noStyle>
                          <InputNumber
                            min={1}
                            disabled={this.state.logLimit ? false : true}
                          />
                        </FormItem>
                        天
                      </>
                    </Col>
                  </Row>
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem label="反亲和性" span={3}>
                  <Row justify="space-between">
                    <Col>
                      <FormItem
                        name="selfAntiAffinity"
                        label="自身打散"
                        valuePropName="checked"
                        tooltip="一台机器最多部署一个节点"
                      >
                        <Switch
                          size="small"
                          onChange={(selfAntiAffinity) =>
                            this.setState({ selfAntiAffinity })
                          }
                        />
                      </FormItem>
                    </Col>

                    <Col style={{ width: "60%" }}>
                      <FormItem
                        name="antiAffinities"
                        tooltip="不与选中服务部署同一台机器"
                        label="打散服务"
                      >
                        <TreeSelect
                          multiple
                          treeCheckable
                          treeDefaultExpandAll
                          treeData={this.state.treeData}
                          style={{ width: 400 }}
                          onSelect={(value: any, node: any) =>
                            this.onAntiAffinitiesSelect(node)
                          }
                          onDeselect={(value: any, node: any) =>
                            this.onAntiAffinitiesDeselect(node)
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem
                  className="tars-person-item"
                  label="服务人员配置"
                  span={6}
                >
                  <FormItem
                    name="apprvoer"
                    label="审批人"
                    valuePropName="checked"
                    rules={[{ required: true, message: "请选择审批人" }]}
                  >
                    <Select
                      options={this.state.userList}
                      showSearch
                      filterOption={(input, option) => {
                        if (typeof option === "object") {
                          return (
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                        return false;
                      }}
                    />
                  </FormItem>
                  <FormItem
                    name="developer"
                    label="开发者"
                    valuePropName="checked"
                    rules={[{ required: true, message: "请选择开发者" }]}
                  >
                    <Select
                      // style={{ width: 200 }}
                      mode="multiple"
                      options={this.state.userList}
                      showSearch
                      filterOption={(input, option) => {
                        if (typeof option === "object") {
                          return (
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                        return false;
                      }}
                    />
                  </FormItem>

                  <FormItem
                    name="first_alarm"
                    label="第一告警人"
                    valuePropName="checked"
                    rules={[{ required: true, message: "请选择第一告警人" }]}
                  >
                    <Select
                      options={this.state.userList}
                      showSearch
                      filterOption={(input, option) => {
                        if (typeof option === "object") {
                          return (
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                        return false;
                      }}
                    />
                  </FormItem>

                  <FormItem
                    name="second_alarm"
                    label="第二告警人"
                    valuePropName="checked"
                    rules={[{ required: true, message: "请选择第二告警人" }]}
                  >
                    <Select
                      options={this.state.userList}
                      showSearch
                      filterOption={(input, option) => {
                        if (typeof option === "object") {
                          return (
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                        return false;
                      }}
                    />
                  </FormItem>

                  <FormItem
                    name="third_alarm"
                    label="第三告警人"
                    valuePropName="checked"
                  >
                    <Select
                      options={this.state.userList}
                      showSearch
                      allowClear
                      filterOption={(input, option) => {
                        if (typeof option === "object") {
                          return (
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                        return false;
                      }}
                    />
                  </FormItem>

                  <FormItem
                    name="visitor"
                    label="关注人"
                    valuePropName="checked"
                  >
                    <Select
                      mode="multiple"
                      options={this.state.userList}
                      showSearch
                      filterOption={(input, option) => {
                        if (typeof option === "object") {
                          return (
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                        return false;
                      }}
                    />
                  </FormItem>
                </DescriptionItem>
              )}

              {!isEmpty(this.state.clusterName) && !isEmpty(nodeResources) && (
                <DescriptionItem label="自定义环境变量" span={3}>
                  <div style={{ marginBottom: 16, marginLeft: 20 }}>
                    <TweenOneGroup
                      enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: "from",
                        duration: 100,
                      }}
                      onEnd={(e) => {
                        if (e.type === "appear" || e.type === "enter") {
                          (e.target as any).style = "display: inline-block";
                        }
                      }}
                      leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                      appear={false}
                    >
                      {Array.from(this.state.envs).map((value) => (
                        <span
                          key={value[0]}
                          style={{ display: "inline-block" }}
                        >
                          <Tag
                            closable
                            onClose={(e) => {
                              e.preventDefault();
                              let envs = this.state.envs;
                              envs.delete(value[0]);
                              this.setState({ envs });
                            }}
                          >
                            {value[0]}={value[1]}
                          </Tag>
                        </span>
                      ))}

                      {this.state.inputVisible && (
                        <>
                          <span>
                            <Input
                              type="text"
                              size="small"
                              value={this.state.inputEnvName}
                              onChange={(e) =>
                                this.setState({ inputEnvName: e.target.value })
                              }
                              style={{ width: 78 }}
                            />
                            =
                            <TextArea
                              rows={1}
                              value={this.state.inputEnvValue}
                              onChange={(e) =>
                                this.setState({ inputEnvValue: e.target.value })
                              }
                              style={{ width: 200 }}
                            />
                          </span>
                          <span style={{ marginLeft: 10 }}>
                            <Button
                              className="tool-btn"
                              type="primary"
                              size="small"
                              ghost
                              style={{ marginLeft: 10 }}
                              onClick={() => this.onInputEnvConfirm()}
                            >
                              确认
                            </Button>
                            <Button
                              className="tool-btn"
                              type="default"
                              danger
                              size="small"
                              ghost
                              style={{ marginLeft: 10 }}
                              onClick={() => this.onInputEnvCancel()}
                            >
                              取消
                            </Button>
                          </span>
                        </>
                      )}

                      {!this.state.inputVisible && (
                        <Tag
                          onClick={() => this.setState({ inputVisible: true })}
                          className="site-tag-plus"
                        >
                          <PlusOutlined /> 添加
                        </Tag>
                      )}
                    </TweenOneGroup>
                  </div>
                </DescriptionItem>
              )}

              <DescriptionItem
                className="tars-person-item"
                label="服务成本"
                span={3}
              >
                <FormItem
                  name="costApps"
                  label="应用"
                  valuePropName="checked"
                  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    options={costApps?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    showSearch
                  />
                </FormItem>
                <FormItem
                  name="costJobCenters"
                  label="业务归属"
                  valuePropName="checked"
                  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    options={costJobCenters?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    showSearch
                  />
                </FormItem>
                <FormItem
                  name="costJob"
                  label="业务类型"
                  valuePropName="checked"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={costJobs?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    showSearch
                  />
                </FormItem>
              </DescriptionItem>

              <DescriptionItem
                className="tars-person-item"
                label="其他"
                span={3}
              >
                {this.props.admin && (
                  <FormItem
                    name="serviceAccount"
                    label="k8s serviceAccount"
                    valuePropName="checked"
                  >
                    <Select
                      options={serviceAccounts?.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      showSearch
                    />
                  </FormItem>
                )}
              </DescriptionItem>
            </Descriptions>

            {isEmpty(nodeResources) && (
              <Result
                status="500"
                title="无法创建服务"
                subTitle="该集群未配置资源"
              />
            )}
          </Form>
        )}

        {!this.state.loading &&
          nodeResources.length > 0 &&
          nodeRequests.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <Button
                size="large"
                type="primary"
                onClick={() => this.onSubmit()}
              >
                创建
              </Button>
            </div>
          )}

        {this.state.loading && (
          <div style={{ height: "100vh", textAlign: "center" }}>
            <Spin
              size="large"
              tip="加载中..."
              style={{ position: "relative", top: "50%" }}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  admin: state.tarsReducer.admin,
});

export default connect(mapStateToProps, null)(CreateServer);
