import { isEmpty } from "lodash";
import React from "react";
import {
  Button,
  Divider,
  Empty,
  InputNumber,
  Form,
  FormInstance,
  Space,
  Switch,
  Slider,
  Tabs,
  Modal,
  Result,
  PageHeader,
  Select,
  Popover,
} from "antd";
import {
  FullscreenOutlined,
  EditOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { RuleObject } from "rc-field-form/lib/interface";

import { connect } from "react-redux";
import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";
import { pickClusterName } from "src/utils/Picker";
import HPADrawer from "src/pages/DevOps/ToolsBar/HPADrawer";

import ApproveButton from "./ApproveButton";
import { ApproveKindKeyEnum } from "src/utils/constant";

const { TabPane } = Tabs;
const FormItem = Form.Item;

const adjustType: Record<string, number> = {
  Pods: 0,
  Percent: 1,
};

interface IProps {
  disabled: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  approveStatus: Number;
  updateApproveStatus: () => void;
  pendingApproveUser: string;
}

interface IAutoScalingItem {
  errCode: number;
  errMsg: string;
  disabled: number;
  minReplicas: number;
  maxReplicas: number;
  cpuMetric: FetchType.IAutoScalingMetric;
  memMetric: FetchType.IAutoScalingMetric;
  scaleUpBehavior: FetchType.IAutoScalingBehavior;
  scaleDownBehavior: FetchType.IAutoScalingBehavior;
  replicas: number;
}

interface IState {
  drawer: boolean;
  visible: boolean;
  clusterName: string;
  items: Map<string, IAutoScalingItem>;

  adjustedAutoscalingDisabled: boolean;
  adjustedMinReplicas: number;
  adjustedMaxReplicas: number;
  adjustedCPUMetric: FetchType.IAutoScalingMetric;
  adjustedMemoryMetric: FetchType.IAutoScalingMetric;
  adjustedScaleUpBehavior: FetchType.IAutoScalingBehavior;
  adjustedScaleDownBehavior: FetchType.IAutoScalingBehavior;

  allowEdit: boolean;
}

class AutoScaling extends React.Component<IProps, IState> {
  private forms: Map<string, React.RefObject<FormInstance>>;

  initialMetric(): FetchType.IAutoScalingMetric {
    return {
      disabled: 0,
      averageUtilization: 0,
    };
  }

  initialBehavior(): FetchType.IAutoScalingBehavior {
    return {
      disabled: 0,
      scalingValues: 0,
      periodSeconds: 0,
      stabilizationWindowSeconds: 0,
      scalingPolicy: adjustType.Pods,
    };
  }

  initialState(): IState {
    return {
      drawer: false,
      visible: false,
      clusterName: "",
      items: new Map<string, IAutoScalingItem>(),
      adjustedAutoscalingDisabled: true,
      adjustedMinReplicas: 0,
      adjustedMaxReplicas: 0,
      adjustedCPUMetric: this.initialMetric(),
      adjustedMemoryMetric: this.initialMetric(),
      adjustedScaleUpBehavior: this.initialBehavior(),
      adjustedScaleDownBehavior: this.initialBehavior(),
      allowEdit: false,
    };
  }

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState();
    this.forms = new Map<string, React.RefObject<FormInstance>>();
  }

  getOrCreateForm(clusterName: string): React.RefObject<FormInstance> {
    let ref = this.forms.get(clusterName);
    if (ref) {
      return ref;
    }
    ref = React.createRef<FormInstance>();
    this.forms.set(clusterName, React.createRef<FormInstance>());
    return ref;
  }

  fetchData() {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer)
      return;
    fetch
      .fetchListServerAutoScaling({
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
      })
      .then((data) => {
        let adjustedAutoscalingDisabled = true;
        let adjustedMinReplicas = 0;
        let adjustedMaxReplicas = 0;
        let adjustedCPUMetric = this.initialMetric();
        let adjustedMemoryMetric = this.initialMetric();
        let adjustedScaleUpBehavior = this.initialBehavior();
        let adjustedScaleDownBehavior = this.initialBehavior();

        let items = new Map<string, IAutoScalingItem>(
          Object.entries(data.items)
        );
        let clusterName = this.state.clusterName;
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(items.keys()));
        }
        let item = items.get(clusterName);
        if (item) {
          adjustedAutoscalingDisabled = item.disabled === 1 ? true : false;
          adjustedMinReplicas = item.minReplicas;
          adjustedMaxReplicas = item.maxReplicas;
          adjustedCPUMetric = item.cpuMetric;
          adjustedMemoryMetric = item.memMetric;
          adjustedScaleUpBehavior = item.scaleUpBehavior;
          adjustedScaleDownBehavior = item.scaleDownBehavior;
        }

        this.setState({
          visible: true,
          clusterName,
          items,
          adjustedAutoscalingDisabled,
          adjustedMinReplicas,
          adjustedMaxReplicas,
          adjustedCPUMetric,
          adjustedMemoryMetric,
          adjustedScaleUpBehavior,
          adjustedScaleDownBehavior,
        });
      })
      .catch(() => {});
  }

  fetchAddOrModifyServerAutoScaling() {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer)
      return;
    const disabled = this.state.adjustedAutoscalingDisabled ? 1 : 0;
    const minReplicas = this.state.adjustedMinReplicas;
    const maxReplicas = this.state.adjustedMaxReplicas;
    const cpuMetric = this.state.adjustedCPUMetric;
    const memMetric = this.state.adjustedMemoryMetric;
    const scaleUpBehavior = this.state.adjustedScaleUpBehavior;
    const scaleDownBehavior = this.state.adjustedScaleDownBehavior;

    fetch
      .fetchAddOrModifyServerAutoScaling({
        clusterName: this.state.clusterName,
        userName: this.props.currentUser || "",
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        disabled,
        minReplicas,
        maxReplicas,
        cpuMetric,
        memMetric,
        scaleUpBehavior,
        scaleDownBehavior,
      })
      .then(() => this.setState(this.initialState()))
      .catch(() => {})
      .finally(() => {
        this.props.updateApproveStatus();
      });
  }

  render() {
    const validateMetric = (rule: RuleObject, value: any): Promise<any> => {
      const form = this.forms.get(this.state.clusterName);
      if (!form?.current) return Promise.reject("未知错误");

      const adjustedAutoscalingChecked = form.current.getFieldValue(
        "adjustedAutoscalingChecked"
      );
      if (adjustedAutoscalingChecked === false) return Promise.resolve();

      const adjustedScaleUpBehaviorChecked = form.current.getFieldValue(
        "adjustedScaleUpBehaviorChecked"
      );
      const adjustedScaleDownBehaviorChecked = form.current.getFieldValue(
        "adjustedScaleDownBehaviorChecked"
      );
      if (
        adjustedScaleUpBehaviorChecked === false &&
        adjustedScaleDownBehaviorChecked === false
      ) {
        return Promise.reject("扩容和缩容指标未开启");
      }

      const cpuMetricChecked = form.current.getFieldValue("cpuMetricChecked");
      const memoryMetricChecked = form.current.getFieldValue(
        "memoryMetricChecked"
      );
      if (cpuMetricChecked === false && memoryMetricChecked === false)
        return Promise.reject("CPU和内存指标必须二选一");

      return Promise.resolve();
    };

    const validateBehavior = (rule: RuleObject, value: any): Promise<any> => {
      const form = this.forms.get(this.state.clusterName);
      if (!form?.current) return Promise.reject("未知错误");
      const adjustedAutoscalingChecked = form.current.getFieldValue(
        "adjustedAutoscalingChecked"
      );
      if (adjustedAutoscalingChecked === false) return Promise.resolve();

      const adjustedScaleUpBehaviorChecked = form.current.getFieldValue(
        "adjustedScaleUpBehaviorChecked"
      );
      const adjustedScaleDownBehaviorChecked = form.current.getFieldValue(
        "adjustedScaleDownBehaviorChecked"
      );
      if (
        adjustedScaleUpBehaviorChecked === false &&
        adjustedScaleDownBehaviorChecked === false
      ) {
        return Promise.reject("扩容和缩容必须二选一");
      }
      return Promise.resolve();
    };

    return (
      <>
        <Popover
          placement="left"
          content={
            <div>
              <Button
                ghost
                type="primary"
                disabled={this.props.disabled}
                size="small"
                className="tool-btn"
                onClick={() => {
                  this.setState({ allowEdit: false });
                  this.fetchData();
                }}
                style={{ marginBottom: "10px" }}
              >
                <Space>
                  <EyeTwoTone />
                  查看配置
                </Space>
              </Button>
              <ApproveButton
                kind={ApproveKindKeyEnum.autoScaling}
                approveStatus={this.props.approveStatus}
                updateApproveStatus={this.props.updateApproveStatus}
                pendingApproveUser={this.props.pendingApproveUser}
              >
                <Button
                  disabled={this.props.disabled}
                  className="tool-btn"
                  size="small"
                  onClick={() => {
                    this.setState({ allowEdit: true });
                    this.fetchData();
                  }}
                >
                  <Space>
                    <EditOutlined />
                    编辑配置
                  </Space>
                </Button>
              </ApproveButton>
            </div>
          }
        >
          <Button
            disabled={this.props.disabled}
            className="tool-btn"
            size="small"
          >
            <Space>
              <FullscreenOutlined />
              自动扩容
            </Space>
          </Button>
        </Popover>

        <Modal
          closable={false}
          visible={this.state.visible}
          width="60%"
          title={
            <PageHeader
              title="自动扩缩容"
              subTitle="(根据CPU或内存自动扩容)"
              extra={
                <Button
                  size="small"
                  type="primary"
                  className="tool-btn"
                  onClick={() => this.setState({ drawer: true })}
                >
                  与【定时扩容】兼容说明
                </Button>
              }
            />
          }
          destroyOnClose
          footer={[
            <Button
              type="primary"
              ghost
              className="tool-btn"
              onClick={() => this.setState(this.initialState())}
            >
              取消
            </Button>,
            <Button
              disabled={!this.state.allowEdit}
              danger
              ghost
              className="tool-btn"
              onClick={async () => {
                try {
                  const form = this.forms.get(this.state.clusterName);
                  if (form?.current) {
                    const fields = await form.current.validateFields();
                    let adjustedCPUMetric = this.state.adjustedCPUMetric;
                    let adjustedMemoryMetric = this.state.adjustedMemoryMetric;
                    let adjustedMinReplicas = this.state.adjustedMinReplicas;
                    let adjustedMaxReplicas = this.state.adjustedMaxReplicas;
                    let adjustedScaleUpBehavior =
                      this.state.adjustedScaleUpBehavior;
                    let adjustedScaleDownBehavior =
                      this.state.adjustedScaleDownBehavior;
                    if (fields.adjustedAutoscalingMinMax) {
                      adjustedMinReplicas = fields.adjustedAutoscalingMinMax[0];
                      adjustedMaxReplicas = fields.adjustedAutoscalingMinMax[1];
                    } else {
                      adjustedMinReplicas = 1;
                      const item = this.state.items.get(this.state.clusterName);
                      if (item) {
                        adjustedMaxReplicas = Math.floor(item.replicas * 1.5);
                      }
                    }

                    if (fields.adjustedScaleUpBehaviorChecked) {
                      adjustedScaleUpBehavior.disabled = 0;
                      adjustedScaleUpBehavior.scalingValues =
                        fields.scaleUpPods;
                      adjustedScaleUpBehavior.periodSeconds =
                        fields.scaleUpPeriodSeconds;
                      adjustedScaleUpBehavior.stabilizationWindowSeconds =
                        fields.scaleUpStabilizationWindowSeconds;
                      adjustedScaleUpBehavior.scalingPolicy =
                        fields.adjustedScaleUpBehaviorType;
                    } else {
                      adjustedScaleUpBehavior.disabled = 1;
                      adjustedScaleUpBehavior.scalingValues = 1;
                      adjustedScaleUpBehavior.periodSeconds = 300;
                      adjustedScaleUpBehavior.stabilizationWindowSeconds = 300;
                      adjustedScaleUpBehavior.scalingPolicy = adjustType.Pods;
                    }

                    if (fields.adjustedScaleDownBehaviorChecked) {
                      adjustedScaleDownBehavior.disabled = 0;
                      adjustedScaleDownBehavior.scalingValues =
                        fields.scaleDownPods;
                      adjustedScaleDownBehavior.periodSeconds =
                        fields.scaleDownPeriodSeconds;
                      adjustedScaleDownBehavior.stabilizationWindowSeconds =
                        fields.scaleDownStabilizationWindowSeconds;
                      adjustedScaleDownBehavior.scalingPolicy =
                        fields.adjustedScaleDownBehaviorType;
                    } else {
                      adjustedScaleDownBehavior.disabled = 1;
                      adjustedScaleDownBehavior.scalingValues = 1;
                      adjustedScaleDownBehavior.periodSeconds = 300;
                      adjustedScaleDownBehavior.stabilizationWindowSeconds = 300;
                      adjustedScaleDownBehavior.scalingPolicy = adjustType.Pods;
                    }

                    const adjustedAutoscalingDisabled =
                      fields.adjustedAutoscalingChecked ? false : true;
                    adjustedCPUMetric.disabled = fields.cpuMetricChecked
                      ? 0
                      : 1;
                    adjustedMemoryMetric.disabled = fields.memoryMetricChecked
                      ? 0
                      : 1;
                    adjustedCPUMetric.averageUtilization =
                      fields.adjustedCPUAverageUtilization
                        ? fields.adjustedCPUAverageUtilization
                        : 80;
                    adjustedMemoryMetric.averageUtilization =
                      fields.adjustedMemoryAverageUtilization
                        ? fields.adjustedMemoryAverageUtilization
                        : 80;
                    this.setState(
                      {
                        adjustedAutoscalingDisabled,
                        adjustedMinReplicas,
                        adjustedMaxReplicas,
                        adjustedCPUMetric,
                        adjustedMemoryMetric,
                        adjustedScaleUpBehavior,
                        adjustedScaleDownBehavior,
                      },
                      () => this.fetchAddOrModifyServerAutoScaling()
                    );
                  }
                } catch {}
              }}
            >
              提交
            </Button>,
          ]}
        >
          {this.state.items.size > 0 && (
            <Tabs
              activeKey={this.state.clusterName}
              type="card"
              size="small"
              onChange={(clusterName) => {
                const items = this.state.items;
                let currItem = items.get(clusterName) as IAutoScalingItem;

                this.setState({
                  clusterName,
                  adjustedAutoscalingDisabled:
                    currItem.disabled === 1 ? true : false,
                  adjustedMinReplicas: currItem.minReplicas,
                  adjustedMaxReplicas: currItem.maxReplicas,
                  adjustedCPUMetric: currItem.cpuMetric,
                  adjustedMemoryMetric: currItem.memMetric,
                  adjustedScaleUpBehavior: currItem.scaleUpBehavior,
                  adjustedScaleDownBehavior: currItem.scaleDownBehavior,
                });
                const form = this.getOrCreateForm(clusterName);
                form.current?.setFieldsValue({
                  replicas: currItem.replicas,
                  autoscalingChecked: currItem.disabled === 1 ? false : true,
                  autoscalingMinMax: [
                    currItem.minReplicas,
                    currItem.maxReplicas,
                  ],
                  scaleUpBehaviorChecked:
                    currItem.scaleUpBehavior.disabled === 0,
                  scaleDownBehaviorChecked:
                    currItem.scaleDownBehavior.disabled === 0,
                  cpuAverageUtilization: currItem.cpuMetric.averageUtilization,
                  memoryAverageUtilization:
                    currItem.memMetric.averageUtilization,

                  adjustedAutoscalingChecked: !(currItem.disabled === 1
                    ? true
                    : false),
                  adjustedAutoscalingMinMax: [
                    currItem.minReplicas > 0
                      ? currItem.minReplicas
                      : Math.max(1, currItem.replicas - 10),
                    currItem.maxReplicas > 0
                      ? currItem.maxReplicas
                      : currItem.replicas + 10,
                  ],

                  adjustedScaleUpBehaviorChecked:
                    currItem.scaleUpBehavior.disabled === 1 ? false : true,
                  scaleUpPeriodSeconds:
                    currItem.scaleUpBehavior.periodSeconds > 0
                      ? currItem.scaleUpBehavior.periodSeconds
                      : 300,
                  scaleUpPods:
                    currItem.scaleUpBehavior.scalingValues > 0
                      ? currItem.scaleUpBehavior.scalingValues
                      : 1,
                  scaleUpStabilizationWindowSeconds:
                    currItem.scaleUpBehavior.stabilizationWindowSeconds > 0
                      ? currItem.scaleUpBehavior.stabilizationWindowSeconds
                      : 300,

                  adjustedScaleDownBehaviorChecked:
                    currItem.scaleDownBehavior.disabled === 1 ? false : true,
                  scaleDownPeriodSeconds:
                    currItem.scaleDownBehavior.periodSeconds > 0
                      ? currItem.scaleDownBehavior.periodSeconds
                      : 300,
                  scaleDownPods:
                    currItem.scaleDownBehavior.scalingValues > 0
                      ? currItem.scaleDownBehavior.scalingValues
                      : 1,
                  scaleDownStabilizationWindowSeconds:
                    currItem.scaleDownBehavior.stabilizationWindowSeconds > 0
                      ? currItem.scaleDownBehavior.stabilizationWindowSeconds
                      : 300,

                  cpuMetricChecked:
                    currItem.cpuMetric.disabled === 1 ? false : true,
                  memoryMetricChecked:
                    currItem.memMetric.disabled === 1 ? false : true,
                  adjustedCPUAverageUtilization:
                    currItem.cpuMetric.averageUtilization > 10
                      ? currItem.cpuMetric.averageUtilization
                      : 80,
                  adjustedMemoryAverageUtilization:
                    currItem.memMetric.averageUtilization > 10
                      ? currItem.memMetric.averageUtilization
                      : 80,
                  adjustedScaleUpBehaviorType:
                    currItem.scaleUpBehavior.scalingPolicy,
                  adjustedScaleDownBehaviorType:
                    currItem.scaleDownBehavior.scalingPolicy,
                });
              }}
              destroyInactiveTabPane={false}
            >
              {Array.from(this.state.items).map((values) => {
                let form = this.getOrCreateForm(values[0]);
                return (
                  <TabPane tab={values[0]} key={values[0]} forceRender={true}>
                    {values[1].errCode !== 0 && (
                      <Result
                        status="error"
                        title="无法配置自动扩容"
                        subTitle={
                          "errCode:" +
                          values[1].errCode +
                          " errMsg:" +
                          values[1].errMsg
                        }
                      />
                    )}

                    {values[1].errCode === 0 && (
                      <Form
                        name="autoscalingForm"
                        ref={form}
                        initialValues={{
                          replicas: values[1].replicas,
                          autoscalingChecked:
                            values[1].disabled === 1 ? false : true,
                          autoscalingMinMax: [
                            values[1].minReplicas,
                            values[1].maxReplicas,
                          ],
                          scaleUpBehaviorChecked:
                            values[1].scaleUpBehavior.disabled === 0,
                          scaleDownBehaviorChecked:
                            values[1].scaleDownBehavior.disabled === 0,
                          cpuAverageUtilization:
                            values[1].cpuMetric.averageUtilization,
                          memoryAverageUtilization:
                            values[1].memMetric.averageUtilization,

                          adjustedAutoscalingChecked:
                            !this.state.adjustedAutoscalingDisabled,
                          adjustedAutoscalingMinMax: [
                            values[1].minReplicas > 0
                              ? values[1].minReplicas
                              : Math.max(1, values[1].replicas - 10),
                            values[1].maxReplicas > 0
                              ? values[1].maxReplicas
                              : values[1].replicas + 10,
                          ],

                          adjustedScaleUpBehaviorChecked:
                            this.state.adjustedScaleUpBehavior.disabled === 1
                              ? false
                              : true,
                          scaleUpPeriodSeconds:
                            this.state.adjustedScaleUpBehavior.periodSeconds > 0
                              ? this.state.adjustedScaleUpBehavior.periodSeconds
                              : 300,
                          scaleUpPods:
                            this.state.adjustedScaleUpBehavior.scalingValues > 0
                              ? this.state.adjustedScaleUpBehavior.scalingValues
                              : 1,
                          scaleUpStabilizationWindowSeconds:
                            this.state.adjustedScaleUpBehavior
                              .stabilizationWindowSeconds > 0
                              ? this.state.adjustedScaleUpBehavior
                                  .stabilizationWindowSeconds
                              : 300,

                          adjustedScaleDownBehaviorChecked:
                            this.state.adjustedScaleDownBehavior.disabled === 1
                              ? false
                              : true,
                          scaleDownPeriodSeconds:
                            this.state.adjustedScaleDownBehavior.periodSeconds >
                            0
                              ? this.state.adjustedScaleDownBehavior
                                  .periodSeconds
                              : 300,
                          scaleDownPods:
                            this.state.adjustedScaleDownBehavior.scalingValues >
                            0
                              ? this.state.adjustedScaleDownBehavior
                                  .scalingValues
                              : 1,
                          scaleDownStabilizationWindowSeconds:
                            this.state.adjustedScaleDownBehavior
                              .stabilizationWindowSeconds > 0
                              ? this.state.adjustedScaleDownBehavior
                                  .stabilizationWindowSeconds
                              : 300,

                          cpuMetricChecked:
                            this.state.adjustedCPUMetric.disabled === 1
                              ? false
                              : true,
                          memoryMetricChecked:
                            this.state.adjustedMemoryMetric.disabled === 1
                              ? false
                              : true,
                          adjustedCPUAverageUtilization:
                            this.state.adjustedCPUMetric.averageUtilization > 10
                              ? this.state.adjustedCPUMetric.averageUtilization
                              : 80,
                          adjustedMemoryAverageUtilization:
                            this.state.adjustedMemoryMetric.averageUtilization >
                            10
                              ? this.state.adjustedMemoryMetric
                                  .averageUtilization
                              : 80,
                          adjustedScaleUpBehaviorType:
                            this.state.adjustedScaleUpBehavior.scalingPolicy,
                          adjustedScaleDownBehaviorType:
                            this.state.adjustedScaleDownBehavior.scalingPolicy,
                        }}
                        labelCol={{ span: 4 }}
                        validateTrigger="onBlur"
                      >
                        <FormItem
                          name="replicas"
                          valuePropName="children"
                          label="期望节点数"
                        >
                          <div />
                        </FormItem>

                        {this.state.adjustedAutoscalingDisabled !==
                          (values[1].disabled === 1) && (
                          <FormItem
                            name="autoscalingChecked"
                            valuePropName="checked"
                            label="调整前状态"
                          >
                            <Switch size="small" disabled />
                          </FormItem>
                        )}

                        {!this.state.adjustedAutoscalingDisabled &&
                          values[1].disabled === 0 &&
                          (this.state.adjustedMinReplicas !==
                            values[1].minReplicas ||
                            this.state.adjustedMaxReplicas !==
                              values[1].maxReplicas) && (
                            <FormItem
                              name="autoscalingMinMax"
                              label="调整前伸缩区间"
                            >
                              <Slider
                                range={true}
                                max={values[1].maxReplicas + 30}
                                disabled
                              />
                            </FormItem>
                          )}

                        {!this.state.adjustedAutoscalingDisabled &&
                          values[1].disabled === 0 &&
                          values[1].scaleUpBehavior.disabled !==
                            this.state.adjustedScaleUpBehavior.disabled && (
                            <FormItem
                              name="scaleUpBehaviorChecked"
                              valuePropName="checked"
                              label="调整前自动扩容"
                            >
                              <Switch size="small" disabled />
                            </FormItem>
                          )}

                        {!this.state.adjustedAutoscalingDisabled &&
                          values[1].disabled === 0 &&
                          values[1].scaleDownBehavior.disabled !==
                            this.state.adjustedScaleDownBehavior.disabled && (
                            <FormItem
                              name="scaleDownBehaviorChecked"
                              valuePropName="checked"
                              label="调整前自动缩容"
                            >
                              <Switch size="small" disabled />
                            </FormItem>
                          )}

                        {!this.state.adjustedAutoscalingDisabled &&
                          this.state.adjustedCPUMetric.disabled === 0 &&
                          values[1].cpuMetric.disabled === 0 &&
                          this.state.adjustedCPUMetric.averageUtilization !==
                            values[1].cpuMetric.averageUtilization && (
                            <FormItem
                              name="cpuAverageUtilization"
                              label="调整前CPU指标"
                            >
                              <InputNumber
                                size="small"
                                disabled
                                addonAfter="%"
                              />
                            </FormItem>
                          )}

                        {!this.state.adjustedAutoscalingDisabled &&
                          this.state.adjustedMemoryMetric.disabled === 0 &&
                          values[1].memMetric.disabled === 0 &&
                          this.state.adjustedMemoryMetric.averageUtilization !==
                            values[1].memMetric.averageUtilization && (
                            <FormItem
                              name="memoryAverageUtilization"
                              label="调整前内存指标"
                            >
                              <InputNumber
                                size="small"
                                disabled
                                addonAfter="%"
                              />
                            </FormItem>
                          )}
                        <Divider orientation="left">调整后</Divider>

                        <FormItem
                          name="adjustedAutoscalingChecked"
                          valuePropName="checked"
                          label="状态"
                        >
                          <Switch
                            size="small"
                            onChange={(checked) => {
                              this.setState({
                                adjustedAutoscalingDisabled: !checked,
                              });
                              if (form.current)
                                form.current.setFieldsValue({
                                  adjustedAutoscalingChecked: checked,
                                });
                            }}
                          />
                        </FormItem>

                        {!this.state.adjustedAutoscalingDisabled && (
                          <FormItem
                            name="adjustedAutoscalingMinMax"
                            label="伸缩区间"
                            tooltip="最小/最大节点数"
                          >
                            <Slider
                              range={true}
                              min={1}
                              max={
                                values[1].replicas < 5
                                  ? 100
                                  : values[1].replicas * 10 <
                                    this.state.adjustedMaxReplicas
                                  ? this.state.adjustedMaxReplicas + 30
                                  : values[1].replicas * 10
                              }
                              onChange={(value) => {
                                this.setState({
                                  adjustedMinReplicas: value[0],
                                  adjustedMaxReplicas: value[1],
                                });
                                if (form.current)
                                  form.current.setFieldsValue({
                                    adjustedAutoscalingMinMax: value,
                                  });
                              }}
                            />
                          </FormItem>
                        )}

                        {!this.state.adjustedAutoscalingDisabled && (
                          <>
                            <Divider />
                            <>
                              <FormItem
                                name="adjustedScaleUpBehaviorChecked"
                                valuePropName="checked"
                                label="自动扩容"
                                rules={[
                                  {
                                    required: true,
                                    validator: validateBehavior,
                                  },
                                ]}
                              >
                                <Switch
                                  size="small"
                                  onChange={(checked) => {
                                    let adjustedScaleUpBehavior = {
                                      ...this.state.adjustedScaleUpBehavior,
                                    };
                                    adjustedScaleUpBehavior.disabled = checked
                                      ? 0
                                      : 1;
                                    this.setState({ adjustedScaleUpBehavior });
                                    if (form.current)
                                      form.current.setFieldsValue({
                                        adjustedScaleUpBehaviorChecked: checked,
                                      });
                                  }}
                                />
                              </FormItem>

                              {!this.state.adjustedAutoscalingDisabled &&
                                this.state.adjustedScaleUpBehavior.disabled !==
                                  1 && (
                                  <FormItem
                                    name="adjustedScaleUpBehaviorType"
                                    label="扩容类型"
                                    rules={[
                                      {
                                        required: true,
                                        message: "请选择扩容类型",
                                      },
                                    ]}
                                    extra="Pod: 按数量扩容；Percent: 按百分比扩容"
                                    wrapperCol={{
                                      span: 6,
                                    }}
                                  >
                                    <Select
                                      size="small"
                                      onChange={(value) => {
                                        let adjustedScaleUpBehavior = {
                                          ...this.state.adjustedScaleUpBehavior,
                                        };
                                        adjustedScaleUpBehavior.scalingPolicy =
                                          value;
                                        this.setState({
                                          adjustedScaleUpBehavior,
                                        });
                                        if (form.current)
                                          form.current.setFieldsValue({
                                            adjustedScaleUpBehaviorType: value,
                                          });
                                      }}
                                    >
                                      {
                                        // @ts-ignore
                                        Object.keys(adjustType).map((key) => (
                                          <Select.Option
                                            value={adjustType[key]}
                                            key={adjustType[key]}
                                          >
                                            {key}
                                          </Select.Option>
                                        ))
                                      }
                                    </Select>
                                  </FormItem>
                                )}

                              {!this.state.adjustedAutoscalingDisabled &&
                                this.state.adjustedScaleUpBehavior.disabled !==
                                  1 && (
                                  <FormItem
                                    name="scaleUpStabilizationWindowSeconds"
                                    label="稳定窗口(s)"
                                    tooltip="当扩缩容时,自动伸缩算法将会查找时间窗口内计算出来的目标伸缩副本数。缩容时使用最大值, 扩容时使用最小值作为最终结果。"
                                  >
                                    <InputNumber
                                      size="small"
                                      min={1}
                                      onChange={(value) => {
                                        let adjustedScaleUpBehavior = {
                                          ...this.state.adjustedScaleUpBehavior,
                                        };
                                        adjustedScaleUpBehavior.stabilizationWindowSeconds =
                                          value;
                                        this.setState({
                                          adjustedScaleUpBehavior,
                                        });
                                        if (form.current)
                                          form.current.setFieldsValue({
                                            scaleUpStabilizationWindowSeconds:
                                              value,
                                          });
                                      }}
                                    />
                                  </FormItem>
                                )}

                              {!this.state.adjustedAutoscalingDisabled &&
                                this.state.adjustedScaleUpBehavior.disabled !==
                                  1 && (
                                  <FormItem
                                    name="scaleUpPeriodSeconds"
                                    label="每次扩容耗时(s)"
                                  >
                                    <InputNumber
                                      size="small"
                                      min={1}
                                      onChange={(value) => {
                                        let adjustedScaleUpBehavior = {
                                          ...this.state.adjustedScaleUpBehavior,
                                        };
                                        adjustedScaleUpBehavior.periodSeconds =
                                          value;
                                        this.setState({
                                          adjustedScaleUpBehavior,
                                        });
                                        if (form.current)
                                          form.current.setFieldsValue({
                                            scaleUpPeriodSeconds: value,
                                          });
                                      }}
                                    />
                                  </FormItem>
                                )}

                              {!this.state.adjustedAutoscalingDisabled &&
                                this.state.adjustedScaleUpBehavior.disabled !==
                                  1 && (
                                  <FormItem
                                    name="scaleUpPods"
                                    label={
                                      this.state.adjustedScaleUpBehavior
                                        ?.scalingPolicy === adjustType.Percent
                                        ? "每次扩容百分比"
                                        : "每次扩容数量"
                                    }
                                  >
                                    <InputNumber
                                      size="small"
                                      min={1}
                                      addonAfter={
                                        this.state.adjustedScaleUpBehavior
                                          ?.scalingPolicy === adjustType.Percent
                                          ? "%"
                                          : "个"
                                      }
                                      onChange={(value) => {
                                        let adjustedScaleUpBehavior = {
                                          ...this.state.adjustedScaleUpBehavior,
                                        };
                                        adjustedScaleUpBehavior.scalingValues =
                                          value;
                                        this.setState({
                                          adjustedScaleUpBehavior,
                                        });
                                        if (form.current)
                                          form.current.setFieldsValue({
                                            scaleUpPods: value,
                                          });
                                      }}
                                    />
                                  </FormItem>
                                )}
                            </>

                            <Divider />
                            <FormItem
                              name="adjustedScaleDownBehaviorChecked"
                              valuePropName="checked"
                              label="自动缩容"
                              rules={[
                                { required: true, validator: validateBehavior },
                              ]}
                            >
                              <Switch
                                size="small"
                                onChange={(checked) => {
                                  let adjustedScaleDownBehavior = {
                                    ...this.state.adjustedScaleDownBehavior,
                                  };
                                  adjustedScaleDownBehavior.disabled = checked
                                    ? 0
                                    : 1;
                                  this.setState({ adjustedScaleDownBehavior });
                                  if (form.current)
                                    form.current.setFieldsValue({
                                      adjustedScaleDownBehaviorChecked: checked,
                                    });
                                }}
                              />
                            </FormItem>

                            {!this.state.adjustedAutoscalingDisabled &&
                              this.state.adjustedScaleDownBehavior.disabled !==
                                1 && (
                                <FormItem
                                  name="adjustedScaleDownBehaviorType"
                                  label="缩容类型"
                                  rules={[
                                    {
                                      required: true,
                                      message: "请选择缩容类型",
                                    },
                                  ]}
                                  extra="Pod: 按数量缩容；Percent: 按百分比缩容"
                                  wrapperCol={{
                                    span: 6,
                                  }}
                                >
                                  <Select
                                    size="small"
                                    onChange={(value) => {
                                      let adjustedScaleDownBehavior = {
                                        ...this.state.adjustedScaleDownBehavior,
                                      };
                                      adjustedScaleDownBehavior.scalingPolicy =
                                        value;
                                      this.setState({
                                        adjustedScaleDownBehavior,
                                      });
                                      if (form.current)
                                        form.current.setFieldsValue({
                                          adjustedScaleDownBehaviorType: value,
                                        });
                                    }}
                                  >
                                    {
                                      // @ts-ignore
                                      Object.keys(adjustType).map((key) => (
                                        <Select.Option
                                          value={adjustType[key]}
                                          key={adjustType[key]}
                                        >
                                          {key}
                                        </Select.Option>
                                      ))
                                    }
                                  </Select>
                                </FormItem>
                              )}

                            {!this.state.adjustedAutoscalingDisabled &&
                              this.state.adjustedScaleDownBehavior.disabled !==
                                1 && (
                                <FormItem
                                  name="scaleDownStabilizationWindowSeconds"
                                  label="稳定窗口(s)"
                                  tooltip="当扩缩容时,自动伸缩算法将会查找时间窗口内计算出来的目标伸缩副本数。缩容时使用最大值, 扩容时使用最小值作为最终结果。"
                                >
                                  <InputNumber
                                    size="small"
                                    min={1}
                                    onChange={(value) => {
                                      let adjustedScaleDownBehavior = {
                                        ...this.state.adjustedScaleDownBehavior,
                                      };
                                      adjustedScaleDownBehavior.stabilizationWindowSeconds =
                                        value;
                                      this.setState({
                                        adjustedScaleDownBehavior,
                                      });
                                      if (form.current)
                                        form.current.setFieldsValue({
                                          scaleDownStabilizationWindowSeconds:
                                            value,
                                        });
                                    }}
                                  />
                                </FormItem>
                              )}

                            {!this.state.adjustedAutoscalingDisabled &&
                              this.state.adjustedScaleDownBehavior.disabled !==
                                1 && (
                                <FormItem
                                  name="scaleDownPeriodSeconds"
                                  label="每次缩容耗时(s)"
                                >
                                  <InputNumber
                                    size="small"
                                    min={1}
                                    onChange={(value) => {
                                      let adjustedScaleDownBehavior = {
                                        ...this.state.adjustedScaleDownBehavior,
                                      };
                                      adjustedScaleDownBehavior.periodSeconds =
                                        value;
                                      this.setState({
                                        adjustedScaleDownBehavior,
                                      });
                                      if (form.current)
                                        form.current.setFieldsValue({
                                          scaleDownPeriodSeconds: value,
                                        });
                                    }}
                                  />
                                </FormItem>
                              )}

                            {!this.state.adjustedAutoscalingDisabled &&
                              this.state.adjustedScaleDownBehavior.disabled !==
                                1 && (
                                <FormItem
                                  name="scaleDownPods"
                                  label={
                                    this.state.adjustedScaleDownBehavior
                                      ?.scalingPolicy === adjustType.Percent
                                      ? "每次缩容百分比"
                                      : "每次缩容数量"
                                  }
                                >
                                  <InputNumber
                                    size="small"
                                    min={1}
                                    addonAfter={
                                      this.state.adjustedScaleDownBehavior
                                        ?.scalingPolicy === adjustType.Percent
                                        ? "%"
                                        : "个"
                                    }
                                    onChange={(value) => {
                                      let adjustedScaleDownBehavior = {
                                        ...this.state.adjustedScaleDownBehavior,
                                      };
                                      adjustedScaleDownBehavior.scalingValues =
                                        value;
                                      this.setState({
                                        adjustedScaleDownBehavior,
                                      });
                                      if (form.current)
                                        form.current.setFieldsValue({
                                          scaleDownPods: value,
                                        });
                                    }}
                                  />
                                </FormItem>
                              )}

                            <Divider />
                            <FormItem
                              name="adjustedCPUAverageUtilization"
                              label="CPU指标"
                              rules={[
                                { required: true, validator: validateMetric },
                              ]}
                            >
                              <InputNumber
                                size="small"
                                disabled={
                                  this.state.adjustedCPUMetric.disabled === 1
                                }
                                addonAfter="%"
                                min={10}
                                addonBefore={
                                  <FormItem
                                    name="cpuMetricChecked"
                                    valuePropName="checked"
                                    noStyle
                                  >
                                    <Switch
                                      size="small"
                                      onChange={(checked) => {
                                        let adjustedCPUMetric = {
                                          ...this.state.adjustedCPUMetric,
                                        };
                                        adjustedCPUMetric.disabled = checked
                                          ? 0
                                          : 1;
                                        this.setState({ adjustedCPUMetric });
                                        if (form.current)
                                          form.current.setFieldsValue({
                                            cpuMetricChecked: checked,
                                          });
                                      }}
                                    />
                                  </FormItem>
                                }
                                onChange={(value) => {
                                  let adjustedCPUMetric = {
                                    ...this.state.adjustedCPUMetric,
                                  };
                                  adjustedCPUMetric.averageUtilization = value;
                                  this.setState({ adjustedCPUMetric });
                                  if (form.current)
                                    form.current.setFieldsValue({
                                      adjustedCPUAverageUtilization: value,
                                    });
                                }}
                              />
                            </FormItem>
                          </>
                        )}
                      </Form>
                    )}
                  </TabPane>
                );
              })}
            </Tabs>
          )}

          {this.state.items.size === 0 && <Empty />}

          <HPADrawer
            visible={this.state.drawer}
            onClose={() => this.setState({ drawer: false })}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
});

export default connect(mapStateToProps, null)(AutoScaling);
