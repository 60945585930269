import React from "react";
import {Button, Form, Input} from "antd";
import { parseUrlQuery } from "src/utils";
import { webApi } from "../../fetch";

const Login4Test = () => {
  const [from] = Form.useForm();
  const { redirect } = parseUrlQuery();

  function handleFinish(val: any) {
    webApi.post("login", val).then((res) => {
      window.location.href = redirect;
    });
  }

  return (
    <Form form={from} onFinish={handleFinish}>
      <h1>快速登录</h1>
      <Form.Item
        rules={[{ required: true, message: "用户名必须填写" }]}
        name="username"
        label="用户名"
      >
        <Input></Input>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">登录</Button>
      </Form.Item>
    </Form>
  );
};

export default Login4Test;
