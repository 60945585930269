export const LOGIN = "LOGIN";

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LOGIN = typeof LOGIN;

export const RESET_STORE = "RESET_STORE";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RESET_STORE = typeof RESET_STORE;

export const CHANGE_ACTIVE_TAB = "CHANGE_ACTIVE_TAB";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CHANGE_ACTIVE_TAB = typeof CHANGE_ACTIVE_TAB;

export const CHANGE_SELECT_TARS_SVC = "CHANGE_SELECT_TARS_SVC";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CHANGE_SELECT_TARS_SVC = typeof CHANGE_SELECT_TARS_SVC;

export const REBUILD_TREE = "REBUILD_TREE";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type REBUILD_TREE = typeof REBUILD_TREE;

export const LIST_TARS_TREE = "LIST_TARS_TREE";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LIST_TARS_TREE = typeof LIST_TARS_TREE;

export const LIST_TARS_SERVER = "LIST_TARS_SERVER";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LIST_TARS_SERVER = typeof LIST_TARS_SERVER;

export const CHANGE_TAB = "CHANGE_TAB";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CHANGE_TAB = typeof CHANGE_TAB;

export const LIST_TARS_SERVER_CLEAR = "LIST_TARS_SERVER_CLEAR";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LIST_TARS_SERVER_CLEAR = typeof LIST_TARS_SERVER_CLEAR;

export const SWITCH_NETSTAT = "SWITCH_NETSTAT";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SWITCH_NETSTAT = typeof SWITCH_NETSTAT;

export const SET_SELECTED_ITEMS = "SET_SELECTED_ITEMS";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SET_SELECTED_ITEMS = typeof SET_SELECTED_ITEMS;

export const SET_TASK_MANAGER_DOT = "SET_TASK_MANAGER_DOT";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SET_TASK_MANAGER_DOT = typeof SET_TASK_MANAGER_DOT;

export const EXPAND_TERMINAL = "EXPAND_TERMINAL";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EXPAND_TERMINAL = typeof EXPAND_TERMINAL;

export const EXPAND_TABLE = "EXPAND_TABLE";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EXPAND_TABLE = typeof EXPAND_TABLE;

export const LIST_DCACHE_TREE = "LIST_DCACHE_TREE";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LIST_DCACHE_TREE = typeof LIST_DCACHE_TREE;

export const STORY_FETCH = "STORY_FETCH";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type STORY_FETCH = typeof STORY_FETCH;

export const LIST_SERVER_STATUS = "LIST_SERVER_STATUS";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LIST_SERVER_STATUS = typeof LIST_SERVER_STATUS;

export const TOGGLE_LOADING = "TOGGLE_LOADING";
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TOGGLE_LOADING = typeof TOGGLE_LOADING;
