import React, {useImperativeHandle, useState, forwardRef, Ref} from 'react';
import MonacoEditor from "react-monaco-editor";

import { Modal, Spin } from 'antd';

import {
  getTarsProtocolFileContent,
} from "../../fetch";

export interface TarsFIleViewModalRef {
  show: (path: string, customFetch?: () => Promise<string>) => void;
  hide: () => void;
}

interface TarsFIleViewModalProps {
  activeRef?: any;
  tarsApplication: string;
  tarsSetName: string;
  tarsServerName: string;
  clusterName: string;
}

export const DEFAULT_EDITOR_THEME = 'vs-dark';
export const DEFAULT_EDITOR_OPTIONS = {
  formatOnPaste: true,
  formatOnType: true,
  readOnly: true,
  scrollbar: {
    alwaysConsumeMouseWheel: false,
  },
};

function TarsFIleViewModal(props: TarsFIleViewModalProps, ref: Ref<TarsFIleViewModalRef>) {
  const {
    activeRef,
    tarsApplication,
    tarsSetName,
    tarsServerName,
    clusterName
  } = props;

  const [visible, updateVisible] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [filePath, updateFilePath] = useState<string>();
  const [fileContent, updateFileContent] = useState<string>();

  const close = () => {
    updateVisible(false);

    if(!activeRef) return;

    activeRef.current = false;
  };

  const show = (fileName: string, customFetch?: () => Promise<string>) => {
    updateVisible(true);
    updateLoading(true);
    updateFilePath(fileName);

    const run = customFetch || getTarsProtocolFileContent;

    run({
      tarsApplication,
      tarsSetName,
      tarsServerName,
      clusterName,
      fileName
    }).then((res) => {
      updateFileContent(res);
    })
      .catch(() => {})
      .finally(() => {
        updateLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    show,
    hide: close,
  }));

  return (
    <Modal
      zIndex={9999}
      visible={visible}
      onCancel={close}
      cancelButtonProps={{style: {display: 'none'}}}
      onOk={close}
      centered
      width={660}
      title={'查看文件内容'}
    >
      <div style={{marginBottom: 24}}>
        <code>
          {filePath}
        </code>
      </div>
      {
        loading ? (
          <Spin spinning>
            <div style={{height: 200}} />
          </Spin>
          ) : (
          <MonacoEditor
            value={fileContent}
            theme={DEFAULT_EDITOR_THEME}
            height="67vh"
            language={'go'}
            options={DEFAULT_EDITOR_OPTIONS}
          />
        )
      }
    </Modal>
  )
}

export default forwardRef(TarsFIleViewModal)
