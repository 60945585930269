import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { Button, Space, message, Form, Modal, Input, AutoComplete } from "antd";
import { BellFilled } from "@ant-design/icons";

import { connect } from "react-redux";
import * as ActionType from "src/stores/types";

import * as fetch from "src/fetch";
import * as util from "src/pages/DevOps/ToolsBar/util";
import NotificationResult from "src/pages/DevOps/ToolsBar/NotificationResult";

const FormItem = Form.Item;

const { TextArea } = Input;
interface IProps {
  disabled: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  selectedItems?: Array<ActionType.ITarsServerItem>;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
}

interface IState {
  confirmVisible: boolean;
  resultVisible: boolean;
  notificationResult: Array<any>;
}

const Notify: React.FC<IProps> = (props) => {
  const [form] = Form.useForm();
  const [state, setState] = React.useState<IState>({
    confirmVisible: false,
    resultVisible: false,
    notificationResult: [],
  });
  const [options, setOptions] = React.useState<Array<any>>([]);
  const CACHE_KEY = "PUBLISH_COMMAND";
  const { 
    selectedTarsServer, 
    selectedTarsApplication, 
    selectedTarsSetName,
  } = props;

  const uniqueKey = `${selectedTarsApplication}-${selectedTarsServer}-${selectedTarsSetName}`;

  useEffect(() => {
    const publishCommondParams: Record<string, any> = JSON.parse(
      localStorage.getItem(CACHE_KEY) || "{}"
    );
    const options = publishCommondParams[uniqueKey] || [];
    setOptions(options.map((item: string) => ({ value: item })));
  }, [uniqueKey, state.confirmVisible]);

  const publishCommond = (value: string) => {
    const CACHE_SIZE = 5;
    let cacheStory = JSON.parse(localStorage.getItem(CACHE_KEY) || "{}");
    const cache = cacheStory[uniqueKey] || [];
    const index = cache.indexOf(value);
    if (index !== -1) {
      cache.splice(index, 1);
    }
    cache.unshift(value);
    if (cache.length > CACHE_SIZE) {
      cache.pop();
    }
    localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({ ...cacheStory, [uniqueKey]: cache })
    );
  };

  return (
    <>
      <Button
        size="small"
        disabled={props.disabled}
        className="tool-btn"
        type="primary"
        ghost
        onClick={() => {
          if (util.disableSelectNull(props.selectedItems)) {
            return;
          }

          if (util.has("inactive", props.selectedItems)) {
            message.destroy();
            message.error("选中的节点中有Inactive的节点");
            return;
          }

          setState({
            confirmVisible: true,
            resultVisible: false,
            notificationResult: [],
          });
        }}
      >
        <Space>
          <BellFilled style={{ color: "orange" }} />
          发送命令
        </Space>
      </Button>

      <Modal
        title="自定义命令"
        closable={false}
        visible={state.confirmVisible}
        okText="发送"
        cancelText="取消"
        onOk={async () => {
          try {
            const values = await form.validateFields();
            const {
              currentUser,
              selectedTarsApplication,
              selectedTarsSetName,
              selectedTarsServer,
              selectedItems,
            } = props;
            if (
              selectedTarsApplication &&
              selectedTarsServer &&
              selectedItems &&
              !isEmpty(selectedItems)
            ) {
              publishCommond(values.notification);
              fetch
                .fetchSendNotification({
                  userName: currentUser || "",
                  notification: values.notification,
                  tarsApplication: selectedTarsApplication,
                  tarsSetName: selectedTarsSetName || "",
                  tarsServerName: selectedTarsServer,
                  podNetworkInterfaces: selectedItems.map((item) => ({
                    podIP: item.podIP,
                    clusterName: item.clusterName,
                  })),
                })
                .then((data) => {
                  setState({
                    confirmVisible: false,
                    resultVisible: true,
                    notificationResult: data.items,
                  });
                  form.resetFields();
                })
                .catch((_) => {});
            }
          } catch {}
        }}
        onCancel={() => {
          setState({
            confirmVisible: false,
            resultVisible: false,
            notificationResult: [],
          });
          form.resetFields();
        }}
      >
        <Form
          name="notifyForm"
          form={form}
          initialValues={{ notification: "" }}
          autoComplete="off"
          validateTrigger="onBlur"
        >
          <FormItem
            label="参数"
            name="notification"
            rules={[{ required: true, message: "输入为空" }]}
          >
            <AutoComplete
              allowClear={true}
              placeholder="eg: tars.setloglevel ERROR"
              options={options}
            >
              <TextArea
                // placeholder="eg: tars.setloglevel ERROR"
                className="custom"
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </AutoComplete>
          </FormItem>
        </Form>
      </Modal>

      <NotificationResult
        visible={state.resultVisible}
        dataSource={state.notificationResult}
        onClose={() =>
          setState({
            confirmVisible: false,
            resultVisible: false,
            notificationResult: [],
          })
        }
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  selectedItems: state.tarsReducer.selectedItems,
});

export default connect(mapStateToProps, null)(Notify);
