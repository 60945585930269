const cacheMap = new Map();

async function generateUniqueKey(obj: { [x: string]: any; url?: string }) {
  const ordered: any = {};
  Object.keys(obj)
    .sort()
    .forEach(function (key) {
      ordered[key] = obj[key];
    });
  const jsonStr = JSON.stringify(ordered);

  return jsonStr;
}

export const cacheFetchAndSave = async (
  url: string,
  payload: Record<string, any>,
  fetchHandle: any
) => {
  //  根据请求的url以及请求的参数生成一个唯一的key，用于缓存
  // 根据缓存的key，去判断是否有正在请求的任务，如果有，就抛弃，如果没有，就创建一个新的任务
  // 请求结束后，将，需要将缓存的key从正在请求的任务中删除，保证下次请求可以正常进行
  // 函数的作用主要是保证同样的请求在没有返回之前，不能够发送下一个
  const key = await generateUniqueKey({ url, ...payload });
  if (cacheMap.has(key)) {
    return cacheMap.get(key);
  }
  try {
    const fetchPromise = fetchHandle();
    cacheMap.set(key, fetchPromise);
    const res = await fetchPromise;
    cacheMap.delete(key);
    return res;
  } catch (e) {
    cacheMap.delete(key);
    Promise.reject(e);
  }
};
