/* eslint-disable eqeqeq */
import React from "react";
import MomentFormat from "src/utils/MomentFormat";
import { isEmpty } from "lodash";
import {
  // Table,
  Button,
  Badge,
  Divider,
  Tooltip,
  Popconfirm,
  Input,
  message,
  Row,
  Col,
  Form,
  Select,
  Empty,
  Checkbox,
  Spin,
} from "antd";
import { AutoSizer, Table, Column, SortDirection } from "react-virtualized";
import "react-virtualized/styles.css";
import { FilterConfirmProps } from "antd/es/table/interface";

import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { ThunkDispatch } from "redux-thunk";
import { CodeFilled, StopOutlined, ApiTwoTone } from "@ant-design/icons";
import { getSortMap } from "./utils/serverTable_util";

import { connect } from "react-redux";

import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";
import * as FetchType from "src/fetch/types";
import ServerTableHead from "src/pages/DevOps/ServerTableHead";
import "./style/serverTable.less";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

function SortIndicator({ dataKey, sortBy, sortDirection }: any): JSX.Element {
  if (sortBy !== dataKey) {
    return <FontAwesomeIcon icon={faSort} color="#c1c1c1" />;
  } else if (sortDirection === "ASC") {
    return <FontAwesomeIcon icon={faSortUp} />;
  } else {
    return <FontAwesomeIcon icon={faSortDown} />;
  }
}
// 定义一个样式类
const centeredHeaderClass = "centered-header";

interface IProps {
  preference: ActionType.IPreference;
  haveOperatePerm?: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  selectedItems?: Array<ActionType.ITarsServerItem>;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  historyRef?: React.RefObject<any>;
  execClicked: (podIP: string, clusterName: string) => void;
  expandTable: (expand: boolean) => void;
  setSelectedItems: (selectedItems: ActionType.ITarsServerItem[]) => void;

  switchServerNetstat: (
    userName: string,
    selectedTarsApplication: string,
    selectedTarsServer: string,
    podNetworkInterfaces: Array<FetchType.IPodNetworkInterface>,
    netstat: string
  ) => void;
}

interface IState {
  searchText: string;
  itemsVersion: Array<Record<string, any>>;
  itemsCluster: Array<Record<string, any>>;
  itemsState: Array<Record<string, any>>;
  sortBy?: string;
  sortDirection?: any;
  filterTarsServerItems?: Array<ActionType.ITarsServerItem>;
  selectedRowKeys: Set<string>;
}

export class ServerTable extends React.Component<IProps, IState> {
  formRef: any;
  topLength: number; //表格到视窗顶部的高度
  fullHeight: number; //整个视窗的高度
  static filterItems: any;
  constructor(props: IProps) {
    super(props);
    this.formRef = null;
    this.topLength = 203;
    this.fullHeight = window.innerHeight;
    this.state = {
      searchText: "",
      itemsVersion: [],
      itemsCluster: [],
      itemsState: [],
      sortBy: "publishVersion",
      sortDirection: SortDirection.DESC,
      filterTarsServerItems: [],
      selectedRowKeys: new Set(),
    };
  }

  fetchSwitchNetstat(currentNetstat: string) {
    if (
      !this.props.selectedTarsApplication ||
      !this.props.selectedTarsServer ||
      !this.props.selectedItems ||
      isEmpty(this.props.selectedItems)
    ) {
      message.error("未知错误");
      return;
    }

    this.props.switchServerNetstat(
      this.props.currentUser || "",
      this.props.selectedTarsApplication,
      this.props.selectedTarsServer,
      this.props.selectedItems.map((x) => ({
        podIP: x.podIP,
        clusterName: x.clusterName,
      })),
      currentNetstat
    );
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState): any {
    if (
      nextProps &&
      nextProps.tarsServerItems &&
      nextProps.tarsServerItems.length > 0
    ) {
      const { tarsServerItems } = nextProps;

      const { itemsVersion, itemsCluster, itemsState } =
        tarsServerItems?.reduce(
          (pre: any, cur) => {
            pre["itemsVersion"][cur.publishVersion] = cur.publishVersion;
            pre["itemsCluster"][cur.clusterName] = cur.clusterName;
            pre["itemsState"][cur.currentState] = cur.currentState;
            return pre;
          },
          {
            itemsVersion: {},
            itemsCluster: {},
            itemsState: {},
          }
        ) as any;

      return {
        ...prevState,
        itemsVersion,
        itemsCluster,
        itemsState,
      };
    }
    return null;
  }
  componentDidMount(): void {
    this.setState({
      filterTarsServerItems: this.filterItems(this.props),
    });
  }
  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    this.fullHeight = window.innerHeight;
    if (
      prevProps.selectedTarsApplication !==
      this.props.selectedTarsApplication ||
      this.props.selectedTarsSetName !== prevProps.selectedTarsSetName ||
      prevProps.selectedTarsServer !== this.props.selectedTarsServer
    ) {
      this.formRef && this.formRef.resetFields();
      this.setState({
        selectedRowKeys: new Set(),
      });
    }
    if (prevProps.tarsServerItems !== this.props.tarsServerItems) {
      this.onSort(
        {
          sortBy: prevState.sortBy,
          sortDirection: prevState.sortDirection,
        },
        this.filterItems(this.props)
      );
    }
  }
  onSearch(
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps | undefined) => void
  ) {
    confirm();
    this.setState({ searchText: selectedKeys[0] as string });
  }

  onSearchReset(
    clearFilters: (() => void) | undefined,
    confirm: (param?: FilterConfirmProps | undefined) => void
  ) {
    if (clearFilters) clearFilters();
    confirm();
    this.setState({ searchText: "" });
  }
  filterItems = (initFilterItems: any) => {
    const values = this.formRef?.getFieldsValue();
    const { podIP, nodeStatus, podReady, version, cluster } = values;
    const { tarsServerItems } = initFilterItems;

    if (!tarsServerItems) {
      return [];
    }
    const filterTarsServerItems = tarsServerItems?.filter(
      (x: {
        podIP: string | any[];
        currentState: string | any[];
        podReady: any;
        publishVersion: any;
        clusterName: any;
      }) => {
        if (podIP && x.podIP.indexOf(podIP) === -1) return false;
        if (nodeStatus && x.currentState.indexOf(nodeStatus) === -1)
          return false;
        if (podReady && x.podReady != podReady) return false;
        if (version && x.publishVersion != version) return false;
        if (cluster && x.clusterName != cluster) return false;
        return true;
      }
    );

    const selectedItems = tarsServerItems.filter((x: any) => {
      return this.state.selectedRowKeys.has(`${x.podIP}|${x.clusterName}`);
    });
    this.props.setSelectedItems(selectedItems);
    return filterTarsServerItems;
  };
  onSearchFormChange = (initFilterItems: any) => {
    const filterTarsServerItems = this.filterItems(this.props);
    this.setState({
      filterTarsServerItems,
    });
  };
  onSort = ({ sortBy, sortDirection }: any, filterTarsServerItems: any) => {
    if (sortBy === "selected" || sortBy === "exec") {
      return;
    }
    console.log(sortBy, sortDirection);
    const sortFnMap: any = getSortMap(sortDirection);
    // 根据column的sorter属性进行排序以及当前的sortBy和sortDirection对filterTarsServerItems这个数据进行排序

    const sortFn = sortFnMap[sortBy] || (() => { });

    const sortedList =
      [
        ...(filterTarsServerItems ||
          (this.state.filterTarsServerItems as any) ||
          []),
      ].sort(sortFn) || [];

    this.setState({ sortBy, sortDirection, filterTarsServerItems: sortedList });
  };
  headerRenderer = ({ dataKey, label, sortBy, sortDirection }: any) => {
    return (
      <div>
        {label}{" "}
        <SortIndicator
          dataKey={dataKey}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </div>
    );
  };
  render() {
    const wrapState = (s: string) => {
      switch (s) {
        case "active":
          return "Active";
        case "activating":
          return "Activating";
        case "prepare":
          return "Prepare";
        case "ready":
          return "Ready";
        case "inactive":
          return "Off";
        case "deactivating":
          return "Deactivating";
      }
      return s;
    };
    const wrapStateColor = (s: string) => {
      switch (s) {
        case "active":
          return "green";
        case "activating":
          return "red";
        case "prepare":
          return "blue";
        case "ready":
          return "hotpink";
        case "inactive":
          return "red";
        case "deactivating":
          return "red";
      }
      return "black";
    };

    // const fixedScrollY = this.props.preference.expandTable ? undefined : 350;
    const { selectedItems } = this.props;
    const { filterTarsServerItems } = this.state;
    const isEmptyList = (filterTarsServerItems as any).length === 0;
    const indeterminate =
      (selectedItems as any).length > 0 &&
      (selectedItems as any).length < (filterTarsServerItems as any).length;
    const checkAll =
      (selectedItems as any).length === (filterTarsServerItems as any).length;
    const rowHeight = 40;
    return (
      <>
        <Spin spinning={!this.props.tarsServerItems?.length}>
          <Form
            name="horizontal_login"
            layout="inline"
            ref={(formRef) => (this.formRef = formRef)}
            onValuesChange={this.onSearchFormChange}
            style={{ marginBottom: 10 }}
          >
            <Form.Item name="podIP" label="podIP">
              <Input placeholder="输入IP模糊查找" allowClear />
            </Form.Item>
            <Form.Item name="nodeStatus" label="节点状态">
              <Select style={{ width: 180 }} allowClear>
                {Object.keys(this.state.itemsState).map((key) => {
                  return <Select.Option value={key}>{key}</Select.Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item name="podReady" label="流量状态">
              <Select style={{ width: 180 }} allowClear>
                <Select.Option value="1">有流量</Select.Option>
                <Select.Option value="0">无流量</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="version" label="版本">
              <Select style={{ width: 180 }} allowClear>
                {Object.keys(this.state.itemsVersion).map((key) => {
                  return <Select.Option value={key}>{key}</Select.Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item name="cluster" label="所属集群">
              <Select style={{ width: 180 }} allowClear>
                {Object.keys(this.state.itemsCluster).map((key) => (
                  <Select.Option value={key}>{key}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>

          <ServerTableHead historyRef={this.props.historyRef} />
          <div
            className="tableWarp"
            style={{
              height: isEmptyList
                ? 200
                : this.props.preference.expandTable
                  ? this.fullHeight - this.topLength >
                    (filterTarsServerItems as any).length * rowHeight
                    ? ((filterTarsServerItems as any).length + 1) * rowHeight
                    : this.fullHeight - this.topLength
                  : (filterTarsServerItems as any)?.length + 1 > 7
                    ? 361
                    : ((filterTarsServerItems as any)?.length + 1) * rowHeight,
            }}
          >
            <AutoSizer>
              {isEmptyList
                ? ({ height, width }: any) => (
                  <div
                    style={{
                      height: height,
                      width: width,
                    }}
                    className="emptyTable"
                  >
                    <Empty />
                  </div>
                )
                : ({ height, width }: any) => (
                  <Table
                    className="tars-server-table"
                    headerHeight={rowHeight}
                    height={height}
                    rowCount={(filterTarsServerItems as any).length}
                    rowGetter={({ index }: any) =>
                      (filterTarsServerItems as any)[index]
                    }
                    sort={this.onSort}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    rowHeight={rowHeight}
                    width={width}
                    onRowClick={(event: any) => {
                      const rowData = event.rowData;
                      const { podIP, clusterName, publishVersion } = rowData;

                      if (publishVersion.indexOf("init") !== -1) {
                        return null;
                      }

                      const copySelectedRowKeys = new Set(
                        this.state.selectedRowKeys
                      );
                      if (
                        this.state.selectedRowKeys.has(
                          `${podIP}|${clusterName}`
                        )
                      ) {
                        copySelectedRowKeys.delete(`${podIP}|${clusterName}`);
                      } else {
                        copySelectedRowKeys.add(`${podIP}|${clusterName}`);
                      }

                      // 判断SelectedItems数组中是否已经存在当前点击的行，如果存在则删除，否则添加
                      const selectedItems: any = (
                        this.props.tarsServerItems as any
                      ).filter((item: any) => {
                        return copySelectedRowKeys.has(
                          `${item.podIP}|${item.clusterName}`
                        );
                      });

                      if (selectedItems.length) {
                        this.setState({
                          selectedRowKeys: copySelectedRowKeys,
                        });
                      } else {
                        this.setState({ selectedRowKeys: new Set() });
                      }

                      this.props.setSelectedItems(selectedItems);
                    }}
                    rowClassName={({ index }: any) => {
                      const rowData = (filterTarsServerItems as any)[index];
                      return rowData &&
                        this.state.selectedRowKeys.has(
                          `${rowData.podIP}|${rowData.clusterName}`
                        )
                        ? "selectedRow tableRow"
                        : "tableRow";
                    }}
                  >
                    <Column
                      label={
                        <Checkbox
                          indeterminate={indeterminate}
                          onChange={(e) => {
                            // 选中所有行
                            if (e.target.checked) {
                              const copySelectedRowKeys: Set<string> =
                                new Set();

                              const notInitItems = (
                                filterTarsServerItems as any
                              ).filter((item: any) => {
                                return (
                                  item.publishVersion.indexOf("init") === -1
                                );
                              });

                              (notInitItems as any).forEach((item: any) => {
                                copySelectedRowKeys.add(
                                  `${item.podIP}|${item.clusterName}`
                                );
                              });
                              this.setState({
                                selectedRowKeys: copySelectedRowKeys,
                              });
                              this.props.setSelectedItems(
                                notInitItems as any
                              );
                            } else {
                              // 取消选中所有行
                              this.setState({ selectedRowKeys: new Set() });
                              this.props.setSelectedItems([]);
                            }
                          }}
                          checked={checkAll}
                        />
                      }
                      dataKey="selected"
                      width={40}
                      cellRenderer={(cell: any) => {
                        const record = cell.rowData;
                        return (
                          <Checkbox
                            disabled={
                              record.publishVersion.indexOf("init") !== -1
                            }
                            checked={this.state.selectedRowKeys.has(
                              `${record.podIP}|${record.clusterName}`
                            )}
                          />
                        );
                      }}
                    />
                    <Column
                      label="IP"
                      dataKey="podIP"
                      headerClassName={centeredHeaderClass}
                      width={130}
                      headerRenderer={this.headerRenderer}
                      cellRenderer={(cell: any) => {
                        const record = cell.rowData;
                        const text = record.podIP;
                        return (
                          <Tooltip title={text}>
                            <Button
                              disabled={
                                record.publishVersion.indexOf("init") !== -1
                              }
                              type="link"
                              onClick={() => {
                                window.open(
                                  `${record.logViewHost}/index?cid=&ip=${record.podIP}&server=${this.props.selectedTarsApplication}.${this.props.selectedTarsServer}`
                                );
                              }}
                              size="small"
                            >
                              {text}
                            </Button>
                          </Tooltip>
                        );
                      }}
                    />
                    <Column
                      label="IDC"
                      dataKey="idcName"
                      width={90}
                      headerRenderer={this.headerRenderer}
                    />
                    <Column
                      label="区域"
                      dataKey="zone"
                      width={60}
                      headerRenderer={this.headerRenderer}
                    />
                    <Column
                      label="流量"
                      dataKey="netstat"
                      width={90}
                      cellRenderer={(cell: any) => {
                        const record = cell.rowData;
                        const text = record.netstat;
                        return text === "正常" ? (
                          <p
                            className={`netstat ${record.publishVersion.indexOf("init") !== -1
                              ? "disable"
                              : ""
                              }`}
                          >
                            {text}
                          </p>
                        ) : (
                          <p
                            className={`link netstat ${record.publishVersion.indexOf("init") !== -1
                              ? "disable"
                              : ""
                              }`}
                          >
                            {text}
                          </p>
                        );
                      }}
                      headerRenderer={this.headerRenderer}
                    />
                    <Column
                      label="预期"
                      dataKey="settingState"
                      width={95}
                      align="center"
                      flexGrow={1}
                      cellRenderer={({ cellData }: any) => (
                        <span style={{ color: wrapStateColor(cellData) }}>
                          {wrapState(cellData)}
                        </span>
                      )}
                      sort={({ sortBy }: any) => {
                        const sortedList = [
                          ...(filterTarsServerItems as any),
                        ];
                        if (sortBy) {
                          sortedList.sort((a, b) =>
                            a[sortBy.key].localeCompare(b[sortBy.key])
                          );
                          if (sortBy.direction === "DESC") {
                            sortedList.reverse();
                          }
                        }
                      }}
                      headerRenderer={this.headerRenderer}
                    />
                    <Column
                      label="当前"
                      dataKey="currentState"
                      width={95}
                      align="center"
                      headerClassName={centeredHeaderClass}
                      flexGrow={1}
                      cellRenderer={({ cellData, rowData }: any) => (
                        <div className="flexCenter">
                          <Badge
                            status={
                              rowData.podReady === 1 ? "success" : "warning"
                            }
                            text={
                              <span
                                style={{ color: wrapStateColor(cellData) }}
                              >
                                {wrapState(cellData)}
                              </span>
                            }
                          />
                        </div>
                      )}
                      headerRenderer={this.headerRenderer}
                    />
                    <Column
                      label="版本(commit)"
                      dataKey="publishVersion"
                      width={200}
                      flexGrow={1}
                      align="center"
                      headerClassName={centeredHeaderClass}
                      cellRenderer={({ cellData }: any) => (
                        <Tooltip title={cellData}>
                          <div className="textCenter">{cellData || "_"}</div>
                        </Tooltip>
                      )}
                      headerRenderer={this.headerRenderer}
                    />
                    <Column
                      label="发布人"
                      dataKey="whoPublished"
                      width={110}
                      headerClassName={centeredHeaderClass}
                      align="center"
                      flexGrow={1}
                      cellRenderer={({ cellData }: any) => (
                        <Tooltip title={cellData}>
                          <div className="textCenter">
                            {!isEmpty(cellData) ? cellData : "-"}
                          </div>
                        </Tooltip>
                      )}
                      headerRenderer={this.headerRenderer}
                    />
                    <Column
                      label="发布时间"
                      dataKey="publishTimestamp"
                      width={120}
                      align="center"
                      flexGrow={1}
                      headerClassName={centeredHeaderClass}
                      cellRenderer={({ cellData }: any) => (
                        <Tooltip
                          title={
                            !isEmpty(cellData) && parseInt(cellData) > 0
                              ? MomentFormat(cellData)
                              : "-"
                          }
                        >
                          <div className="textCenter">
                            {!isEmpty(cellData) && parseInt(cellData) > 0
                              ? MomentFormat(cellData, "MM-DD HH:mm:ss")
                              : "-"}
                          </div>
                        </Tooltip>
                      )}
                      headerRenderer={this.headerRenderer}
                    />
                    <Column
                      label="重启时间"
                      dataKey="startTimestamp"
                      width={120}
                      align="center"
                      flexGrow={1}
                      headerClassName={centeredHeaderClass}
                      cellRenderer={({ cellData }: any) => (
                        <Tooltip
                          title={
                            !isEmpty(cellData) && parseInt(cellData) > 0
                              ? MomentFormat(cellData)
                              : "-"
                          }
                        >
                          <div className="textCenter">
                            {!isEmpty(cellData) && parseInt(cellData) > 0
                              ? MomentFormat(cellData, "MM-DD HH:mm:ss")
                              : "-"}
                          </div>
                        </Tooltip>
                      )}
                      headerRenderer={this.headerRenderer}
                    />
                    <Column
                      label="集群"
                      headerClassName={centeredHeaderClass}
                      dataKey="clusterName"
                      width={200}
                      flexGrow={1}
                      cellRenderer={({ cellData }: any) => (
                        <div className="textCenter">{cellData || "_"}</div>
                      )}
                      headerRenderer={this.headerRenderer}
                    />
                    <Column
                      label="调试"
                      dataKey="exec"
                      width={120}
                      // align="center"
                      flexGrow={1}
                      headerClassName={centeredHeaderClass}
                      cellRenderer={({ cellData, rowData }: any) => (
                        <div
                          style={{
                            margin: "0 5px",
                            textAlign: "center",
                          }}
                        >
                          <Button
                            type="dashed"
                            size="small"
                            disabled={
                              !this.props.haveOperatePerm ||
                              rowData.publishVersion.indexOf("init") !== -1
                            }
                            onClick={(e) => {
                              this.props.execClicked(rowData.podIP, rowData.clusterName);
                              e.stopPropagation();
                            }}
                          >
                            <CodeFilled />
                            webshell
                          </Button>
                        </div>
                      )}
                    />
                  </Table>
                )}
            </AutoSizer>
          </div>
          <div className="tableFooter">
            <Row>
              <Col span={12} style={{ textAlign: "left" }}>
                <Popconfirm
                  title="设置成无流量?"
                  okButtonProps={{
                    className: "tool-btn",
                    danger: true,
                    ghost: true,
                  }}
                  cancelButtonProps={{
                    className: "tool-btn",
                    type: "primary",
                    ghost: true,
                  }}
                  onConfirm={() => this.fetchSwitchNetstat("正常")}
                  disabled={
                    !this.props.haveOperatePerm ||
                    isEmpty(this.props.selectedItems)
                  }
                >
                  <Button
                    size="small"
                    type="dashed"
                    className="tool-btn"
                    style={{ marginRight: 8 }}
                    disabled={
                      !this.props.haveOperatePerm ||
                      isEmpty(this.props.selectedItems)
                    }
                  >
                    <StopOutlined style={{ color: "#c41d7f" }} />
                    无流量
                  </Button>
                </Popconfirm>

                {/* <Divider type="vertical" /> */}

                <Popconfirm
                  title="将流量状态恢复?"
                  okButtonProps={{
                    className: "tool-btn",
                    type: "primary",
                    ghost: true,
                  }}
                  cancelButtonProps={{
                    className: "tool-btn",
                    danger: true,
                    ghost: true,
                  }}
                  onConfirm={() => this.fetchSwitchNetstat("无流量")}
                  disabled={
                    !this.props.haveOperatePerm ||
                    isEmpty(this.props.selectedItems)
                  }
                >
                  <Button
                    size="small"
                    type="dashed"
                    disabled={
                      !this.props.haveOperatePerm ||
                      isEmpty(this.props.selectedItems)
                    }
                  >
                    <ApiTwoTone />
                    恢复流量
                  </Button>
                </Popconfirm>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                {this.props.tarsServerItems &&
                  this.props.tarsServerItems.length >= 10 && (
                    <span style={{ marginLeft: 8 }}>
                      {!this.props.preference.expandTable ? (
                        <Button
                          size="small"
                          type="link"
                          onClick={() => this.props.expandTable(true)}
                        >
                          展开列表
                          <DownOutlined />
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          type="link"
                          onClick={() => this.props.expandTable(false)}
                        >
                          收起列表
                          <UpOutlined />
                        </Button>
                      )}
                    </span>
                  )}
              </Col>
            </Row>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  preference: state.tarsReducer.preference,
  haveOperatePerm: state.tarsReducer.selectedTarsPerm === "W",
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  selectedItems: state.tarsReducer.selectedItems,
  tarsServerItems: state.tarsReducer.tarsServerItems,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  setSelectedItems: (selectedItems: ActionType.ITarsServerItem[]) =>
    dispatch(Actions.setSelectedItems(selectedItems)),

  switchServerNetstat: (
    userName: string,
    selectedTarsApplication: string,
    selectedTarsServer: string,
    podNetworkInterfaces: Array<FetchType.IPodNetworkInterface>,
    netstat: string
  ) =>
    dispatch(
      Actions.switchNetstat(
        userName,
        selectedTarsApplication,
        selectedTarsServer,
        podNetworkInterfaces,
        netstat
      )
    ),
  expandTable: (expand: boolean) => dispatch(Actions.expandTable(expand)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServerTable);
