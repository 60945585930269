import React from "react";
import {
  Row,
  Button,
  Col,
  Table,
  Tabs,
  Tooltip,
  Spin,
  message,
  Popconfirm,
} from "antd";
import {
  ClearOutlined,
  DeleteTwoTone,
  ReloadOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { ColumnsType } from "antd/es/table";
import { FilterConfirmProps } from "antd/es/table/interface";

import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";

import CommonTableProps from "src/utils/CommonTableProps";
import ViewPodButton from "src/pages/Common/ViewPodButton";
import ViewTaintButton from "src/pages/Common/ViewTaintButton";
import EditMachineUsageButton from "src/pages/Common/EditMachineUsageButton";
import { pickClusterName } from "src/utils/Picker";
import { isEmpty } from "lodash";

const { TabPane } = Tabs;

interface IProps {
  currentUser?: string;
}

interface IMachine {
  instanceID: string;
  ip: string;
  zone: string;
  instance: string;
  usage: string;
  availCpu: string;
  availMem: string;
  billingType: string;
  pods: number;
  nodePool: string;
  taints: Array<FetchType.ITaint>;
}

interface IState {
  loading: boolean;
  innerLoading: boolean;

  clusterName: string;
  dataSource: Map<string, Array<IMachine>>;

  searchText?: string;
}

export default class ManageMachine extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      innerLoading: true,
      clusterName: "",
      dataSource: new Map(),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData(innerLoading?: boolean) {
    if (innerLoading) this.setState({ innerLoading: true });
    fetch
      .fetchListMachine()
      .then((data) => {
        let dataSource = new Map<string, Array<IMachine>>();
        Object.entries(data.items).forEach((value: [string, any]) => {
          dataSource.set(value[0], value[1] as Array<IMachine>);
        });
        let clusterName = this.state.clusterName;
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(dataSource.keys()));
        }
        this.setState({
          clusterName,
          dataSource,
          loading: false,
          innerLoading: false,
        });
      })
      .catch((_) => {});
  }

  fetchGCNodes() {
    const clusterName = this.state.clusterName;
    fetch
      .fetchGCNodes({ clusterName })
      .then((data) => {
        if (data.errCode === 0)
          message.success("回收成功,稍后到tars-api-0上查看日志");
      })
      .catch((_) => {});
  }

  onSearch(
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps | undefined) => void
  ) {
    confirm();
    this.setState({ searchText: selectedKeys[0] as string });
  }

  onSearchReset(
    clearFilters: (() => void) | undefined,
    confirm: (param?: FilterConfirmProps | undefined) => void
  ) {
    if (clearFilters) clearFilters();
    confirm();
    this.setState({ searchText: "" });
  }

  render() {
    const columns: ColumnsType<IMachine> = [
      {
        title: "实例ID",
        dataIndex: "instanceID",
        width: 100,
        align: "center",
        ellipsis: true,
        ...CommonTableProps(
          "instanceID",
          "实例ID",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
      },
      {
        title: "IP",
        dataIndex: "ip",
        width: 50,
        align: "center",
        ...CommonTableProps(
          "ip",
          "IP",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
      },
      {
        title: "可用区",
        dataIndex: "zone",
        width: 50,
        align: "center",
        ...CommonTableProps(
          "zone",
          "可用区",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
      },
      {
        title: "规格",
        dataIndex: "instance",
        width: 50,
        align: "center",
        ...CommonTableProps(
          "instance",
          "规格",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
      },
      {
        title: "Label",
        dataIndex: "usage",
        width: 50,
        align: "center",
        ...CommonTableProps(
          "usage",
          "用途",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
      },
      {
        title: "可用CPU",
        dataIndex: "availCpu",
        width: 40,
        align: "center",
        defaultSortOrder: "descend",
        sorter: (a: IMachine, b: IMachine): number =>
          parseInt(a.availCpu) - parseInt(b.availCpu),
      },
      {
        title: "可用Mem",
        dataIndex: "availMem",
        width: 40,
        align: "center",
        sorter: (a: IMachine, b: IMachine): number =>
          parseInt(a.availMem) - parseInt(b.availMem),
      },
      {
        title: "付费类型",
        dataIndex: "billingType",
        width: 40,
        align: "center",
        ...CommonTableProps(
          "billingType",
          "付费类型",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
      },
      {
        title: "容器",
        dataIndex: "pods",
        width: 30,
        align: "center",
        sorter: (a: IMachine, b: IMachine): number => a.pods - b.pods,
      },
      {
        title: "节点池",
        dataIndex: "nodePool",
        width: 95,
        align: "center",
        ellipsis: true,
        ...CommonTableProps(
          "nodePool",
          "节点池",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
        render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
      },
      {
        title: "污点",
        dataIndex: "taints",
        width: 40,
        align: "center",
        ellipsis: true,
        sorter: (a: IMachine, b: IMachine) => a.taints.length - b.taints.length,
        render: (value, record) => <ViewTaintButton taints={record.taints} />,
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 95,
        align: "center",
        ellipsis: true,
        render: (text: string, record: IMachine) => (
          <Row justify="space-around">
            <Col>
              <ViewPodButton
                currentUser={this.props.currentUser}
                clusterName={this.state.clusterName}
                machineIP={record.ip}
              />
            </Col>
            <Col>
              <EditMachineUsageButton
                currentUser={this.props.currentUser}
                clusterName={this.state.clusterName}
                machineIP={record.ip}
                onOK={() => {
                  message.success("更新成功");
                  this.fetchData(true);
                }}
              />
            </Col>
            <Col>
              <Button
                size="small"
                style={{ padding: "0 8px" }}
                className="tool-btn"
                disabled
              >
                <DeleteTwoTone twoToneColor="#DC143C" />
                下线
              </Button>
            </Col>
          </Row>
        ),
      },
    ];

    return (
      <>
        {!this.state.loading && (
          <Tabs
            size="large"
            type="card"
            style={{ marginLeft: 8 }}
            onChange={(clusterName) => this.setState({ clusterName })}
            activeKey={this.state.clusterName}
          >
            {Array.from(this.state.dataSource).map((values) => (
              <TabPane tab={values[0]} key={values[0]}>
                <Table
                  size="small"
                  pagination={{
                    pageSize: 75,
                    simple: true,
                    hideOnSinglePage: true,
                    defaultCurrent: 1,
                  }}
                  rowKey="ip"
                  columns={columns}
                  dataSource={values[1]}
                  loading={this.state.innerLoading}
                  title={() => (
                    <div style={{ textAlign: "center" }}>
                      <Button
                        size="small"
                        type="dashed"
                        className="tool-btn"
                        onClick={() => this.fetchData(true)}
                      >
                        <ReloadOutlined
                          style={{ color: "green", fontSize: 15 }}
                        />
                        刷新
                      </Button>
                      <Popconfirm
                        placement="bottom"
                        title="进行集群机器整理、回收?"
                        icon={
                          <QuestionCircleOutlined style={{ color: "red" }} />
                        }
                        onConfirm={() => this.fetchGCNodes()}
                      >
                        <Button
                          size="small"
                          danger
                          style={{ marginLeft: 10 }}
                          className="tool-btn"
                        >
                          <ClearOutlined />
                          回收
                        </Button>
                      </Popconfirm>
                    </div>
                  )}
                />
              </TabPane>
            ))}
          </Tabs>
        )}

        {this.state.loading && (
          <div style={{ height: 300 }}>
            <Spin
              size="large"
              tip="加载中..."
              style={{ position: "relative", left: "50%", top: "50%" }}
            />
          </div>
        )}
      </>
    );
  }
}
