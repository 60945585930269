import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchServerInfo, updateServerInfo } from "src/fetch";
import {
  Form,
  Checkbox,
  InputNumber,
  Button,
  Spin,
  Tabs,
  Card,
  message,
  Select,
  Switch,
} from "antd";
import { pickClusterName } from "src/utils/Picker";


const Item = Form.Item;
const { TabPane } = Tabs;
interface IProps {
  tab?: string;
  currentUser?: string;
  haveOperatePerm?: boolean;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
}

const ServerInfo = (props: IProps) => {
  const {
    selectedTarsApplication,
    selectedTarsServer,
    selectedTarsSetName,
    currentUser,
    haveOperatePerm,
  } = props;
  const [form] = Form.useForm();
  const [loading, setIsLoading] = useState(false);
  const [clusterItems, setClusterItems] = useState<any[]>([]);
  // 当前选中的集群
  const [clusterName, setClusterName] = useState("");

  const [availCostCenterList, setAvailCostCenterList] = useState<any[]>([]);

  const [availServiceTypeList, setAvailServiceTypeList] = useState<any[]>([]);

  const [availAppList, setAvailAppList] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);
    form.resetFields();
    const _clusterItems: any[] = [];
    fetchServerInfo({
      tarsApplication: selectedTarsApplication,
      tarsSetName: selectedTarsSetName,
      tarsServerName: selectedTarsServer,
    })
      .then((clusterServerInfo) => {
        if (clusterServerInfo) {
          Object.keys(clusterServerInfo).forEach((cluster) => {
            _clusterItems.push(cluster);
          });
          setClusterItems(_clusterItems);

          let clusterName = pickClusterName(_clusterItems) || _clusterItems[0]
          setClusterName(clusterName);

          setTimeout(() => {
            setAvailCostCenterList(
              clusterServerInfo[clusterName].availCostJobCenters || []
            );

            setAvailServiceTypeList(
              clusterServerInfo[clusterName].availCostJobs || []
            );

            setAvailAppList(
              clusterServerInfo[clusterName].availCostApps || []
            );

            form.setFieldsValue({
              ...clusterServerInfo[clusterName],
              masterSlave:
                clusterServerInfo[clusterName].masterSlave === 1,
              ignoreDelete:
                clusterServerInfo[clusterName].ignoreDelete === 0,
            });
          }, 200);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedTarsApplication, selectedTarsServer, selectedTarsSetName]);

  function handleChangeCluster(val: string) {
    if (val !== clusterName) {
      form.resetFields();
      setClusterName(val);
      setIsLoading(true);
      fetchServerInfo({
        userName: currentUser,
        tarsApplication: props.selectedTarsApplication,
        tarsSetName: props.selectedTarsSetName,
        tarsServerName: props.selectedTarsServer,
      })
        .then((clusterServerInfo) => {
          if (clusterServerInfo[val]) {
            setAvailCostCenterList(clusterServerInfo[val].availCostJobCenters);
            setAvailServiceTypeList(clusterServerInfo[val].availCostJobs);

            setAvailAppList(clusterServerInfo[val].availCostApps);

            form.setFieldsValue({
              ...clusterServerInfo[val],
              masterSlave: clusterServerInfo[val].masterSlave === 1,
              ignoreDelete: clusterServerInfo[val].ignoreDelete === 0,
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  function handleFinish(data: any) {
    setIsLoading(true);
    updateServerInfo({
      userName: currentUser,
      clusterName,
      tarsApplication: selectedTarsApplication,
      tarsSetName: selectedTarsSetName,
      tarsServerName: selectedTarsServer,
      ...data,
      masterSlave: data.masterSlave ? 1 : 0,
      ignoreDelete: data.ignoreDelete ? 0 : 1,
    })
      .then(() => {
        message.success("更新成功!");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  return (
    <Spin spinning={loading}>
      <Tabs
        style={{ minHeight: "50vh" }}
        size="small"
        activeKey={clusterName}
        onChange={handleChangeCluster}
        tabPosition="right"
      >
        {clusterItems.map((item) => (
          <TabPane tab={item} key={item}>
            {item === clusterName ? (
              <Card>
                <Form
                  onFinish={async (data) => {
                    await form.validateFields();

                    handleFinish(data);
                  }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  form={form}
                >
                  <Item
                    name="masterSlave"
                    label="主备模式"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={!haveOperatePerm} />
                  </Item>
                  <Item
                    name="ignoreDelete"
                    label="日志清理"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={!haveOperatePerm} />
                  </Item>
                  <Item
                    rules={[{ required: true }]}
                    name="logLimitMB"
                    label="日志上限(M)"
                  >
                    <InputNumber
                      disabled={!haveOperatePerm}
                      placeholder="默认1024，0为不设上限"
                      style={{ width: 240 }}
                    />
                  </Item>
                  <Item
                    rules={[{ required: true }]}
                    name="logLimitDays"
                    label="日志清理(天)"
                  >
                    <InputNumber
                      disabled={!haveOperatePerm}
                      placeholder="0为不清理"
                      style={{ width: 240 }}
                    />
                  </Item>

                  <Item
                    name="costApps"
                    label="应用"
                    rules={[{ required: true }]}
                  >
                    <Select
                      mode="multiple"
                      disabled={!haveOperatePerm}
                      placeholder="请选择"
                      style={{ width: 240 }}
                      options={availAppList.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </Item>
                  <Item
                    name="costJobCenters"
                    label="业务归属"
                    rules={[{ required: true }]}
                  >
                    <Select
                      mode="multiple"
                      disabled={!haveOperatePerm}
                      placeholder="请选择"
                      style={{ width: 240 }}
                      options={availCostCenterList.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </Item>
                  <Item
                    name="costJob"
                    label="业务类型"
                    rules={[{ required: true }]}
                  >
                    <Select
                      disabled={!haveOperatePerm}
                      placeholder="请选择"
                      style={{ width: 240 }}
                      options={availServiceTypeList.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </Item>

                  <Item wrapperCol={{ offset: 3 }}>
                    <Button
                      htmlType="submit"
                      disabled={!haveOperatePerm}
                      type="primary"
                    >
                      更新
                    </Button>
                  </Item>
                </Form>
              </Card>
            ) : null}
          </TabPane>
        ))}
      </Tabs>
    </Spin>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  haveOperatePerm: state.tarsReducer.selectedTarsPerm === "W",
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
});

export default connect(mapStateToProps, null)(ServerInfo);
