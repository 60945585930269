import React from "react";
import { isEmpty } from "lodash";
import { Empty, Table, Tabs, Tag, Spin, Button, Result } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { ColumnsType } from "antd/es/table";

import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";
import AdjustNodePoolPlaceholderReplicasButton from "src/pages/Common/AdjustNodePoolPlaceholderReplicasButton";
import AdjustNodePoolPlaceholderCronHPAButton from "src/pages/Common/AdjustNodePoolPlaceholderCronHPAButton";
import { pickClusterName } from "src/utils/Picker";

const { TabPane } = Tabs;

interface IProps {
  currentUser?: string;
}

interface IPlaceholderItem {
  nodeResourceID: number;
  resourceName: string;
  nodeSelectorTerms: Array<FetchType.ISelectorTerm>;
  description: string;
  replicas: number;
  pendings: number;
  cpu: number;
  mem: number;
}

interface IPlaceholder {
  errCode: number;
  errMsg: string;
  items: Array<IPlaceholderItem>;
}

interface IState {
  loading: boolean;
  innerLoading: boolean;

  clusterName: string;
  dataSource: Map<string, IPlaceholder>;
}

export default class ManageNodePoolPlaceholder extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      innerLoading: true,
      clusterName: "",
      dataSource: new Map(),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData(innerLoading?: boolean) {
    if (innerLoading) this.setState({ innerLoading: true });
    fetch
      .fetchListNodePoolPlaceholder()
      .then((data) => {
        let dataSource = new Map<string, IPlaceholder>(
          Object.entries(data.items)
        );
        let clusterName = this.state.clusterName;
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(dataSource.keys()));
        }
        dataSource.forEach((val) => {
          val.items.sort((a, b) => a.nodeResourceID - b.nodeResourceID);
        });
        this.setState({
          loading: false,
          innerLoading: false,
          clusterName,
          dataSource,
        });
      })
      .catch((_) => {});
  }

  render() {
    const columns: ColumnsType<IPlaceholderItem> = [
      {
        title: "ID",
        dataIndex: "nodeResourceID",
        width: 30,
        align: "center",
        ellipsis: true,
      },
      {
        title: "部署集",
        dataIndex: "nodeSelectorTerms",
        width: 200,
        align: "center",
        render: (text: string, record: IPlaceholderItem) => (
          <span>
            {
              <>
                <Tag color="blue" style={{ marginLeft: 5 }}>
                  {record.description}
                </Tag>
                <Tag color="red">
                  {record.cpu}x{record.mem}
                </Tag>
                {record.nodeSelectorTerms.map((nodeSelectorTerm, index) => (
                  <Tag key={index} color="green">
                    {nodeSelectorTerm.constraintKey}=
                    {nodeSelectorTerm.constraintValue}
                  </Tag>
                ))}
              </>
            }
          </span>
        ),
      },
      {
        title: "当前数量",
        dataIndex: "replicas",
        width: 30,
        align: "center",
      },
      {
        title: "Pending数量",
        dataIndex: "pendings",
        width: 30,
        align: "center",
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 95,
        align: "center",
        ellipsis: true,
        render: (text: string, record: IPlaceholderItem) => (
          <>
            <span>
              <AdjustNodePoolPlaceholderReplicasButton
                replicas={record.replicas}
                currentUser={this.props.currentUser}
                clusterName={this.state.clusterName}
                nodeResourceID={record.nodeResourceID}
              />
            </span>

            <span style={{ marginLeft: 10 }}>
              <AdjustNodePoolPlaceholderCronHPAButton
                replicas={record.replicas}
                currentUser={this.props.currentUser}
                clusterName={this.state.clusterName}
                nodeResourceID={record.nodeResourceID}
              />
            </span>
          </>
        ),
      },
    ];

    return (
      <>
        {this.state.loading && (
          <div style={{ height: 300 }}>
            <Spin
              size="large"
              tip="加载中..."
              style={{ position: "relative", left: "50%", top: "50%" }}
            />
          </div>
        )}

        {!this.state.loading && (
          <Tabs
            size="large"
            type="card"
            style={{ marginLeft: 8 }}
            onChange={(clusterName) => this.setState({ clusterName })}
            activeKey={this.state.clusterName}
          >
            {Array.from(this.state.dataSource).map((values) => (
              <TabPane tab={values[0]} key={values[0]}>
                {!isEmpty(values[1].items) && (
                  <Table
                    size="small"
                    pagination={{
                      pageSize: 75,
                      simple: true,
                      hideOnSinglePage: true,
                      defaultCurrent: 1,
                    }}
                    columns={columns}
                    loading={this.state.innerLoading}
                    rowKey="nodeResourceID"
                    dataSource={values[1].items}
                    title={() => (
                      <div style={{ textAlign: "center" }}>
                        <Button
                          size="small"
                          type="dashed"
                          className="tool-btn"
                          style={{ marginLeft: 20 }}
                          onClick={() => this.fetchData(true)}
                        >
                          <ReloadOutlined
                            style={{ color: "green", fontSize: 15 }}
                          />
                          刷新
                        </Button>
                      </div>
                    )}
                  />
                )}

                {values[1].errCode === 0 && isEmpty(values[1].items) && (
                  <Empty style={{ marginTop: 100 }} />
                )}

                {values[1].errCode !== 0 && (
                  <Result
                    status="500"
                    title={`请求失败: errCode: ${values[1].errCode}`}
                    subTitle={`errMsg:${values[1].errMsg}`}
                  />
                )}
              </TabPane>
            ))}
          </Tabs>
        )}
      </>
    );
  }
}
