import React, {useState, ChangeEvent, useMemo} from 'react';
import {Select, Divider, Space, Input, message} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { SelectProps } from 'antd/es/select';

const nameReg = /^[a-z]+$/;
const areaReg = /^[a-z]+$/;
const groupReg = /^(\d+|\*)$/;

export default function SetSelect(props: SelectProps) {
    const { onChange, options =[], ...rstProps } = props;

    const [open, updateOpen] = useState(false);
    const [customSet, updateCustomSet] = useState('');
    const [inputVal, updateInputVal] = useState('');

    const onCustomSetInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateInputVal(e.target.value)
    };

    const confirmAdd = () => {
        const newSet = (inputVal || '').trim();

        if(options.some(item => item.value === newSet)) {
            message.warn('该Set已存在');
            return;
        }

        const [name, area, group] = newSet.split('.');

        const hasError = [
            !name,
            !nameReg.test(name),
            !area,
            !areaReg.test(area),
            !group,
            !groupReg.test(group)
        ].some(Boolean);

        if(hasError) {
            message.warn('Set名必须满足 `纯小写字母.纯小写字母.数字或*` 的格式', 4);

            return;
        }

        updateCustomSet(newSet);
        updateInputVal('');
        updateOpen(false);

        onChange?.(newSet, []);
    };

    const selectOptions = useMemo(() => {
        if(!customSet?.trim()) return options;

        return [...options, {label: customSet, value: customSet}];
    }, [options, customSet]);

    return (
        <Select
            showSearch
            {...rstProps}
            onChange={onChange}
            options={selectOptions}
            open={open}
            onDropdownVisibleChange={updateOpen}
            dropdownRender={menu => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px', whiteSpace: 'nowrap' }}>
                        <Input
                            placeholder="name.area.group"
                            value={inputVal}
                            onChange={onCustomSetInputChange}
                        />
                        <a onClick={confirmAdd}>
                            <PlusOutlined style={{marginRight: 2}} />
                            新增Set
                        </a>
                    </Space>
                </>
            )}
        />
    );
}