import thunk from "redux-thunk";
import logger from "redux-logger";
import reduxPromise from "redux-promise";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "src/stores/reducers";

const enhancer =
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(logger, thunk, reduxPromise))
    : applyMiddleware(thunk, reduxPromise);

const store = createStore(rootReducer, enhancer);
export default store;
