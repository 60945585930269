/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import MomentFormat from "src/utils/MomentFormat";
import { isEmpty } from "lodash";
import {
  Button,
  Card,
  Empty,
  Divider,
  Input,
  Timeline,
  Tabs,
  Pagination,
  Tooltip,
  Spin,
  Select,
  Row,
  Col,
} from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { pickClusterName } from "src/utils/Picker";
import JSONPretty from "react-json-prettify";

import * as fetch from "src/fetch";

const TimelineItem = Timeline.Item;
const { TabPane } = Tabs;
const CardMeta = Card.Meta;
const { Search } = Input;

const EVENT_TYPE = {
  ALL_EVENT: "all",
  SYSTEM_EVENT: "system",
  USER_EVENT: "user",
  ROLL_EVENT: "roll",
};

const ServerOperationHistory = (props: {
  onRef: React.Ref<unknown> | undefined;
  isRollout: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [clusterName, setClusterName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState();
  const [withSystemEvent, setWithSystemEvent] = useState(false);
  const [withRolloutEvent, setWithRolloutEvent] = useState(false);
  const [eventType, setEventType] = useState(EVENT_TYPE.ALL_EVENT);

  useImperativeHandle(props.onRef, () => ({
    fetchData: (withLoading: boolean | undefined) => {
      fetchOperationHistory(currentPage || 1);
    },
  }));

  const {
    selectedTarsApplication,
    selectedTarsSetName,
    selectedTarsServer,
    operationItems,
    operationLenItems,
    systemOperationLenItems,
  } = useSelector((state: any) => ({
    selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
    selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
    selectedTarsServer: state.tarsReducer.selectedTarsServer,
    operationItems: state.tarsReducer.operationItems,
    operationLenItems: state.tarsReducer.operationLenItems,
    systemOperationLenItems: state.tarsReducer.systemOperationLenItems,
  }));


  console.log(props.isRollout, 'props.isRollout')

  // 初始化clusterName
  useEffect(() => {
    if (operationItems && operationItems.size > 0) {
      let newClusterName = "";
      if (isEmpty(clusterName)) {
        newClusterName = pickClusterName(Array.from(operationItems.keys()));
        setClusterName(newClusterName);
      }
    }

    buildTotal();
  }, [
    selectedTarsApplication,
    selectedTarsServer,
    selectedTarsSetName,
    operationItems,
    operationLenItems,
  ]);

  const buildTotal = () => {
    if (operationLenItems && operationLenItems.size > 0) {
      let newTotal = operationLenItems.get(clusterName) || 0;
      if (!withSystemEvent && systemOperationLenItems && newTotal > 0) {
        let len = systemOperationLenItems.get(clusterName);
        if (len) newTotal -= len;
      }
      setTotal(newTotal);
    }
  };


  useEffect(() => {
    if (props.isRollout) {
      setWithRolloutEvent(true);
      setWithSystemEvent(false);
      setEventType(EVENT_TYPE.ROLL_EVENT);
    } else {
      setWithRolloutEvent(false);
      setWithSystemEvent(true);
    }
  }, [
    props.isRollout
  ])

  // 初始化total
  useEffect(() => {
    fetchOperationHistory(1);
  }, [
    clusterName,
    withSystemEvent,
    withRolloutEvent,
    selectedTarsApplication,
    selectedTarsServer,
    selectedTarsSetName,
  ]);

  const fetchOperationHistory = (currentPage: any) => {
    if (!selectedTarsApplication || !selectedTarsServer) return;

    if (!clusterName) {
      return;
    }
    setIsLoading(true);
    fetch
      .fetchQueryOperation({
        currentPage,
        withSystemEvent: withSystemEvent ? 1 : 0,
        withRolloutEvent: withRolloutEvent ? 1 : 0,
        clusterName: clusterName,
        tarsApplication: selectedTarsApplication,
        tarsSetName: selectedTarsSetName || "",
        tarsServerName: selectedTarsServer,
      })
      .then((data) => {
        setCurrentPage(currentPage);
        setItems(data.items);
        buildTotal();
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(true);
      });
  };

  const PAGE_SIZE = 12;

  return (
    <>
      {operationItems && operationItems.size > 0 && (
        <Tabs
          size="small"
          activeKey={clusterName}
          onChange={(newClusterName) => {
            setClusterName(newClusterName);
            fetchOperationHistory(1);
          }}
          tabPosition="top"
        >
          {Array.from(operationItems).map((values: any) => (
            <TabPane tab={values[0]} key={values[0]}>
              {!isEmpty(items) && (
                <>
                  <Card
                    title={
                      <span>
                        历史日志 <Divider type="vertical" />
                      </span>
                    }
                    extra={
                      <Button size="small" type="link">
                        Timezone (UTC+08:00)
                      </Button>
                    }
                    style={{ height: "100%", width: "100%" }}
                  >
                    <CardMeta
                      description={
                        <Row style={{ textAlign: "center" }}>
                          <Col
                            style={{
                              textAlign: "left",
                              marginRight: 20,
                            }}
                          >
                            <span style={{ fontSize: 3 }}>
                              日志类型 ：
                              <Select
                                style={{
                                  width: 200,
                                }}
                                value={eventType}
                                onSelect={(val: any) => {
                                  if (val === EVENT_TYPE.ALL_EVENT) {
                                    setWithSystemEvent(true);
                                  } else {
                                    setWithSystemEvent(false);
                                  }


                                  if (val === EVENT_TYPE.ROLL_EVENT) {
                                    setWithRolloutEvent(true);
                                  } else {
                                    setWithRolloutEvent(false);
                                  }
                                  setEventType(val);
                                }}
                              >
                                <Select.Option value={EVENT_TYPE.ALL_EVENT}>
                                  所有日志(包含系统日志)
                                </Select.Option>
                                <Select.Option value={EVENT_TYPE.USER_EVENT}>
                                  用户操作日志
                                </Select.Option>
                                <Select.Option value={EVENT_TYPE.ROLL_EVENT}>
                                  滚动发布日志
                                </Select.Option>
                              </Select>
                            </span>
                          </Col>
                          <Col flex="1 1 auto">
                            <Search
                              style={{ width: "100%" }}
                              placeholder="Search by IP, user, event, or time..."
                              onChange={({ target: { value } }) =>
                                setSearchText(value as any)
                              }
                            />
                          </Col>
                        </Row>
                      }
                    />{" "}
                    <Timeline
                      pending={isEmpty(items) ? false : <>Now...</>}
                      reverse={true}
                      style={{ marginTop: 35 }}
                    >
                      {items
                        .filter((item: any) =>
                          searchText && (searchText as any).length >= 2
                            ? item.summary.includes(searchText) ||
                            item.description.includes(searchText) ||
                            MomentFormat(item.creationTimestamp).includes(
                              searchText
                            )
                            : true
                        )
                        .filter((item: any) =>
                          withSystemEvent
                            ? true
                            : item.userName !== "tars" &&
                            item.userName !== "定时检查脚本"
                        )
                        .reverse()
                        .map((item: any, index) => (
                          <TimelineItem
                            color={item.color}
                            dot={
                              item.userName === "tars" ? (
                                <ClockCircleOutlined />
                              ) : null
                            }
                          >
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    maxHeight: "600px",
                                    minWidth: "500px",
                                    overflow: "auto",
                                  }}
                                >
                                  <b>Time: </b>
                                  <br />
                                  {MomentFormat(item.creationTimestamp)}
                                  <br />
                                  <b>参数: </b>
                                  <br />
                                  <JSONPretty
                                    width="100%"
                                    json={(() => {
                                      try {
                                        const jsonString =
                                          item.description.split("参数: ")[1];
                                        let newJson = JSON.parse(jsonString);
                                        return newJson;
                                      } catch (error) {
                                        return "";
                                      }
                                    })()}
                                  />
                                </div>
                              }
                              overlayStyle={{
                                maxWidth: "65vw",
                                marginLeft: 30,
                              }}
                              key={index}
                              placement="top"
                            >
                              <span
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                {MomentFormat(
                                  item.creationTimestamp,
                                  "MM-DD HH:mm:ss"
                                )}{" "}
                                {item.userName !== "tars"
                                  ? item.userName
                                  : null}
                                {item.summary}
                              </span>
                            </Tooltip>
                          </TimelineItem>
                        ))}
                    </Timeline>
                  </Card>

                  <Pagination
                    simple
                    size="small"
                    hideOnSinglePage
                    pageSize={PAGE_SIZE}
                    current={currentPage}
                    onChange={(newPage) => {
                      fetchOperationHistory(newPage);
                    }}
                    style={{
                      position: "absolute",
                      bottom: "12px",
                      right: "8px",
                    }}
                    total={total}
                  />
                </>
              )}
              {isEmpty(items) && (
                <Spin spinning={isLoading}>
                  <Empty style={{ marginTop: 150 }} />
                </Spin>
              )}
            </TabPane>
          ))}
        </Tabs>
      )}

      {(!operationItems || operationItems.size === 0) && (
        <Empty style={{ marginTop: 150 }} />
      )}
    </>
  );
};

export default ServerOperationHistory;
