import React from "react";
import { Table, Tabs, Spin, Tag, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { FilterConfirmProps } from "antd/es/table/interface";

import * as fetch from "src/fetch";
import CommonTableProps from "src/utils/CommonTableProps";
import ExpandNodePoolButton from "src/pages/Common/ExpandNodePoolButton";
import ExpandNodePoolTitle from "src/pages/Common/ExpandNodePoolTitle";
import { pickClusterName } from "src/utils/Picker";
import { isEmpty } from "lodash";

const { TabPane } = Tabs;

interface IProps {
  currentUser?: string;
}

interface INodePool {
  zone: string;
  instance: string;
  nodePoolName: string;
  runningCount: string;
  description: string;

  confirmVisible?: boolean;
}

interface IState {
  loading: boolean;
  innerLoading: boolean;

  clusterName: string;
  dataSource: Map<string, Array<INodePool>>;

  searchText?: string;
}

export default class ManageNodePool extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      innerLoading: true,
      clusterName: "",
      dataSource: new Map(),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData(innerLoading?: boolean) {
    if (innerLoading) this.setState({ innerLoading: true });
    fetch
      .fetchListNodePool()
      .then((data) => {
        let dataSource = new Map<string, Array<INodePool>>();
        Object.entries(data.items).forEach((value: [string, any]) => {
          dataSource.set(value[0], value[1] as Array<INodePool>);
        });
        let clusterName = this.state.clusterName;
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(dataSource.keys()));
        }
        this.setState({
          loading: false,
          innerLoading: false,
          clusterName,
          dataSource,
        });
      })
      .catch((_) => {});
  }

  fetchExpandNodePool(nodePoolName: string, count: number, reason: string) {
    fetch
      .fetchExpandNodePool({
        clusterName: this.state.clusterName,
        nodePoolName,
        count,
        reason,
        userName: this.props.currentUser || "",
      })
      .then(() => {
        notification.success({ message: "扩容成功" });
      })
      .catch((_) => {});
  }

  onSearch(
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps | undefined) => void
  ) {
    confirm();
    this.setState({ searchText: selectedKeys[0] as string });
  }

  onSearchReset(
    clearFilters: (() => void) | undefined,
    confirm: (param?: FilterConfirmProps | undefined) => void
  ) {
    if (clearFilters) clearFilters();
    confirm();
    this.setState({ searchText: "" });
  }

  render() {
    const columns: ColumnsType<INodePool> = [
      {
        title: "节点池",
        dataIndex: "nodePoolName",
        width: 100,
        align: "center",
        ellipsis: true,
        ...CommonTableProps(
          "nodePoolName",
          "节点池",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
      },
      {
        title: "规格",
        dataIndex: "instance",
        width: 50,
        align: "center",
        ...CommonTableProps(
          "instance",
          "规格",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
        render: (text: string) => (
          <span>
            {text
              .split(",")
              .sort()
              .map((value: string, index: number) => (
                <>
                  <Tag color="geekblue" key={index}>
                    {value}
                  </Tag>
                  <br />
                </>
              ))}
          </span>
        ),
      },
      {
        title: "可用区",
        dataIndex: "zone",
        width: 30,
        align: "center",
        ...CommonTableProps(
          "zone",
          "可用区",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
        render: (text: string) => (
          <span>
            {text
              .split(",")
              .sort()
              .map((value: string, index: number) => (
                <Tag color="geekblue" key={index}>
                  {value}
                </Tag>
              ))}
          </span>
        ),
      },
      {
        title: "数量",
        dataIndex: "runningCount",
        width: 50,
        align: "center",
        ...CommonTableProps(
          "runningCount",
          "数量",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
      },
      {
        title: "描述",
        dataIndex: "description",
        width: 50,
        align: "center",
        ...CommonTableProps(
          "description",
          "描述",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
        render: (text: string) => (
          <span>
            {text.split("\n").map((value: string, index: number) => (
              <Tag color="volcano" key={index}>
                {value}
              </Tag>
            ))}
          </span>
        ),
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 95,
        align: "center",
        ellipsis: true,
        render: (text: string, record: INodePool) => (
          <ExpandNodePoolButton
            currentUser={this.props.currentUser}
            clusterName={this.state.clusterName}
            nodePoolName={record.nodePoolName}
          />
        ),
      },
    ];
    return (
      <>
        {this.state.loading && (
          <div style={{ height: 300 }}>
            <Spin
              size="large"
              tip="加载中..."
              style={{ position: "relative", left: "50%", top: "50%" }}
            />
          </div>
        )}

        {!this.state.loading && (
          <Tabs
            size="large"
            type="card"
            style={{ marginLeft: 8 }}
            onChange={(clusterName) => this.setState({ clusterName })}
            activeKey={this.state.clusterName}
          >
            {Array.from(this.state.dataSource).map((values) => (
              <TabPane tab={values[0]} key={values[0]}>
                <Table
                  size="small"
                  title={() => (
                    <ExpandNodePoolTitle
                      fetchData={() => this.fetchData(true)}
                    />
                  )}
                  pagination={{
                    pageSize: 75,
                    simple: true,
                    hideOnSinglePage: true,
                    defaultCurrent: 1,
                  }}
                  columns={columns}
                  loading={this.state.innerLoading}
                  rowKey="nodePoolName"
                  dataSource={values[1]}
                />
              </TabPane>
            ))}
          </Tabs>
        )}
      </>
    );
  }
}
