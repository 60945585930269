import { AxiosRequestConfig } from "axios";
import { get as _get, post as _post } from "./baseApi";
import { notification } from "antd";

const CODE_MAP = {
  OK: 0,
};
function get<T>(
  path: string,
  params?: { [key: string]: any },
  option?: AxiosRequestConfig
) {
  return _get<{
    code: number;
    message: string;
    data: T;
  }>(`api/${path}`, params || {}, option).then((res) => {
    if (res.code === CODE_MAP.OK) {
      return res.data;
    } else {
      notification.error({
        message: `${path} 请求失败, 稍后重试`,
        description: `errCode:${res.code}\nerrMsg:${res.message}`,
        style: { width: 500, whiteSpace: "pre-line" },
      });
      return Promise.reject();
    }
  });
}

function post<T>(
  path: string,
  params: { [key: string]: any },
  option?: AxiosRequestConfig
) {
  return _post<{
    code: number;
    message: string;
    data?: T;
  }>(`api/${path}`, params, option).then((res) => {
    if (res.code === CODE_MAP.OK) {
      return res.data;
    } else {
      notification.error({
        message: `${path} 请求失败, 稍后重试`,
        description: `errCode:${res.code}\nerrMsg:${res.message}`,
        style: { width: 500, whiteSpace: "pre-line" },
      });
      return Promise.reject();
    }
  });
}

const webApi = {
  get,
  post,
};

export { get, post };
export default webApi;
