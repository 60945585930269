import React from 'react';
import { Button, InputNumber, Form, FormInstance, Popconfirm, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import * as fetch from 'src/fetch';

const FormItem = Form.Item;

interface IProps {
  replicas: number;
  currentUser?: string;
  clusterName: string;
  nodeResourceID: number;
}

interface IState {
  visible: boolean;
}

export default class AdjustNodePoolPlaceholderButton extends React.Component<IProps, IState> {
  private adjustForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.state = {visible: false};
    this.adjustForm = React.createRef<FormInstance>();
  }

  fetchAdjustNodePoolPlaceholder(count: number) {
    fetch
    .fetchAdjustNodePoolPlaceholder({
      clusterName: this.props.clusterName,
      nodeResourceID: this.props.nodeResourceID,
      replicas: count,
      userName: this.props.currentUser || ''
    })
    .then(() => notification.success({message: '调整成功, 稍后再来查看结果'}))
    .catch(_ => {});
  }

  render() {
    return (
      <Popconfirm
        icon={null}
        placement='left'
        title={
          <Form
            name='adjustForm'
            ref={this.adjustForm}
            validateTrigger='onBlur'
          >
            <FormItem name='count' rules={[{ required: true, message: '数量必填' }]}>
              <InputNumber min={0} defaultValue={this.props.replicas} addonBefore='数量' />
            </FormItem>
          </Form>
        }
        trigger='click'
        okText='调整'
        cancelText='取消'
        visible={this.state.visible}
        okButtonProps={{className: 'tool-btn', ghost: true, size: 'small', type: 'primary'}}
        cancelButtonProps={{className: 'tool-btn', ghost: true, size: 'small', danger: true}}
        onConfirm={async () => {
          try {
            if (this.adjustForm.current) {
              const { count } = await this.adjustForm.current.validateFields();
              this.setState({visible: false})
              this.fetchAdjustNodePoolPlaceholder(count);
            }
          } catch {}
        }}
        onCancel={() => this.setState({visible: false})}
        destroyTooltipOnHide
      >
        <Button size='small' className='tool-btn' onClick={() => this.setState({visible: true})}>
          <PlusOutlined />调整
        </Button>
      </Popconfirm>
    );
  }
};
