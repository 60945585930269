import React from "react";
import { isEmpty } from "lodash";
import {
  Button,
  Space,
  Popconfirm,
  Modal,
  InputNumber,
  message,
  notification,
} from "antd";
import { SyncOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import * as fetch from "src/fetch";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";
import * as util from "src/pages/DevOps/ToolsBar/util";

interface IProps {
  disabled: boolean;
  currentUser?: string;
  gracefully: boolean;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  selectedItems?: Array<ActionType.ITarsServerItem>;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  story_fetch: Record<string, any>;

  setTaskManagerDot: (taskManagerDot: boolean) => void;
}

interface IState {
  animate: boolean;
  confirmVisible: boolean;
}

const Restart: React.FC<IProps> = (props) => {
  const [state, setState] = React.useState<IState>({
    animate: false,
    confirmVisible: false,
  });
  const {
    currentUser,
    selectedTarsApplication,
    selectedTarsSetName,
    selectedTarsServer,
    selectedItems,
  } = props;
  const [restartBatches, setBatches] = React.useState(1);
  const [visible, setVisible] = React.useState(false);

  const handleRestart = (batches?: number) => {
    setState({ animate: true, confirmVisible: false });
    const bs = batches
      ? Math.ceil((selectedItems?.length as number) / batches)
      : 1;

    if (
      selectedTarsApplication &&
      selectedTarsServer &&
      selectedItems &&
      !isEmpty(selectedItems)
    ) {
      fetch
        .fetchRestartServer({
          userName: currentUser || "",
          graceful: props.gracefully ? 1 : 0,
          batches: bs,
          tarsApplication: selectedTarsApplication,
          tarsSetName: selectedTarsSetName || "",
          tarsServerName: selectedTarsServer,
          podNetworkInterfaces: selectedItems.map((item) => ({
            podIP: item.podIP,
            clusterName: item.clusterName,
          })),
        })
        .then((data) => {
          notification.destroy();
          notification.success({
            message:
              (props.gracefully ? "无损" : "") +
              "重启成功, 左侧【任务】中查看任务进度",
            description: `taskID:${data.taskID}`,
            duration: 15,
            style: { width: 600, whiteSpace: "pre-line" },
          });
          setState({ animate: false, confirmVisible: false });
          console.log(
            "restart server success  story_fetch:",
            props.story_fetch
          );
          setTimeout(() => {
            props?.story_fetch?.taskRefresh();
          }, 3000);
          props.setTaskManagerDot(true);
        })
        .catch((err) => {
          setState({ animate: false, confirmVisible: false });
        })
    }
  };

  return (
    <>
      <Popconfirm
        title={
          props.gracefully
            ? `确定【无损重启】${
                props.selectedItems ? props.selectedItems.length : "0"
              }个节点?`
            : "直接【重启】没有流量切换过程,继续?"
        }
        visible={state.confirmVisible}
        placement="left"
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        onCancel={() => setState({ animate: false, confirmVisible: false })}
        onConfirm={() => handleRestart()}
        disabled={props.disabled}
      >
        <Button
          ghost
          disabled={props.disabled}
          size="small"
          className="tool-btn"
          type={props.gracefully ? "primary" : "default"}
          danger={!props.gracefully}
          onClick={() => {
            if (util.disableSelectNull(props.selectedItems)) {
              return;
            }

            if (props.gracefully) {
              if (props.tarsServerItems && props.tarsServerItems.length === 1) {
                message.error("单节点无损重启无意义");
                return;
              }

              if (util.allInactive(props.selectedItems)) {
                handleRestart(1);
                return;
              }

              if (props.selectedItems && props.selectedItems.length === 1) {
                handleRestart(1);
                return;
              }

              setVisible(true);
            } else {
              if (util.has("active", props.selectedItems)) {
                setState({ animate: false, confirmVisible: true });
                return;
              }

              setState({ animate: true, confirmVisible: true });
            }
          }}
        >
          <Space>
            <SyncOutlined
              style={{
                color: props.gracefully ? "#1890ff" : "#ff4d4f",
              }}
              spin={state.animate ? true : false}
            />
            {props.gracefully ? "无损重启" : "重启服务"}
          </Space>
        </Button>
      </Popconfirm>

      <Modal
        title="设置每批节点数量"
        destroyOnClose
        width={300}
        closable={false}
        visible={visible}
        okText={"无损重启"}
        onOk={() => {
          handleRestart(restartBatches);
          setVisible(false);
        }}
        onCancel={() => {
          setBatches(1);
          setVisible(false);
        }}
        okButtonProps={{ type: "primary", ghost: true, className: "tool-btn" }}
        cancelButtonProps={{ danger: true, ghost: true, className: "tool-btn" }}
      >
        <InputNumber
          min={1}
          style={{ width: "100%" }}
          size="large"
          value={restartBatches}
          required
          onChange={(batches) => setBatches(batches)}
        />
        <p
          style={{
            marginTop: 10,
          }}
        >
          节点分
          <b>
            【
            {restartBatches
              ? Math.ceil((selectedItems?.length as number) / restartBatches)
              : "_"}
            】
          </b>
          批重启, 每批为: <b>【{restartBatches || "_"}】</b>个
        </p>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  selectedItems: state.tarsReducer.selectedItems,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  replicasItems: state.tarsReducer.replicasItems,
  story_fetch: state.tarsReducer.story_fetch,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  setTaskManagerDot: (taskManagerDot: boolean) =>
    dispatch(Actions.setTaskManagerDot(taskManagerDot)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Restart);
