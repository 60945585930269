import React from 'react';
import { Button, Space } from 'antd';
import { ShrinkOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';

interface IProps {
  currentUser?: string;
};

interface IState {
  animate: boolean;
};

class Stop extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      animate: false,
    };
  }

  render() {
    return (
      <>
        <Button type='primary' size='small' className='tool-btn' ghost>
          <Space><ShrinkOutlined spin={this.state.animate ? true : false} />缩容</Space>
        </Button>
      </>
    );
  };
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
});

export default connect(mapStateToProps, null)(Stop);
