import React from 'react';
import { Button, Space } from 'antd';
import { BugOutlined } from '@ant-design/icons';


import { connect } from 'react-redux';

interface IProps {
  currentUser?: string;
};

interface IState {
  animate: boolean;
};

class DebugInterface extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      animate: false,
    };
  }

  render() {
    return (
      <>
        <Button className='tool-btn' size='small'>
          <Space><BugOutlined className={this.state.animate ? 'animate__animated animate__wobble animate__infinite animate__slower' : ''} />接口调试</Space>
        </Button>
      </>
    );
  };
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
});

export default connect(mapStateToProps, null)(DebugInterface);
