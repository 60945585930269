import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Modal, notification } from "antd";

export const APIBaseUrl = window.location.origin;
// test集群;
// export const APIBaseUrl =
//   process.env.NODE_ENV === "development"
//     ? "http://47.109.76.144:80/server/api/v1"
//     : `${window.location.origin}/server/api/v1`;

// export const APIBaseUrl =
//   process.env.NODE_ENV === "development"
//     ? "http://47.254.174.85:3000/server/api/v1"
//     : `${window.location.origin}/server/api/v1`;

// pre
// export const APIBaseUrl =
//   process.env.NODE_ENV === "development"
//     ? "http://47.91.86.121:3000/server/api/v1"
//     : `${window.location.origin}/server/api/v1`;

const catchHandler = (err: AxiosError) => {
  if (err.response?.status === 401) {
    Modal.destroyAll();
    window.location.href = `${
      process.env.NODE_ENV === "development" ? "/login_4_test" : "/login"
    }?redirect=${encodeURIComponent(window.location.href)}`;
  } else if (err.response?.status === 403) {
    Modal.destroyAll();
    Modal.warn({
      title: "权限拒绝",
      content: "用户权限不足，拒绝处理请求",
    });
  } else {
    notification.destroy();
    notification.error({
      message: `请求失败, 稍后重试`,
      description: `http code:${err.response?.status}\n 请求：${
        err.request.responseURL.split(APIBaseUrl)[1]
      }`,
      style: { width: 500, whiteSpace: "pre-line" },
    });
  }

  return Promise.reject(err);
};

const getTarsServer = (params: { [key: string]: any }) => {
  if (params.tarsApplication || params.tarsServerName) {
    return `${params.tarsApplication}.${params.tarsServerName}`;
  } else if (params.server) {
    return params.server;
  }
  return "";
};

function get<T>(
  path: string,
  params: { [key: string]: any },
  option?: AxiosRequestConfig
) {
  const _opt = option || {};
  return axios
    .get<T>(`${APIBaseUrl}/${path}`, {
      ..._opt,
      params,
      headers: {
        "tars-server": getTarsServer(params),
        ...(_opt.headers ? _opt.headers : {}),
      },
    })
    .then((res) => res.data)
    .catch(catchHandler);
}

function post<T>(
  path: string,
  params: { [key: string]: any },
  option?: AxiosRequestConfig
) {
  const _opt = option || {};
  return axios
    .post<T>(`${APIBaseUrl}/${path}`, params, {
      ...option,
      headers: {
        "tars-server": getTarsServer(params),
        ...(_opt.headers ? _opt.headers : {}),
      },
    })
    .then((res) => res.data)
    .catch(catchHandler);
}

export { get, post };
