import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  Ref,
} from 'react';

import {
  Modal,
  Spin,
  Empty,
  Space,
  Checkbox,
  CheckboxProps,
  List,
  Row,
  Col,
} from 'antd';

import { IRepoItem } from './Patch';

import {
  rolloutPreCheck,
  RolloutPreCheckData,
  DiffData
} from '../../../fetch';

import MomentFormat from "../../../utils/MomentFormat";

const { Item: ListItem } = List;

interface PostModalData {
  imgList: IRepoItem[],
  imgSelected: any
}

export interface PreCheckModalRef {
  show: (data: PostModalData) => void;
  hide: () => void;
}

interface PatchPreCheckModalProps {
  onConfirm?: () => void;
  userName: string;
  clusterName: string;
  tarsApplication: string;
  tarsSetName: string;
  tarsServerName: string;
}

const MAX_CHECK_COUNT = 3;

const DiffItemContent = (props: { data: DiffData}) => {
  const { data } = props;

  if(!data) return null;

  const { fullMessage, sha, commiter, commitDate, commitLink } = data;

  return (
    <div>
      <Row>
        <Col style={{width: 60}}>Commit:</Col>
        <Col><a target={'_blank'} rel={'noopener noreferrer'} href={commitLink}>{sha}</a></Col>
      </Row>

      <Row>
        <Col style={{width: 60}}>Author:</Col>
        <Col>
          <Space>
            <span>{commiter?.name}</span>
            <span>{commiter?.email ? `<${commiter.email}>` : null}</span>
          </Space>
        </Col>
      </Row>

      <Row>
        <Col style={{width: 60}}>Date:</Col>
        <Col>
          <span>{MomentFormat(commitDate/1000)}</span>
        </Col>
      </Row>

      <div style={{whiteSpace: 'pre-line', padding: '8px 0 0 24px'}}>
        {fullMessage}
      </div>
    </div>
  )
};

function PatchPreCheckModal(props: PatchPreCheckModalProps, ref: Ref<PreCheckModalRef>) {
  const {
    onConfirm,
    userName,
    clusterName,
    tarsApplication,
    tarsSetName,
    tarsServerName
  } = props;

  const [visible, updateVisible] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [data, updateData] = useState<RolloutPreCheckData>();
  const [checkCount, updateCheckCount] = useState(0);

  const close = () => {
    updateVisible(false);
  };

  const show = (data?: PostModalData) => {
    updateLoading(true);
    updateData(undefined);
    updateCheckCount(0);

    rolloutPreCheck({
      userName,
      clusterName,
      tarsApplication,
      tarsSetName,
      tarsServerName,
      tag: data?.imgSelected?.patchImage || ''
    })
      .then((res) => updateData(res))
      .catch(() => {})
      .finally(() => {
        updateLoading(false);
      })

    updateVisible(true);
  };

  const onOk = () => {
    close();
    onConfirm?.();
  };

  const onCheck: CheckboxProps['onChange'] = (e) => {
    const checked = e.target.checked;

    updateCheckCount(count => checked ? count + 1 : count - 1);
  };

  useImperativeHandle(ref, () => ({
    show,
    hide: close,
  }));

  const masterDiff = data?.diffWithMaster?.differentOfCommitInfo || [];
  const onlineDiff = data?.diffWithOnline?.differentOfCommitInfo || [];

  return (
    <Modal
      visible={visible}
      onCancel={close}
      onOk={onOk}
      centered
      width={580}
      okButtonProps={{
        disabled: Boolean(data) && checkCount !== MAX_CHECK_COUNT || loading
      }}
      okText={'下一步'}
      title={'版本确认'}
    >
      {
        loading ? (
          <Spin spinning>
            <div style={{height: 200}} />
          </Spin>
          ) : (
          <div>
            <div style={{marginBottom: 24, fontWeight: 'bold'}}>
              请仔细核对发布的版本，勾选确认后方可继续操作
            </div>
            {
              data ? (
                <Space size={24} style={{width: '100%'}} direction={'vertical'}>
                  <Space>
                    <Checkbox onChange={onCheck} />
                    <span>
                      当前发布版本是否在master分支:
                    </span>
                    <span style={{fontWeight: 'bold'}}>
                      {
                        data.isMaster ? (
                          <span>是</span>
                        ) : (
                          <span style={{color: '#ff4d4f'}}>未在master</span>
                        )
                      }
                    </span>
                  </Space>

                  <Space direction={'vertical'} style={{width: '100%'}}>
                    <Space>
                      <Checkbox onChange={onCheck} />
                      <span>
                        当前版本与master分支commit差异:
                        {
                          masterDiff.length ? (
                            <span style={{fontWeight: 'bold'}}>
                                {` ${masterDiff.length} commit`}
                              </span>
                          ) : null
                        }
                      </span>
                      {
                        !masterDiff.length && (
                          <span style={{fontWeight: 'bold'}}>无</span>
                        )
                      }
                    </Space>
                    {
                      Boolean(masterDiff.length) && (
                        <div style={{paddingLeft: 24}}>
                          <List
                            bordered
                            dataSource={data.diffWithMaster?.differentOfCommitInfo || []}
                            renderItem={item => (
                              <ListItem>
                                <DiffItemContent data={item} />
                              </ListItem>
                            )}
                          />
                        </div>
                      )
                    }
                  </Space>

                  <Space direction={'vertical'} style={{width: '100%'}}>
                    <Space>
                      <Checkbox onChange={onCheck} />
                      <span>
                        当前版本与线上版本commit差异:
                        {
                          onlineDiff.length ? (
                            <span style={{fontWeight: 'bold'}}>
                                {` ${onlineDiff.length} commit`}
                              </span>
                          ) : null
                        }
                      </span>
                      {
                        !onlineDiff.length && (
                          <span style={{fontWeight: 'bold'}}>无</span>
                        )
                      }
                    </Space>
                    {
                      Boolean(onlineDiff.length) && (
                        <div style={{paddingLeft: 24}}>
                          <List
                            bordered
                            dataSource={data.diffWithOnline?.differentOfCommitInfo || []}
                            renderItem={item => (
                              <ListItem>
                                <DiffItemContent data={item} />
                              </ListItem>
                            )}
                          />
                        </div>
                      )
                    }
                  </Space>
                </Space>
              ) : (
                <Empty description={'当前无版本信息'} />
              )
            }
          </div>
        )
      }
    </Modal>
  );
}

export default forwardRef(PatchPreCheckModal);
