import React from 'react';
import { Row, Col } from 'antd';

export default class PodQosClassTooltip extends React.Component {
  render() {
    return (
      <Row>
        <Col>节点主机超负载时</Col>
        <Col>按优先级从低到高无损驱逐服务</Col>
        <Col>优先级:(Guaranteed &gt; Burstable)</Col>
        <Col>Guaranteed资源不能超限制使用</Col>
        <Col>内存超过限制会被oom kill</Col>
        <Col>Burstable资源限制为Request x 1.5</Col>
      </Row>
    );
  }
};
