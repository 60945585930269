import { isEmpty } from 'lodash';
import store from 'src/stores';

export const pickClusterName = (clusterNames: Array<string>): string => {
  if (isEmpty(clusterNames)) {
    return "";
  }

  const preference  = store.getState().tarsReducer.preference;
  if (clusterNames.indexOf(preference.defaultClusterName) !== -1) {
    return preference.defaultClusterName;
  }

  return clusterNames[0];
};
