
import React from 'react';
import { isEmpty } from 'lodash';
import { Button, Popover, Tag } from 'antd';
import { EyeTwoTone } from '@ant-design/icons';

import * as FetchType from 'src/fetch/types';

interface IProps {
  taints: Array<FetchType.ITaint>;
}

interface IState {
  visible: boolean;
};

export default class ViewTaintButton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false };
  }

  render() {
    return (
      <>
        <Popover
          trigger='click'
          placement='left'
          title='污点（Taints）'
          visible={this.state.visible}
          onVisibleChange={visible => isEmpty(this.props.taints) ? this.setState({visible: false}) : this.setState({visible})}
          content={
            <>
              {
                this.props.taints.map((value, index) => (
                  <Tag key={index}>
                    {value.taintKey}:{value.taintValue} effect:{value.effect}
                  </Tag>
                ))
              }
            </>
          }
        >
          <Button size='small' className='tool-btn' disabled={this.props.taints.length === 0}>
            <EyeTwoTone />查看
          </Button>
        </Popover>
      </>
    );
  }
};
