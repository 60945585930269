
import React from 'react';
import { Table, Drawer } from 'antd';

import { ColumnsType } from 'antd/es/table';

interface IProps {
  visible: boolean;
  onClose: () => void;
};

interface IExplain {
  index: number;
  hpa: string;
  cronhpa: string;
  deployment: string;
  result: React.ReactNode;
  explain: React.ReactNode;
};

export default class HPADrawer extends React.Component<IProps> {
  render() {
    const columns : ColumnsType<IExplain> = [
      {
        title: '自动扩容(min/max)', dataIndex: 'hpa'
      },
      {
        title: '定时扩容', dataIndex: 'cronhpa'
      },
      {
        title: '当前数量', dataIndex: 'deployment'
      },
      {
        title: '扩容结果', dataIndex: 'result'
      },
      {
        title: '兼容规则说明', dataIndex: 'explain'
      },
    ];
    const dataSource : Array<IExplain> = [{
      index: 0,
      hpa: '1/10',
      cronhpa: '5',
      deployment: '5',
      result: <><p>自动扩容（min/max）：1/10</p> <p>当前数量：5</p></>,
      explain: '当【定时扩容】中的目标副本数和当前副本数一致时，【自动扩容】中的最大和最小副本数，还有应用当前的副本数无需变更。'
    }, {
      index: 1,
      hpa: '1/10',
      cronhpa: '4',
      deployment: '5',
      result: <><p>自动扩容（min/max）：1/10</p> <p>当前数量：5</p></>,
      explain: '当【定时扩容】中的目标副本数低于当前副本数时，保留当前副本数。'
    }, {
      index: 2,
      hpa: '1/10',
      cronhpa: '6',
      deployment: '5',
      result: <><p>自动扩容（min/max）：6/10</p> <p>当前数量：6</p></>,
      explain: <>
        <p>当【定时扩容】中的目标副本数高于当前副本数时，保留【定时扩容】的目标副本数。</p>
        <p>【定时扩容】目标副本数高于【自动扩容】副本数下限（minReplicas）时，修改【自动扩容】的副本数下限。</p>
        </>
    }, {
      index: 3,
      hpa: '5/10',
      cronhpa: '4',
      deployment: '5',
      result: <><p>自动扩容（min/max）：4/10</p> <p>当前数量：5</p></>,
      explain: <>
        <p>当【定时扩容】中的目标副本数低于当前副本数时，保留当前应用副本数。</p>
        <p>【定时扩容】目标副本数低于【自动扩容】副本数下限（minReplicas）时，修改HPA的副本数下限。</p>
        </>
    }, {
      index: 4,
      hpa: '5/10',
      cronhpa: '11',
      deployment: '5',
      result: <><p>自动扩容（min/max）：11/11</p> <p>当前数量：11</p></>,
      explain: <>
        <p>当【定时扩容】中的目标副本数高于当前副本数时，保留【定时扩容】的目标副本数。</p>
        <p>【定时扩容】目标副本数高于【自动扩容】副本数上限（maxReplicas）时，修改【自动扩容】的副本数上限。</p>
        </>
    }];
    return (
      <Drawer
        size='large'
        visible={this.props.visible}
        placement='bottom'
        closable={false}
        onClose={() => this.props.onClose()}
        title='【自动扩容】与【定时扩容】同时配置兼容行为'
      >
        <Table
          rowKey='index'
          size='small'
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </Drawer>
    );
  }
}
