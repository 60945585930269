export const formatValueToOptions = (value: any[]) => {
  return value.map((item) => {
    if (item.label || item.value) {
      return item;
    }
    return {
      label: item,
      value: item,
    };
  });
};

export const REPROTER_STATUS = {
  ADD: "add",
  EDIT: "edit",
  VIEW: "view",
};

type EditType =
  | "text"
  | "date"
  | "checkbox"
  | "textArea"
  | "code"
  | "select"
  | "switch"
  | "coding";

export interface Field {
  readonly field: string;
  readonly fieldDisplayName: string;
  readonly type: "int";
  readonly editType: EditType;
  options?: Array<FieldOption>;
  readonly isMultiSelect?: boolean;
  readonly formatType?: string;
  readonly essential: boolean;
  readonly defaultValue?: string;
  readonly disableEdit: boolean;
  readonly placeholder?: string;
  readonly tips?: string;
  readonly hide: boolean;
  readonly formatByTimezone: boolean;
  isAdvanced?: boolean;
}

type FieldOption = string;
