/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { DataNode } from "rc-tree/lib/interface";
import { DataNode as TreeSelectDataNode } from "rc-tree-select/lib/interface";

import * as ActionType from "src/stores/types";
import * as FetchType from "src/fetch/types";
export { default as webApi } from "./webApi";
export * from "./tarsServerApi";

export function buildDCacheDataNodesFromMemory(
  treeNodes: Array<ActionType.IDcacheTreeItem>
): Array<DataNode> {
  let dataNodes = new Array<DataNode>();
  let kvRoot: DataNode = {
    key: "kv",
    isLeaf: false,
    title: "KV",
    children: [],
  };
  let mkvRoot: DataNode = {
    key: "mkv",
    isLeaf: false,
    title: "MKV",
    children: [],
  };
  let listRoot: DataNode = {
    key: "list",
    isLeaf: false,
    title: "List",
    children: [],
  };
  let setRoot: DataNode = {
    key: "set",
    isLeaf: false,
    title: "Set",
    children: [],
  };
  let zsetRoot: DataNode = {
    key: "zset",
    isLeaf: false,
    title: "ZSet",
    children: [],
  };
  let coreRoot: DataNode = {
    key: "core",
    isLeaf: false,
    title: "系统服务",
    children: [],
  };
  const generateTitle = (title: string) => (
    <span
      style={{
        width: 130,
        display: "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {title}
    </span>
  );
  for (const i in treeNodes) {
    const node = treeNodes[i];
    const title = generateTitle(node.moduleName);
    if (node.type === "kv") {
      if (kvRoot.children)
        kvRoot.children.push({ key: node.moduleName, isLeaf: true, title });
    } else if (node.type === "mkv") {
      if (mkvRoot.children)
        mkvRoot.children.push({ key: node.moduleName, isLeaf: true, title });
    } else if (node.type === "list") {
      if (listRoot.children)
        listRoot.children.push({ key: node.moduleName, isLeaf: true, title });
    } else if (node.type === "set") {
      if (setRoot.children)
        setRoot.children.push({ key: node.moduleName, isLeaf: true, title });
    } else if (node.type === "zset") {
      if (zsetRoot.children)
        zsetRoot.children.push({ key: node.moduleName, isLeaf: true, title });
    }
  }
  const addButton = (key: string, type: string) => ({
    key,
    type,
    isLeaf: true,
    title: (
      <Button type="dashed" size="small" className="tool-btn">
        <PlusCircleOutlined />
        上线模块
      </Button>
    ),
    icon: <></>,
  });

  if (kvRoot.children) {
    kvRoot.children.push(addButton("addKV", "kv"));
  }
  if (mkvRoot.children) {
    mkvRoot.children.push(addButton("addMKV", "mkv"));
  }
  if (listRoot.children) {
    listRoot.children.push(addButton("addList", "list"));
  }
  if (setRoot.children) {
    setRoot.children.push(addButton("addSet", "set"));
  }
  if (zsetRoot.children) {
    zsetRoot.children.push(addButton("addZSet", "zset"));
  }
  if (coreRoot.children) {
    coreRoot.children.push({
      key: "MTTRouter",
      isLeaf: true,
      title: "MTTRouter",
    });
    coreRoot.children.push({
      key: "MTTProxy",
      isLeaf: true,
      title: "MTTProxy",
    });
  }

  dataNodes.push(kvRoot);
  dataNodes.push(mkvRoot);
  dataNodes.push(listRoot);
  dataNodes.push(setRoot);
  dataNodes.push(zsetRoot);
  dataNodes.push(coreRoot);
  return dataNodes;
}

export function buildDataNodesFromMemory(
  treeNodes: Array<ActionType.ITreeItem>
): Array<DataNode> {
  let dataNodes = new Array<DataNode>();
  let tree: Map<string, DataNode> = new Map();
  let subTree: Map<string, DataNode> = new Map(); // set子树
  let leafNodes: Map<string, DataNode> = new Map(); // 无set服务子节点
  const generateTitle = (title: string) => (
    <span
      style={{
        width: "calc(100% - 35px)",
        display: "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {title}
    </span>
  );
  console.log(treeNodes);
  for (const item of treeNodes) {
    const tarsApplication = item.tarsApplication || "";
    const tarsServerName = item.tarsServerName || "";
    const tarsSetName = item.tarsSetName || "";
    if (tarsSetName === ".." || tarsSetName === "") {
      const key = tarsApplication + "." + tarsServerName;
      const title = generateTitle(tarsServerName);
      let leafNode: DataNode = { key, isLeaf: true, title, icon: null };
      leafNodes.set(key, leafNode);
    } else {
      const key = tarsApplication + "." + tarsSetName + "." + tarsServerName;
      const title = generateTitle(tarsServerName);
      let leafNode: DataNode = { key, isLeaf: true, title, children: [] };
      const parentKey = tarsApplication + "." + tarsSetName;
      let parent = subTree.get(parentKey);
      if (parent && parent.children) {
        parent.children.push(leafNode);
        subTree.set(parentKey, parent);
      } else {
        let parent: DataNode = {
          key: parentKey,
          title: tarsSetName,
          children: [leafNode],
        };
        subTree.set(parentKey, parent);
      }
    }

    if (!tree.get(tarsApplication)) {
      tree.set(tarsApplication, {
        key: tarsApplication,
        title: tarsApplication,
        children: [],
      });
    }
  }

  leafNodes.forEach((leafNode, key) => {
    const parts = key.split(".");
    let parent = tree.get(parts[0]);
    if (parent && parent.children) {
      parent.children.push(leafNode);
      tree.set(parts[0], parent);
    }
  });
  subTree.forEach((leafNode, key) => {
    const parts = key.split(".");
    if (leafNode.isLeaf || parts.length !== 4) {
      return true;
    } else {
      let grandParent = tree.get(parts[0]) || {
        key: parts[0],
        title: parts[0],
        children: [],
      };
      if (grandParent.children) {
        let found = false;
        const parentKey = parts.slice(0, -1).join(".");
        for (let parent of grandParent.children) {
          if (parent.key === parentKey) {
            found = true;
            if (!parent.children) {
              parent.children = leafNode.children;
            } else {
              parent.children = parent.children.concat(leafNode.children || []);
            }
            break;
          }
        }
        if (!found) {
          const title = generateTitle(parts.slice(1).join("."));
          let parent: DataNode = {
            key: key,
            title,
            children: leafNode.children,
            icon: null,
          };
          grandParent.children.push(parent);
        }
        tree.set(parts[0], grandParent);
      }
    }
    return true;
  });
  tree.forEach((leafNode, key) => {
    dataNodes.push(leafNode);
  });

  let scoreTable = new Map([
    ["MTT", -100],
    ["Ad", -99],
    ["Profile", -98],
    ["NLP", -97],
    ["Crawler", -96],
    ["Firebase", -95],
    ["tars", 100],
  ]);
  dataNodes.sort((a: DataNode, b: DataNode) => {
    let accessScore = (val: DataNode): number => {
      const title = val.title as string;
      const score = scoreTable.get(title);
      if (!score) return title.length + 50;
      return score;
    };
    let x = accessScore(a);
    let y = accessScore(b);
    return x - y;
  });

  console.log(tree, dataNodes);
  const sortSecondLevel = (node: DataNode) => {
    if (node.children) {
      node.children.sort((a, b) => {
        const titleA = a.key as string;
        const titleB = b.key as string;

        const regex = /^([a-zA-Z.]+)(\d*)$/;
        const matchA = titleA.match(regex);
        const matchB = titleB.match(regex);

        if (matchA && matchB) {
          const [, prefixA, numA] = matchA;
          const [, prefixB, numB] = matchB;

          if (prefixA < prefixB) return -1;
          if (prefixA > prefixB) return 1;
          if (numA && numB) return parseInt(numA, 10) - parseInt(numB, 10);
        }

        return 0;
      });

      // Apply sorting to the second level of the tree
      node.children.forEach((childNode) => {
        sortSecondLevel(childNode);
      });
    }
  };

  // // Call the function to sort the second level of the tree
  dataNodes.forEach((node) => {
    sortSecondLevel(node);
  });
  return dataNodes;
}

export function buildSelectDataNodesFromMemory(
  treeNodes: Array<FetchType.IAntiAffinity>
): Array<TreeSelectDataNode> {
  let dataNodes = new Array<TreeSelectDataNode>();
  for (let node of treeNodes) {
    let title = "";
    if (node.tarsSetName.length > 0) {
      title =
        node.tarsApplication +
        "." +
        node.tarsSetName +
        "." +
        node.tarsServerName;
    } else {
      title = node.tarsApplication + "." + node.tarsServerName;
    }
    dataNodes.push({
      title,
      key: title,
      value: title,
      isLeaf: true,
      resourceName: node.resourceName,
    });
  }
  return dataNodes;
}
