import * as fetch from "src/fetch";
import * as constants from "src/stores/constants";

import * as ActionType from "src/stores/types";
import { DataNode } from "rc-tree/lib/interface";

import { message } from "antd";
import { MessageType } from "antd/lib/message";
import * as FetchType from "src/fetch/types";

const { webApi } = fetch;

export type LoginPayLoad = {
  currentUser: string;
  avatar: string;
  hasAdminPerm: boolean;
  admin: boolean;
};

export type ListTarsServerTreePayLoad = {
  loadDone?: boolean;
  treeNodes?: Array<ActionType.ITreeItem>;
};

export type ListDCacheTreePayLoad = {
  loadDCacheDone?: boolean;
  dcacheTreeNodes?: Array<ActionType.IDcacheTreeItem>;
  dcacheDataNodes?: Array<DataNode>;
};

export type RebuildTreePayLoad = {
  dataNodes?: Array<DataNode>;
};

export type ListServerPayLoad = {
  tarsApplication?: string;
  tarsSetName?: string;
  tarsServer?: string;

  depotID?: number;
  pausedItems?: Map<string, number>;
  canResumeItems?: Map<string, number>;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  operationItems?: Map<string, Array<ActionType.IOperationItem>>;
  operationLenItems?: Map<string, number>;
  systemOperationLenItems?: Map<string, number>;
  newRSCreationTimestampItems?: Map<string, string>;
  jobItems?: Map<string, ActionType.IJobItem>;
  statefulsetItems?: Map<string, number>;
  replicasItems?: Map<string, number>;
  newRSItems?: Map<string, number>;
  commentsItems: Map<string, string>;
  httpItems: Map<string, number>;
};

export type SwitchNetstatPayLoad = {
  podNetworkInterfaces: Array<FetchType.IPodNetworkInterface>;
  netstat: string;
  hide: MessageType;
};

export type Server_Status_Payload = {
  roll_out_status: string;
};

export type STORY_FetchPayLoad = Record<string, any>;

export type ILoginAction = {
  type: constants.LOGIN;
  payload: LoginPayLoad;
};

export type IResetAction = {
  type: constants.RESET_STORE;
  payload: {};
};

export interface IListTarsServerTreeAction {
  type: constants.LIST_TARS_TREE;
  payload: Promise<any> | ListTarsServerTreePayLoad;
}

export interface IChangeTabAction {
  type: constants.CHANGE_TAB;
  payload: { tab: string };
}

export interface IRebuildTreeAction {
  type: constants.REBUILD_TREE;
  payload: RebuildTreePayLoad;
}

export interface IChangeSelectTarsSvcAction {
  type: constants.CHANGE_SELECT_TARS_SVC;
  payload: {
    selectedTarsApplication: string;
    selectedTarsSetName: string;
    selectedTarsServer: string;
    selectedTarsPerm?: string;
    selectedItems: Array<ActionType.ITarsServerItem>;
  };
}

export interface IListServerAction {
  type: constants.LIST_TARS_SERVER;
  payload: Promise<any> | ListServerPayLoad;
}

export interface IListServerClearAction {
  type: constants.LIST_TARS_SERVER_CLEAR;
  payload: {};
}

export interface ISwitchNetstatAction {
  type: constants.SWITCH_NETSTAT;
  payload: Promise<any> | SwitchNetstatPayLoad;
}

export interface ISetSelectedItemsAction {
  type: constants.SET_SELECTED_ITEMS;
  payload: { selectedItems: Array<ActionType.ITarsServerItem> };
}

export interface ISetTaskManagerDotAction {
  type: constants.SET_TASK_MANAGER_DOT;
  payload: { taskManagerDot: boolean };
}

export interface IExpandTerminalAction {
  type: constants.EXPAND_TERMINAL;
  payload: { expand: boolean };
}

export interface IExpandTableAction {
  type: constants.EXPAND_TABLE;
  payload: { expand: boolean };
}

export interface IListDCacheTreeAction {
  type: constants.LIST_DCACHE_TREE;
  payload: Promise<any> | ListDCacheTreePayLoad;
}

export interface ISTORY_FETCH {
  type: constants.STORY_FETCH;
  payload: STORY_FetchPayLoad;
}
export interface ILIST_SERVER_STATUS {
  type: constants.LIST_SERVER_STATUS;
  payload: Server_Status_Payload;
}

export interface IToggleLoadingAction {
  type: constants.TOGGLE_LOADING;
  payload: {};
}

export type IAction =
  | ILoginAction
  | IResetAction
  | IChangeTabAction
  | IChangeSelectTarsSvcAction
  | IListTarsServerTreeAction
  | IRebuildTreeAction
  | IListServerAction
  | IListServerClearAction
  | ISwitchNetstatAction
  | ISetSelectedItemsAction
  | ISetTaskManagerDotAction
  | IExpandTerminalAction
  | IExpandTableAction
  | IListDCacheTreeAction
  | ISTORY_FETCH
  | ILIST_SERVER_STATUS // dcache
  | IToggleLoadingAction;

export const changeTab = (currentTab: string): IChangeTabAction => ({
  type: constants.CHANGE_TAB,
  payload: { tab: currentTab },
});

export const changeSelectServer = (
  selectedTarsApplication: string,
  selectedTarsSetName: string,
  selectedTarsServer: string
): IChangeSelectTarsSvcAction => ({
  type: constants.CHANGE_SELECT_TARS_SVC,
  payload: {
    selectedTarsApplication,
    selectedTarsSetName,
    selectedTarsServer,
    selectedItems: [],
  },
});

export const setSelectedItems = (
  selectedItems: Array<ActionType.ITarsServerItem>
): ISetSelectedItemsAction => ({
  type: constants.SET_SELECTED_ITEMS,
  payload: { selectedItems },
});

export const listServerTree = (
  withLoading?: boolean
): IListTarsServerTreeAction => ({
  type: constants.LIST_TARS_TREE,
  payload: new Promise((resolve, reject) => {
    let hide: MessageType | undefined = undefined;
    if (withLoading) {
      message.destroy();
      hide = message.loading("加载中...", 0);
    }
    webApi
      .get<{ items: ActionType.ITreeItem[] }>("server/list")
      .then((res) => {
        if (hide) hide();
        const dataNodes = fetch.buildDataNodesFromMemory(res.items);
        resolve({ loadDone: true, treeNodes: res.items, dataNodes });
      })
      .catch((err) => {
        if (hide) hide();
        reject(err);
      });
  }),
});

export const rebuildTree = (
  treeNodes: ActionType.ITreeItem[]
): IRebuildTreeAction => ({
  type: constants.REBUILD_TREE,
  payload: { dataNodes: fetch.buildDataNodesFromMemory(treeNodes) },
});

export const listServerClear = () => ({
  type: constants.LIST_TARS_SERVER_CLEAR,
  payload: {},
});

export const toggleLoading = () => ({
  type: constants.TOGGLE_LOADING,
  payload: {},
});

export const listServer = (
  selectedTarsApplication: string,
  selectedTarsSetName: string,
  selectedTarsServer: string,
  withLoading?: boolean
) => ({
  type: constants.LIST_TARS_SERVER,
  payload: fetch.fetchListServer({
    tarsApplication: selectedTarsApplication,
    tarsSetName: selectedTarsSetName,
    tarsServerName: selectedTarsServer,
    withLoading,
  }),
});

export const getServerStatus = (
  userName: string,
  clusterName: string,
  tarsApplication: string,
  tarsSetName: string,
  tarsServerName: string
) => ({
  type: constants.LIST_SERVER_STATUS,
  payload: fetch.fetchBaseServerStatus({
    userName,
    clusterName,
    tarsApplication,
    tarsSetName,
    tarsServerName,
  }),
});

export const login = (param: {
  username: string;
  avatar: string;
  rootPerm: boolean;
  useRootPerm: boolean;
}) => ({
  type: constants.LOGIN,
  payload: {
    currentUser: param.username,
    avatar: param.avatar,
    hasAdminPerm: param.rootPerm,
    admin: param.useRootPerm && param.rootPerm,
  },
});

export const resetStore = () => ({
  type: constants.RESET_STORE,
  payload: {},
});

export const switchNetstat = (
  userName: string,
  tarsApplication: string,
  tarsServerName: string,
  podNetworkInterfaces: Array<FetchType.IPodNetworkInterface>,
  netstat: string
) => ({
  type: constants.SWITCH_NETSTAT,
  payload: fetch.fetchSwitchNetstat({
    userName,
    tarsApplication,
    tarsSetName: "",
    tarsServerName,
    podNetworkInterfaces,
    netstat,
  }),
});

export const setTaskManagerDot = (taskManagerDot: boolean) => ({
  type: constants.SET_TASK_MANAGER_DOT,
  payload: { taskManagerDot },
});

export const expandTerminal = (expand: boolean) => ({
  type: constants.EXPAND_TERMINAL,
  payload: { expand },
});

export const expandTable = (expand: boolean) => ({
  type: constants.EXPAND_TABLE,
  payload: { expand },
});

export const listDCacheTree = (
  currentUser: string,
  withLoading?: boolean
): IListDCacheTreeAction => ({
  type: constants.LIST_DCACHE_TREE,
  payload: new Promise((resolve, reject) => {
    let hide: MessageType | undefined = undefined;
    if (withLoading) {
      message.destroy();
      hide = message.loading("加载中...", 0);
    }
    fetch
      .fetchListDCacheTree({ userName: currentUser })
      .then((value: any) => {
        if (hide) hide();
        let dcacheTreeNodes: Array<ActionType.IDcacheTreeItem> = [];
        for (const i in value.kvItems) {
          dcacheTreeNodes.push({ type: "kv", moduleName: value.kvItems[i] });
        }
        for (const i in value.mkvItems) {
          dcacheTreeNodes.push({ type: "mkv", moduleName: value.kvItems[i] });
        }
        for (const i in value.listItems) {
          dcacheTreeNodes.push({
            type: "list",
            moduleName: value.listItems[i],
          });
        }
        for (const i in value.setItems) {
          dcacheTreeNodes.push({ type: "set", moduleName: value.setItems[i] });
        }
        for (const i in value.zsetItems) {
          dcacheTreeNodes.push({
            type: "zset",
            moduleName: value.zsetItems[i],
          });
        }
        const dcacheDataNodes =
          fetch.buildDCacheDataNodesFromMemory(dcacheTreeNodes);
        resolve({ loadDCacheDone: true, dcacheTreeNodes, dcacheDataNodes });
      })
      .catch((err) => {
        if (hide) hide();
        reject(err);
      });
  }),
});

export const storyFetch = (payload: STORY_FetchPayLoad) => ({
  type: constants.STORY_FETCH,
  payload,
});
