import React from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import {
  Button,
  Row,
  Result,
  Col,
  Empty,
  Table,
  Tabs,
  Spin,
  Tooltip,
  Popover,
} from "antd";
import { EyeTwoTone } from "@ant-design/icons";

import { ColumnsType } from "antd/es/table";
import { FilterConfirmProps } from "antd/es/table/interface";

import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";

import CommonTableProps from "src/utils/CommonTableProps";
import ViewPodButton from "src/pages/Common/ViewPodButton";
import ViewTaintButton from "src/pages/Common/ViewTaintButton";
import ExpandNodePoolTitle from "src/pages/Common/ExpandNodePoolTitle";
import ExpandNodePoolButton from "src/pages/Common/ExpandNodePoolButton";
import DeletePodButton from "src/pages/Common/DeletePodButton";
import DeleteMachineButton from "src/pages/Common/DeleteMachineButton";
import { pickClusterName } from "src/utils/Picker";
import { Delete } from "@material-ui/icons";

const { TabPane } = Tabs;

interface IProps {
  haveOperatePerm?: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
}

interface INodeItem {
  instanceID: string;
  ip: string;
  zone: string;
  instance: string;
  usage: string;
  availCpu: string;
  availMem: string;
  billingType: string;
  pods: number;
  nodePool: string;
  podIP: string;
  podName: string;
  taints: Array<FetchType.ITaint>;
  message: string;
  canGc: boolean;
}

interface INodes {
  errCode: number;
  errMsg: string;
  items: Array<INodeItem>;
}

interface IState {
  loading: boolean;
  innerLoading: boolean;

  tarsApplication: string;
  tarsSetName: string;
  tarsServerName: string;
  clusterName: string;
  items: Map<string, INodes>;

  searchText: string;
  searchIp: string;
}

class MachineInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      innerLoading: true,
      tarsApplication: "",
      tarsSetName: "",
      tarsServerName: "",
      clusterName: "",
      items: new Map(),
      searchText: "",
      searchIp: "",
    };
  }

  componentWillUnmount() {
    this.setState = () => false;
  }

  fetchData(innerLoading?: boolean) {
    const userName = this.props.currentUser || "";
    const tarsApplication = this.state.tarsApplication;
    const tarsSetName = this.state.tarsSetName;
    const tarsServerName = this.state.tarsServerName;
    if (innerLoading) this.setState({ innerLoading: true });
    if (!isEmpty(tarsApplication) && !isEmpty(tarsServerName)) {
      fetch
        .fetchListServerNodes({
          userName,
          tarsApplication,
          tarsSetName,
          tarsServerName,
        })
        .then((data) => {
          const items = new Map<string, INodes>(Object.entries(data.items));
          let clusterName = this.state.clusterName;
          if (isEmpty(clusterName)) {
            clusterName = pickClusterName(Array.from(items.keys()));
          }
          this.setState({
            loading: false,
            innerLoading: false,
            items,
            clusterName,
          });
        })
        .catch((_) => { });
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (
      props.selectedTarsApplication !== state.tarsApplication ||
      props.selectedTarsSetName !== state.tarsSetName ||
      props.selectedTarsServer !== state.tarsServerName
    ) {
      return {
        tarsApplication: props.selectedTarsApplication,
        tarsSetName: props.selectedTarsSetName,
        tarsServerName: props.selectedTarsServer,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevState.tarsApplication !== this.state.tarsApplication ||
      prevState.tarsSetName !== this.state.tarsSetName ||
      prevState.tarsServerName !== this.state.tarsServerName
    ) {
      this.setState({ items: new Map<string, INodes>() }, () =>
        this.fetchData()
      );
    }
  }

  onSearch(
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps | undefined) => void
  ) {
    confirm();
    const searchText = selectedKeys[0] as string;
    this.setState({ searchText });
  }

  onSearchReset(
    clearFilters: (() => void) | undefined,
    confirm: (param?: FilterConfirmProps | undefined) => void
  ) {
    if (clearFilters) clearFilters();
    confirm();
    this.setState({ searchText: "" });
  }

  render() {
    const tooltipRender = (text: string, record: INodeItem) => {
      return isEmpty(record.ip) ? null : (
        <Tooltip
          title={text}
          placement="top"
          arrowPointAtCenter
          autoAdjustOverflow={false}
        >
          {text}
        </Tooltip>
      );
    };

    const toPhaseText = (phase: string, message: string) => {
      switch (phase) {
        case "Pending":
          return <span style={{ color: "#F90" }}>Pending</span>;
        case "Running":
          return <span style={{ color: "#090" }}>Running</span>;
        case "Succeeded":
          return <span style={{ color: "#090" }}>Succeeded</span>;
        case "Failed":
          return <span style={{ color: "red" }}>Failed</span>;
        case "Unknown":
          return <span style={{ color: "red" }}>Unknown</span>;
        default:
          return (
            <Tooltip title={message ? message : phase}>
              <span style={{ color: "red" }}>{phase}</span>
            </Tooltip>
          );
      }
    };

    const columns: ColumnsType<INodeItem> = [
      {
        title: "容器名",
        dataIndex: "podName",
        width: 100,
        align: "center",
        ellipsis: true,
        render: (text, record) => (
          <Popover content={text} placement="top" trigger="clicked">
            <Button className="tool-btn" size="small">
              <EyeTwoTone />
              查看
            </Button>
          </Popover>
        ),
      },
      {
        title: "状态",
        dataIndex: "podPhase",
        width: 100,
        align: "center",
        ellipsis: true,
        ...CommonTableProps(
          "podPhase",
          "状态",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
        render: (text, record) => toPhaseText(text, record.message),
      },
      {
        title: "IP",
        dataIndex: "podIP",
        width: 120,
        align: "center",
        ellipsis: true,
        ...CommonTableProps(
          "podIP",
          "IP",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
        render: tooltipRender,
      },

      {
        title: "机器信息",
        dataIndex: "zone",
        width: 130,
        align: "center",
        ellipsis: true,
        render: (text, record) => (
          <Popover
            trigger="click"
            placement="right"
            title={record.instanceID}
            content={
              <span>
                <p>机器IP: {record.ip}</p>
                <p>可用区: {record.zone}</p>
                <p>规格: {record.instance}</p>
                <p>
                  付费类型: {record.billingType === "PrePaid" ? "包月" : "按量"}
                </p>
              </span>
            }
          >
            <Button size="small" className="tool-btn">
              <EyeTwoTone />
              查看
            </Button>
          </Popover>
        ),
      },
      {
        title: "Label",
        dataIndex: "usage",
        width: 100,
        align: "center",
        ellipsis: true,
        ...CommonTableProps(
          "usage",
          "用途",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
        render: tooltipRender,
      },
      {
        title: "可用CPU",
        dataIndex: "availCpu",
        width: 120,
        align: "center",
        ellipsis: true,
        sorter: (a: INodeItem, b: INodeItem): number =>
          parseInt(a.availCpu) - parseInt(b.availCpu),
        render: tooltipRender,
      },
      {
        title: "可用Mem",
        dataIndex: "availMem",
        width: 120,
        align: "center",
        ellipsis: true,
        sorter: (a: INodeItem, b: INodeItem): number =>
          parseInt(a.availMem) - parseInt(b.availMem),
        render: tooltipRender,
      },
      {
        title: "容器",
        dataIndex: "pods",
        width: 60,
        align: "center",
        ellipsis: true,
        sorter: (a: INodeItem, b: INodeItem): number => a.pods - b.pods,
        render: tooltipRender,
      },
      {
        title: "节点池",
        dataIndex: "nodePool",
        width: 100,
        align: "center",
        ellipsis: true,
        ...CommonTableProps(
          "nodePool",
          "节点池",
          this.onSearch.bind(this),
          this.onSearchReset.bind(this)
        ),
        render: tooltipRender,
      },
      {
        title: "污点",
        dataIndex: "taints",
        width: 40,
        align: "center",
        ellipsis: true,
        sorter: (a: INodeItem, b: INodeItem) =>
          a.taints.length - b.taints.length,
        render: (value, record) => <ViewTaintButton taints={record.taints} />,
      },
    ];

    if (this.props.haveOperatePerm) {
      columns.push({
        title: "操作",
        dataIndex: "operation",
        width: 70,
        align: "center",
        render: (text: string, record: INodeItem) =>
          isEmpty(record.ip) ? null : (
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              gap: "10px",
            }}>
              <div>
                <ViewPodButton
                  currentUser={this.props.currentUser}
                  clusterName={this.state.clusterName}
                  machineIP={record.ip}
                />
              </div>
              <div >
                <ExpandNodePoolButton
                  currentUser={this.props.currentUser}
                  clusterName={this.state.clusterName}
                  nodePoolName={record.nodePool}
                />
              </div>
              <div>
                <DeletePodButton
                  currentUser={this.props.currentUser}
                  clusterName={this.state.clusterName}
                  podName={record.podName}
                  onOK={() => {
                    this.fetchData();
                  }}
                />
              </div>
              {record.canGc &&
                <div>
                  <DeleteMachineButton
                    currentUser={this.props.currentUser}
                    clusterName={this.state.clusterName}
                    tarsApplication={this.props.selectedTarsApplication || ""}
                    tarsSetName={this.props.selectedTarsSetName || ""}
                    tarsServerName={this.props.selectedTarsServer || ""}
                    info={record}
                    onOK={() => {
                      this.fetchData();
                    }}
                  />
                </div>
              }
            </div>
          ),
      });
    }

    return (
      <Tabs
        size="small"
        activeKey={this.state.clusterName}
        onChange={(clusterName) => this.setState({ clusterName })}
        tabPosition="right"
      >
        {this.state.loading && (
          <div style={{ height: 300, position: "relative", left: "50%" }}>
            <Spin
              size="large"
              tip="加载中..."
              style={{ position: "relative", top: "50%" }}
            />
          </div>
        )}

        {!this.state.loading &&
          Array.from(this.state.items).map((values) => (
            <TabPane tab={values[0]} key={values[0]}>
              {!isEmpty(values[1].items) && (
                <Table
                  size="small"
                  rowKey="podName"
                  columns={columns}
                  scroll={{ x: true }}
                  dataSource={values[1].items.filter((item: INodeItem) =>
                    item.podIP.includes(this.state.searchIp)
                  )}
                  loading={this.state.innerLoading}
                  pagination={{
                    pageSize: 20,
                    simple: true,
                    hideOnSinglePage: true,
                    defaultCurrent: 1,
                  }}
                  title={() => (
                    <ExpandNodePoolTitle
                      fetchData={() => this.fetchData(true)}
                      onSearchIp={(val) => {
                        this.setState({ searchIp: val });
                      }}
                    />
                  )}
                />
              )}

              {values[1].errCode === 0 && isEmpty(values[1].items) && (
                <Empty style={{ marginTop: 100 }} />
              )}

              {values[1].errCode !== 0 && (
                <Result
                  status="500"
                  title={`请求失败: errCode: ${values[1].errCode}`}
                  subTitle={`errMsg:${values[1].errMsg}`}
                />
              )}
            </TabPane>
          ))}
      </Tabs>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  haveOperatePerm: state.tarsReducer.selectedTarsPerm === "W",
});

export default connect(mapStateToProps, null)(MachineInfo);
