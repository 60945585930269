
import React from 'react';
import { Button, Form, FormInstance, Input, Popconfirm } from 'antd';
import { EditFilled } from '@ant-design/icons';

import * as fetch from 'src/fetch';

const FormItem = Form.Item;

interface IProps {
  currentUser?: string;
  clusterName: string;
  machineIP: string;
  onOK: () => void;
}

interface IState {
  updateUsageVisible: boolean;
}

export default class EditMachineUsageButton extends React.Component<IProps, IState> {
  private updateUsageForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.state = {updateUsageVisible: false};
    this.updateUsageForm = React.createRef<FormInstance>();
  }

  fetchUpdateMachineUsage(machineIP: string, usage: string) {
    const clusterName = this.props.clusterName;
    fetch
    .fetchUpdateMachineUsage({
      clusterName,
      userName: this.props.currentUser || '',
      machineIP,
      usage,
    })
    .then(() => {
      this.props.onOK();
    })
    .catch(() => {});
  }

  render() {
    return (
      <Popconfirm
        icon={null}
        placement='left'
        title={
          <Form
            name='updateUsageForm'
            ref={this.updateUsageForm}
            validateTrigger='onBlur'
          >        
            <FormItem name='usage' label='用途' rules={[{ required: true }]}>
              <Input placeholder='eg: normal'/>
            </FormItem>
          </Form>
        }
        trigger='click'
        okText='修改'
        cancelText='取消'
        visible={this.state.updateUsageVisible}
        okButtonProps={{className: 'tool-btn', ghost: true, size: 'small', type: 'primary'}}
        cancelButtonProps={{className: 'tool-btn', ghost: true, size: 'small', danger: true}}
        onConfirm={async () => {
          try {
            if (this.updateUsageForm.current) {
              const { usage } = await this.updateUsageForm.current.validateFields();
              this.setState({updateUsageVisible: false})
              this.fetchUpdateMachineUsage(this.props.machineIP, usage);
            }
          } catch {}
        }}
        onCancel={() => this.setState({updateUsageVisible: false})}
      >
        <Button size='small'  style={{padding:"0 8px"}} className='tool-btn' onClick={() => this.setState({updateUsageVisible: true})}>
          <EditFilled />修改用途
        </Button>
      </Popconfirm>
    );
  }
}
