import React, {useState, useMemo, useEffect, useRef, ReactNode, MouseEvent} from 'react';

import {Space, TreeSelect, TreeSelectProps} from "antd";
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';

import './style/common.less';

import TarsFIleViewModal from "./TarsFIleViewModal";

import { listTarsProtocolFile, TarsProtocolFile } from "../../fetch";

interface TreeDataType {
  value: string;
  title: ReactNode;
  titleLabel?: string;
  selectable?: boolean;
  isLeaf?: boolean;
  children?: TreeDataType[]
}

interface ProtocolSelectProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  tarsApplication: string;
  tarsSetName: string;
  tarsServerName: string;
  clusterName: string;
}

export default function ProtocolSelect(props: ProtocolSelectProps) {
  const {
    value: propsValue,
    onChange,
    tarsApplication,
    tarsSetName,
    tarsServerName,
    clusterName
  } = props;

  const [loading, updateLoading] = useState(false);
  const [fileList, updateFileList] = useState<TarsProtocolFile[]>([]);

  const [open, updateOpen] = useState<boolean>(false);
  const [value, updateValue] = useState<string[]>([]);
  const [openKeys, updateOpenKeys] = useState<string[]>([]);

  const valueRef = useRef<string[]>([]);

  const modalActiveRef = useRef(false);

  const fileViewModalRef = useRef<any>();

  const onDropdownVisibleChange = (v: boolean) => {
    if(modalActiveRef.current) return;

    updateOpen(v);
  };

  const onFileView = (e: MouseEvent, path: string) => {
    e.stopPropagation();

    modalActiveRef.current = true;

    fileViewModalRef.current?.show(path);
  };

  const { treeData, parentSet } = useMemo(() => {
    if(!fileList?.length) return {
      treeData: [],
      parentSet: new Set()
    };

    // 默认第一个为根节点
    const [root, ...subData] = fileList;

    const subDataWithParent = subData.map(item => ({
      ...item,
      parentPath: item.path.split('/').slice(0, -1).join('/')
    }));

    const parentSet = new Set<string>(root.path);

    function getChildren(parentPath: string) {

      const children: TreeDataType[] = subDataWithParent
        .filter(item => item.parentPath === parentPath)
        .map(item => {
          const curChildren = getChildren(item.path);

          const hasChildren = Boolean(curChildren.length);

          if(hasChildren) {
            parentSet.add(item.path);
          }

          return {
            value: item.path,
            titleLabel: item.name,
            title: hasChildren ? item.name : (
              <Space>
                <EyeOutlined
                  title={'查看文件内容'}
                  className={'protocol-view-icon'}
                  onClick={(e) => onFileView(e, item.path)}
                />
                <span>{item.name}</span>
              </Space>
            ),
            children: curChildren
          };
        })

      return children;
    }

    const rootChildren = getChildren(root.path);

    const tree: TreeDataType[] = [
      {
        value: root.path,
        title: root.name,
        titleLabel: root.name,
        selectable: false,
        children: rootChildren
      }
    ];

    return {
      treeData: tree,
      parentSet
    };

  }, [fileList]);

  const onTreeExpand: TreeSelectProps['onTreeExpand'] = (keys) => {
    updateOpenKeys(keys as string[])
  };

  const onSelectChange: TreeSelectProps['onChange'] = (val, title, target) => {
    const targetVal = target.triggerValue as string;

    if(parentSet.has(targetVal)) {
      updateOpenKeys(state => {
        if(state.includes(targetVal)) {
          return state.filter(item => item !== targetVal);
        }else {
          return [...state, targetVal]
        }
      })
    }else {
      updateValue(val);

      valueRef.current = val;

      onChange?.(val);
    }
  };

  useEffect(() => {
    if(!('value' in props)) return;

    if(propsValue?.toString() === valueRef.current?.toString()) return;

    updateValue(propsValue || []);
  }, [propsValue]);

  useEffect(() => {
    updateLoading(true);

    listTarsProtocolFile({
      tarsApplication,
      tarsSetName,
      tarsServerName,
      clusterName
    }).then(res => {
      updateFileList(res);
      updateOpenKeys([res[0]?.path])
    })
      .catch(() => {})
      .finally(() => updateLoading(false));
  }, [tarsApplication, tarsSetName, tarsServerName, clusterName]);

  return (
    <>
      <TreeSelect
        placeholder={'请选择'}
        treeExpandedKeys={openKeys}
        treeNodeLabelProp={'titleLabel'}
        treeIcon
        onTreeExpand={onTreeExpand}
        onDropdownVisibleChange={onDropdownVisibleChange}
        open={open}
        style={{width: 280}}
        treeLine={{ showLeafIcon: true }}
        showSearch
        multiple
        allowClear
        value={value}
        onChange={onSelectChange}
        treeData={treeData}
      />
      {
        loading && (
          <span style={{margin: '0 4px'}}>
            <LoadingOutlined />
          </span>
        )
      }
      <TarsFIleViewModal
        ref={fileViewModalRef}
        activeRef={modalActiveRef}
        clusterName={clusterName || ''}
        tarsApplication={tarsApplication || ''}
        tarsSetName={tarsSetName || ''}
        tarsServerName={tarsServerName || ''}
      />
    </>
  );
}
