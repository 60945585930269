import React from "react";
import { isEmpty } from "lodash";
import {
  Button,
  Badge,
  Col,
  Row,
  Select,
  Tooltip,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Progress,
  message,
  Alert,
  Tag,
} from "antd";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  WarningFilled,
  ReloadOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  StopOutlined,
  PauseCircleOutlined,
  AimOutlined,
} from "@ant-design/icons";

import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Marquee from "react-fast-marquee";
import * as fetch from "src/fetch";
import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";

import {
  TaskCommendEnum,
  mockData,
  TaskItem,
  SERVER_STATUS,
  getNodesHasError,
} from "./utils";

import "./style/common.less";

const SelectOption = Select.Option;
const FormItem = Form.Item;
const minElapseToPatch = 900;

interface IProgressItem {
  percent: number;
  status: "success" | "exception" | "active" | "normal" | undefined;
  format: React.ReactNode;
  length: number;
}

interface IProps {
  currentUser?: string;
  haveOperatePerm?: boolean;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  historyRef?: React.RefObject<any>;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  selectedItems?: Array<ActionType.ITarsServerItem>;
  pausedItems?: Map<string, number>;
  canResumeItems?: Map<string, number>;
  newRSCreationTimestampItems?: Map<string, string>;
  replicasItems?: Map<string, number>;
  newRSItems?: Map<string, number>;
  commentsItems?: Map<string, string>;
  serverStatus?: SERVER_STATUS;

  listServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string,
    withLoading?: boolean
  ) => void;
  storyCallback: (callbackMap: Actions.STORY_FetchPayLoad) => void;
  getServerStatus: (
    userName: string,
    clusterName: string,
    tarsApplication: string,
    tarsSetName: string,
    tarsServerName: string
  ) => void;
}

interface IState {
  reloadInterval: number;
  tarsApplication: string;
  tarsSetName: string;
  tarsServer: string;

  selectedRolloutClusterVisible?: boolean;

  patchElapse: number;

  maxSurge: number;
  rolloutUndoReason?: string;
  rolloutUndoVisible?: boolean;

  comments: string;
  commentsClicked: boolean;
  currTask: any;
}

let timeTicker: NodeJS.Timer | undefined;
let reloadTicker: NodeJS.Timer | undefined;
let taskStateTimer: NodeJS.Timer | undefined;
let serverStatusTimer: NodeJS.Timer | undefined; //整个服务状态的定时器
class ServerTableHead extends React.Component<IProps, IState> {
  private reasonForm: React.RefObject<FormInstance>;
  private batchForm: React.RefObject<FormInstance>;
  private resumeForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      reloadInterval: 30,
      tarsApplication: "",
      tarsSetName: "",
      tarsServer: "",
      maxSurge: 1,
      patchElapse: 0,
      comments: "",
      commentsClicked: false,
      currTask: {},
    };
    this.reasonForm = React.createRef<FormInstance>();
    this.batchForm = React.createRef<FormInstance>();
    this.resumeForm = React.createRef<FormInstance>();
    this.setTicker(this.state.reloadInterval);
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (
      props.selectedTarsApplication !== state.tarsApplication ||
      props.selectedTarsSetName !== state.tarsSetName ||
      props.selectedTarsServer !== state.tarsServer
    ) {
      return {
        reloadInterval: 30,
        patchElapse: 0,
        tarsApplication: props.selectedTarsApplication,
        tarsSetName: props.selectedTarsSetName,
        tarsServer: props.selectedTarsServer,
      };
    }
    return null;
  }

  componentDidMount() {
    timeTicker = setInterval(this.updateElapsed.bind(this), 1000);
    this.setTicker(this.state.reloadInterval);

    this.noticeItemTaskStatus(
      this.state.tarsApplication,
      this.state.tarsSetName,
      this.state.tarsServer
    );

    this.subRefreshCallback();
    // 获取当前服务的状态
    this.fetchServerStatus();
  }

  subRefreshCallback = () => {
    this.props.storyCallback({
      key: "taskRefresh",
      value: () => {
        taskStateTimer && clearInterval(taskStateTimer);
        this.noticeItemTaskStatus(
          this.state.tarsApplication,
          this.state.tarsSetName,
          this.state.tarsServer
        );
      },
    });
  };
  componentWillUnmount() {
    if (reloadTicker) {
      clearInterval(reloadTicker);
      reloadTicker = undefined;
    }

    if (timeTicker) {
      clearInterval(timeTicker);
      timeTicker = undefined;
    }

    if (taskStateTimer) {
      clearInterval(taskStateTimer);
      taskStateTimer = undefined;
    }

    if (serverStatusTimer) {
      clearInterval(serverStatusTimer);
      serverStatusTimer = undefined;
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevState.tarsApplication !== this.state.tarsApplication ||
      prevState.tarsSetName !== this.state.tarsSetName ||
      prevState.tarsServer !== this.state.tarsServer ||
      prevState.reloadInterval !== this.state.reloadInterval
    ) {
      this.setTicker(this.state.reloadInterval);

      taskStateTimer && clearInterval(taskStateTimer);
      this.noticeItemTaskStatus(
        this.state.tarsApplication,
        this.state.tarsSetName,
        this.state.tarsServer
      );
      this.subRefreshCallback();

      serverStatusTimer && clearInterval(serverStatusTimer);
      this.fetchServerStatus();
    } else if (prevProps.serverStatus !== this.props.serverStatus) {
      // 状态发生变化，重新获取当前服务的状态
      // 需要重新校验当前服务的状态，
      // 重置状态
      serverStatusTimer && clearInterval(serverStatusTimer);
      serverStatusTimer = undefined;
      // 如果处于灰度发布中，则需要轮询获取当前服务的状态，有没有进入到灰度发布暂停

      if (
        this.props.serverStatus === SERVER_STATUS.RolloutStatusGreyPublishing ||
        this.props.serverStatus === SERVER_STATUS.RolloutStatusPublishing ||
        this.props.serverStatus === SERVER_STATUS.RolloutStatusUndoing
      ) {
        // 8s轮询一次

        serverStatusTimer = setInterval(() => {
          this.fetchServerStatus();
        }, 1000 * 8);
      }
    } else if (
      prevProps.tarsServerItems?.length !== this.props.tarsServerItems?.length
    ) {
      this.fetchServerStatus();
    }
    // 获取当前服务的状态，根据服务状态来控制整个状态的展示TODO:
  }

  async noticeItemTaskStatus(
    tarsApplication: string,
    tarsSetName: string,
    tarsServer: string
  ) {
    try {
      // 查询接口，获取当前的任务状态
      const taskResult = await fetch.fetchOperationTaskList({
        userName: this.props.currentUser,
        operationType: ["graceful_restart", "graceful_stop"],
        tarsApplication,
        tarsSetName,
        tarsServerName: tarsServer,
      });

      const validKey = Object.keys(taskResult).find(
        (key) => ((taskResult as any)[key] as any).logItems.length
      );

      const validData = validKey ? (taskResult as any)[validKey] : undefined;

      if (validData) {
        this.setState({
          currTask: {
            key: validKey,
            list: validData?.logItems || [],
          },
        });
        taskStateTimer && clearInterval(taskStateTimer);
        taskStateTimer = setInterval(() => {
          this.noticeItemTaskStatus(
            this.state.tarsApplication,
            this.state.tarsSetName,
            this.state.tarsServer
          );
        }, 1000 * 5);
      } else {
        taskStateTimer && clearInterval(taskStateTimer);

        this.setState({
          currTask: {},
        });
        this.listServerAgain.bind(this, false);
      }
    } catch (error) {}
  }

  updateElapsed() {
    if (
      !this.props.newRSCreationTimestampItems ||
      this.props.newRSCreationTimestampItems.size === 0
    )
      return;

    let minRSCreationTimestamp = 0;
    if (this.props.newRSCreationTimestampItems) {
      minRSCreationTimestamp = Array.from(
        this.props.newRSCreationTimestampItems.values()
      ).reduce((prev, cur) => {
        if (cur.length === 0) return prev;
        if (prev === 0) return parseInt(cur);
        return Math.min(prev, parseInt(cur));
      }, 0);
    }
    const patchElapse =
      Math.floor(new Date().getTime() / 1000) - minRSCreationTimestamp;
    this.setState({ patchElapse });
  }

  listServerAgain(withLoading?: boolean) {
    if (
      !isEmpty(this.state.tarsApplication) &&
      !isEmpty(this.state.tarsServer)
    ) {
      this.props.listServer(
        this.state.tarsApplication,
        this.state.tarsSetName,
        this.state.tarsServer,
        withLoading
      );
      this.props.historyRef?.current?.fetchData(withLoading);
    }
  }

  setTicker(reloadInterval: number) {
    if (reloadTicker !== undefined) {
      clearInterval(reloadTicker);
      reloadTicker = undefined;
    }
    if (
      reloadInterval > 0 &&
      !isEmpty(this.state.tarsApplication) &&
      !isEmpty(this.state.tarsServer)
    ) {
      reloadTicker = global.setInterval(
        this.listServerAgain.bind(this, false),
        reloadInterval * 1000
      );
    }
    this.setState({ reloadInterval });
  }
  /**
   * 滚动发布
   */
  fetchRolloutResume(urgent?: boolean, reason?: string) {
    if (!this.props.pausedItems) return;
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer)
      return;

    const items = Array.from(this.props.pausedItems).filter(
      (value: [string, number]) => value.length === 2 && value[1] > 0
    );

    if (items.length <= 0) return;
    const clusterName = items[0][0];

    fetch
      .fetchRolloutResume({
        userName: this.props.currentUser || "",
        clusterName,
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        maxSurge: this.state.maxSurge || 1,
        urgent: urgent ? 1 : 0,
        reason: reason || "",
      })
      .then(() => {
        this.listServerAgain(false);
        this.asyncFetchServerStatus(1500);
        serverStatusTimer && clearInterval(serverStatusTimer);
        serverStatusTimer = setInterval(() => {
          this.fetchServerStatus();
        }, 1000 * 8);
      })
      .catch((_) => {});
  }

  asyncFetchServerStatus(timer: number = 1000 * 5) {
    setTimeout(() => {
      this.fetchServerStatus();
    }, timer);
  }
  fetchRolloutUndo() {
    let clusterName;

    if (this.props.tarsServerItems?.length) {
      const sorted = [...this.props.tarsServerItems];
      const items = sorted?.sort(
        (a: any, b: any) =>
          Number(b.publishTimestamp) - Number(a.publishTimestamp)
      );
      clusterName = items?.[0]?.clusterName || "";
    }
    fetch
      .fetchRolloutUndo({
        userName: this.props.currentUser || "",
        clusterName: clusterName || "",
        tarsApplication: this.props.selectedTarsApplication || "",
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer || "",
        toRevision: 0,
        reason: this.state.rolloutUndoReason || "",
      })
      .then(() => {
        this.listServerAgain(false);
        this.asyncFetchServerStatus(3000);
      })
      .catch(() => {});
  }

  fetchAddServerComments() {
    if (isEmpty(this.state.comments)) {
      message.error("注释内容为空");
      this.setState({ comments: "", commentsClicked: false });
      return;
    }

    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer) {
      message.error("系统错误");
      return;
    }

    fetch
      .fetchAddServerComments({
        userName: this.props.currentUser || "",
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        comments: this.state.comments,
      })
      .then(() => {
        this.setState({ comments: "", commentsClicked: false });
        this.listServerAgain(true);
      })
      .catch((_) => this.setState({ comments: "", commentsClicked: false }));
  }

  fetchServerStatus() {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer) {
      message.error("系统错误");
      return;
    }
    let clusterName;

    if (this.props.tarsServerItems?.length) {
      const sorted = [...this.props.tarsServerItems];
      const items = sorted?.sort(
        (a: any, b: any) =>
          Number(b.publishTimestamp) - Number(a.publishTimestamp)
      );
      clusterName = items?.[0]?.clusterName || "";
    }

    if (!clusterName) {
      this.asyncFetchServerStatus();
      return;
    }
    this.props.getServerStatus(
      this.props.currentUser || "",
      clusterName || "",
      this.props.selectedTarsApplication,
      this.props.selectedTarsSetName || "",
      this.props.selectedTarsServer
    );
  }

  terminateServerTask(taskId: string, key: string, command: string) {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer) {
      message.error("系统错误");
      return;
    }

    fetch
      .fetchTerminateTask({
        userName: this.props.currentUser || "",
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        clusterName: key || "",
        taskId,
        command,
      })
      .then(() => {
        setTimeout(() => {
          this.listServerAgain(false);
        }, 3000);
        this.asyncFetchServerStatus();
        taskStateTimer && clearInterval(taskStateTimer);
        this.noticeItemTaskStatus(
          this.props.selectedTarsApplication || "",
          this.props.selectedTarsSetName || "",
          this.props.selectedTarsServer || ""
        );
      })
      .catch((_) => {});
  }
  /**
   * 发布暂停
   */
  patchPause() {
    // 点击发布做后，需要请求接口，做5s的延迟请求
    // 点击滚动发布之后，需要请求接口，做5s的延迟请求  done
    // 点击紧急发布之后，需要请求接口，做5s的延迟请求 done
    // 点击回滚之后，需要请求接口，做5s的延迟请求  done
    // 点击继续之后，需要请求接口，做5s的延迟请求
    // 点击暂停之后，需要请求接口，做5s的延迟请求

    let clusterName;

    if (this.props.tarsServerItems?.length) {
      const sorted = [...this.props.tarsServerItems];
      const items = sorted?.sort(
        (a: any, b: any) =>
          Number(b.publishTimestamp) - Number(a.publishTimestamp)
      );
      clusterName = items?.[0]?.clusterName || "";
    }

    fetch
      .fetchRolloutPause({
        userName: this.props.currentUser || "",
        clusterName,
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
      })
      .then(() => {
        this.listServerAgain(false);
        this.asyncFetchServerStatus(2000);
      })
      .catch((_) => {});
  }

  render() {
    // 之前的逻辑是只有一个副本的情况的时候

    // 判断是否可以滚动发布
    let disableResume = false;

    if (this.state.patchElapse > 0) {
      // 基于newRSCreationTimestampItems时间来计算出patchElapse已发布时间，如果patchElapse小于minElapseToPatch，则不允许滚动发布
      // 需要在大于900s的时间情况下，才允许滚动发布
      disableResume = this.state.patchElapse < minElapseToPatch;
    }

    if (this.props.tarsServerItems) {
      let versionInClusterMap = new Map<string, string>();
      for (const item of this.props.tarsServerItems) {
        const version = versionInClusterMap.get(item.clusterName);
        if (!version) {
          versionInClusterMap.set(item.clusterName, item.publishVersion);
        }
      }
    }

    // 控制展示时间的文案
    let elapseTooSmall = false;

    // 如果不是不允许滚动发布，那就计算看看是否已经发布完成
    if (!disableResume) {
      disableResume = this.props.canResumeItems
        ? Array.from(this.props.canResumeItems.values()).every((x) => x === 0)
        : false;
    } else {
      // 如果是不允许滚动，才可以看到现在的时间
      elapseTooSmall = true;
    }

    // 进度状态和文案
    let progresses = new Map<string, IProgressItem>();
    if (this.props.replicasItems) {
      this.props.replicasItems.forEach((replicas, key) => {
        if (replicas === 0) return;
        let newRS = 0;
        let percent = 0;
        const length = 25;
        let format: React.ReactNode = <></>;
        let status: "success" | "exception" | "active" | "normal" | undefined =
          "success";
        if (this.props.newRSItems) {
          newRS = this.props.newRSItems.get(key) || 0;
        }
        if (newRS === 0) {
          status = "exception";
          format = (
            <div style={{ width: 30 }}>
              <CloseCircleFilled />
              {newRS}/{replicas}
            </div>
          );
        } else if (newRS < replicas) {
          status = "success";
          format = (
            <div style={{ width: 30 }}>
              <CheckCircleFilled />
              {newRS}/{replicas}
            </div>
          );
          percent = Math.round((newRS / replicas) * 100);
        } else {
          percent = 100;
          status = "success";
          format = (
            <div style={{ width: 30 }}>
              <CheckCircleFilled />
              {newRS}/{replicas}
            </div>
          );
        }
        progresses.set(key, { percent, status, format, length });
      });
    }

    let processStatus:
      | "success"
      | "processing"
      | "default"
      | "error"
      | "warning"
      | undefined = "processing";
    if (
      Array.from(progresses.values()).every(
        (x) => x.percent === 0 || x.percent === 100
      )
    ) {
      processStatus = "success";
    }

    // 服务的注释
    let comments: Array<string> = [];
    if (this.props.commentsItems && this.props.commentsItems.size > 0) {
      comments = Array.from(this.props.commentsItems.values());
    }

    const currServerStatus = this.props.serverStatus ?? 0;

    const hasPauseError = getNodesHasError(
      this.props.tarsServerItems as any,
      this.props.pausedItems as any,
      currServerStatus
    );

    // 有状态节点准备好了，默认准备好
    let statefulNodeReady = true;

    // 有状态，并且暂停中，那么需要判断是否有节点准备好了，没有准备好，那么就不允许滚动发布
    if (currServerStatus === SERVER_STATUS.RolloutStatusGreyPublishPaused) {
      let pausedNodeTime;
      this.props.newRSCreationTimestampItems?.forEach((value) => {
        if (value) {
          pausedNodeTime = value;
        }
      });
      if (!pausedNodeTime) {
        statefulNodeReady = false;
      }
    }

    let rollingUpdate = false;
    if (this.props.tarsServerItems) {
      const versionReduced = this.props.tarsServerItems.reduce((prev, cur) => {
        if (cur.publishVersion.indexOf("(") === -1) {
          return prev;
        }
        const ver = prev.get(cur.clusterName);
        if (typeof ver === "boolean" && !ver) {
          return prev;
        } else if (typeof ver === "string") {
          if (cur.publishVersion !== ver) {
            prev.set(cur.clusterName, false);
          }
          return prev;
        }
        prev.set(cur.clusterName, cur.publishVersion);
        return prev;
      }, new Map<string, string | boolean>());
      if (Array.from(versionReduced.values()).some((x) => x === false)) {
        rollingUpdate = true;
      }
    }

    let isSingleNode = false;
    if (this.props.replicasItems) {
      // 包含单节点为1的情况
      const totalNumber = Array.from(
        this.props.replicasItems as Map<string, number>
      ).reduce((pre, curr) => {
        return pre + curr[1];
      }, 0);
      isSingleNode = totalNumber === 1;
    }

    return (
      <>
        <Row
          style={{
            marginBottom: 8,
            padding: "0 6px 0 0",
          }}
          justify="space-between"
        >
          {/* 左边的服务信息和选择信息 */}
          <Col>
            {/* 当前的服务信息名称 */}
            <span style={{ lineHeight: "28px" }}>
              {this.props.selectedTarsApplication}.
              {this.props.selectedTarsServer}
            </span>

            {/* 当前的set */}
            {this.props.selectedTarsSetName !== ".." && (
              <span style={{ marginLeft: 8 }}>
                {this.props.selectedTarsSetName}
              </span>
            )}

            {/* 当前服务备注及编辑 */}
            <span style={{ marginLeft: 12 }}>
              {!this.state.commentsClicked &&
                this.props.haveOperatePerm &&
                comments.every((x) => isEmpty(x)) && (
                  <span style={{ fontSize: 10, color: "grey" }}>
                    点击编辑备注..
                  </span>
                )}

              {!this.state.commentsClicked &&
                this.props.haveOperatePerm &&
                comments.some((x) => !isEmpty(x)) && (
                  <span style={{ fontSize: 10, color: "grey" }}>
                    {comments.reduce(
                      (acc, cur) => (cur.length === 0 ? acc : cur),
                      ""
                    )}
                  </span>
                )}

              {this.state.commentsClicked && this.props.haveOperatePerm && (
                <Input
                  size="small"
                  style={{ width: 150 }}
                  value={this.state.comments}
                  onChange={(e) => this.setState({ comments: e.target.value })}
                  placeholder="输入服务注释.."
                />
              )}

              {!this.state.commentsClicked && this.props.haveOperatePerm && (
                <span
                  style={{ marginLeft: 5 }}
                  onClick={() => this.setState({ commentsClicked: true })}
                >
                  <EditOutlined />
                </span>
              )}

              {this.state.commentsClicked && (
                <>
                  <span
                    style={{ marginLeft: 5, color: "green" }}
                    onClick={() => this.fetchAddServerComments()}
                  >
                    <CheckOutlined />
                  </span>
                  <span
                    style={{ marginLeft: 5, color: "red" }}
                    onClick={() =>
                      this.setState({ comments: "", commentsClicked: false })
                    }
                  >
                    <CloseOutlined />
                  </span>
                </>
              )}
            </span>

            {/* 当前已选择的服务信息 */}
            <span style={{ marginLeft: 8 }}>
              {this.props.selectedItems &&
                !isEmpty(this.props.selectedItems) && (
                  <>已选择:{this.props.selectedItems.length}个节点</>
                )}
            </span>
          </Col>

          {/* 所有集群暂停的节点数，某一个集群为1的情况*/}
          {
            // 此时先注释了判断逻辑，通过状态来判断
            <Col>
              <Row align="middle">
                {/* 当有错误服务信息时 */}
                {hasPauseError && (
                  <Col className="tableHeadTip">
                    <Alert
                      banner
                      message={
                        <Marquee pauseOnHover gradient={false}>
                          当前发布暂停中的服务未达到【准备好，健康状态】，请[检查服务重新发布]或[等待状态变更]；
                        </Marquee>
                      }
                    />
                  </Col>
                )}
                {/* 滚动发布按钮控制 */}
                {/* 在灰度暂停中，可以展示滚动发布按钮，但是也需要有时间的控制，也需要判断服务有没有问题， */}
                {!hasPauseError &&
                  statefulNodeReady &&
                  currServerStatus ==
                    SERVER_STATUS.RolloutStatusGreyPublishPaused && (
                    <Col>
                      <Popconfirm
                        title={
                          <Form
                            name="batchForm"
                            ref={this.batchForm}
                            validateTrigger="onBlur"
                            initialValues={{ maxSurge: this.state.maxSurge }}
                          >
                            <FormItem
                              name="maxSurge"
                              label="更新速度"
                              rules={[{ required: true }]}
                              tooltip="每次更新滚动多少个节点"
                            >
                              <InputNumber
                                min={1}
                                onChange={(maxSurge: number) =>
                                  this.setState({ maxSurge })
                                }
                              />
                            </FormItem>
                          </Form>
                        }
                        icon={null}
                        okText="发布"
                        cancelText="取消"
                        okButtonProps={{
                          className: "tool-btn",
                          type: "primary",
                          ghost: true,
                        }}
                        cancelButtonProps={{
                          className: "tool-btn",
                          danger: true,
                          ghost: true,
                        }}
                        trigger="click"
                        placement="bottom"
                        overlayStyle={{ minWidth: 100 }}
                        onConfirm={() => this.fetchRolloutResume()}
                        disabled={disableResume || !this.props.haveOperatePerm}
                      >
                        {elapseTooSmall && (
                          <span style={{ fontSize: 10 }}>
                            已发布 {this.state.patchElapse} 秒
                          </span>
                        )}

                        {disableResume && (
                          <Tooltip
                            placement="top"
                            overlayStyle={{ minWidth: 200 }}
                            title={"至少灰度15分钟后才能继续发布"}
                          >
                            <QuestionCircleOutlined
                              style={{ marginLeft: 10 }}
                            />
                          </Tooltip>
                        )}

                        <Button
                          size="small"
                          type="dashed"
                          className="tool-btn"
                          style={{ marginLeft: 8 }}
                          disabled={
                            disableResume || !this.props.haveOperatePerm
                          }
                        >
                          <WarningFilled style={{ color: "#faad14" }} />
                          滚动发布
                        </Button>
                      </Popconfirm>
                    </Col>
                  )}

                {/* 回滚逻辑控制 */}
                {/* 非完成和滚动中，都可以回滚 */}
                {((currServerStatus !== SERVER_STATUS.RolloutStatusFinished &&
                  currServerStatus !== SERVER_STATUS.RolloutStatusUndoing) ||
                  (rollingUpdate && isSingleNode)) && (
                  <Col style={{ marginLeft: 8 }}>
                    <Popconfirm
                      title={
                        <Form
                          name="reasonForm"
                          ref={this.reasonForm}
                          validateTrigger="onBlur"
                          initialValues={{ reason: "" }}
                        >
                          <FormItem
                            name="reason"
                            label="详情"
                            rules={[
                              {
                                required: true,
                                min: 6,
                                message: "原因过于简单",
                              },
                            ]}
                            tooltip="新节点将被全部下线"
                          >
                            <Input size="small" placeholder="请输入回滚原因" />
                          </FormItem>
                        </Form>
                      }
                      disabled={
                        this.state.rolloutUndoVisible ||
                        !this.props.haveOperatePerm
                      }
                      onConfirm={async () => {
                        try {
                          let reason = "";
                          if (this.reasonForm.current) {
                            const fields =
                              await this.reasonForm.current.validateFields();
                            reason = fields.reason;
                            this.setState(
                              {
                                rolloutUndoReason: reason,
                                rolloutUndoVisible: false,
                              },
                              this.fetchRolloutUndo.bind(this)
                            );
                          } else {
                            message.error("未知错误");
                          }
                        } catch {}
                      }}
                      onCancel={() =>
                        this.setState({
                          rolloutUndoReason: undefined,
                          rolloutUndoVisible: false,
                        })
                      }
                      icon={null}
                      okText="回滚"
                      cancelText="取消"
                      okButtonProps={{
                        className: "tool-btn",
                        danger: true,
                        ghost: true,
                      }}
                      cancelButtonProps={{
                        className: "tool-btn",
                        type: "primary",
                        ghost: true,
                      }}
                      overlayStyle={{ minWidth: 300 }}
                      placement="bottom"
                    >
                      <Button
                        disabled={!this.props.haveOperatePerm}
                        size="small"
                        type="dashed"
                        className="tool-btn"
                        onClick={() =>
                          this.setState({ rolloutUndoVisible: true })
                        }
                      >
                        <CloseCircleOutlined style={{ color: "red" }} />
                        回滚发布
                      </Button>
                    </Popconfirm>
                  </Col>
                )}

                {/* 紧急发布 */}
                {/* 灰度暂停中，需要判断有没有服务有问题 */}
                {!hasPauseError &&
                  statefulNodeReady &&
                  currServerStatus ===
                    SERVER_STATUS.RolloutStatusGreyPublishPaused && (
                    <Col>
                      <Popconfirm
                        title={
                          <Form
                            name="resumeForm"
                            ref={this.resumeForm}
                            validateTrigger="onBlur"
                            initialValues={{ maxSurge: this.state.maxSurge }}
                          >
                            <FormItem
                              name="maxSurge"
                              label="更新速度"
                              rules={[{ required: true }]}
                              tooltip="每次更新滚动多少个节点"
                            >
                              <InputNumber
                                size="small"
                                min={1}
                                onChange={(maxSurge: number) =>
                                  this.setState({ maxSurge })
                                }
                              />
                            </FormItem>
                            <FormItem
                              name="reason"
                              label="发布原因"
                              rules={[
                                {
                                  required: true,
                                  min: 6,
                                  message: "原因过于简单",
                                },
                              ]}
                              tooltip="非特殊场景慎用"
                            >
                              <Input
                                size="small"
                                placeholder="请输入紧急发布原因"
                              />
                            </FormItem>
                          </Form>
                        }
                        icon={null}
                        okText="发布"
                        cancelText="取消"
                        okButtonProps={{
                          className: "tool-btn",
                          type: "primary",
                          ghost: true,
                        }}
                        cancelButtonProps={{
                          className: "tool-btn",
                          danger: true,
                          ghost: true,
                        }}
                        trigger="click"
                        placement="bottom"
                        overlayStyle={{ minWidth: 100 }}
                        disabled={!this.props.haveOperatePerm}
                        onConfirm={async () => {
                          try {
                            if (this.resumeForm.current) {
                              const fields =
                                await this.resumeForm.current.validateFields();
                              this.fetchRolloutResume(true, fields.reason);
                            }
                          } catch {}
                        }}
                      >
                        <Button
                          disabled={!this.props.haveOperatePerm}
                          size="small"
                          type="dashed"
                          className="tool-btn"
                          style={{ marginLeft: 8 }}
                        >
                          <StopOutlined style={{ color: "red" }} />
                          紧急发布
                        </Button>
                      </Popconfirm>
                    </Col>
                  )}

                {/* 提示 */}
                {!hasPauseError &&
                  currServerStatus ===
                    SERVER_STATUS.RolloutStatusGreyPublishPaused &&
                  this.props.pausedItems && (
                    <Col style={{ marginLeft: 8 }}>
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            发布暂停中的集群:
                            <br />
                            {Array.from(
                              this.props.pausedItems as Map<string, number>
                            )
                              .filter(
                                (value: [string, number]) =>
                                  value.length === 2 && value[1] > 0
                              )
                              .map((value: [string, number]) => (
                                <div key={value[0]}>
                                  {value[0]}
                                  <br />
                                </div>
                              ))}
                          </>
                        }
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Col>
                  )}

                {currServerStatus === SERVER_STATUS.RolloutStatusPublishing && (
                  <Col>
                    <Popconfirm
                      title={"确定暂停发布吗？"}
                      icon={null}
                      okText="暂停"
                      cancelText="取消"
                      okButtonProps={{
                        className: "tool-btn",
                        type: "primary",
                        ghost: true,
                      }}
                      cancelButtonProps={{
                        className: "tool-btn",
                        danger: true,
                        ghost: true,
                      }}
                      trigger="click"
                      placement="bottom"
                      overlayStyle={{ minWidth: 100 }}
                      disabled={!this.props.haveOperatePerm}
                      onConfirm={() => {
                        this.patchPause();
                      }}
                    >
                      <Button
                        disabled={!this.props.haveOperatePerm}
                        size="small"
                        type="dashed"
                        className="tool-btn"
                        style={{ marginLeft: 8 }}
                      >
                        <PauseCircleOutlined style={{ color: "red" }} />
                        暂停发布
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
                {currServerStatus ===
                  SERVER_STATUS.RolloutStatusPublishPaused && (
                  <Col>
                    <Popconfirm
                      title={
                        <Form
                          name="batchForm"
                          ref={this.batchForm}
                          validateTrigger="onBlur"
                          initialValues={{ maxSurge: this.state.maxSurge }}
                        >
                          <FormItem
                            name="maxSurge"
                            label="更新速度"
                            rules={[{ required: true }]}
                            tooltip="每次更新滚动多少个节点"
                          >
                            <InputNumber
                              min={1}
                              onChange={(maxSurge: number) =>
                                this.setState({ maxSurge })
                              }
                            />
                          </FormItem>
                        </Form>
                      }
                      icon={null}
                      okText="继续"
                      cancelText="取消"
                      okButtonProps={{
                        className: "tool-btn",
                        type: "primary",
                        ghost: true,
                      }}
                      cancelButtonProps={{
                        className: "tool-btn",
                        danger: true,
                        ghost: true,
                      }}
                      trigger="click"
                      placement="bottom"
                      overlayStyle={{ minWidth: 100 }}
                      disabled={!this.props.haveOperatePerm}
                      onConfirm={() => {
                        this.fetchRolloutResume();
                      }}
                    >
                      <Button
                        disabled={!this.props.haveOperatePerm}
                        size="small"
                        type="dashed"
                        className="tool-btn"
                        style={{ marginLeft: 8 }}
                      >
                        <AimOutlined style={{ color: "green" }} />
                        继续发布
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
              </Row>
            </Col>
          }

          {/* 发布进度 */}
          {this.props.pausedItems &&
            Array.from(this.props.pausedItems).every(
              (value: [string, number]) => value.length === 2 && value[1] === 0
            ) && (
              <Popover
                placement="bottom"
                trigger="click"
                overlayStyle={{ minWidth: 350 }}
                content={
                  <span>
                    {Array.from(progresses).map((values, index) => (
                      <p key={index}>
                        {values[0]}:
                        <Progress
                          strokeColor={{ from: "#108ee9", to: "#87d068" }}
                          size="small"
                          style={{ paddingRight: values[1].length }}
                          status={values[1].status}
                          percent={values[1].percent}
                          format={() => values[1].format}
                        />
                      </p>
                    ))}
                  </span>
                }
              >
                <Button
                  disabled={!this.props.haveOperatePerm}
                  size="small"
                  className="tool-btn"
                  type="dashed"
                >
                  <Badge status={processStatus} text="发布进度" />
                </Button>
              </Popover>
            )}

          {/* 重启任务进度 */}
          {this.state?.currTask?.list?.length ? (
            <Popover
              placement="bottom"
              trigger="click"
              overlayStyle={{ minWidth: 350 }}
              content={
                <span>
                  {this.state.currTask.list.map(
                    (task: TaskItem, index: number) => {
                      const processing: any[] = task.progressBar.split("/");
                      return (
                        <div className="processBar">
                          <Row
                            key={index}
                            align="middle"
                            justify="space-between"
                          >
                            <Col>
                              {" "}
                              <Tooltip title={task.nodeList}>
                                <Button
                                  disabled={!this.props.haveOperatePerm}
                                  size="small"
                                  type="link"
                                  className="noPadding"
                                >
                                  第{index + 1}个任务 (
                                  {
                                    { restart: "重启", stop: "停止" }[
                                      task.command
                                    ]
                                  }
                                  )：
                                </Button>
                              </Tooltip>
                            </Col>
                            <Col>
                              <Button
                                disabled={!this.props.haveOperatePerm}
                                onClick={() => {
                                  this.terminateServerTask(
                                    task.taskID,
                                    this.state.currTask.key,
                                    task.command
                                  );
                                }}
                                size="small"
                                type="ghost"
                                ghost
                                danger
                              >
                                中止任务
                              </Button>
                            </Col>
                          </Row>
                          <div>
                            <Progress
                              strokeColor={{ from: "#108ee9", to: "#87d068" }}
                              size="small"
                              status="active"
                              percent={(processing[0] / processing[1]) * 100}
                              format={() => (
                                <div style={{ width: 30 }}>
                                  {task.progressBar}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </span>
              }
            >
              <Button size="small" className="tool-btn" type="dashed">
                <Badge
                  status={"processing"}
                  text={`${
                    ({ restart: "重启", stop: "停止" } as any)[
                      this.state.currTask.list[0]?.command
                    ] ?? "重启/停止"
                  }进度`}
                />
              </Button>
            </Popover>
          ) : null}

          {/**提示 */}

          {!hasPauseError &&
            currServerStatus !== SERVER_STATUS.RolloutStatusFinished && (
              <Col>
                <Row align="middle">
                  <Col className="tableHeadTip">
                    <Alert
                      banner
                      message={
                        <Marquee pauseOnHover gradient={false}>
                          无状态服务发灰度会
                          <Tag color="rgb(255, 85, 0)">暂停自动扩容</Tag>,
                          有状态服务发灰度会
                          <Tag color="rgb(255, 85, 0)">暂停自动缩容</Tag>
                        </Marquee>
                      }
                    />
                  </Col>
                </Row>
              </Col>
            )}

          <Col>
            <Row>
              <Col
                style={{
                  marginRight: "8px",
                }}
              >
                <Button
                  size="small"
                  type="dashed"
                  className="tool-btn"
                  onClick={this.listServerAgain.bind(this, true)}
                >
                  <ReloadOutlined style={{ color: "green", fontSize: 15 }} />
                  刷新
                </Button>
              </Col>
              <Col>
                <Select
                  size="small"
                  dropdownMatchSelectWidth={false}
                  optionLabelProp="label"
                  className="time_select"
                  value={this.state.reloadInterval}
                  onChange={(value: number) =>
                    this.setState((s) => ({ ...s, reloadInterval: value }))
                  }
                >
                  <SelectOption value={0} label="Off">
                    Off
                  </SelectOption>
                  <SelectOption value={10} label="10s">
                    10s
                  </SelectOption>
                  <SelectOption value={30} label="30s">
                    30s
                  </SelectOption>
                  <SelectOption value={60} label="1m">
                    1m
                  </SelectOption>
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  haveOperatePerm: state.tarsReducer.selectedTarsPerm === "W",
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  selectedItems: state.tarsReducer.selectedItems,
  pausedItems: state.tarsReducer.pausedItems,
  canResumeItems: state.tarsReducer.canResumeItems,
  newRSCreationTimestampItems: state.tarsReducer.newRSCreationTimestampItems,
  replicasItems: state.tarsReducer.replicasItems,
  newRSItems: state.tarsReducer.newRSItems,
  commentsItems: state.tarsReducer.commentsItems,
  serverStatus: state.tarsReducer.serverStatus,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  listServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string,
    withLoading?: boolean
  ) =>
    dispatch(
      Actions.listServer(
        selectedTarsApplication,
        selectedTarsSetName,
        selectedTarsServer,
        withLoading
      )
    ),
  storyCallback: (callbackMap: Actions.STORY_FetchPayLoad) =>
    dispatch(Actions.storyFetch(callbackMap)),
  getServerStatus: (
    userName: string,
    clusterName: string,
    tarsApplication: string,
    tarsSetName: string,
    tarsServerName: string
  ) =>
    dispatch(
      Actions.getServerStatus(
        userName,
        clusterName,
        tarsApplication,
        tarsSetName,
        tarsServerName
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServerTableHead);
