import React from 'react';
import { Anchor } from 'antd';
import { connect } from 'react-redux';

import Stop from 'src/pages/DCache/ToolsBar/Stop';
import Restart from 'src/pages/DCache/ToolsBar/Restart';
import Expand from 'src/pages/DCache/ToolsBar/Expand';
import Shrink from 'src/pages/DCache/ToolsBar/Shrink';
import Transfer from 'src/pages/DCache/ToolsBar/Transfer';
import Notify from 'src/pages/DCache/ToolsBar/Notify';
import DebugInterface from 'src/pages/DCache/ToolsBar/DebugInterface';
import Backup from 'src/pages/DCache/ToolsBar/Backup';
import Restore from 'src/pages/DCache/ToolsBar/Restore';
import Switch  from 'src/pages/DCache/ToolsBar/Switch';

const { Link } = Anchor;

interface IProps {
}

interface IState {
};

class RightToolsBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {
          <Anchor showInkInFixed={false} bounds={10} getCurrentAnchor={() => ''}>
            <Link href='/dcache' title='工具栏'>
              <Link title={<Stop />} />
              <Link title={<Restart />} />
              <Link title={<Expand />} />
              <Link title={<Shrink />} />
              <Link title={<Transfer />} />
              <Link title={<Switch />} />
              <Link title={<Notify />} />
              <Link title={<DebugInterface />} />
              <Link title={<Backup />} />
              <Link title={<Restore />} />
            </Link>
          </Anchor>
        }
      </>
    )
  }
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, null)(RightToolsBar)
