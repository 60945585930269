import * as Actions from "src/stores/actions";
// import * as ActionType from 'src/stores/types';
import { IStoreState } from "../types";
import * as constants from "src/stores/constants";

import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

const resetToDefault = (state: IStoreState): IStoreState => {
  let s = {
    ...state,
    globalLoading: false,
    loadDone: false,
    tab: "devops",
    selectedTarsApplication: "",
    selectedTarsSetName: "",
    selectedTarsServer: "",
    selectedTarsPerm: "",
    selectedItems: [],

    // tars
    depotID: undefined,
    tarsServerItems: [],
    operationItems: new Map(),
    operationLenItems: new Map(),
    systemOperationLenItems: new Map(),
    pausedItems: new Map(),
    canResumeItems: new Map(),
    newRSCreationTimestampItems: new Map(),
    jobItems: new Map(),
    statefulsetItems: new Map(),
    replicasItems: new Map(),
    newRSItems: new Map(),
    commentsItems: new Map(),
    httpItems: new Map(),
    // dcache
    loadDCacheDone: false,
    serverStatus: "0", //默认发布完成
  };
  if (!s.preference) {
    s.preference = {
      defaultClusterName: "env-product-terway",
      expandTable: false,
      expandTerminal: false,
    };
  } else {
    s.preference.defaultClusterName = "env-product-terway";
  }
  return s;
};

const reducer = (
  state: IStoreState = resetToDefault({} as any),
  action: Actions.IAction
): IStoreState => {
  switch (action.type) {
    case constants.RESET_STORE: {
      const initialState = resetToDefault(state);
      initialState.currentUser = "";
      initialState.dataNodes = [];
      initialState.treeNodes = [];
      return initialState;
    }
    case constants.LOGIN: {
      const payload = action.payload as Actions.LoginPayLoad;
      return {
        ...state,
        ...payload,
      };
    }
    case constants.CHANGE_SELECT_TARS_SVC: {
      const { treeNodes = [] } = state;
      action.payload.selectedTarsPerm = "R";
      if (
        action.payload.selectedTarsApplication !== "" &&
        action.payload.selectedTarsServer !== ""
      ) {
        const targetServer = treeNodes.find(
          (item) =>
            item.tarsApplication === action.payload.selectedTarsApplication &&
            item.tarsServerName === action.payload.selectedTarsServer
        );
        if (targetServer) {
          action.payload.selectedTarsPerm = targetServer.perm;
        }
      }

      return { ...state, ...action.payload };
    }
    case constants.LIST_TARS_TREE:
      return { ...state, ...action.payload };
    case constants.REBUILD_TREE:
      return { ...state, ...action.payload };
    case constants.LIST_TARS_SERVER:
      const payload = action.payload as Actions.ListServerPayLoad;
      // 当前选择服务 和 返回的服务不同， 直接丢弃
      if (
        payload &&
        state.selectedTarsApplication === payload.tarsApplication &&
        state.selectedTarsSetName === payload.tarsSetName &&
        state.selectedTarsServer === payload.tarsServer
      ) {
        if (payload.tarsServerItems) {
          payload.tarsServerItems.sort((a, b) => {
            if (a.publishVersion.indexOf("(") === -1) return 1;
            if (b.publishVersion.indexOf("(") === -1) return -1;
            const x = parseInt(a.publishVersion.split("(")[0]);
            const y = parseInt(b.publishVersion.split("(")[0]);
            return y - x;
          });
        }
        return { ...state, ...payload };
      }
      return { ...state };
    case constants.LIST_SERVER_STATUS: {
      // TODO: 请求返回的数据
      const payloadStatus = action.payload as Actions.Server_Status_Payload;
      // console.log(state, "payloadStatus");
      let newStat = { ...state };
      newStat.serverStatus = payloadStatus.roll_out_status;
      return newStat;
    }
    case constants.LIST_TARS_SERVER_CLEAR:
      let newStat = { ...state };
      newStat.depotID = undefined;
      newStat.pausedItems = undefined;
      newStat.canResumeItems = undefined;
      newStat.tarsServerItems = undefined;
      newStat.operationItems = undefined;
      newStat.operationLenItems = undefined;
      newStat.systemOperationLenItems = undefined;
      return newStat;
    case constants.CHANGE_TAB:
      return { ...state, ...action.payload };
    case constants.SWITCH_NETSTAT: {
      let newStat = { ...state };
      newStat.tarsServerItems = Array.from(state.tarsServerItems || []);
      if (newStat.tarsServerItems) {
        const payload = action.payload as Actions.SwitchNetstatPayLoad;
        const pods = new Set(
          Array.from(
            payload.podNetworkInterfaces.map(
              (x) => x.podIP + "|" + x.clusterName
            )
          )
        );
        for (let i in newStat.tarsServerItems) {
          if (
            pods.has(
              newStat.tarsServerItems[i].podIP +
                "|" +
                newStat.tarsServerItems[i].clusterName
            )
          ) {
            if (payload.netstat)
              newStat.tarsServerItems[i].netstat = payload.netstat;
          }
        }
        if (payload.hide) setTimeout(payload.hide, 1500);
      }
      return newStat;
    }
    case constants.SET_SELECTED_ITEMS: {
      let newStat = { ...state };
      newStat.selectedItems = (
        action as Actions.ISetSelectedItemsAction
      ).payload.selectedItems;
      return newStat;
    }
    case constants.SET_TASK_MANAGER_DOT: {
      let newStat = { ...state };
      newStat.taskManagerDot = (
        action as Actions.ISetTaskManagerDotAction
      ).payload.taskManagerDot;
      return newStat;
    }
    case constants.EXPAND_TABLE: {
      const payload = (action as Actions.IExpandTableAction).payload;
      let preference = { ...state.preference };
      preference.expandTable = payload.expand;
      return { ...state, preference };
    }
    case constants.EXPAND_TERMINAL: {
      const payload = (action as Actions.IExpandTerminalAction).payload;
      let preference = { ...state.preference };
      preference.expandTerminal = payload.expand;
      return { ...state, preference };
    }
    case constants.LIST_DCACHE_TREE: {
      return { ...state, ...action.payload };
    }
    case constants.STORY_FETCH: {
      const payload = action.payload as Actions.STORY_FetchPayLoad;
      return {
        ...state,
        story_fetch: {
          ...state?.story_fetch,
          [payload.key]: payload.value,
        },
      };
    }
    case constants.TOGGLE_LOADING: {
      return {
        ...state,
        // @ts-ignore
        globalLoading: !state.globalLoading,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  routerReducer,
  tarsReducer: reducer,
});
