export const ApproveKindEnum: any = {
  "2": "stop",
  "3": "publish",
  "5": "delete",
  "9": "alterDomain",
  "10": "editConfig",
  "11": "autoScaling",
};

export const ApproveKindTextEnum: any = {
  "2": "停止服务",
  "3": "发布服务",
  "5": "删除服务",
  "9": "修改域名",
  "10": "服务配置",
  "11": "自动扩容",
};

export const ApproveKindOptLevelEnum: any = {
  "2": 0,
  "3": 1,
  "5": 0,
  "9": 1,
  "10": 1,
  "11": 1,
};

interface IApproveKindKey {
  stop: Number;
  publish: Number;
  delete: Number;
  alterDomain: Number;
  editConfig: Number;
  autoScaling: Number;
}

export const ApproveKindKeyEnum: IApproveKindKey = {
  stop: 2,
  publish: 3,
  delete: 5,
  alterDomain: 9,
  editConfig: 10,
  autoScaling: 11,
};

interface IApproveStatus {
  Required: Number;
  Pending: Number;
  Approved: Number;
  NoNeed: Number;
}

export const ApproveStatusEnum: IApproveStatus = {
  Required: 1,
  Pending: 2,
  Approved: 3,
  NoNeed: 4,
};

export const getStaticClusterName = () => {
  if (
    window.location.host.indexOf("localhost") > -1 ||
    window.location.host.indexOf("dev.tars") > -1 ||
    window.location.host.indexOf("518") > -1
  ) {
    // return "env-test-terway";
  }
  return "env-product-terway";
};

export const getIsDevEnv = () => {
  if (
    window.location.host.indexOf("localhost") > -1 ||
    window.location.host.indexOf("dev.tars") > -1 ||
    window.location.host.indexOf("518") > -1 ||
    window.location.host.indexOf("917") > -1
  ) {
    return true;
  }
  return false;
};
