import React from 'react';
import { List, Modal } from 'antd';

const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;

interface NotificationResultItem {
  errCode: number;
  errMsg: string;
  podIP: string;
  response: string;
};

interface IProps {
  visible: boolean;
  dataSource: Array<NotificationResultItem>;
  onClose: () => void;
}

interface IState {
  visible: boolean;
  dataSource: Array<NotificationResultItem>;
}

export default class NotificationResult extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: props.visible, dataSource: [] };
  }

  static getDerivedStateFromProps(props : IProps, state : IState) {
    if (props.visible !== state.visible) {
      return { ...props };
    }
    return null
  }

  render() {
    return (
      <>  
        <Modal
          title='通知结果'
          closable={false}
          visible={this.state.visible}
          okText='确认'
          cancelText='关闭'
          onOk={this.props.onClose}
          onCancel={this.props.onClose}
        >
          <List
            size='small'
            bordered={true}
            itemLayout='horizontal'
            dataSource={this.props.dataSource}
            pagination={{ pageSize: 4, simple: true, hideOnSinglePage: true, defaultCurrent: 1 }}
            renderItem={(item, index) => (
              <ListItem key={index}>
                <ListItemMeta
                  title={(<p>{ item.podIP + '  |  errCode:' + item.errCode + '  |  响应:' }</p>)}
                  description={(<p  style={{whiteSpace: 'pre-line'}}>{ item.response }</p>)}
                />
              </ListItem>
            )}
          />
        </Modal>
      </>
    );   
  }
};
