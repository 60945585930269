/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import {
  fetchBaseCommonAddRecord,
  fetchBaseCommonConfig,
  fetchBaseCommonEditRecord,
  fetchBaseCommonEnum,
  fetchBaseCommonGetList,
  fetchBaseCommonRemoveRecord,
} from "./../../fetch/";
import { Tabs } from "antd";
import TableRender from "./tableRender";
import TableEditRender from "./tableEditRender";
// import { mockRenderConfig, mockEnumConfigList, mockData } from "./mockConfig";
import { REPROTER_STATUS as REPORTER_STATUS } from "./until";
import "./index.less";

const { TabPane } = Tabs;

interface IProps {
  disabled?: boolean;
  tableId?: string;
  userName?: string;
  clusterName?: string;
  tarsApplication?: string;
  tarsSetName?: string;
  tarsServerName?: string;
  tabsConfig?: Record<string, string>;
  baseFilterCond?: any[];
  focusColumns?: Record<string, any>; //强行替换某一个Columns的配置,或者混入
}

// 处理逻辑
const CommonReporter = (props: IProps) => {
  // 默认横向tab，可以控制竖项
  const {
    tableId = "",
    userName = "",
    clusterName = "",
    tarsApplication = "",
    tarsSetName = "",
    tarsServerName = "tars.global",
    tabsConfig = {}, //控制tab的配置
    /**
     *  需要特别处理的查询条件，不展示，但是必须要查询的时候使用
     *  比如在告警历史的时候
     */
    baseFilterCond = [],
    focusColumns,
  } = props;

  const [status, setStatus] = useState(REPORTER_STATUS.VIEW);

  //   所有集群的config
  const [baseConfig, setBaseConfig] = useState({});
  //当前选中集群的config
  const [renderConfig, setRenderConfig] = useState({});

  const [enumMap, setEnumMap] = useState({});

  const [clusterList, setClusterList] = useState([]);
  const [currentCluster, setCurrentCluster] = useState("");
  const [dataSource, setDataSource] = useState({});

  const [loading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  //   初始化加载
  useEffect(() => {
    setLoading(true);
    // 初始化请求配置
    fetchBaseCommonConfig({
      tableId,
      clusterName,
      tarsServerName,
      tarsApplication,
      tarsSetName,
      userName,
    })
      .then((renderConfig) => {
        // 这里需要处理一下，把数据，放到state里面
        // 基本config发来之后，需要请求一下基本的config
        // 这里是mock数据，后面需要处理一下
        setStatus(REPORTER_STATUS.VIEW);
        setBaseConfig(renderConfig);
        const clusterList = Object.keys(renderConfig);
        setClusterList(clusterList as any);
        setCurrentCluster(clusterName || clusterList[0]);
        setRenderConfig(
          focusColumn(
            (renderConfig as any)[clusterName || clusterList[0]]
              .configTemplateItem
          )
        );

        getEnumList(
          (renderConfig as any)[clusterName || clusterList[0]]
            .configTemplateItem.inputConfig,
          clusterName || clusterList[0]
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [tableId, clusterName, tarsServerName, tarsApplication, tarsSetName]);

  //  拉回来的时候，会有集群信息，这个时候，需要把集群信息，放到state里面，同时这个需要支持切换集群的横向还是竖向

  const getEnumList = (inputConfig: [], clusterName: string) => {
    setLoading(true);

    fetchBaseCommonEnum({
      clusterName,
      tarsServerName,
      tarsApplication,
      tarsSetName,
      userName,
      recordBase: {
        tableId,
        data: JSON.stringify(inputConfig),
      },
    })
      .then((enumConfigList) => {
        const enumMap: any = {};
        enumConfigList.forEach(
          (item: { field: string | number; list: any }) => {
            enumMap[item.field] = item.list;
          }
        );
        setEnumMap(enumMap);
        setLoading(false);
      })
      .finally(() => {});
  };

  const toModify = (record: Record<string, any>) => {
    const columnList = (renderConfig as any)?.outputConfig?.config?.columnList;

    const columnMap = columnList.reduce(
      (acc: { [x: string]: any }, cur: { field: string | number }) => {
        acc[cur.field] = cur;
        return acc;
      },
      {}
    );

    return Object.entries(record)
      .filter(([key, value]) => columnMap[key]?.type)
      .map(([key, value]) => {
        if (columnMap[key]?.editType === "date") {
          return {
            field: key,
            value: new Date(value).getTime(),
            type: columnMap[key].type,
          };
        } else if (
          columnMap[key]?.editType === "select" &&
          columnMap[key]?.isMultiSelect
        ) {
          return {
            field: key,
            value: value?.join ? value?.join(";") : "",
            type: columnMap[key].type,
          };
        }

        return {
          field: key,
          value: value,
          type: columnMap[key].type,
        };
      });
  };

  const addRecord = async (record: Record<string, string>) => {
    return fetchBaseCommonAddRecord({
      clusterName: currentCluster,
      userName,
      tarsServerName,
      tarsApplication,
      tarsSetName,
      recordBase: {
        tableId,
        data: JSON.stringify(toModify(record)),
      },
    });
  };
  const editRecord = async (record: Record<string, string>) => {
    return fetchBaseCommonEditRecord({
      clusterName: currentCluster,
      userName,
      tarsServerName,
      tarsApplication,
      tarsSetName,
      recordBase: {
        tableId,
        data: JSON.stringify(toModify(record)),
      },
    });
  };

  const removeRecord = async (record: Record<string, string>) => {
    setLoading(true);
    await fetchBaseCommonRemoveRecord({
      clusterName: currentCluster,
      userName,
      tarsServerName,
      tarsApplication,
      tarsSetName,
      recordBase: {
        tableId,
        data: JSON.stringify(toModify(record)),
      },
    });
    const { baseConfig } = (renderConfig as any)?.outputConfig?.config;
    getDataSource(baseConfig?.paginActionSize, 1, []);
    setLoading(false);
  };

  const onFinishModify = async (record: Record<string, string>) => {
    // 新增和编辑的时候，需要把数据，提交到后端
    if (status === REPORTER_STATUS.ADD) {
      await addRecord(record);
    } else if (status === REPORTER_STATUS.EDIT) {
      await editRecord(record);
    }

    // 刷新数据
    const { baseConfig } = (renderConfig as any)?.outputConfig?.config;
    getDataSource(baseConfig?.paginActionSize, 1, []);
    setStatus(REPORTER_STATUS.VIEW);
    setCurrentRecord(null);
  };
  const getDataSource = useCallback(
    (
      pageSize: Number,
      pageNo: Number,
      filterCond: Record<string, string>[],
      inputClusterName?: string
    ) => {
      setLoading(true);
      const filterCondition = filterCond.concat(baseFilterCond);
      fetchBaseCommonGetList({
        clusterName: inputClusterName || currentCluster,
        tarsServerName,
        tarsApplication,
        tarsSetName,
        userName,
        recordFilter: {
          tableId,
          pageSize,
          pageNo,
          filterCondition: filterCondition,
        },
      })
        .then((data) => {
          setLoading(false);
          setDataSource(data as any);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      currentCluster,
      tableId,
      tarsServerName,
      tarsApplication,
      tarsSetName,
      userName,
    ]
  );
  // 获取基本的参数，只有一个参数，table和基本的请求参数，useName，clusterName，tarsApplication，tarsSetName，tarsServerName

  const focusColumn = (renderConfig: any) => {
    const { columnList } = (renderConfig as any)?.outputConfig?.config;

    const focusesColumnList = columnList.map((item: { field: any }) => {
      const { field } = item;
      if (focusColumns && focusColumns[field]) {
        return { ...item, ...focusColumns[field] };
      }
      return item;
    });
    return {
      ...renderConfig,
      outputConfig: {
        ...renderConfig.outputConfig,
        config: {
          ...renderConfig.outputConfig.config,
          columnList: focusesColumnList,
        },
      },
    };
  };

  return (
    <div className="commonReporter">
      <Tabs
        size="large"
        type="card"
        activeKey={currentCluster}
        style={{ marginLeft: 8 }}
        onChange={(clusterName) => {
          setRenderConfig(focusColumn((baseConfig as any)[clusterName]));
          getEnumList(
            (baseConfig as any)[clusterName].inputConfig,
            clusterName
          );
          setCurrentCluster(clusterName);
        }}
        {...tabsConfig}
      >
        {clusterList.map((clusterName) => (
          <TabPane tab={clusterName} key={clusterName}>
            {currentCluster === clusterName ? (
              status === REPORTER_STATUS.VIEW ? (
                <div>
                  <TableRender
                    disabled={props.disabled}
                    loading={loading}
                    renderConfig={(renderConfig as any) || {}}
                    enumMap={enumMap}
                    onEdit={(record) => {
                      setCurrentRecord(record);
                      setStatus(REPORTER_STATUS.EDIT);
                    }}
                    onAdd={() => {
                      setCurrentRecord(null);
                      setStatus(REPORTER_STATUS.ADD);
                    }}
                    onSearch={(param: any) => {
                      // 这里需要做一下搜索的逻辑

                      getDataSource(
                        param.paginActionSize,
                        param.page,
                        param.filterCond,
                        currentCluster
                      );
                      // 基于搜索条件查询所有
                    }}
                    onDel={(param: any) => {
                      // 这里需要做一下删除的逻辑
                      removeRecord(param);
                    }}
                    dataSource={dataSource || []}
                  />
                </div>
              ) : (
                <div>
                  <TableEditRender
                    renderConfig={(renderConfig as any) || {}}
                    status={status}
                    currentRecord={currentRecord}
                    isAdd={status === REPORTER_STATUS.ADD}
                    onFinishSubmit={(value: Record<string, string>) => {
                      // 提交之后做的事情，
                      // 需要判断是新增还是编辑，然后做不同的事情
                      setLoading(true);
                      onFinishModify(value);
                    }}
                    onCancel={() => {
                      setStatus(REPORTER_STATUS.VIEW);
                      setCurrentRecord(null);
                    }}
                  />
                </div>
              )
            ) : null}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default CommonReporter;
