import React from "react";
import { Dropdown, Button, Avatar, message } from "antd";
import { connect } from "react-redux";
import { webApi } from "src/fetch";
import "./index.less";

interface IProps {
  avatar?: string;
  username?: string;
  hasAdminPerm?: boolean;
  admin?: boolean;
}

const role = {
  admin: "admin",
  developer: "developer",
};
const UserInfoDetail: React.FC<IProps> = (props) => {
  function handleLogOut() {
    webApi.get("logout").then(() => {
      window.location.href = `${
        process.env.NODE_ENV === "development" ? "/login_4_test" : "/login"
      }`;
    });
  }

  function handleSwitchRole() {
    webApi.post("user/switchRole", {}).then(() => {
      message.success("用户身份切换成功! 开始刷新页面");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    });
  }

  return (
    <div className="userInfo-wrapper">
      <div className="basic-info">
        {props.avatar ? (
          <Avatar src={props.avatar} size={50} />
        ) : (
          <div className="avatar-word-icon">
            {props.username?.slice(0, 1).toUpperCase()}
          </div>
        )}
        <p className="username">{props.username}</p>
        <div className="tag-contain">
          <span className={"tag"}>
            {props.hasAdminPerm && props.admin ? role.admin : role.developer}
          </span>
        </div>
      </div>
      <div>
        {props.hasAdminPerm ? (
          <Button
            onClick={handleSwitchRole}
            style={{ marginRight: 8 }}
            type="primary"
          >{`切换 ${props.admin ? role.developer : role.admin}`}</Button>
        ) : null}
        <Button block={!props.hasAdminPerm} onClick={handleLogOut}>
          登出
        </Button>
      </div>
    </div>
  );
};

const UserInfo: React.FC<IProps> = (props) => {
  return (
    <div className="tars-user-info">
      <div className="user-info">
        <p>{props.username}</p>
        <p>{`${!props.admin ? role.developer : role.admin} 用户`}</p>
      </div>
      <Dropdown overlay={<UserInfoDetail {...props} />} trigger={["hover"]}>
        <div className="avatar-box">
          <div
            className="avatar"
            style={{ backgroundImage: `url('${props.avatar}')` }}
          >
            {props.avatar ? null : props.username?.slice(0, 1).toUpperCase()}
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  username: state.tarsReducer.currentUser,
  avatar: state.tarsReducer.avatar,
  hasAdminPerm: state.tarsReducer.hasAdminPerm,
  admin: state.tarsReducer.admin,
});

export default connect(mapStateToProps, null)(UserInfo);
