import React from "react";
import MomentFormat from "src/utils/MomentFormat";
import { isEmpty, cloneDeep } from "lodash";
import {
  Row,
  Col,
  Table,
  Tabs,
  Button,
  Popover,
  Spin,
  Input,
  Popconfirm,
  Modal,
  notification,
  Select,
  Form,
  FormInstance,
} from "antd";
import {
  EyeTwoTone,
  EditOutlined,
  DeleteTwoTone,
  QuestionCircleOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import MonacoEditor, { MonacoDiffEditor } from "react-monaco-editor";

import * as fetch from "src/fetch";
import { pickClusterName } from "src/utils/Picker";

const { TabPane } = Tabs;

const FormItem = Form.Item;
const SelectOption = Select.Option;

interface IProps {
  currentUser?: string;
}

interface ITemplate {
  templateID: number;
  templateName: string;
  parentTemplateName: string;
  templateContent: string;
  userName: string;
  updateTimestamp: string;

  editingItem?: ITemplate;
}

interface IState {
  loading: boolean;
  innerLoading: boolean;

  clusterName: string;
  dataSource: Map<string, Array<ITemplate>>;

  addItem?: ITemplate;
  editItem?: ITemplate;
  language: string;
  languages?: Array<string>;
}

export default class ManageTemplate extends React.Component<IProps, IState> {
  private addForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      innerLoading: true,
      clusterName: "",
      dataSource: new Map(),
      language: "xml",
    };
    this.addForm = React.createRef<FormInstance>();
  }

  componentDidMount() {
    this.fetchData();
  }

  editorDidMount(editor: any, monaco: any) {
    if (isEmpty(this.state.languages) && monaco && monaco.languages) {
      const languages = monaco.languages.getLanguages();
      if (!isEmpty(languages)) {
        this.setState({
          languages: languages.map((language: any) => language.id),
        });
      }
    }
    editor.focus();
  }

  fetchData(innerLoading?: boolean) {
    if (innerLoading) this.setState({ innerLoading: true });
    fetch
      .fetchListTemplate()
      .then((data) => {
        let dataSource = new Map<string, Array<ITemplate>>();
        Object.entries(data.items).forEach((value: [string, any]) => {
          dataSource.set(value[0], value[1] as Array<ITemplate>);
        });
        let clusterName = this.state.clusterName;
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(dataSource.keys()));
        }
        this.setState({
          loading: false,
          innerLoading: false,
          clusterName,
          dataSource,
        });
      })
      .catch((_) => {});
  }

  fetchDeleteTemplate(
    templateID: number,
    templateName: string,
    parentTemplateName: string
  ) {
    fetch
      .fetchDeleteTemplate({
        clusterName: this.state.clusterName,
        templateID,
        templateName,
        parentTemplateName,
      })
      .then(() => {
        notification["success"]({ message: "删除成功" });
        let dataSource = this.state.dataSource;
        let dataArray = dataSource.get(this.state.clusterName);
        if (dataArray) {
          dataArray = dataArray.filter(
            (item) => item.templateID !== templateID
          );
          dataSource.set(this.state.clusterName, dataArray);
        }
        this.setState({ dataSource });
      })
      .catch((_) => {});
  }

  fetchModifyTemplate(item: ITemplate) {
    fetch
      .fetchAddOrModifyTemplate({
        userName: this.props.currentUser || "",
        clusterName: this.state.clusterName,
        templateID: item.templateID,
        templateName: item.templateName,
        parentTemplateName: item.parentTemplateName,
        templateContent: item.templateContent,
      })
      .then(() => {
        notification.success({ message: "修改成功" });
        this.setState({ editItem: undefined });
        this.fetchData(true);
      })
      .catch((_) => {});
  }

  fetchAddTemplate(item: ITemplate) {
    fetch
      .fetchAddOrModifyTemplate({
        userName: this.props.currentUser || "",
        clusterName: this.state.clusterName,
        templateID: 0,
        templateName: item.templateName,
        parentTemplateName: item.parentTemplateName,
        templateContent: item.templateContent,
      })
      .then(() => {
        notification.success({ message: "添加成功" });
        this.setState({ addItem: undefined });
        this.fetchData(true);
      })
      .catch((_) => {});
  }

  render() {
    const columns: ColumnsType<ITemplate> = [
      { title: "模板", dataIndex: "templateName", width: 95, align: "left" },
      {
        title: "父模板",
        dataIndex: "parentTemplateName",
        width: 95,
        align: "left",
      },
      {
        title: "上一次修改时间",
        dataIndex: "updateTimestamp",
        width: 95,
        align: "left",
        render: (text: string) => <>{MomentFormat(text)}</>,
      },
      { title: "编辑人", dataIndex: "userName", width: 95, align: "left" },
      {
        title: "操作",
        dataIndex: "operation",
        width: 95,
        align: "center",
        render: (text: string, record: ITemplate) => (
          <Row>
            <Col style={{ margin: "0 5px" }}>
              <Popover
                title={record.templateName}
                trigger="click"
                placement="left"
                arrowPointAtCenter
                content={
                  <div
                    style={{
                      maxHeight: "80vh",
                      maxWidth: "90vw",
                      overflowY: "scroll",
                    }}
                  >
                    <code style={{ whiteSpace: "pre-wrap" }}>
                      {record.templateContent}
                    </code>
                  </div>
                }
              >
                <Button size="small" className="tool-btn">
                  <EyeTwoTone />
                  查看
                </Button>
              </Popover>
            </Col>
            <Col style={{ margin: "0 5px" }}>
              <Button
                size="small"
                className="tool-btn"
                onClick={() => {
                  let editItem = cloneDeep(record);
                  editItem.editingItem = cloneDeep(record);
                  this.setState({ editItem });
                }}
              >
                <EditOutlined />
                编辑
              </Button>
            </Col>
            <Col style={{ margin: "0 5px" }}>
              <Popconfirm
                title="删除配置无法再恢复"
                okText="删除"
                okType="danger"
                cancelText="取消"
                placement="left"
                disabled={record.templateName === "tars.default"}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() =>
                  this.fetchDeleteTemplate(
                    record.templateID,
                    record.templateName,
                    record.parentTemplateName
                  )
                }
              >
                <Button
                  size="small"
                  className="tool-btn"
                  disabled={record.templateName === "tars.default"}
                >
                  <DeleteTwoTone twoToneColor="#DC143C" />
                  删除
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        ),
      },
    ];
    return (
      <>
        {this.state.loading && (
          <div style={{ height: 300 }}>
            <Spin
              size="large"
              tip="加载中..."
              style={{ position: "relative", left: "50%", top: "50%" }}
            />
          </div>
        )}

        {!this.state.loading && (
          <Tabs
            size="large"
            type="card"
            style={{ marginLeft: 8 }}
            onChange={(clusterName) => this.setState({ clusterName })}
            activeKey={this.state.clusterName}
          >
            {Array.from(this.state.dataSource).map((values) => (
              <TabPane tab={values[0]} key={values[0]}>
                <Table
                  bordered
                  size="small"
                  pagination={{
                    pageSize: 50,
                    simple: true,
                    hideOnSinglePage: true,
                    defaultCurrent: 1,
                  }}
                  columns={columns}
                  dataSource={values[1]}
                  loading={this.state.innerLoading}
                  title={() => (
                    <div style={{ textAlign: "center" }}>
                      <Button
                        size="small"
                        type="dashed"
                        onClick={() => {
                          let addItem: ITemplate = {
                            templateID: 0,
                            templateName: "",
                            templateContent: "",
                            parentTemplateName: "tars.default",
                            updateTimestamp: "",
                            userName: this.props.currentUser || "",
                          };
                          this.setState({ addItem });
                        }}
                      >
                        <PlusCircleOutlined />
                        新增配置
                      </Button>

                      <Button
                        size="small"
                        type="dashed"
                        className="tool-btn"
                        style={{ marginLeft: 20 }}
                        onClick={() => this.fetchData(true)}
                      >
                        <ReloadOutlined
                          style={{ color: "green", fontSize: 15 }}
                        />
                        刷新
                      </Button>
                    </div>
                  )}
                />
              </TabPane>
            ))}
          </Tabs>
        )}

        {this.state.editItem && (
          <Modal
            centered
            closable={false}
            visible={this.state.editItem ? true : false}
            title={
              <Row justify="space-between">
                <Col>
                  <h3>编辑配置</h3>
                </Col>
                <Col>
                  <h3>{this.state.editItem.templateName}</h3>
                </Col>
                {!isEmpty(this.state.languages) && (
                  <Col>
                    <FormItem label="语法高亮">
                      <Select
                        showSearch={true}
                        style={{ width: 100 }}
                        defaultValue={this.state.language}
                        dropdownMatchSelectWidth={false}
                        onChange={(language) => this.setState({ language })}
                      >
                        {this.state.languages &&
                          this.state.languages.map((item, index) => (
                            <SelectOption value={item} key={index}>
                              {item}
                            </SelectOption>
                          ))}
                      </Select>
                    </FormItem>
                  </Col>
                )}
              </Row>
            }
            okText="提交"
            cancelText="关闭"
            width="80vw"
            okButtonProps={{
              type: "primary",
              ghost: true,
              className: "tool-btn",
            }}
            cancelButtonProps={{ danger: true, className: "tool-btn" }}
            bodyStyle={{ width: "80vw", height: "75vh" }}
            onCancel={() => this.setState({ editItem: undefined })}
            onOk={() => {
              const editItem = this.state.editItem;
              if (editItem && editItem.editingItem) {
                this.fetchModifyTemplate(cloneDeep(editItem.editingItem));
              }
            }}
          >
            <Row>
              <Col flex={1}>
                <div style={{ textAlign: "center" }}>
                  <h4>待编辑内容</h4>
                </div>
              </Col>
              <Col flex={1}>
                <div style={{ textAlign: "center" }}>
                  <h4>原配置</h4>
                </div>
              </Col>
            </Row>
            <MonacoDiffEditor
              theme="vs-dark"
              height="67vh"
              language={this.state.language}
              options={{ minimap: { enabled: false }, originalEditable: false }}
              editorDidMount={this.editorDidMount.bind(this)}
              original={this.state.editItem.templateContent}
              value={
                this.state.editItem.editingItem
                  ? this.state.editItem.editingItem.templateContent
                  : ""
              }
              onChange={(value) => {
                let editItem = this.state.editItem;
                if (editItem && editItem.editingItem) {
                  editItem.editingItem.templateContent = value;
                  this.setState({ editItem });
                }
              }}
            />
          </Modal>
        )}

        {this.state.addItem && (
          <Modal
            centered
            visible={this.state.addItem ? true : false}
            title={
              <Form
                name="addForm"
                ref={this.addForm}
                validateTrigger="onBlur"
                initialValues={{
                  parentTemplateName:
                    this.state.addItem && this.state.addItem.parentTemplateName
                      ? this.state.addItem.parentTemplateName
                      : "",
                }}
              >
                <Row justify="space-between">
                  <Col>
                    <h3>添加模板</h3>
                  </Col>
                  <Col>
                    <FormItem name="parentTemplateName" label="父模板">
                      <Select
                        allowClear
                        showSearch
                        style={{ width: 150 }}
                        dropdownMatchSelectWidth={false}
                      >
                        {this.state.dataSource &&
                          this.state.clusterName &&
                          Array.from(
                            this.state.dataSource.get(this.state.clusterName) ||
                              []
                          ).map((item, index) => (
                            <SelectOption value={item.templateName} key={index}>
                              {item.templateName}
                            </SelectOption>
                          ))}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col>
                    <FormItem
                      name="templateName"
                      rules={[
                        {
                          required: true,
                          validator: (rule, value) => {
                            if (isEmpty(value)) {
                              return Promise.reject(
                                new Error("模板名不能为空")
                              );
                            }
                            const dataArray = this.state.dataSource.get(
                              this.state.clusterName
                            );
                            if (
                              dataArray &&
                              dataArray.some((x) => x.templateName === value)
                            ) {
                              return Promise.reject(
                                new Error(`${value}模板名重复`)
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        addonBefore="模板名"
                        style={{ width: 400 }}
                        placeholder={"eg: tars.default"}
                      />
                    </FormItem>
                  </Col>
                  {!isEmpty(this.state.languages) && (
                    <Col>
                      <FormItem label="语法高亮">
                        <Select
                          showSearch={true}
                          style={{ width: 100 }}
                          defaultValue={this.state.language}
                          dropdownMatchSelectWidth={false}
                          onChange={(language) => this.setState({ language })}
                        >
                          {this.state.languages &&
                            this.state.languages.map((item, index) => (
                              <SelectOption value={item} key={index}>
                                {item}
                              </SelectOption>
                            ))}
                        </Select>
                      </FormItem>
                    </Col>
                  )}
                </Row>
              </Form>
            }
            closable={false}
            width="70vw"
            bodyStyle={{
              width: "70vw",
              height: "70vh",
            }}
            okText="添加"
            cancelText="关闭"
            okButtonProps={{
              type: "primary",
              ghost: true,
              className: "tool-btn",
            }}
            cancelButtonProps={{ danger: true, className: "tool-btn" }}
            onCancel={() => this.setState({ addItem: undefined })}
            onOk={async () => {
              try {
                if (this.addForm.current) {
                  const fields = await this.addForm.current.validateFields();
                  const templateName = fields.templateName;
                  const parentTemplateName = fields.parentTemplateName;
                  let addItem = this.state.addItem;
                  if (addItem) {
                    addItem.templateName = templateName;
                    addItem.parentTemplateName = parentTemplateName;
                    addItem.templateContent = addItem.templateContent || "";
                    this.fetchAddTemplate(cloneDeep(addItem));
                  }
                }
              } catch {}
            }}
          >
            <MonacoEditor
              theme="vs-dark"
              language={this.state.language}
              options={{ minimap: { enabled: false } }}
              editorDidMount={this.editorDidMount.bind(this)}
              onChange={(value) => {
                let addItem = this.state.addItem;
                if (addItem) {
                  addItem.templateContent = value;
                  this.setState({ addItem });
                }
              }}
            />
          </Modal>
        )}
      </>
    );
  }
}
