import React from "react";
import {
  Button,
  Form,
  FormInstance,
  Space,
  Select,
  InputNumber,
  Modal,
  message,
  notification,
} from "antd";
import { RocketOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as ActionType from "src/stores/types";
import * as fetch from "src/fetch";
import { pickClusterName } from "src/utils/Picker";

const FormItem = Form.Item;
const SelectOption = Select.Option;

interface IProps {
  disabled: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
}

interface IState {
  visible: boolean;
}

class ExpandNode extends React.Component<IProps, IState> {
  private expandForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.state = { visible: false };
    this.expandForm = React.createRef<FormInstance>();
  }

  fetchScaleOutServer(clusterName: string, replicas: number) {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer)
      return;

    if (clusterName.length === 0 || replicas <= 0) {
      message.error("系统错误");
      return;
    }

    fetch
      .fetchScaleOutServer({
        clusterName,
        userName: this.props.currentUser || "",
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        replicas,
      })
      .then(() => {
        notification.success({
          message: "扩容成功",
          description: "节点将被自动上线, 稍后刷新页面查看",
        });
      })
      .catch((_) => {});
  }

  render() {
    let clusterName: string | undefined = undefined;
    if (this.props.tarsServerItems) {
      clusterName = pickClusterName(
        this.props.tarsServerItems.map((item) => item.clusterName)
      );
    }

    return (
      <>
        <Button
          disabled={this.props.disabled}
          className="tool-btn"
          size="small"
          onClick={() => this.setState({ visible: true })}
        >
          <Space>
            <RocketOutlined />
            服务扩容
          </Space>
        </Button>

        <Modal
          centered
          closable={false}
          visible={this.state.visible}
          okText="扩容"
          cancelText="取消"
          onCancel={() => {
            this.setState({ visible: false });
            if (this.expandForm.current) this.expandForm.current.resetFields();
          }}
          okButtonProps={{
            type: "primary",
            ghost: true,
            className: "tool-btn",
          }}
          cancelButtonProps={{
            danger: true,
            ghost: true,
            className: "tool-btn",
          }}
          onOk={async () => {
            try {
              if (this.expandForm.current) {
                const fields = await this.expandForm.current.validateFields();
                this.setState({ visible: false });
                this.fetchScaleOutServer(fields.clusterName, fields.replicas);
              }
            } catch {}
          }}
        >
          <Form
            name="expandForm"
            ref={this.expandForm}
            validateTrigger="onBlur"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{ clusterName, replicas: 1 }}
          >
            <FormItem
              name="clusterName"
              label="扩容集群"
              rules={[{ required: true, message: "没选集群" }]}
            >
              <Select
                placeholder="集群"
                dropdownMatchSelectWidth={false}
                style={{ width: "100%" }}
              >
                {(this.props.tarsServerItems || [])
                  .reduce((prev, cur) => {
                    if (-1 === prev.indexOf(cur.clusterName))
                      prev.push(cur.clusterName);
                    return prev;
                  }, Array<string>())
                  .map((value, index) => (
                    <SelectOption key={index} value={value}>
                      {value}
                    </SelectOption>
                  ))}
              </Select>
            </FormItem>
            <FormItem
              name="replicas"
              label="扩容数量"
              rules={[{ required: true, message: "没填扩容数量" }]}
            >
              <InputNumber style={{ width: "100%" }} min={1} max={100} />
            </FormItem>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
});

export default connect(mapStateToProps, null)(ExpandNode);
