import React from "react";
import { isEmpty } from "lodash";
import {
  Button,
  Form,
  FormInstance,
  Space,
  Select,
  Popconfirm,
  message,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as fetch from "src/fetch";
import * as ActionType from "src/stores/types";

import ApproveButton from "./ApproveButton";
import { ApproveKindKeyEnum } from "src/utils/constant";

const FormItem = Form.Item;
const SelectOption = Select.Option;

interface IProps {
  disabled: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  approveStatus: Number;
  updateApproveStatus: () => void;
  pendingApproveUser: string;
}

class Undeploy extends React.Component<IProps> {
  private clusterForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.clusterForm = React.createRef<FormInstance>();
  }

  fetchDeleteServer(clusterName: string) {
    const {
      currentUser,
      selectedTarsApplication,
      selectedTarsSetName,
      selectedTarsServer,
    } = this.props;
    if (selectedTarsApplication && selectedTarsServer) {
      fetch
        .fetchDeleteServer({
          clusterName,
          userName: currentUser || "",
          tarsApplication: selectedTarsApplication,
          tarsSetName: selectedTarsSetName || "",
          tarsServerName: selectedTarsServer,
        })
        .then(() => {
          window.location = window.location.origin as
            | Location
            | (string & Location);
        })
        .catch(() => {})
        .finally(() => {
          this.props.updateApproveStatus();
        });
    } else {
      message.error("未知错误");
    }
  }

  render() {
    return (
      <ApproveButton
        kind={ApproveKindKeyEnum.delete}
        approveStatus={this.props.approveStatus}
        updateApproveStatus={this.props.updateApproveStatus}
        pendingApproveUser={this.props.pendingApproveUser}
      >
        <Popconfirm
          icon={null}
          title={
            <Form
              name="clusterForm"
              ref={this.clusterForm}
              validateTrigger="onBlur"
            >
              <span>
                <p>
                  服务下线属于<span style={{ color: "red" }}>高危操作</span>,
                  三思而后行
                </p>
                <p>
                  <FormItem
                    name="clusterName"
                    label="集群"
                    rules={[{ required: true, message: "没选集群" }]}
                  >
                    <Select
                      size="small"
                      placeholder="选集群"
                      showSearch={true}
                      style={{ width: "100%" }}
                      dropdownMatchSelectWidth={false}
                    >
                      {Array.from(this.props.tarsServerItems || [])
                        .reduce((prev, cur) => {
                          if (prev.indexOf(cur.clusterName) === -1)
                            prev.push(cur.clusterName);
                          return prev;
                        }, Array<string>())
                        .map((clusterName, index) => (
                          <SelectOption key={index} value={clusterName}>
                            {clusterName}
                          </SelectOption>
                        ))}
                    </Select>
                  </FormItem>
                </p>
              </span>
            </Form>
          }
          cancelButtonProps={{
            className: "tool-btn",
            type: "primary",
            ghost: true,
          }}
          okButtonProps={{ className: "tool-btn", danger: true, ghost: true }}
          disabled={this.props.disabled}
          placement="left"
          overlayStyle={{ width: 300 }}
          onCancel={() => {
            if (this.clusterForm.current)
              this.clusterForm.current.resetFields();
          }}
          onConfirm={async () => {
            if (!this.clusterForm.current) return;
            try {
              const { clusterName } =
                await this.clusterForm.current.validateFields();
              if (isEmpty(clusterName)) {
                message.error("集群为空");
                return;
              }
              this.fetchDeleteServer(clusterName);
            } catch {}
          }}
        >
          <Button
            disabled={this.props.disabled}
            className="tool-btn"
            size="small"
          >
            <Space>
              <InfoCircleOutlined style={{ color: "#F90" }} />
              删除服务
            </Space>
          </Button>
        </Popconfirm>
      </ApproveButton>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
});

export default connect(mapStateToProps, null)(Undeploy);
