
import React from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';

import * as fetch from 'src/fetch';

interface IProps {
  currentUser?: string;
  podName: string;
  clusterName: string;
  onOK: () => void;
}

export default class DeletePodButton extends React.Component<IProps> {
  fetchDeletePod(podName: string, clusterName: string) {
    fetch
      .fetchDeletePod({
        clusterName,
        podName,
        userName: this.props.currentUser || '',
      })
      .then(() => {
        this.setState({ visible: false });
        this.props.onOK();
      })
      .catch(_ => { });
  }

  render() {
    return (
      <Popconfirm
        title='删除会重新上线容器'
        okText='删除'
        okType='danger'
        trigger='click'
        placement='left'
        onConfirm={() => this.fetchDeletePod(this.props.podName, this.props.clusterName)}
        okButtonProps={{ className: 'tool-btn', danger: true, ghost: true }}
        cancelButtonProps={{ className: 'tool-btn', type: 'primary', ghost: true }}
      >
        <Button size='small' className='tool-btn'>
          <DeleteTwoTone twoToneColor='#DC143C' />删除容器
        </Button>
      </Popconfirm>
    );
  }
}
