import React from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { Key, DataNode } from "rc-tree/lib/interface";
import { Input, Button, Tree, Empty } from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";

import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";

import "./style/common.less";
const { DirectoryTree } = Tree;

interface IProps {
  currentUser?: string;
  loadDone?: boolean;
  dataNodes: Array<DataNode>; // 搜索 展开之后tree结构
  treeNodes: Array<ActionType.ITreeItem>; // 原始响应
  selectedKey?: string;
  changeSelectServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string
  ) => void;

  listServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string
  ) => void;

  rebuildTree: (treeNodes: ActionType.ITreeItem[]) => void;

  listServerTree: (withLoading?: boolean) => void;
}

interface IState {
  taskManagerVisible: boolean;
  searchKey: string;
  expandedKeys: Array<Key>;
  selectedKeys: Array<Key>;
}

const LeftSideBar: React.FC<IProps> = (props) => {
  const [state, setState] = React.useState<IState>({
    taskManagerVisible: false,
    searchKey: "",
    expandedKeys: [],
    selectedKeys: [],
  });
  const [pageClientRect, setPageClientRect] = React.useState<DOMRect | null>();

  const [inputValue, setInputValue] = React.useState<string>("");

  const navigate = useNavigate();

  let currentUser = "";
  if (props.currentUser) currentUser = props.currentUser;
  const listServerTree = props.listServerTree;
  const rebuildTree = props.rebuildTree;
  const changeSelectServer = props.changeSelectServer;
  const treeNodes = props.treeNodes;

  React.useEffect(() => {
    if (currentUser) {
      listServerTree();
      changeSelectServer("", "", "");
    }
  }, [currentUser, listServerTree, changeSelectServer]);

  React.useEffect(() => {
    const onResize = () => {
      const bodyRect = document.body.getBoundingClientRect();
      setPageClientRect(bodyRect);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  React.useEffect(() => {
    // 如果有值的情况之下
    if (props.selectedKey && props.treeNodes && !state.searchKey) {
      const hasKey = props.treeNodes.some(
        (node: ActionType.ITreeItem) =>
          `${node.tarsApplication}${
            node.tarsSetName === ".." ? `.${node.tarsSetName}` : ""
          }.${node.tarsServerName}` === props.selectedKey
      );

      if (!hasKey) {
        return;
      }

      const parts = props.selectedKey.split(".");
      const expandedKeys: any = [];
      if (parts.length === 2) {
        expandedKeys.push(parts[0]);
      } else if (parts.length === 5) {
        const setName = parts.slice(1, 4).join(".");
        expandedKeys.push(parts[0], parts[0] + "." + setName);
      }
      setTimeout(() => {
        setState({
          ...state,
          expandedKeys: expandedKeys,
          selectedKeys: [props.selectedKey as any],
        });
      }, 1500);
    }
  }, [props.selectedKey, props.treeNodes, props.dataNodes]);

  // 点击树和展开触发
  const onTreeSelectOrExpand = (keys: Key[], nodeInfo: any) => {
    if (!nodeInfo.node.isLeaf) {
      let expandedKeys = [...state.expandedKeys];
      if (
        expandedKeys.some(
          (key) => (key as string).indexOf(nodeInfo.node.key) === 0
        )
      ) {
        expandedKeys = expandedKeys.filter(
          (key) => (key as string).indexOf(nodeInfo.node.key) !== 0
        );
      } else {
        expandedKeys.push(nodeInfo.node.key);
      }
      changeSelectServer("", "", "");
      setState({
        ...state,
        selectedKeys: [],
        expandedKeys: Array.from(new Set(expandedKeys)),
      });
      return;
    }

    const parts = nodeInfo.node.key.split(".");
    if (parts.length === 2) {
      props.listServer(parts[0], "", parts[1]);
      props.changeSelectServer(parts[0], "", parts[1]);
    } else if (parts.length === 5) {
      const setName = parts.slice(1, 4).join(".");
      props.listServer(parts[0], setName, parts[4]);
      props.changeSelectServer(parts[0], setName, parts[4]);
    }
    navigate(`/home?key=${nodeInfo.node.key}`, {
      replace: true,
    });

    setState({ ...state, selectedKeys: keys });
  };

  // 搜索
  const onSearch = (e: React.ChangeEvent) => {
    const target = e.target as any;
    const searchKey = target.value as string;
    setInputValue(searchKey);
    if (isEmpty(searchKey)) {
      rebuildTree(treeNodes);
      setState({ ...state, searchKey: "" });
      return;
    }

    if (searchKey.length < 2 || isEmpty(props.treeNodes)) {
      setState({ ...state, searchKey: "" });
      return;
    }

    let expandedKeysSet = new Set<Key>();

    const filteredTreeNodes = props.treeNodes.filter(
      (node: ActionType.ITreeItem) => {
        const pos = node.tarsServerName
          .toLowerCase()
          .indexOf(searchKey.toLowerCase());
        if (pos > -1) {
          expandedKeysSet.add(node.tarsApplication);
          if (node.tarsSetName === "..") {
            expandedKeysSet.add(
              node.tarsApplication + "." + node.tarsServerName
            );
          } else {
            expandedKeysSet.add(node.tarsApplication + "." + node.tarsSetName);
            expandedKeysSet.add(
              node.tarsApplication +
                "." +
                node.tarsSetName +
                "." +
                node.tarsServerName
            );
          }
          return true;
        }
        return false;
      }
    );
    rebuildTree(filteredTreeNodes);
    const expandedKeys =
      expandedKeysSet.size > 0
        ? Array.from(expandedKeysSet)
        : state.expandedKeys;
    setState({ ...state, searchKey, expandedKeys });
  };

  return (
    <>
      <Input
        placeholder="输服务名(最少2字符).."
        prefix={<SearchOutlined />}
        value={inputValue}
        addonAfter={
          <Button
            type="link"
            size="small"
            icon={
              <ReloadOutlined style={{ color: "green", fontSize: "15px" }} />
            }
            onClick={() => {
              setInputValue("");
              listServerTree(true);
              rebuildTree(treeNodes);
            }}
          />
        }
        onChange={onSearch}
      />

      {!isEmpty(props.treeNodes) && (
        <DirectoryTree
          height={(pageClientRect?.height as number) - 104 || 1080}
          className="mainSideLeftCard"
          treeData={props.dataNodes}
          expandedKeys={state.expandedKeys}
          selectedKeys={state.selectedKeys}
          onSelect={onTreeSelectOrExpand}
          onExpand={onTreeSelectOrExpand}
        />
      )}

      {props.loadDone && isEmpty(props.treeNodes) && (
        <Empty style={{ marginTop: 100 }} description="授权服务列表为空" />
      )}

      {props.loadDone &&
        isEmpty(props.dataNodes) &&
        !isEmpty(props.treeNodes) && (
          <Empty style={{ marginTop: 100 }} description="搜不到" />
        )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,

  loadDone: state.tarsReducer.loadDone,
  dataNodes: state.tarsReducer.dataNodes || [],
  treeNodes: state.tarsReducer.treeNodes || [],
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  changeSelectServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string
  ) =>
    dispatch(
      Actions.changeSelectServer(
        selectedTarsApplication,
        selectedTarsSetName,
        selectedTarsServer
      )
    ),

  listServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string
  ) => {
    dispatch(Actions.listServerClear());
    dispatch(
      Actions.listServer(
        selectedTarsApplication,
        selectedTarsSetName,
        selectedTarsServer
      )
    );
  },

  listServerTree: (withLoading?: boolean) =>
    dispatch(Actions.listServerTree(withLoading)),

  rebuildTree: (treeNodes: ActionType.ITreeItem[]) =>
    dispatch(Actions.rebuildTree(treeNodes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar);
