import React from "react";
import { Tabs, PageHeader } from "antd";

import { connect } from "react-redux";
import { NavigateFunction } from "react-router-dom";

import ManageTemplate from "src/pages/Tools/ManageTemplate";
import ManageMachine from "src/pages/Tools/ManageMachine";
import ManageNodePool from "src/pages/Tools/ManageNodePool";
import ManageNodePoolPlaceholder from "src/pages/Tools/ManageNodePoolPlaceholder";
import TrackOperation from "src/pages/Tools/TrackOperation";

import GlobalAlarmConfig from "src/pages/Tools/GlobalAlarmConfig";
import GlobalAlarmHistory from "src/pages/Tools/GlobalAlarmHistory";
import ThirdAlarmConfig from "src/pages/Tools/ThirdAlarmConfig";
import GlobalAlarmPhone from "src/pages/Tools/GlobalAlarmPhone";

const { TabPane } = Tabs;

interface IProps {
  currentUser?: string;
  admin?: boolean;
  navigator: NavigateFunction;
}

interface IState {
  currTabKey: string;
}

class Tools extends React.Component<IProps, IState> {
  private manageTemplateRef: React.RefObject<ManageTemplate>;
  private manageMachineRef: React.RefObject<ManageMachine>;
  private manageNodePoolRef: React.RefObject<ManageNodePool>;
  private manageNodePoolPlaceholderRef: React.RefObject<ManageNodePoolPlaceholder>;
  private trackOperationRef: React.RefObject<TrackOperation>;
  private globalAlarmConfigRef: React.RefObject<any>;
  private globalAlarmHistoryRef: React.RefObject<any>;
  private thirdAlarmConfigRef: React.RefObject<any>;
  private globalAlarmPhoneRef: React.RefObject<any>;

  constructor(props: IProps) {
    super(props);
    this.manageTemplateRef = React.createRef<ManageTemplate>();
    this.manageMachineRef = React.createRef<ManageMachine>();
    this.manageNodePoolRef = React.createRef<ManageNodePool>();
    this.manageNodePoolPlaceholderRef =
      React.createRef<ManageNodePoolPlaceholder>();
    this.trackOperationRef = React.createRef<TrackOperation>();
    this.globalAlarmConfigRef = React.createRef<any>();
    this.globalAlarmHistoryRef = React.createRef<any>();
    this.thirdAlarmConfigRef = React.createRef<any>();
    this.globalAlarmPhoneRef = React.createRef<any>();
    this.state = {
      currTabKey: "server-creating",
    };
  }

  render() {
    return (
      <>
        <PageHeader
          title="服务运维"
          onBack={() => {
            this.props.navigator(-1);
          }}
        />
        <Tabs
          size="large"
          type="card"
          style={{ marginLeft: 8 }}
          onChange={(tabKey) => {
            this.setState({ currTabKey: tabKey });
            if (
              tabKey === "template-managing" &&
              this.manageTemplateRef.current
            ) {
              this.manageTemplateRef.current.fetchData();
            } else if (
              tabKey === "machine-managing" &&
              this.manageMachineRef.current
            ) {
              this.manageMachineRef.current.fetchData();
            } else if (
              tabKey === "nodePool-managing" &&
              this.manageNodePoolRef.current
            ) {
              this.manageNodePoolRef.current.fetchData();
            } else if (
              tabKey === "nodePool-placeholder-managing" &&
              this.manageNodePoolPlaceholderRef.current
            ) {
              this.manageNodePoolPlaceholderRef.current.fetchData();
            } else if (
              tabKey === "operation-tracking" &&
              this.trackOperationRef.current
            ) {
              this.trackOperationRef.current.fetchData();
            } else if (
              tabKey === "global-alarm-config" &&
              this.globalAlarmConfigRef.current
            ) {
              this.globalAlarmConfigRef.current.fetchData();
            } else if (
              tabKey === "global-alarm-history" &&
              this.globalAlarmHistoryRef.current
            ) {
              this.globalAlarmHistoryRef.current.fetchData();
            } else if (
              tabKey === "third-alarm-config" &&
              this.thirdAlarmConfigRef.current
            ) {
              this.thirdAlarmConfigRef.current.fetchData();
            } else if (
              tabKey === "global-alarm-phone" &&
              this.globalAlarmPhoneRef.current
            ) {
              this.globalAlarmPhoneRef.current.fetchData();
            }
          }}
        >
          <TabPane tab="模板管理" key="template-managing">
            <ManageTemplate
              ref={this.manageTemplateRef}
              currentUser={this.props.currentUser}
            />
          </TabPane>
          <TabPane tab="机器管理" key="machine-managing">
            <ManageMachine
              ref={this.manageMachineRef}
              currentUser={this.props.currentUser}
            />
          </TabPane>
          <TabPane tab="节点池管理" key="nodePool-managing">
            <ManageNodePool
              ref={this.manageNodePoolRef}
              currentUser={this.props.currentUser}
            />
          </TabPane>
          <TabPane tab="节点池缓冲区" key="nodePool-placeholder-managing">
            <ManageNodePoolPlaceholder
              ref={this.manageNodePoolPlaceholderRef}
              currentUser={this.props.currentUser}
            />
          </TabPane>

          {this.props.admin && (
            <TabPane tab="操作审计" key="operation-tracking">
              <TrackOperation
                ref={this.trackOperationRef}
                currentUser={this.props.currentUser}
              />
            </TabPane>
          )}
          <TabPane tab="全局告警配置" key="global-alarm-config">
            {"global-alarm-config" === this.state.currTabKey && (
              <GlobalAlarmConfig
                ref={this.globalAlarmConfigRef}
                currentUser={this.props.currentUser}
              />
            )}
          </TabPane>
          <TabPane tab="全局告警历史" key="global-alarm-history">
            {"global-alarm-history" === this.state.currTabKey && (
              <GlobalAlarmHistory
                ref={this.globalAlarmHistoryRef}
                currentUser={this.props.currentUser}
              />
            )}
          </TabPane>
          <TabPane tab="接入告警配置(三方)" key="third-alarm-config">
            {"third-alarm-config" === this.state.currTabKey && (
              <ThirdAlarmConfig
                ref={this.thirdAlarmConfigRef}
                currentUser={this.props.currentUser}
              />
            )}
          </TabPane>
        </Tabs>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  admin: state.tarsReducer.admin,
});

export default connect(mapStateToProps, null)(Tools);
