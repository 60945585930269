import React, { useState, useEffect } from "react";
import { Form, Select, Button, Modal, Space, Spin, message } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { webApi } from "../../../fetch";
import * as ActionType from "../../../stores/types";

interface IProps {
  disabled: boolean;
  forbitEdit: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
}

interface IPerson {
  apprvoer: string;
  developer: string[];
  visitor: string[];
  first_alarm: string;
  second_alarm: string;
  third_alarm: string;
}

interface IUserInfo {
  uid: string;
  username: string;
  avatar: string;
}

const { Item } = Form;
const EditPerson: React.FC<IProps> = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [userList, setUserList] = useState<{ value: string; label: string }[]>(
    []
  );

  useEffect(() => {
    if (isShow) {
      form.resetFields();
      setLoading(true);
      Promise.all([fetchUserList(), fetchServerPersonConfig()]).finally(() => {
        setLoading(false);
      });
    }
  }, [isShow]);

  function fetchUserList() {
    webApi.get<IUserInfo[]>("user/list").then((res) =>
      setUserList(
        res.map((item) => ({
          value: item.uid,
          label: item.username,
        }))
      )
    );
  }

  function fetchServerPersonConfig() {
    webApi
      .get<IPerson>("server/person/detail", {
        server: `${props.selectedTarsApplication}.${props.selectedTarsServer}`,
      })
      .then((personConfig) => {
        form.setFieldsValue(personConfig);
      });
  }

  function handleSubmit(val: any) {
    const { first_alarm, second_alarm, third_alarm } = val;

    if (
      first_alarm === second_alarm ||
      first_alarm === third_alarm ||
      (second_alarm && second_alarm === third_alarm)
    ) {
      message.warn("服务告警人不能出现重复");
      return;
    }

    setLoading(true);
    webApi
      .post<IPerson>("server/person/update", {
        server: `${props.selectedTarsApplication}.${props.selectedTarsServer}`,
        ...val,
      })
      .then(() => {
        message.success("success");
        setIsShow(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Button
        onClick={() => setIsShow(true)}
        size="small"
        disabled={props.disabled}
        className="tool-btn"
      >
        <Space>
          <UsergroupAddOutlined />
          人员配置
        </Space>
      </Button>

      <Modal
        centered
        onCancel={() => setIsShow(false)}
        onOk={() => form.submit()}
        okButtonProps={{
          disabled: props.disabled || props.forbitEdit,
        }}
        width={600}
        visible={isShow}
        title="服务人员配置"
      >
        <Spin spinning={loading}>
          <Form
            onFinish={handleSubmit}
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Item
              label="审批人"
              rules={[
                {
                  required: true,
                },
              ]}
              name="apprvoer"
            >
              <Select
                disabled={props.forbitEdit}
                options={userList}
                showSearch
                filterOption={(input, option) => {
                  if (typeof option === "object") {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }
                  return false;
                }}
              />
            </Item>
            <Item
              rules={[
                {
                  required: true,
                },
              ]}
              label="服务开发者"
              name="developer"
            >
              <Select
                disabled={props.forbitEdit}
                mode="multiple"
                options={userList}
                showSearch
                filterOption={(input, option) => {
                  if (typeof option === "object") {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }
                  return false;
                }}
              />
            </Item>
            <Item
              label="第一告警人"
              rules={[
                {
                  required: true,
                },
              ]}
              name="first_alarm"
            >
              <Select
                disabled={props.forbitEdit}
                options={userList}
                showSearch
                filterOption={(input, option) => {
                  if (typeof option === "object") {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }
                  return false;
                }}
              />
            </Item>
            <Item
              label="第二告警人"
              name="second_alarm"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                disabled={props.forbitEdit}
                options={userList}
                showSearch
                filterOption={(input, option) => {
                  if (typeof option === "object") {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }
                  return false;
                }}
              />
            </Item>
            <Item label="第三告警人" name="third_alarm">
              <Select
                disabled={props.forbitEdit}
                options={userList}
                showSearch
                allowClear
                filterOption={(input, option) => {
                  if (typeof option === "object") {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }
                  return false;
                }}
              />
            </Item>
            <Item label="服务关注人" name="visitor">
              <Select
                disabled={props.forbitEdit}
                mode="multiple"
                options={userList}
                showSearch
                filterOption={(input, option) => {
                  if (typeof option === "object") {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }
                  return false;
                }}
              />
            </Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
});
export default connect(mapStateToProps, null)(EditPerson);
