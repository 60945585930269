/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Button, Drawer, Radio, Space } from "antd";
import { AlertFilled } from "@ant-design/icons";

import type { RadioChangeEvent } from "antd";

import CommonReporter from "src/components/CommonReporter";
import ServerPropertyRule from "./ServerPropertyRule";
import { connect } from "react-redux";

import "./style/common.less";
// TODO: 需要注意在pre测试，要把t_alarm_conf ==> t_alarm_conf_v1
// 其他情况修改回来
const serverAlarmTag = `db_resource_{cluster_name}.t_alarm_conf.server`;
const tabList = [
  {
    name: "服务维度告警配置 ",
    tableId: serverAlarmTag,
  },
  {
    name: "服务维度告警历史",
    tableId: "db_resource_{cluster_name}.t_alarm_history.server",
  },
  {
    name: "服务特性告警规则配置",
    tableId: "db_tars_web.t_alarm_config_new.server_feature",
  },
];

interface IProps {
  disabled: boolean;
  forbitEdit: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  tarsServerItems?: any[];
}

interface IState {
  animate: boolean;
  visible: boolean;
  editing: boolean;
  searchText?: string;
  clusterName: string;
  currTabKey: string;
}

class EditAlarm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      animate: false,
      visible: false,
      editing: false,
      clusterName: "",
      currTabKey: serverAlarmTag,
    };
  }

  initAlarmConfig() {
    this.setState({
      visible: true,
    });
  }

  onChange = (e: RadioChangeEvent) => {
    console.log(e.target.value);
    this.setState({ currTabKey: e.target.value });
  };
  render() {
    /*const serverInfoName = `${this.props.selectedTarsApplication}.${
      this.props.selectedTarsServer
    }${
      this.props.selectedTarsSetName ? `.${this.props.selectedTarsSetName}` : ""
    }`;
    */

    return (
      <>
        <Button
          size="small"
          className="tool-btn"
          disabled={this.props.disabled}
          onClick={() => this.initAlarmConfig()}
        >
          <Space>
            <AlertFilled
              className={
                this.state.animate
                  ? "animate__animated animate__tada animate__infinite animate__fast"
                  : ""
              }
              style={{ color: "red" }}
            />
            告警配置
          </Space>
        </Button>

        <Drawer
          title="告警配置"
          placement="right"
          width="calc(100% - 50px)"
          visible={this.state.visible}
          onClose={() => {
            this.setState({ animate: true });
            setTimeout(() => this.setState({ animate: false }), 6000);
            this.setState({
              visible: false,
            });
          }}
        >
          {this.state.visible && (
            <>
              <div className="flex_center">
                <Radio.Group
                  value={this.state.currTabKey}
                  onChange={this.onChange}
                  style={{ marginBottom: 16 }}
                >
                  {tabList.map((tab) => {
                    return (
                      <Radio.Button value={tab.tableId}>
                        {tab.name}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </div>
              {this.state.currTabKey ===
              "db_tars_web.t_alarm_config_new.server_feature" ? (
                <>
                  <ServerPropertyRule />
                </>
              ) : (
                <CommonReporter
                  disabled={this.props.forbitEdit}
                  tableId={this.state.currTabKey}
                  tarsApplication={this.props.selectedTarsApplication}
                  tarsSetName={this.props.selectedTarsSetName}
                  tarsServerName={this.props.selectedTarsServer}
                  baseFilterCond={
                    this.state.currTabKey === serverAlarmTag
                      ? [
                          {
                            name: "server_name",
                            operation: "eq",
                            value: this.props.selectedTarsServer,
                          },
                          {
                            name: "application",
                            operation: "eq",
                            value: this.props.selectedTarsApplication,
                          },
                          {
                            name: "set_name",
                            operation: "eq",
                            value: this.props.selectedTarsSetName,
                          },
                        ]
                      : [
                          {
                            name: "server_name",
                            operation: "like",
                            value: this.props.selectedTarsServer,
                          },
                        ]
                  }
                  userName={this.props.currentUser}
                  focusColumns={
                    this.state.currTabKey === serverAlarmTag
                      ? {
                          application: {
                            field: "application",
                            fieldDisplayName: "应用名",
                            type: "string",
                            formatType: "",
                            defaultValue: this.props.selectedTarsApplication,
                            disableEdit: true,
                            hide: false,
                            formatByTimezone: false,
                            essential: true,
                            placeholder: "",
                            tips: "应用名",
                            editType: "text",
                            options: [],
                            isMultiSelect: false,
                            isAdvanced: false,
                          },
                          server_name: {
                            field: "server_name",
                            fieldDisplayName: "服务名",
                            type: "string",
                            formatType: "",
                            defaultValue: this.props.selectedTarsServer,
                            disableEdit: true,
                            hide: false,
                            formatByTimezone: false,
                            required: true,
                            placeholder: "",
                            tips: "",
                            editType: "text",
                            options: [],
                            isMultiSelect: false,
                          },
                          set_name: {
                            field: "set_name",
                            fieldDisplayName: "set名",
                            type: "string",
                            formatType: "",
                            defaultValue: this.props.selectedTarsSetName,
                            disableEdit: true,
                            hide: false,
                            formatByTimezone: false,
                            essential: false,
                            placeholder: "",
                            tips: "set名",
                            editType: "text",
                            options: [],
                            isMultiSelect: false,
                            isAdvanced: false,
                          },
                          receptUser: {
                            defaultValue: this.props.currentUser,
                          },
                        }
                      : {}
                  }
                  tabsConfig={{
                    size: "small",
                  }}
                />
              )}
            </>
          )}
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
});

export default connect(mapStateToProps, null)(EditAlarm);
