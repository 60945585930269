import React from 'react';
import { isEmpty } from 'lodash';
import { Button, Card, Col, Checkbox, Form, FormInstance, Input, InputNumber, Row, Result, Modal, PageHeader } from 'antd';
import { ClockCircleOutlined, DeleteTwoTone, PlusCircleOutlined } from '@ant-design/icons';

import * as fetch from 'src/fetch';
import * as FetchType from 'src/fetch/types';

const FormItem = Form.Item;
const FormList = Form.List;

interface IProps {
  replicas: number;
  currentUser?: string;
  clusterName: string;
  nodeResourceID: number;
}

interface IState {
  visible: boolean;
  item: FetchType.ICronHPAItem;
}

export default class AdjustNodePoolPlaceholderCronHPAButton extends React.Component<IProps, IState> {
  private form: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.form = React.createRef<FormInstance>();
    this.state = {visible: false, item: {errCode: 0, errMsg: 'OK', items: [], excludeDates: []}};
  }

  fetchData() {
    fetch
    .fetchListNodePoolPlaceholderCronHPA({
      clusterName: this.props.clusterName,
      nodeResourceID: this.props.nodeResourceID
    })
    .then(item => this.setState({item, visible: true}))
    .catch(_ => {});
  }

  fetchAddOrModifyNodePoolPlaceholderCronHPA() {
    let items: Array<FetchType.ICronHPAJob> = [];
    for (let item of this.state.item.items) {
      items.push({
        name: item.name,
        schedule: item.schedule,
        targetSize: item.targetSize,
        runOnce: item.runOnce ? 1 : 0
      });
    }
    fetch
    .fetchAddOrModifyNodePoolPlaceholderCronHPA({
      clusterName: this.props.clusterName,
      nodeResourceID: this.props.nodeResourceID,
      excludeDates: this.state.item.excludeDates,
      items,
      userName: this.props.currentUser || '',
    })
    .then(_ => this.setState({visible: false}))
    .catch(_ => {});
  }

  render() {
    const validateExcludeDates = (index: number): Promise<any> => {
      const value = this.state.item.excludeDates[index];
      if (isEmpty(value)) return Promise.reject('输入不能为空');
      return Promise.resolve();
    };

    const validateCronJobs = (index: number): Promise<any> => {
      let uniqJobs = new Set();
      for (const job of this.state.item.items) {
        if (uniqJobs.has(job.name)) return Promise.reject('名称不能重复');
        uniqJobs.add(job.name);
      }
      const value = this.state.item.items[index];
      if (isEmpty(value.name)) return Promise.reject('输入不能为空');
      if (isEmpty(value.schedule)) return Promise.reject('输入不能为空');
      return Promise.resolve();
    };

    return (
      <>
        <Button size='small' className='tool-btn' onClick={() => this.fetchData()}>
          <ClockCircleOutlined />定时
        </Button>
        
        <Modal
          closable={false}
          visible={this.state.visible}
          width='60%'
          title={<PageHeader title='定时伸缩' subTitle='(定时缩容或扩容)' footer={<><span style={{color: 'red'}}>注意</span>:时区(UTC+08:00)</>} extra={<Button size='small' type='primary' className='tool-btn' />} />}
          destroyOnClose
          footer={[
            <Button type='primary' ghost className='tool-btn' onClick={() => this.setState({visible: false})}>
              取消
            </Button>,
            <Button danger ghost className='tool-btn' onClick={async () => {
              try {
                if (this.form.current) {
                  await this.form.current.validateFields();
                  this.fetchAddOrModifyNodePoolPlaceholderCronHPA();
                }
              } catch {}
            }}>
              提交
            </Button>
          ]}
        >
          {
            this.state.item.errCode !== 0 &&
            <Result status='error' title='无法配置定时扩容'
              subTitle={'errCode:' + this.state.item.errCode + ' errMsg:' + this.state.item.errMsg}
            />
          }

          {
            this.state.item.errCode === 0 &&
            <Form
              name='form'
              ref={this.form}
              initialValues={{}}
              validateTrigger='onBlur'
            >
              <FormList
                name='excludeDates'
                initialValue={this.state.item.excludeDates}
                children={fields => (
                  <>
                    {
                      fields.map((field, index) => (
                        <div key={index}>
                          {
                            <Row>
                              <Col>
                                <FormItem name={[field.name, 'date']} label='排除日期' tooltip='遇到符合日期时任务将会被跳过' extra='* * * * * *" 表示 - "(seconds) (minutes) (hours) (day of month) (months) (day of week)' rules={[{ required: true, validator: validateExcludeDates.bind(this, index) }]}>
                                  <Input placeholder='eg: * * * 15 11 *' onChange={e => {
                                    const value = e.target.value;
                                    let obj = {...this.state.item};
                                    let excludeDates = this.state.item.excludeDates;
                                    excludeDates[index] = value;
                                    this.setState({item: obj});
                                  }}/>
                                </FormItem>
                              </Col>

                              {
                                <Col>
                                  <FormItem>
                                    <Button type='dashed' size='small' className='tool-btn'
                                      onClick={() => {
                                        let obj = {...this.state.item};
                                        obj.excludeDates.splice(index, 1);
                                        this.setState({item: obj});
                                        const excludeDates = obj.excludeDates;
                                        if (this.form.current) this.form.current.setFieldsValue({excludeDates});
                                      }}
                                    >
                                      <DeleteTwoTone twoToneColor='#DC143C' /> 删除一行
                                    </Button>
                                  </FormItem>
                                </Col>
                              }
                            </Row>
                          }
                        </div>
                      ))
                    }
                  </>
                )}
              />

              <FormItem>
                <Button type='dashed' size='small' className='tool-btn'
                  onClick={async () => {
                    let obj = {...this.state.item};
                    let excludeDates = obj.excludeDates;
                    excludeDates.push('');
                    this.setState({item: obj});
                    if (this.form.current) this.form.current.setFieldsValue({excludeDates});
                  }}
                >
                  <PlusCircleOutlined /> 新增排除日期
                </Button>
              </FormItem>

              <FormList
                name='jobs'
                initialValue={this.state.item.items}
                children={fields => (
                  <>
                    {
                      fields.map((field, index) => (
                        <Card bordered key={index} style={{marginTop: 8}}>
                          {
                            <Row>
                              <Col>
                                <FormItem label='任务名' name={[field.name, 'name']} rules={[{ required: true, validator: validateCronJobs.bind(this, index) }]}>
                                  <Input placeholder='eg: scale-down' onChange={e => {
                                    const value = e.target.value;
                                    let obj = {...this.state.item};
                                    let cronJobs = obj.items;
                                    cronJobs[index].name = value;
                                    this.setState({item: obj});
                                    if (this.form.current) this.form.current.setFieldsValue({cronJobs});
                                  }}/>
                                </FormItem>

                                <FormItem label='Cron表达式' tooltip={
                                  <>
                                    Go Cron表达式:<Button type='link' onClick={() => window.open('https://pkg.go.dev/github.com/robfig/cron')}>源码</Button>文档
                                  </>
                                }
                                  extra='* * * * * *" 表示 - "(seconds) (minutes) (hours) (days of month) (months)'
                                  name={[field.name, 'schedule']}
                                  rules={[{ required: true, validator: validateCronJobs.bind(this, index) }]}
                                >
                                  <Input placeholder='exclude November 15th eg: * * * 15 11 *' onChange={e => {
                                    const value = e.target.value;
                                    let obj = {...this.state.item};
                                    let cronJobs = obj.items;
                                    cronJobs[index].schedule = value;
                                    this.setState({item: obj});
                                    if (this.form.current) this.form.current.setFieldsValue({cronJobs});
                                  }}/>
                                </FormItem>

                                <FormItem label='目标数量' name={[field.name, 'targetSize']} rules={[{ required: true, validator: validateCronJobs.bind(this, index) }]}>
                                  <InputNumber size='small' min={0} onChange={value => {
                                    let obj = {...this.state.item};
                                    let cronJobs = obj.items;
                                    cronJobs[index].targetSize = value;
                                    this.setState({item: obj});
                                    if (this.form.current) this.form.current.setFieldsValue({cronJobs});
                                  }}/>
                                </FormItem>

                                <FormItem label='仅运行一次' name={[field.name, 'runOnce']} valuePropName='checked' rules={[{ required: true }]}>
                                  <Checkbox onChange={({target: {checked}}) => {
                                    let obj = {...this.state.item};
                                    let cronJobs = obj.items;
                                    cronJobs[index].runOnce = checked;
                                    this.setState({item: obj});
                                    if (this.form.current) this.form.current.setFieldsValue({cronJobs});
                                  }}/>
                                </FormItem>

                                <FormItem>
                                  <Button type='dashed' size='small' className='tool-btn'
                                    onClick={() => {
                                      let obj = {...this.state.item};
                                      let cronJobs = obj.items;
                                      cronJobs.splice(index, 1);
                                      this.setState({item: obj});
                                      if (this.form.current) this.form.current.setFieldsValue({cronJobs});
                                    }}
                                  >
                                    <DeleteTwoTone twoToneColor='#DC143C' /> 删除
                                  </Button>
                                </FormItem>
                              </Col>
                            </Row>
                          }
                        </Card>
                      ))
                    }
                  </>
                )}
              />

              <FormItem>
                <Button type='dashed' size='small' className='tool-btn'
                  onClick={() => {
                    let obj = {...this.state.item};
                    obj.items.push({name: 'scale-up', schedule: '30 */1 * * * *', targetSize: 2, runOnce: false});
                    this.setState({item: obj});
                    if (this.form.current) this.form.current.setFieldsValue({jobs: obj.items});
                  }}
                >
                  <PlusCircleOutlined /> 新增任务
                </Button>
              </FormItem>
            </Form>
          }
        </Modal>
      </>
    );
  }
};
