import React from 'react';
import { Button, Space } from 'antd';
import { UserSwitchOutlined } from '@ant-design/icons';


import { connect } from 'react-redux';

interface IProps {
  currentUser?: string;
};

interface IState {
};

class Switch extends React.Component<IProps, IState> {
  render() {
    return (
      <>
        <Button size='small' danger ghost className='tool-btn'>
          <Space><UserSwitchOutlined />主备切换</Space>
        </Button>
      </>
    );
  };
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
});

export default connect(mapStateToProps, null)(Switch);
