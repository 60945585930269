export function parseUrlQuery(searchString = window.location.search) {
  const query: { [key: string]: string } = {};
  const url = searchString.substr(1);
  url.split("&").forEach((part) => {
    const item = part.split("=");
    query[item[0]] = decodeURIComponent(item[1]);
  });
  return query;
}

export function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp * 1000);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
