import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Button, Table, notification, Tooltip, Tabs, Badge, Radio } from "antd";
import * as fetch from "../../fetch";
import { getStaticClusterName } from '../../utils/constant';
import { webApi } from "../../fetch";
import { columns, columns4History } from './config';
import { getServerKey } from './util';
import { DataType } from './type';

import './index.less';

const { TabPane } = Tabs;

interface IProps {
  currentUser?: string;
  admin?: any;
}

let CurrentUserCache: string = "";
let AdminCache: any;

const ApproveCenter: React.FC<IProps> = (props) => {
  const {
    currentUser,
    admin,
  } = props;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    meReqList: [],
    meApproveList: [],
  });
  const [approveNum, setApproveNum] = useState(0);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [historyData, setHistoryData] = useState({
    submitted: [],
    approved: [],
  });
  const [showHistoryType, setShowHistoryType] = useState('submitted');

  // const isMe = (createdBy: string) => {
  //   if (createdBy === currentUser || createdBy === CurrentUserCache) {
  //     return true;
  //   }
  //   return false;
  // }

  const getAllServerApproveStatusList = () => {
    Promise.all([
      fetch.fetchAllServerApproveStatus({
        userName: currentUser || CurrentUserCache || "",
        clusterName: getStaticClusterName(),
      }),
      webApi.get("server/userRole/getServerUserRole", {
        role: 2,
      })
    ]).then(([approveData, serverUserRole]) => {
      if (approveData && approveData.errCode === 0 && serverUserRole) {
        const serverUserRoleMap: any = serverUserRole;
        const meReqList: any = [];
        const meApproveList: any = [];
        
        approveData.items.forEach((one: DataType)=>{
          const serverKey = getServerKey(one);
          if (one.createdBy === (currentUser || CurrentUserCache)) {
            meReqList.push(Object.assign(JSON.parse(JSON.stringify(one)), {
              tabGroup: 'me',
            }));
          }
          if ((admin || AdminCache) || (serverUserRoleMap[serverKey] === (currentUser || CurrentUserCache))) {
            meApproveList.push(Object.assign(JSON.parse(JSON.stringify(one)), {
              tabGroup: 'approve',
            }));
          }
        });
        
        setData({
          meReqList,
          meApproveList,
        });
        setApproveNum(meApproveList.length);
      } else {
        Modal.error({
          title: '审批异常',
          content: '获取待审批列表异常',
        }); 
      }
      setLoading(false);
    }).catch((e)=>{
      console.error(e);
    });
  }

  const submitApproveStatus = ({
    tarsApplication,
    tarsSetName,
    tarsServerName,
    kind,
    action,
    emergency = 0,
    rejectReason = '',
    reason = '',
  }: any) => {
    setLoading(true);
    fetch.fetchSubmitApproveStatus({
      userName: currentUser || CurrentUserCache || "",
      clusterName: getStaticClusterName(),
      tarsApplication,
      tarsSetName,
      tarsServerName,
      kind,
      action,
      emergency,
      rejectReason,
      reason,
    }).then((data) => {
      if (data && data.errCode === 0) {
        notification.success({
          message: '操作成功',
          placement: 'topRight',
        });
        getAllServerApproveStatusList()
      } else {
        setLoading(false);
        Modal.error({
          title: '审批异常',
          content: '提交审批结果失败',
        });
      }
    });
  }

  const handleApprove = (raw: DataType) => {
    submitApproveStatus({
      tarsApplication: raw.tarsApplication,
      tarsSetName: raw.tarsSetName,
      tarsServerName: raw.tarsServerName,
      kind: raw.kind,
      action: 1,
      reason: '通过'
    })
  }

  const handleReject = (raw: DataType) => {
    submitApproveStatus({
      tarsApplication: raw.tarsApplication,
      tarsSetName: raw.tarsSetName,
      tarsServerName: raw.tarsServerName,
      kind: raw.kind,
      action: 2,
      reason: '拒绝'
    })
  }

  const handleRollBack = (raw: DataType) => {
    submitApproveStatus({
      tarsApplication: raw.tarsApplication,
      tarsSetName: raw.tarsSetName,
      tarsServerName: raw.tarsServerName,
      kind: raw.kind,
      action: 3,
      rejectReason: '撤回',
      reason: '撤回'
    })
  }

  const handleEmergencyApprove = (raw: DataType) => {
    submitApproveStatus({
      tarsApplication: raw.tarsApplication,
      tarsSetName: raw.tarsSetName,
      tarsServerName: raw.tarsServerName,
      kind: raw.kind,
      action: 1,
      emergency: 1,
    })
  }

  const handleTabChange = (tabKey: any) => {
    if (tabKey === 'history') {
      setHistoryLoading(true);
      fetch.fetchApproveHistory({
        userName: currentUser || CurrentUserCache || "",
        clusterName: getStaticClusterName(), 
      }).then((res) => {
        if (res && res.errCode === 0) {
          const {submitted, approved} = res;
          setHistoryData({
            submitted, 
            approved,
          });
          setHistoryLoading(false);
        }
      }).catch((error)=>{console.error(error)})
    }
  }

  useEffect(() => {
    if (columns[columns.length - 1].key !== 'opt') {
      columns.push({
        title: '操作',
        key: 'opt',
        render: (_, raw) => {
          if (raw.tabGroup === 'me' || raw.tabGroup === 'approve') {
            return (
              <div className="opt-btn-wrap">
                {
                  raw.tabGroup === 'me'
                  ? (
                    <div className="opt-btn-wrap-sub">
                      <Tooltip
                        color="#ffffff"
                        title={(
                          <div className="emergency-tip">
                            <p className="emergency-title">你不能审批自己提的申请。</p>
                            <p className="emergency-desc">如遇紧急情况，可自己紧急通过，但会留下记录，需有合理理由。</p>
                            <Button type="primary" size="small" danger onClick={()=>{handleEmergencyApprove(raw)}}>
                              紧急通过
                            </Button>
                          </div>
                        )}
                      >
                        <Button type="primary" disabled>通过</Button>
                      </Tooltip>
                      <Button type="primary" danger onClick={()=>{handleRollBack(raw)}}>撤回</Button>
                    </div>
                  )
                  : null
                }
                {
                  raw.tabGroup === 'approve'
                  ? (
                    <div className="opt-btn-wrap-sub">
                      <Button type="primary" onClick={()=>{handleApprove(raw)}}>通过</Button>
                      <Button type="primary" danger onClick={()=>{handleReject(raw)}}>拒绝</Button>
                    </div>
                  )
                  : null
                }
              </div>
            )
          }

          return '-';
        }
      })
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      CurrentUserCache = currentUser;
      AdminCache = admin;
      getAllServerApproveStatusList();
    }
  }, [currentUser]);

  return (
    <div className="approve-center-page">
      <Tabs
        size="large"
        defaultActiveKey="me"
        animated={false}
        destroyInactiveTabPane
        onChange={handleTabChange}
      >
        <TabPane 
          key="me"
          tab="我的申请"
        >
          <div className="approve-center">
            <Table
              key="meTable"
              columns={columns}
              dataSource={data.meReqList}
              loading={loading}
            />
          </div>
        </TabPane>
        
        <TabPane
          key="approve"
          tab={<Badge count={approveNum} size="small" offset={[4, -2]}>待我审批</Badge>}
        >
          <div className="approve-center">
            <Table
              key="approveTable"
              columns={columns}
              dataSource={data.meApproveList}
              loading={loading}
            />
          </div>
        </TabPane>
        
        <TabPane
          key="history"
          tab="历史记录"
        >
          <div className="approve-center">
            <div className="history-header">
              <Radio.Group
                options={[
                  { label: '我的申请记录', value: 'submitted' },
                  { label: '我的审批记录', value: 'approved' },
                ]}
                onChange={(e: any) => {setShowHistoryType(e.target.value)}}
                defaultValue="submitted"
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            <Table
              key="historyTable"
              columns={columns4History}
              dataSource={showHistoryType === 'submitted' ? historyData.submitted : historyData.approved}
              loading={historyLoading}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  )
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  admin: state.tarsReducer.admin,
});

export default connect(mapStateToProps, null)(ApproveCenter);
