import React from "react";
import MomentFormat from "src/utils/MomentFormat";
import { isEmpty } from "lodash";
import {
  Button,
  Card,
  Checkbox,
  Empty,
  Tabs,
  Spin,
  Input,
  Timeline,
  Pagination,
  Tooltip,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";

import * as fetch from "src/fetch";
import { pickClusterName } from "src/utils/Picker";

const { TabPane } = Tabs;
const TimelineItem = Timeline.Item;

const CardMeta = Card.Meta;
const { Search } = Input;

interface IProps {
  currentUser?: string;
}

interface IOperation {
  uuid: string;
  creationTimestamp: string;
  summary: string;
  description: string;
  userName: string;
  color: string;
}

interface IState {
  loading: boolean;
  clusterName: string;
  dataSource: Map<string, Array<IOperation>>;

  currentPage: number;
  withSystemEvent: boolean;
  searchText?: string;
}

export default class TrackOperation extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      clusterName: "",
      dataSource: new Map(),
      currentPage: 1,
      withSystemEvent: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({ loading: true });
    fetch
      .fetchListOperation()
      .then((data) => {
        let dataSource = new Map<string, Array<IOperation>>();
        Object.entries(data.items).forEach((value: [string, any]) => {
          dataSource.set(value[0], value[1] as Array<IOperation>);
        });
        let clusterName = this.state.clusterName;
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(dataSource.keys()));
        }
        this.setState({ clusterName, dataSource, loading: false });
      })
      .catch((_) => {});
  }

  render() {
    const PAGE_SIZE = 30;
    const dataSource = (this.state.dataSource.get(this.state.clusterName) || [])
      .filter((item) =>
        this.state.searchText && this.state.searchText.length >= 2
          ? item.summary.includes(this.state.searchText) ||
            item.description.includes(this.state.searchText) ||
            MomentFormat(item.creationTimestamp).includes(this.state.searchText)
          : true
      )
      .filter((item) =>
        this.state.withSystemEvent ? true : item.userName !== "tars"
      );

    return (
      <>
        {!this.state.loading && (
          <Tabs
            size="large"
            type="card"
            style={{ marginLeft: 8 }}
            onChange={(clusterName) => this.setState({ clusterName })}
            activeKey={this.state.clusterName}
          >
            {Array.from(this.state.dataSource).map((values) => (
              <TabPane tab={values[0]} key={values[0]}>
                {!isEmpty(values[1]) && (
                  <Card>
                    <CardMeta
                      title={
                        <span>
                          <Button size="small" type="link">
                            时区(UTC+08:00)
                          </Button>
                          <Checkbox
                            style={{ fontSize: 3 }}
                            onChange={({ target: { checked } }) => {
                              this.setState({
                                currentPage: 1,
                                withSystemEvent: checked,
                              });
                            }}
                          >
                            系统事件
                          </Checkbox>
                        </span>
                      }
                      description={
                        <div style={{ textAlign: "center" }}>
                          <Search
                            style={{ width: 500 }}
                            placeholder="根据IP、用户、事件、参数或时间等信息搜索.."
                            onChange={({ target: { value } }) =>
                              this.setState({ searchText: value })
                            }
                          />
                        </div>
                      }
                    />
                    <Timeline
                      pending={isEmpty(dataSource) ? false : <>Now...</>}
                      reverse={true}
                      mode="alternate"
                      style={{ marginTop: 25 }}
                    >
                      {dataSource
                        .slice(
                          (this.state.currentPage - 1) * PAGE_SIZE,
                          this.state.currentPage * PAGE_SIZE
                        )
                        .reverse()
                        .map((value, index) => (
                          <TimelineItem
                            color={value.color}
                            key={index}
                            label={
                              <Tooltip
                                title={
                                  <>
                                    时间:{MomentFormat(value.creationTimestamp)}
                                    <br />
                                    {value.description}
                                  </>
                                }
                                overlayStyle={{ maxWidth: "40vw" }}
                                placement="rightBottom"
                                trigger="click"
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement || document.body
                                }
                              >
                                <Button
                                  type="primary"
                                  ghost
                                  className="tool-btn"
                                  size="small"
                                >
                                  <EyeOutlined />
                                  请求参数
                                </Button>
                              </Tooltip>
                            }
                          >
                            {MomentFormat(value.creationTimestamp)}{" "}
                            {value.userName}
                            {value.summary}
                          </TimelineItem>
                        ))}
                    </Timeline>
                    <Pagination
                      simple
                      size="small"
                      pageSize={PAGE_SIZE}
                      hideOnSinglePage
                      current={this.state.currentPage}
                      onChange={(currentPage) => this.setState({ currentPage })}
                      style={{ position: "absolute", bottom: 10, left: 20 }}
                      total={dataSource.length}
                    />
                  </Card>
                )}

                {isEmpty(dataSource) && <Empty style={{ marginTop: 100 }} />}
              </TabPane>
            ))}
          </Tabs>
        )}

        {this.state.loading && (
          <div style={{ height: 300 }}>
            <Spin
              size="large"
              tip="加载中..."
              style={{ position: "relative", left: "50%", top: "50%" }}
            />
          </div>
        )}
      </>
    );
  }
}
