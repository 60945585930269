/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
// 渲染编辑和新增的情况
import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Form,
  Button,
  Select,
  DatePicker,
  Input,
  Switch,
  message,
  Modal,
} from "antd";

// import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";
import MonacoEditor from "react-monaco-editor";
import type { Field } from "./until";
import { formatValueToOptions } from "./until";
import "./index.less";

interface IProps {
  disabled?: boolean;
  status: string;
  currentRecord: any;
  renderConfig: Record<string, string | any>;
  isAdd: boolean;
  onFinishSubmit: (values: any) => void;
  onCancel: () => void;
}

export default function tableEditRender(props: IProps) {
  const {
    status,
    currentRecord,
    renderConfig,
    isAdd,
    onFinishSubmit,
    onCancel,
  } = props;
  const { outputConfig = {} } = renderConfig;
  const columnList = outputConfig?.config?.columnList;
  const baseConfig = outputConfig?.config?.baseConfig;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (status === "edit" && currentRecord) {
      const setFieldsValue = columnList.reduce((acc: any, item: Field) => {
        if (item.editType === "select" && item.isMultiSelect) {
          acc[item.field] = currentRecord[item.field].split(";");
          return acc;
        }
        acc[item.field] = currentRecord[item.field];
        return acc;
      }, {});
      form.setFieldsValue(setFieldsValue);
    } else {
      form.resetFields();
    }
  }, [status, currentRecord]);

  const [form] = Form.useForm();

  useEffect(() => {
    columnList.forEach((item: Field) => {
      // 所有defaultValues，如果有值，就设置默认值,通过form.setFieldsValue设置，并且是在新增的情况之下
      if (isAdd && item.defaultValue) {
        form.setFieldsValue({
          [item.field]: item.defaultValue,
        });
      }
    });
  }, [renderConfig]);

  const buildInputField = (item: Field) => {
    let initValue = {};
    if (item.editType === "coding") {
      try {
        currentRecord[item.field] &&
          (initValue = JSON.parse(currentRecord[item.field] || "{}"));
      } catch (error) {
        console.log(error, "error");
      }
    }

    let lastCodeText = JSON.stringify(initValue || {});
    // 根据不同的类型渲染不同的组件，根据editType字段来判断
    switch (item.editType) {
      case "select":
        const defaultValueObj = item.defaultValue
          ? { defaultValue: item.defaultValue }
          : {};
        return (
          <Select
            placeholder={item.placeholder}
            allowClear
            showArrow
            {...defaultValueObj}
            dropdownMatchSelectWidth={false}
            style={{
              minWidth: "200px",
              maxWidth: "300px",
            }}
            mode={item.isMultiSelect ? "multiple" : undefined}
            options={formatValueToOptions(item.options || [])}
            filterOption={(input, option) =>
              ((option?.label ?? "") as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            showSearch
          />
        );
      case "date":
        return <DatePicker placeholder={item.placeholder} />;
      case "text":
        return (
          <Input
            defaultValue={item.defaultValue}
            placeholder={item.placeholder}
          />
        );
      case "switch":
        return <Switch size="small" defaultChecked={!!item.defaultValue} />;
      case "textArea":
        return (
          <Input.TextArea
            autoSize={{ minRows: 5, maxRows: 5 }}
            defaultValue={item.defaultValue}
            placeholder={item.placeholder}
          />
        );
      case "coding":
        return (
          <MonacoEditor
            theme="vs-dark"
            language="json"
            value={JSON.stringify(initValue || {})}
            editorDidMount={(value) => {
              const { onDidBlurEditorText } = value;
              onDidBlurEditorText(() => {
                try {
                  const jsonValue = JSON.parse(lastCodeText);
                  form.setFieldsValue({
                    [item.field]: JSON.stringify(jsonValue),
                  });
                  console.log("设置成功 ");
                } catch (error) {
                  message.error("请输入正确的json格式");
                  return;
                }
              });
            }}
            onChange={(value) => {
              // console.log(value);
              lastCodeText = value;
            }}
            /* onBlur={(value: any) => {
              if (!value.jsObject) {
                message.error("请输入正确的json格式");
                return;
              }
              form.setFieldsValue({
                [item.field]: JSON.stringify(value.jsObject),
              });
            }}
            onChange={(value: any) => {
              if (!value.jsObject) return;
              form.setFieldsValue({
                [item.field]: JSON.stringify(value.jsObject),
              });
            }}*/
            height="550px"
          />
        );
      default:
        return <Input placeholder={item.placeholder} />;
    }
  };
  const buildFormItem = (item: Field) => {
    return (
      <Form.Item
        label={item.fieldDisplayName}
        name={item.field}
        hidden={item.disableEdit}
        rules={[
          {
            required: item.essential,
            message: `请输入${item.fieldDisplayName}`,
          },
          {
            pattern: new RegExp(item.formatType || ""), // 正则表达式
            message: `请输入正确的${item.fieldDisplayName}`,
          },
        ]}
        extra={
          item.tips ? (
            <div dangerouslySetInnerHTML={{ __html: item.tips || "" }} />
          ) : null
        }
      >
        {buildInputField(item)}
      </Form.Item>
    );
  };

  const hasAdvanced = columnList.some((item: Field) => item.isAdvanced);

  return (
    <div className="form_modify_info">
      <PageHeader
        title={isAdd ? "新增" : "编辑"}
        onBack={() => {
          onCancel();
          form.resetFields();
        }}
      />
      <div className="form_modify_info_content">
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={(values) => {
            onFinishSubmit(values);
            baseConfig.formTips &&
              Modal.info({
                title: "提示",
                content: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: baseConfig.formTips,
                    }}
                  />
                ),
              });
          }}
        >
          {columnList
            .filter((item: Field) => {
              return !item.isAdvanced;
            })
            .map((item: Field) => {
              return buildFormItem(item);
            })}
          {hasAdvanced && (
            <div className="advanced">
              <div
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className={`advanced_title ${isOpen ? "open" : "close"}`}
              >
                <span>高级设置</span>
              </div>
              <div className={`advanced_content ${isOpen ? "open" : "close"}`}>
                {columnList
                  .filter((item: Field) => {
                    return item.isAdvanced;
                  })
                  .map((item: Field) => {
                    return buildFormItem(item);
                  })}
              </div>
            </div>
          )}
          <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
