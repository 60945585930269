import React from 'react';
import { Button, Space } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';


import { connect } from 'react-redux';

interface IProps {
  currentUser?: string;
};

interface IState {
};

class Expand extends React.Component<IProps, IState> {
  render() {
    return (
      <>
        <Button type='primary' size='small' className='tool-btn' ghost>
          <Space><FullscreenOutlined />扩容</Space>
        </Button>
      </>
    );
  };
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
});

export default connect(mapStateToProps, null)(Expand);
