import React from "react";
import { Tooltip } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { ApproveKindTextEnum, ApproveKindOptLevelEnum } from '../../utils/constant';
import { formatTimestamp } from '../../utils/index';
import { DataType, HistoryType } from './type';

const OptLevelColor = ['#ff4d4f', '#faad14', '#000000']

export const columns: ColumnsType<DataType> = [
  {
    title: '应用名',
    dataIndex: 'tarsApplication',
    key: 'tarsApplication',
  },
  {
    title: 'Set名',
    dataIndex: 'tarsSetName',
    key: 'tarsSetName',
    render: (text) => (text|| '-')
  },
  {
    title: '服务名',
    dataIndex: 'tarsServerName',
    key: 'tarsServerName',
  },
  {
    title: '待审批行为',
    dataIndex: 'kind',
    key: 'kind',
    render: (kind,) => {
      const text = ApproveKindTextEnum[String(kind)] || '';
      const optLevel = OptLevelColor[ApproveKindOptLevelEnum[String(kind)]];

      return <span style={{color: `${optLevel}`}}>{text}</span>
    }
  },
  {
    title: '申请原因',
    dataIndex: 'reason',
    key: 'reason',
    render: (reason,) => {
      if (!reason) {
        return '-';
      }
      if (reason.length > 60) {
        return (
          <Tooltip title={reason.split('\n').map((one: string)=>(<p>{one}</p>))}>
            <p style={{textDecoration: 'underline dotted'}}>{reason.substring(0, 60) + '...'}</p>
          </Tooltip>
        )
      }
      return reason;
    }
  },
  {
    title: '提交申请时间',
    key: 'creationTimestamp',
    dataIndex: 'creationTimestamp',
    render: (timeStr,) => (formatTimestamp(Number(timeStr))),
  },
  {
    title: '申请人',
    key: 'createdBy',
    dataIndex: 'createdBy',
  }
];

export const columns4History: ColumnsType<HistoryType> = [
  {
    title: '应用名',
    dataIndex: 'application',
    key: 'application',
  },
  {
    title: 'Set名',
    dataIndex: 'setName',
    key: 'setName',
  },
  {
    title: '服务名',
    dataIndex: 'serverName',
    key: 'serverName',
  },
  {
    title: '行为',
    dataIndex: 'summary',
    key: 'summary',
    render: (text, raw) => (<p style={{color: `${raw.color === 'red' ? '#ff4d4f' : '#52c41a'}`}}>{text}</p>)
  },
  {
    title: '时间',
    key: 'creationTimestamp',
    dataIndex: 'creationTimestamp',
  }
];