import React from "react";
import MomentFormat from "src/utils/MomentFormat";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import {
  Badge,
  Col,
  Empty,
  Tag,
  Spin,
  Layout,
  Button,
  Result,
  Row,
  Drawer,
  List,
  Timeline,
  Tabs,
} from "antd";
import * as fetch from "src/fetch";
import { pickClusterName } from "src/utils/Picker";

const { TabPane } = Tabs;

const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;

const TimelineItem = Timeline.Item;

const { Sider, Content } = Layout;

export interface IOperationLogItem {
  taskID: string;
  command: string;
  graceful: number;
  status: number;
  log: string;
  userName: string;
  creationTimestamp: string;
}

export interface IOperationLogs {
  errCode: number;
  errMsg: string;
  items: Array<IOperationLogItem>;
  len: number;
}

interface IProps {
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
}

interface IState {
  loading: boolean;
  drawerVisible: boolean;

  currentPage: number;
  clusterName: string;
  selectedTaskID: string;
  selectedTaskLogs: string;
  logItems: Map<string, IOperationLogs>;
}

class TaskManager extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentPage: 1,
      clusterName: "",
      selectedTaskID: "",
      selectedTaskLogs: "",
      logItems: new Map(),
      loading: true,
      drawerVisible: false,
    };
  }

  componentDidMount() {
    this.fetchOperationLogList();
  }

  fetchOperationLogList() {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer) {
      this.setState({ loading: false });
      return;
    }

    fetch
      .fetchQueryOperationLogList({
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        currentPage: this.state.currentPage,
      })
      .then((data) => {
        let logItems = new Map<string, IOperationLogs>(
          Object.entries(data.logItems)
        );
        let clusterName = this.state.clusterName;
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(logItems.keys()));
        }
        this.setState({ clusterName, loading: false, logItems });
      });
  }

  render() {
    const toText = (text: string) => {
      if (text === "stop") return "停止";
      else if (text === "restart") return "重启";
      else return text;
    };

    const toStatus = (status: number) => {
      if (status === 1) return "running";
      else if (status === 2) return "success";
      else if (status === 2) return "failed";
      else return "unknown";
    };

    const toStatusColor = (status: number) => {
      if (status === 2) return "yellowgreen";
      else if (status === 1) return "green";
      else if (status === 3) return "red";
      else return "grey";
    };

    return (
      <div>
        {!this.state.loading &&
          this.state.logItems &&
          this.state.logItems.size > 0 && (
            <Tabs
              size="small"
              activeKey={this.state.clusterName}
              onChange={(clusterName) =>
                this.setState({ clusterName, currentPage: 1 }, () =>
                  this.fetchOperationLogList()
                )
              }
              tabPosition="top"
            >
              {Array.from(this.state.logItems).map((values) => (
                <TabPane tab={values[0]} key={values[0]}>
                  {values[1].errCode !== 0 && (
                    <Result
                      status="error"
                      title="请求失败"
                      subTitle={
                        "errCode:" +
                        values[1].errCode +
                        " errMsg:" +
                        values[1].errMsg
                      }
                    />
                  )}

                  {values[1].errCode === 0 && (
                    <List
                      size="small"
                      style={{ height: "100%", width: "100%" }}
                      pagination={{
                        pageSize: 6,
                        simple: true,
                        total: values[1].len,
                        hideOnSinglePage: true,
                        current: this.state.currentPage,
                        onChange: (currentPage) => {
                          this.setState({ currentPage }, () =>
                            this.fetchOperationLogList()
                          );
                        },
                      }}
                      dataSource={values[1].items}
                      renderItem={(item, index) => (
                        <ListItem key={index}>
                          <ListItemMeta
                            title={
                              <Layout
                                hasSider={true}
                                style={{ backgroundColor: "white" }}
                              >
                                <Sider theme="light" width={70}>
                                  <Tag
                                    color={toStatusColor(item.status)}
                                    style={{ width: 74 }}
                                  >
                                    {toStatus(item.status)}
                                  </Tag>
                                  <Tag
                                    color={
                                      item.graceful === 1 ? "green" : "red"
                                    }
                                    style={{ width: 74 }}
                                  >
                                    {(item.graceful === 1 ? "无损" : "有损") +
                                      toText(item.command)}
                                  </Tag>
                                </Sider>
                                <Content style={{ marginLeft: 8 }}>
                                  <p style={{ fontSize: 6 }}>
                                    任务ID:{item.taskID}
                                  </p>
                                  <p>
                                    <Row justify="space-between">
                                      <Col>
                                        操作人:{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                          &lt;{item.userName}&gt;
                                        </span>
                                      </Col>
                                      <Col>
                                        <Button
                                          size="small"
                                          type="dashed"
                                          onClick={() => {
                                            this.setState({
                                              selectedTaskID: item.taskID,
                                              selectedTaskLogs: item.log,
                                              drawerVisible: true,
                                            });
                                          }}
                                        >
                                          查看日志
                                        </Button>
                                      </Col>
                                    </Row>
                                  </p>
                                </Content>
                              </Layout>
                            }
                            description={
                              <span>
                                发起时间: {MomentFormat(item.creationTimestamp)}
                              </span>
                            }
                          />
                        </ListItem>
                      )}
                    />
                  )}
                </TabPane>
              ))}
            </Tabs>
          )}

        {this.state.loading && (
          <Spin
            size="large"
            tip="加载中..."
            style={{
              position: "relative",
              left: "50%",
              top: "50%",
              marginTop: 200,
            }}
          />
        )}

        {!this.state.loading &&
          (!this.state.logItems || this.state.logItems.size === 0) &&
          this.props.selectedTarsApplication &&
          this.props.selectedTarsServer && (
            <Empty style={{ marginTop: 100 }} description="暂无数据" />
          )}

        {(!this.props.selectedTarsApplication ||
          !this.props.selectedTarsServer) && (
          <Empty style={{ marginTop: 100 }} description="未选择服务" />
        )}

        {
          <Drawer
            placement="right"
            width={800}
            title={"任务详情:" + this.state.selectedTaskID}
            visible={this.state.drawerVisible}
            onClose={() => {
              this.setState({
                drawerVisible: false,
                selectedTaskID: "",
                selectedTaskLogs: "",
              });
            }}
          >
            <Timeline>
              {this.state.selectedTaskLogs.split("\n").map((log, index) => (
                <TimelineItem
                  key={index}
                  dot={<Badge color="lime" text={index + 1} />}
                >
                  <span style={{ fontSize: 6 }}>{log}</span>
                </TimelineItem>
              ))}
            </Timeline>
          </Drawer>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
});

export default connect(mapStateToProps, null)(TaskManager);
