import React from "react";
import { isEmpty } from "lodash";
import {
  Button,
  Checkbox,
  Card,
  Empty,
  Form,
  FormInstance,
  Space,
  Input,
  Tabs,
  Modal,
  Result,
  PageHeader,
  Row,
  Col,
  InputNumber,
} from "antd";
import {
  ClockCircleOutlined,
  DeleteTwoTone,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";

import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";
import * as ActionType from "src/stores/types";
import { pickClusterName } from "src/utils/Picker";
import HPADrawer from "src/pages/DevOps/ToolsBar/HPADrawer";

const { TabPane } = Tabs;

const FormItem = Form.Item;
const FormList = Form.List;

interface IProps {
  disabled: boolean;
  forbitEdit: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
}

interface IState {
  visible: boolean;
  drawer: boolean;
  clusterName: string;
  cronJobs: Array<FetchType.ICronHPAJobItem>;
  excludeDates: Array<FetchType.IExcludeDateItem>;
  items: Map<string, FetchType.ICronHPAItem>;
}

class CronHPA extends React.Component<IProps, IState> {
  private forms: Map<string, React.RefObject<FormInstance>>;

  initialState(): IState {
    return {
      visible: false,
      drawer: false,
      clusterName: "",
      cronJobs: [],
      excludeDates: [],
      items: new Map<string, FetchType.ICronHPAItem>(),
    };
  }

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState();
    this.forms = new Map<string, React.RefObject<FormInstance>>();
  }

  getOrCreateForm(clusterName: string): React.RefObject<FormInstance> {
    let ref = this.forms.get(clusterName);
    if (ref) {
      return ref;
    }
    ref = React.createRef<FormInstance>();
    this.forms.set(clusterName, React.createRef<FormInstance>());
    return ref;
  }

  fetchData() {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer)
      return;
    fetch
      .fetchListServerCronHPA({
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
      })
      .then((data) => {
        let items = new Map<string, FetchType.ICronHPAItem>(
          Object.entries(data.items)
        );
        let clusterName = this.state.clusterName;
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(items.keys()));
        }
        items.forEach((value) => {
          value.items.forEach((job) => {
            job.runOnce = job.runOnce === 1 ? true : false;
          });
        });

        let cronJobs = Array<FetchType.ICronHPAJobItem>();
        let excludeDates = Array<FetchType.IExcludeDateItem>();
        const value = items.get(clusterName);
        if (value) {
          value.excludeDates.forEach((excludeDate) => {
            excludeDates.push({ date: excludeDate });
          });
          value.items.forEach((job) => {
            cronJobs.push(job);
          });
        }

        this.setState({
          visible: true,
          drawer: false,
          clusterName,
          items,
          cronJobs,
          excludeDates,
        });
      })
      .catch(() => {});
  }

  fetchAddOrModifyServerCronHPA() {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer)
      return;
    let excludeDates = Array<string>();
    let items = Array<FetchType.ICronHPAJob>();
    for (const item of this.state.excludeDates) {
      if (item.date.length > 0) excludeDates.push(item.date);
    }

    for (const item of this.state.cronJobs) {
      items.push({
        name: item.name,
        schedule: item.schedule,
        targetSize: item.targetSize,
        runOnce: item.runOnce ? 1 : 0,
      });
    }

    fetch
      .fetchAddOrModifyServerCronHPA({
        clusterName: this.state.clusterName,
        userName: this.props.currentUser || "",
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        items,
        excludeDates,
      })
      .then(() => this.setState(this.initialState()))
      .catch(() => {});
  }

  render() {
    const form = this.getOrCreateForm(this.state.clusterName);

    const validateExcludeDates = (index: number): Promise<any> => {
      const value = this.state.excludeDates[index];
      if (isEmpty(value.date)) return Promise.reject("输入不能为空");
      return Promise.resolve();
    };

    const validateCronJobs = (index: number): Promise<any> => {
      let uniqJobs = new Set();
      for (const job of this.state.cronJobs) {
        if (uniqJobs.has(job.name)) return Promise.reject("名称不能重复");
        uniqJobs.add(job.name);
      }
      const value = this.state.cronJobs[index];
      if (isEmpty(value.name)) return Promise.reject("输入不能为空");
      if (isEmpty(value.schedule)) return Promise.reject("输入不能为空");
      return Promise.resolve();
    };

    return (
      <>
        <Button
          size="small"
          className="tool-btn"
          disabled={this.props.disabled}
          onClick={() => this.fetchData()}
        >
          <Space>
            <ClockCircleOutlined />
            定时伸缩
          </Space>
        </Button>

        <Modal
          closable={false}
          visible={this.state.visible}
          width="60%"
          title={
            <PageHeader
              title="定时伸缩"
              subTitle="(定时缩容或扩容)"
              footer={
                <>
                  <span style={{ color: "red" }}>注意</span>:时区(UTC+08:00)
                </>
              }
              extra={
                <Button
                  size="small"
                  type="primary"
                  className="tool-btn"
                  onClick={() => this.setState({ drawer: true })}
                >
                  与【自动扩容】兼容说明
                </Button>
              }
            />
          }
          destroyOnClose
          footer={[
            <Button
              type="primary"
              ghost
              className="tool-btn"
              onClick={() => this.setState(this.initialState())}
            >
              取消
            </Button>,
            <Button
              danger
              ghost
              disabled={this.props.forbitEdit}
              className="tool-btn"
              onClick={async () => {
                try {
                  if (form.current) {
                    await form.current.validateFields();
                    this.fetchAddOrModifyServerCronHPA();
                  }
                } catch {}
              }}
            >
              提交
            </Button>,
          ]}
        >
          {this.state.items.size > 0 && (
            <Tabs
              activeKey={this.state.clusterName}
              type="card"
              size="small"
              onChange={(clusterName) => {
                let cronJobs: Array<FetchType.ICronHPAJobItem> = [];
                let excludeDates: Array<FetchType.IExcludeDateItem> = [];
                const value = this.state.items.get(clusterName);
                if (value) {
                  value.excludeDates.forEach((excludeDate) => {
                    excludeDates.push({ date: excludeDate });
                  });
                  value.items.forEach((job) => {
                    cronJobs.push(job);
                  });
                }
                this.setState({ clusterName, cronJobs, excludeDates });
                if (form.current) {
                  form.current.setFieldsValue({ cronJobs, excludeDates });
                }
              }}
            >
              {Array.from(this.state.items).map((values) => (
                <TabPane tab={values[0]} key={values[0]} forceRender={true}>
                  {values[1].errCode !== 0 && (
                    <Result
                      status="error"
                      title="无法配置定时扩容"
                      subTitle={
                        "errCode:" +
                        values[1].errCode +
                        " errMsg:" +
                        values[1].errMsg
                      }
                    />
                  )}

                  {values[1].errCode === 0 && (
                    <Form
                      name="cronhpaForm"
                      ref={form}
                      initialValues={{}}
                      validateTrigger="onBlur"
                    >
                      <FormList
                        name="excludeDates"
                        initialValue={this.state.excludeDates}
                        children={(fields) => (
                          <>
                            {fields.map((field, index) => (
                              <div key={index}>
                                {
                                  <Row>
                                    <Col>
                                      <FormItem
                                        name={[field.name, "date"]}
                                        label="排除日期"
                                        tooltip="遇到符合日期时任务将会被跳过"
                                        extra='* * * * * *" 表示 - "(seconds) (minutes) (hours) (day of month) (months) (day of week)'
                                        rules={[
                                          {
                                            required: true,
                                            validator:
                                              validateExcludeDates.bind(
                                                this,
                                                index
                                              ),
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={this.props.forbitEdit}
                                          placeholder="eg: * * * 15 11 *"
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            let excludeDates =
                                              this.state.excludeDates;
                                            excludeDates[index].date = value;
                                            this.setState({ excludeDates });
                                          }}
                                        />
                                      </FormItem>
                                    </Col>

                                    <Col>
                                      <FormItem>
                                        <Button
                                          disabled={this.props.forbitEdit}
                                          type="dashed"
                                          size="small"
                                          className="tool-btn"
                                          onClick={() => {
                                            let excludeDates =
                                              this.state.excludeDates;
                                            excludeDates.splice(index, 1);
                                            this.setState({ excludeDates });
                                            if (form.current)
                                              form.current.setFieldsValue({
                                                excludeDates,
                                              });
                                          }}
                                        >
                                          <DeleteTwoTone twoToneColor="#DC143C" />{" "}
                                          删除一行
                                        </Button>
                                      </FormItem>
                                    </Col>
                                  </Row>
                                }
                              </div>
                            ))}
                          </>
                        )}
                      />

                      <FormItem>
                        <Button
                          disabled={this.props.forbitEdit}
                          type="dashed"
                          size="small"
                          className="tool-btn"
                          onClick={async () => {
                            let excludeDates = this.state.excludeDates;
                            excludeDates.push({ date: "" });
                            this.setState({ excludeDates });
                            if (form.current)
                              form.current.setFieldsValue({ excludeDates });
                          }}
                        >
                          <PlusCircleOutlined /> 新增排除日期
                        </Button>
                      </FormItem>

                      <FormList
                        name="jobs"
                        initialValue={this.state.cronJobs}
                        children={(fields) => (
                          <>
                            {fields.map((field, index) => (
                              <Card
                                bordered
                                key={index}
                                style={{ marginTop: 8 }}
                              >
                                {
                                  <Row>
                                    <Col>
                                      <FormItem
                                        label="任务名"
                                        name={[field.name, "name"]}
                                        rules={[
                                          {
                                            required: true,
                                            validator: validateCronJobs.bind(
                                              this,
                                              index
                                            ),
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={this.props.forbitEdit}
                                          placeholder="eg: scale-down"
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            let cronJobs = this.state.cronJobs;
                                            cronJobs[index].name = value;
                                            this.setState({ cronJobs });
                                            if (form.current)
                                              form.current.setFieldsValue({
                                                cronJobs,
                                              });
                                          }}
                                        />
                                      </FormItem>

                                      <FormItem
                                        label="Cron表达式"
                                        tooltip={
                                          <>
                                            Go Cron表达式:
                                            <Button
                                              type="link"
                                              onClick={() =>
                                                window.open(
                                                  "https://pkg.go.dev/github.com/robfig/cron"
                                                )
                                              }
                                            >
                                              源码
                                            </Button>
                                            文档
                                          </>
                                        }
                                        extra='* * * * * *" 表示 - "(seconds) (minutes) (hours) (day of month) (months) (day of week)'
                                        name={[field.name, "schedule"]}
                                        rules={[
                                          {
                                            required: true,
                                            validator: validateCronJobs.bind(
                                              this,
                                              index
                                            ),
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={this.props.forbitEdit}
                                          placeholder="exclude November 15th eg: * * * 15 11 *"
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            let cronJobs = this.state.cronJobs;
                                            cronJobs[index].schedule = value;
                                            this.setState({ cronJobs });
                                            if (form.current)
                                              form.current.setFieldsValue({
                                                cronJobs,
                                              });
                                          }}
                                        />
                                      </FormItem>

                                      <FormItem
                                        label="目标数量"
                                        name={[field.name, "targetSize"]}
                                        rules={[
                                          {
                                            required: true,
                                            validator: validateCronJobs.bind(
                                              this,
                                              index
                                            ),
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          disabled={this.props.forbitEdit}
                                          size="small"
                                          min={0}
                                          onChange={(value) => {
                                            let cronJobs = this.state.cronJobs;
                                            cronJobs[index].targetSize = value;
                                            this.setState({ cronJobs });
                                            if (form.current)
                                              form.current.setFieldsValue({
                                                cronJobs,
                                              });
                                          }}
                                        />
                                      </FormItem>

                                      <FormItem
                                        label="仅运行一次"
                                        name={[field.name, "runOnce"]}
                                        valuePropName="checked"
                                        rules={[{ required: true }]}
                                      >
                                        <Checkbox
                                          disabled={this.props.forbitEdit}
                                          onChange={({
                                            target: { checked },
                                          }) => {
                                            let cronJobs = this.state.cronJobs;
                                            cronJobs[index].runOnce = checked;
                                            this.setState({ cronJobs });
                                            if (form.current)
                                              form.current.setFieldsValue({
                                                cronJobs,
                                              });
                                          }}
                                        />
                                      </FormItem>

                                      <FormItem>
                                        <Button
                                          disabled={this.props.forbitEdit}
                                          type="dashed"
                                          size="small"
                                          className="tool-btn"
                                          onClick={() => {
                                            let cronJobs = this.state.cronJobs;
                                            cronJobs.splice(index, 1);
                                            this.setState({ cronJobs });
                                            if (form.current)
                                              form.current.setFieldsValue({
                                                cronJobs,
                                              });
                                          }}
                                        >
                                          <DeleteTwoTone twoToneColor="#DC143C" />{" "}
                                          删除
                                        </Button>
                                      </FormItem>
                                    </Col>
                                  </Row>
                                }
                              </Card>
                            ))}
                          </>
                        )}
                      />

                      <FormItem>
                        <Button
                          disabled={this.props.forbitEdit}
                          type="dashed"
                          size="small"
                          className="tool-btn"
                          onClick={() => {
                            let cronJobs = this.state.cronJobs;
                            cronJobs.push({
                              name: "scale-up",
                              schedule: "30 */1 * * * *",
                              targetSize: 2,
                              runOnce: false,
                            });
                            this.setState({ cronJobs });
                            if (form.current)
                              form.current.setFieldsValue({ jobs: cronJobs });
                          }}
                        >
                          <PlusCircleOutlined /> 新增任务
                        </Button>
                      </FormItem>
                    </Form>
                  )}
                </TabPane>
              ))}
            </Tabs>
          )}

          {this.state.items.size === 0 && <Empty />}
        </Modal>

        <HPADrawer
          visible={this.state.drawer}
          onClose={() => this.setState({ drawer: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
});

export default connect(mapStateToProps, null)(CronHPA);
