import React from "react";
import { isEmpty, cloneDeep } from "lodash";
import { TweenOneGroup } from "rc-tween-one";
import { DataNode } from "rc-tree-select/lib/interface";
import {
  Button,
  Divider,
  Empty,
  Form,
  FormInstance,
  Result,
  Switch,
  Space,
  Select,
  TreeSelect,
  Tabs,
  Tag,
  Tooltip,
  Input,
  Modal,
  PageHeader,
  notification,
  message,
} from "antd";
import {
  ToolOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { connect } from "react-redux";
import * as ActionType from "src/stores/types";
import PodQosClassTooltip from "src/pages/Common/PodQosClassTooltip";

import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";
import { pickClusterName } from "src/utils/Picker";

import "./style/common.less";

const { TabPane } = Tabs;
const FormItem = Form.Item;
const SelectOption = Select.Option;

const { TextArea } = Input;

interface IProps {
  disabled: boolean;
  forbitEdit: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  admin?: boolean | undefined;
}

interface IServerResource {
  errCode: number;
  errMsg: string;
  resourceName: string;
  kind: string;
  replicas: number;
  podQOSClass: string;
  resource: FetchType.IResource;
  nodeResource: FetchType.INodeResource;
  envs: Map<string, string>;
  availNodeRequests: Array<FetchType.INodeRequest>;
  availNodeResources: Array<FetchType.INodeResource>;
  availPodQOSClasses: Array<string>;
  sharedStorageSubPaths: Array<string>;
  availServiceAccount: Array<string>;
  serviceAccount: string;
  memBurstable: number;
  disableSchedule: number;

  antiAffinities: Array<FetchType.IAntiAffinity>;
  availAntiAffinities: Array<FetchType.IAntiAffinity>;
}

interface IState {
  animate: boolean;
  visible: boolean;
  inputVisible: boolean;
  inputEnvName: string;
  inputEnvValue: string;

  clusterName: string;
  resources: Map<string, IServerResource>;

  reason?: string;
  adjustedResource?: string;
  adjustedPodQOSClass?: string;
  adjustedNodeResourceID?: number;
  adjustedEnvs: Map<string, string>;
  adjustedSharedPath?: string;
  adjustedServiceAccount?: string;
  treeData: Array<DataNode>;
  selectedTreeData: Array<string>;

  selfAntiAffinity: boolean;
  adjustedSelfAntiAffinity: boolean;
  sharedStorage: boolean;
  antiAffinities: Array<FetchType.IAntiAffinity>;
  adjustedAntiAffinities: Array<FetchType.IAntiAffinity>;
  adjustedUnScheduleAble: boolean;
}

class AdjustResource extends React.Component<IProps, IState> {
  private forms: Map<string, React.RefObject<FormInstance>>;

  initialState(): IState {
    return {
      animate: false,
      visible: false,
      inputVisible: false,
      inputEnvName: "",
      inputEnvValue: "",
      clusterName: "",
      resources: new Map<string, IServerResource>(),
      adjustedEnvs: new Map<string, string>(),

      reason: undefined,
      adjustedResource: undefined,
      adjustedNodeResourceID: undefined,
      selectedTreeData: [],
      adjustedSharedPath: undefined,
      adjustedServiceAccount: undefined,
      treeData: [],

      selfAntiAffinity: false,
      adjustedSelfAntiAffinity: false,
      adjustedUnScheduleAble: false,
      antiAffinities: [],
      adjustedAntiAffinities: [],
      sharedStorage: false,
    };
  }

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState();
    this.forms = new Map<string, React.RefObject<FormInstance>>();
  }

  getOrCreateForm(clusterName: string): React.RefObject<FormInstance> {
    let ref = this.forms.get(clusterName);
    if (ref) {
      return ref;
    }
    ref = React.createRef<FormInstance>();
    this.forms.set(clusterName, React.createRef<FormInstance>());
    return ref;
  }

  parseAntiAffinities(originAntiAffinities: Array<FetchType.IAntiAffinity>): {
    selfAntiAffinity: boolean;
    antiAffinities: Array<FetchType.IAntiAffinity>;
    selectedTreeData: Array<string>;
  } {
    let selfAntiAffinity = false;
    let selectedTreeData: Array<string> = [];
    let antiAffinities: Array<FetchType.IAntiAffinity> = [];
    for (const antiAffinity of originAntiAffinities) {
      if (
        antiAffinity.tarsApplication === this.props.selectedTarsApplication &&
        antiAffinity.tarsSetName === this.props.selectedTarsSetName &&
        antiAffinity.tarsServerName === this.props.selectedTarsServer
      ) {
        selfAntiAffinity = true;
      } else {
        let title = "";
        if (antiAffinity.tarsSetName.length > 0) {
          title =
            antiAffinity.tarsApplication +
            "." +
            antiAffinity.tarsSetName +
            "." +
            antiAffinity.tarsServerName;
        } else {
          title =
            antiAffinity.tarsApplication + "." + antiAffinity.tarsServerName;
        }
        selectedTreeData.push(title);
        antiAffinities.push(antiAffinity);
      }
    }
    return { selfAntiAffinity, antiAffinities, selectedTreeData };
  }

  onInputEnvConfirm() {
    if (isEmpty(this.state.inputEnvName) || isEmpty(this.state.inputEnvValue)) {
      this.setState({
        inputEnvName: "",
        inputEnvValue: "",
        inputVisible: false,
      });
      return;
    }

    let reg = /^[a-zA-Z_$][a-zA-Z_$0-9]*$/g;
    if (!reg.test(this.state.inputEnvName)) {
      notification.destroy();
      notification.error({
        message: "环境变量错误",
        description: "环境变量名称只能包含字母、数字、下划线",
        duration: 2,
      });
      return;
    }

    let adjustedEnvs = this.state.adjustedEnvs;
    adjustedEnvs.set(this.state.inputEnvName, this.state.inputEnvValue);
    this.setState({
      adjustedEnvs,
      inputEnvName: "",
      inputEnvValue: "",
      inputVisible: false,
    });
  }

  onInputEnvCancel() {
    this.setState({ inputEnvName: "", inputEnvValue: "", inputVisible: false });
  }

  fetchData() {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer)
      return;
    fetch
      .fetchListServerResource({
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
      })
      .then((data) => {
        let clusterName = "";
        let selfAntiAffinity = false;
        let selectedTreeData: Array<string> = [];
        let antiAffinities: Array<FetchType.IAntiAffinity> = [];
        let treeData: Array<DataNode> = [];
        let adjustedEnvs: Map<string, string> = new Map<string, string>();
        let adjustedResource: string | undefined = undefined;
        let adjustedNodeResourceID: number | undefined = undefined;
        let adjustedPodQOSClass: string | undefined = undefined;
        let adjustedSharedPath: string | undefined = undefined;
        let adjustedServiceAccount: string | undefined = undefined;
        let adjustedUnScheduleAble = false;
        let resources = new Map<string, IServerResource>();
        for (const i in data.items) {
          let item = data.items[i];
          resources.set(i, item);
          if (isEmpty(clusterName) && item.errCode === 0) {
            clusterName = i;
            const envs = cloneDeep(item.envs);
            item.envs = new Map<string, string>(Object.entries(envs));
            adjustedNodeResourceID = item.nodeResource.logicNodePoolID;
            adjustedResource =
              item.resource.cpu.toString() + "x" + item.resource.mem.toString();
            adjustedPodQOSClass = item.podQOSClass;
            adjustedEnvs = new Map<string, string>(Object.entries(envs));
            adjustedSharedPath = item?.sharedStorageSubPaths
              ? item?.sharedStorageSubPaths[0]
              : undefined;
            adjustedServiceAccount = item?.serviceAccount || undefined;
            adjustedUnScheduleAble = !!item.disableSchedule;

            const key = `${this.props.selectedTarsApplication}${
              isEmpty(this.props.selectedTarsSetName)
                ? ""
                : "." + this.props.selectedTarsSetName
            }.${this.props.selectedTarsServer}`;
            treeData = fetch
              .buildSelectDataNodesFromMemory(item.availAntiAffinities)
              .filter((x) => {
                return x.title !== key;
              });
            const obj = this.parseAntiAffinities(item.antiAffinities);
            selfAntiAffinity = obj.selfAntiAffinity;
            antiAffinities = obj.antiAffinities;
            selectedTreeData = obj.selectedTreeData;
          }
        }
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(resources.keys()));
          const resource = resources.get(clusterName);
          if (resource) {
            const key = `${this.props.selectedTarsApplication}${
              isEmpty(this.props.selectedTarsSetName)
                ? ""
                : "." + this.props.selectedTarsSetName
            }.${this.props.selectedTarsServer}`;
            treeData = fetch
              .buildSelectDataNodesFromMemory(resource.availAntiAffinities)
              .filter((x) => {
                return x.title !== key;
              });
            const obj = this.parseAntiAffinities(resource.availAntiAffinities);
            selfAntiAffinity = obj.selfAntiAffinity;
            antiAffinities = obj.antiAffinities;
            selectedTreeData = obj.selectedTreeData;
          }
        }

        const adjustedSelfAntiAffinity = selfAntiAffinity;
        const adjustedAntiAffinities = Array.from(antiAffinities).filter(
          (x) => {
            return (
              x.tarsApplication === this.props.selectedTarsApplication &&
              x.tarsSetName === this.props.selectedTarsSetName &&
              x.tarsServerName === this.props.selectedTarsServer
            );
          }
        );

        this.setState({
          visible: true,
          clusterName,
          resources,
          adjustedNodeResourceID,
          adjustedResource,
          adjustedPodQOSClass,
          adjustedEnvs,
          treeData,
          selectedTreeData,
          selfAntiAffinity,
          antiAffinities,
          adjustedSelfAntiAffinity,
          adjustedAntiAffinities,
          adjustedSharedPath,
          adjustedServiceAccount,
          adjustedUnScheduleAble,
        });
      })
      .catch(() => {});
  }

  equalAntiAffinity(
    x: Array<FetchType.IAntiAffinity>,
    y: Array<FetchType.IAntiAffinity>
  ): boolean {
    if (x.length !== y.length) return false;
    const a = new Set(x.map((val) => val.resourceName));
    const b = new Set(y.map((val) => val.resourceName));
    const j = Array.from(a);
    for (const val of j) {
      if (!b.has(val)) return false;
    }
    const k = Array.from(b);
    for (const val of k) {
      if (!a.has(val)) return false;
    }
    return true;
  }

  equalMap(a: Map<string, string>, b: Map<string, string>) {
    if (!a && !b) return true;
    if (a.size !== b.size) return false;

    let eq = true;
    a.forEach((value, key) => {
      if (!eq) return;

      const x = b.get(key);
      if (!x || value !== x) eq = false;
    });
    if (!eq) return false;

    b.forEach((value, key) => {
      if (!eq) return;

      const x = a.get(key);
      if (!x || value !== x) eq = false;
    });
    if (!eq) return false;

    return true;
  }

  fetchAdjustResource(reason: string) {
    const resource = this.state.resources.get(this.state.clusterName);
    if (!resource || resource.errCode !== 0) {
      message.error("当前集群不能调整");
      return;
    }

    const adjustedEnvs = this.state.adjustedEnvs;
    const adjustedResource = this.state.adjustedResource;
    const adjustedNodeResourceID = this.state.adjustedNodeResourceID;
    const adjustedPodQOSClass = this.state.adjustedPodQOSClass;
    const adjustedSharedPath = this.state.adjustedSharedPath;
    const adjustedServiceAccount = this.state.adjustedServiceAccount;
    const adjustedUnScheduleAble = this.state.adjustedUnScheduleAble;
    if (
      !adjustedResource ||
      !adjustedNodeResourceID ||
      !adjustedPodQOSClass ||
      !this.props.selectedTarsApplication ||
      !this.props.selectedTarsServer
    ) {
      message.error("系统错误");
      return;
    }

    const parts = adjustedResource.split("x");
    if (
      adjustedNodeResourceID === resource.nodeResource.logicNodePoolID &&
      adjustedPodQOSClass === resource.podQOSClass &&
      adjustedServiceAccount === resource.serviceAccount &&
      adjustedUnScheduleAble === !!resource.disableSchedule &&
      adjustedSharedPath ===
        (resource.sharedStorageSubPaths
          ? resource.sharedStorageSubPaths[0]
          : undefined) &&
      resource.resource.cpu === parseInt(parts[0]) &&
      (resource.resource.mem || 0) === parseInt(parts[1] || "0") &&
      this.equalMap(adjustedEnvs, resource.envs) &&
      this.state.selfAntiAffinity === this.state.adjustedSelfAntiAffinity &&
      this.equalAntiAffinity(
        this.state.antiAffinities,
        this.state.adjustedAntiAffinities
      )
    ) {
      message.error("资源未发生变更");
      return;
    }

    let antiAffinities: Array<FetchType.IAntiAffinityReq> = [];
    if (this.state.adjustedSelfAntiAffinity) {
      antiAffinities.push({
        resourceName: "",
        isSelf: 1,
      });
    }
    for (const antiAffinity of this.state.adjustedAntiAffinities) {
      antiAffinities.push({
        resourceName: antiAffinity.resourceName,
        isSelf: 0,
      });
    }

    let envs: Object = {};
    if (this.state.adjustedEnvs) {
      envs = Object.fromEntries(this.state.adjustedEnvs.entries());
    }

    const cpu = parseInt(parts[0]);
    const mem = parseInt(parts[1] || "0");
    this.setState({ animate: true });
    fetch
      .fetchAdjustServerResource({
        userName: this.props.currentUser || "",
        clusterName: this.state.clusterName,
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        logicNodePoolId: adjustedNodeResourceID,
        resource: { cpu, mem },
        antiAffinities,
        envs,
        podQOSClass: adjustedPodQOSClass,
        sharedStorageSubPaths: adjustedSharedPath
          ? [adjustedSharedPath]
          : resource.sharedStorageSubPaths,
        serviceAccount: adjustedServiceAccount ?? resource.serviceAccount,
        reason,
        disableSchedule: adjustedUnScheduleAble ? 1 : 0,
      })
      .then(() => {
        notification.success({
          message: "修改成功",
          description: "稍后查看修改状态",
        });
        this.setState({ ...this.initialState(), animate: false });
      })
      .catch((_) => this.setState({ animate: false }));
  }

  generateTitle(node: FetchType.IAntiAffinity): string {
    let title = "";
    if (node.tarsSetName.length > 0) {
      title =
        node.tarsApplication +
        "." +
        node.tarsSetName +
        "." +
        node.tarsServerName;
    } else {
      title = node.tarsApplication + "." + node.tarsServerName;
    }
    return title;
  }

  onAntiAffinitiesSelect(value: string, node: any) {
    let adjustedAntiAffinities = this.state.adjustedAntiAffinities;
    const resource = this.state.resources.get(this.state.clusterName);
    if (resource) {
      let antiAffinity: FetchType.IAntiAffinity | undefined = undefined;
      for (const val of resource.availAntiAffinities) {
        if (val.resourceName === node.resourceName) {
          antiAffinity = val;
          break;
        }
      }
      if (antiAffinity) {
        let selectedTreeData = this.state.selectedTreeData;
        selectedTreeData.push(value);
        adjustedAntiAffinities.push(antiAffinity);
        this.setState({ selectedTreeData, adjustedAntiAffinities });
      }
    } else {
      message.error("系统错误");
    }
  }

  onAntiAffinitiesDeselect(value: string, node: any) {
    let adjustedAntiAffinities = this.state.adjustedAntiAffinities;
    let selectedTreeData = this.state.selectedTreeData;
    adjustedAntiAffinities = adjustedAntiAffinities.filter(
      (item) => item.resourceName !== node.resourceName
    );
    selectedTreeData = selectedTreeData.filter((item) => item !== value);
    this.setState({ selectedTreeData, adjustedAntiAffinities });
  }

  render() {
    const form = this.getOrCreateForm(this.state.clusterName);
    const resource = this.state.resources.get(this.state.clusterName);
    let resourceChanged = false;
    let nodeResourceChanged = false;
    let podQOSClassChanged = false;
    let nodeResourceDescriptionChanged = false;
    let envsChanged = false;

    let sharedPathChanged = false;
    let serverAccountChanged = false;

    let unScheduleAbleChanged = false;

    let envs = new Map<string, string>();
    let nodeResourceChangedSelectorTerms = new Set<string>();
    if (resource) {
      if (this.state.adjustedResource) {
        const parts = this.state.adjustedResource.split("x");
        resourceChanged =
          resource.resource.cpu !== parseInt(parts[0]) ||
          resource.resource.mem !== parseInt(parts[1] || "0");
      }
      if (this.state.adjustedPodQOSClass) {
        podQOSClassChanged =
          resource.podQOSClass !== this.state.adjustedPodQOSClass;
      }
      envsChanged = !this.equalMap(
        this.state.adjustedEnvs,
        new Map<string, string>(Object.entries(resource.envs))
      );

      unScheduleAbleChanged =
        !!resource.disableSchedule != this.state.adjustedUnScheduleAble;
      sharedPathChanged =
        this.state.adjustedSharedPath !==
        (resource.sharedStorageSubPaths
          ? resource.sharedStorageSubPaths[0]
          : undefined);
      serverAccountChanged =
        this.state.adjustedServiceAccount !== resource.serviceAccount;

      const nodeResource = resource.nodeResource;
      if (this.state.adjustedNodeResourceID) {
        const adjustedNodeResource = resource.availNodeResources.find(
          (item) => item.logicNodePoolID === this.state.adjustedNodeResourceID
        );
        if (adjustedNodeResource && nodeResource) {
          nodeResourceChanged =
            adjustedNodeResource.logicNodePoolID !==
            nodeResource.logicNodePoolID;
          nodeResourceDescriptionChanged =
            adjustedNodeResource.description !== nodeResource.description;
          nodeResource.nodeSelectorTerms.forEach((term) => {
            const one = adjustedNodeResource.nodeSelectorTerms.find(
              (adjustedTerm) =>
                term.constraintKey === adjustedTerm.constraintKey &&
                term.constraintValue === adjustedTerm.constraintValue
            );
            if (!one) {
              nodeResourceChangedSelectorTerms.add(
                term.constraintKey + "=" + term.constraintValue
              );
            }
          });
        }
      }
    }

    return (
      <>
        <Button
          disabled={this.props.disabled}
          className="tool-btn"
          size="small"
          onClick={() => this.fetchData()}
        >
          <Space>
            <ToolOutlined
              className={
                this.state.animate
                  ? "animate__animated animate__heartBeat animate__infinite animate__slower"
                  : ""
              }
            />
            资源调整
          </Space>
        </Button>

        <Modal
          closable={false}
          visible={this.state.visible}
          width="80%"
          title={
            <PageHeader title="资源调整" subTitle="(流程与服务发布相同)" />
          }
          destroyOnClose
          footer={[
            <Button
              type="primary"
              ghost
              className="tool-btn"
              onClick={() =>
                this.setState({ ...this.initialState(), animate: true })
              }
            >
              取消
            </Button>,

            this.state.adjustedResource ||
            this.state.adjustedNodeResourceID ||
            this.state.adjustedPodQOSClass ? (
              <Button
                danger
                disabled={this.props.forbitEdit}
                ghost
                className="tool-btn"
                onClick={async () => {
                  try {
                    if (form.current) {
                      const fields = await form.current.validateFields();
                      this.fetchAdjustResource(fields.reason);
                    }
                  } catch (e) {
                    message.error("校验不通过:" + JSON.stringify(e));
                  }
                }}
              >
                提交
              </Button>
            ) : null,
          ]}
        >
          {this.state.resources.size > 0 && (
            <Tabs
              activeKey={this.state.clusterName}
              type="card"
              size="small"
              onChange={(clusterName) => {
                const rs = this.state.resources.get(clusterName);
                console.log(rs, "当前的类型资源", this.state.resources);
                if (form.current && rs && rs.errCode === 0) {
                  const memBurstable = rs.memBurstable;
                  const disableSchedule = rs.disableSchedule;
                  const adjustedResource = memBurstable
                    ? rs.resource.cpu.toString()
                    : rs.resource.cpu.toString() +
                      "x" +
                      rs.resource.mem.toString();
                  const adjustedNodeResourceID =
                    rs.nodeResource.logicNodePoolID;
                  const kind =
                    rs.kind.length > 1
                      ? rs.kind[0].toUpperCase() + rs.kind.substring(1)
                      : rs.kind;
                  const adjustedEnvs = new Map<string, string>(
                    Object.entries(rs.envs)
                  );
                  form.current.setFieldsValue({
                    resourceName: rs.resourceName,
                    kind,

                    nodeResourceID: adjustedNodeResourceID,
                    resource: adjustedResource,

                    reason: undefined,
                    adjustedNodeResourceID,
                    memBurstable: !!memBurstable,
                    disableSchedule: !!disableSchedule,
                    adjustedResource,
                    adjustedEnvs,
                    adjustedUnScheduleAble: !!disableSchedule,
                  });
                  this.setState({
                    clusterName,
                    adjustedNodeResourceID,
                    adjustedResource,
                    adjustedEnvs,
                    adjustedUnScheduleAble: !!disableSchedule,
                  });
                } else {
                  this.setState({
                    clusterName,
                    reason: undefined,
                    adjustedResource: undefined,
                    adjustedNodeResourceID: undefined,
                  });
                }
              }}
            >
              {Array.from(this.state.resources).map((values) => (
                <TabPane key={values[0]} tab={values[0]} forceRender={true}>
                  {values[1].errCode !== 0 && (
                    <Result
                      status="error"
                      title="无法调整资源"
                      subTitle={
                        "errCode:" +
                        values[1].errCode +
                        " errMsg:" +
                        values[1].errMsg
                      }
                    />
                  )}

                  {values[1].errCode === 0 && (
                    <Form
                      name="adjustForm"
                      ref={form}
                      initialValues={{
                        resourceName: values[1].resourceName,
                        kind:
                          values[1].kind.length > 1
                            ? values[1].kind[0].toUpperCase() +
                              values[1].kind.substring(1)
                            : values[1].kind,

                        envs,
                        nodeResourceID: values[1].nodeResource.logicNodePoolID,
                        replicas: values[1].replicas,
                        resource: values[1].memBurstable
                          ? values[1].resource.cpu.toString() +
                            "x" +
                            values[1].resource.mem.toString()
                          : values[1].resource.cpu.toString(),
                        podQOSClass: values[1].podQOSClass,
                        memBurstable: !!values[1].memBurstable,
                        disableSchedule: !!values[1].disableSchedule,
                        reason: undefined,
                        adjustedNodeResourceID:
                          this.state.adjustedNodeResourceID,
                        adjustedResource: this.state.adjustedResource,

                        adjustedPodQOSClass: this.state.adjustedPodQOSClass,

                        selfAntiAffinity: this.state.selfAntiAffinity,
                        adjustedSelfAntiAffinity:
                          this.state.adjustedSelfAntiAffinity,

                        antiAffinities: this.state.antiAffinities.map((item) =>
                          this.generateTitle(item)
                        ),
                        adjustedAntiAffinities: this.state.selectedTreeData,
                        sharedStorageSubPaths: this.state.adjustedSharedPath,
                        serviceAccount: this.state.adjustedServiceAccount,
                        adjustedUnScheduleAble:
                          this.state.adjustedUnScheduleAble,
                      }}
                      labelCol={{ span: 4 }}
                      validateTrigger="onBlur"
                    >
                      <FormItem
                        name="resourceName"
                        valuePropName="children"
                        label="资源名称"
                      >
                        <div />
                      </FormItem>

                      <FormItem
                        name="kind"
                        valuePropName="children"
                        label="资源类型"
                      >
                        <div />
                      </FormItem>

                      <FormItem
                        name="replicas"
                        valuePropName="children"
                        label="节点数"
                      >
                        <div />
                      </FormItem>

                      {(nodeResourceChanged ||
                        resourceChanged ||
                        podQOSClassChanged ||
                        envsChanged) && (
                        <>
                          {nodeResourceChanged && (
                            <FormItem
                              name="nodeResourceID"
                              label="调整前部署集"
                            >
                              <>
                                {values[1].nodeResource && (
                                  <p style={{ textDecoration: "line-through" }}>
                                    <Tag
                                      color={
                                        nodeResourceDescriptionChanged
                                          ? "gray"
                                          : "blue"
                                      }
                                      style={{
                                        textDecoration:
                                          nodeResourceDescriptionChanged
                                            ? "line-through"
                                            : "none",
                                      }}
                                    >
                                      {values[1].nodeResource.description}
                                    </Tag>

                                    {values[1].nodeResource.nodeSelectorTerms.map(
                                      (term, index) => (
                                        <Tag
                                          key={index}
                                          color={
                                            nodeResourceChangedSelectorTerms.has(
                                              term.constraintKey +
                                                "=" +
                                                term.constraintValue
                                            )
                                              ? "gray"
                                              : "green"
                                          }
                                          style={{
                                            textDecoration:
                                              nodeResourceChangedSelectorTerms.has(
                                                term.constraintKey +
                                                  "=" +
                                                  term.constraintValue
                                              )
                                                ? "line-through"
                                                : "none",
                                          }}
                                        >
                                          {term.constraintKey}=
                                          {term.constraintValue}
                                        </Tag>
                                      )
                                    )}
                                  </p>
                                )}
                              </>
                            </FormItem>
                          )}

                          {resourceChanged && (
                            <FormItem name="resource" label="调整前资源">
                              <p
                                style={{
                                  textDecoration: "line-through",
                                  verticalAlign: "middle",
                                }}
                              >
                                {values[1].resource.cpu / 1000}核{" "}
                                {values[1].resource.mem
                                  ? `x${" "}
                                ${values[1].resource.mem.toString()}Mi`
                                  : ""}
                              </p>
                            </FormItem>
                          )}

                          {podQOSClassChanged && (
                            <FormItem
                              name="podQOSClass"
                              label="调整前服务优先级"
                            >
                              <p
                                style={{
                                  textDecoration: "line-through",
                                  verticalAlign: "middle",
                                }}
                              >
                                {values[1].podQOSClass}
                              </p>
                            </FormItem>
                          )}

                          {envsChanged && (
                            <FormItem name="envs" label="调整前环境变量">
                              <TweenOneGroup
                                enter={{
                                  scale: 0.8,
                                  opacity: 0,
                                  type: "from",
                                  duration: 100,
                                }}
                                onEnd={(e) => {
                                  if (
                                    e.type === "appear" ||
                                    e.type === "enter"
                                  ) {
                                    (e.target as any).style =
                                      "display: inline-block";
                                  }
                                }}
                                leave={{
                                  opacity: 0,
                                  width: 0,
                                  scale: 0,
                                  duration: 200,
                                }}
                                appear={false}
                              >
                                {Object.keys(values[1].envs).map((key) => (
                                  <Tooltip
                                    title={(values[1].envs as any)[key]}
                                    arrowPointAtCenter
                                  >
                                    <span key={key}>
                                      <Tag
                                        style={{
                                          textDecoration:
                                            this.state.adjustedEnvs.get(key) ===
                                            (values[1].envs as any)[key]
                                              ? undefined
                                              : "line-through",
                                        }}
                                      >
                                        {key}={(values[1].envs as any)[key]}
                                      </Tag>
                                    </span>
                                  </Tooltip>
                                ))}
                              </TweenOneGroup>
                            </FormItem>
                          )}
                        </>
                      )}

                      {unScheduleAbleChanged && (
                        <FormItem
                          name="disableSchedule"
                          label="调整前禁止调度"
                          valuePropName="checked"
                        >
                          <Switch size="small" disabled />
                        </FormItem>
                      )}

                      {this.state.adjustedSelfAntiAffinity !==
                        this.state.selfAntiAffinity && (
                        <FormItem
                          name="selfAntiAffinity"
                          label="调整前自身打散"
                          valuePropName="checked"
                        >
                          <Switch size="small" disabled />
                        </FormItem>
                      )}

                      {!this.equalAntiAffinity(
                        this.state.adjustedAntiAffinities,
                        this.state.antiAffinities
                      ) && (
                        <FormItem name="antiAffinities" label="调整前打散服务">
                          <Select size="small" mode="tags" value disabled />
                        </FormItem>
                      )}

                      {sharedPathChanged && (
                        <FormItem name="sharedPath" label="调整前共享路径">
                          <p>
                            {values[1]?.sharedStorageSubPaths
                              ? values[1].sharedStorageSubPaths[0]
                              : ""}
                          </p>
                        </FormItem>
                      )}

                      {serverAccountChanged && (
                        <FormItem name="serverAccount" label="调整前服务账号">
                          <p>{values[1]?.serviceAccount ?? ""}</p>
                        </FormItem>
                      )}

                      <Divider orientation="left">调整后</Divider>

                      <FormItem
                        name="adjustedNodeResourceID"
                        label={nodeResourceChanged ? "调整后部署集" : "部署集"}
                      >
                        <Select
                          disabled={this.props.forbitEdit}
                          dropdownMatchSelectWidth={false}
                          value={this.state.adjustedNodeResourceID}
                          onChange={(adjustedNodeResourceID) => {
                            let adjustedResource = this.state.adjustedResource;
                            do {
                              if (!form.current) break;

                              if (!this.state.adjustedResource) break;

                              const nodeResource =
                                values[1].availNodeResources.find(
                                  (nodeResource) =>
                                    nodeResource.logicNodePoolID ===
                                    adjustedNodeResourceID
                                );
                              if (!nodeResource) break;

                              if (
                                nodeResource.cpu < values[1].resource.cpu ||
                                nodeResource.mem < values[1].resource.mem
                              ) {
                                message.warning(
                                  "该部署集无法满足当前资源,请重新选择"
                                );
                                form.current.setFieldsValue({
                                  adjustedResource: undefined,
                                });
                              }
                            } while (0);

                            this.setState({
                              adjustedResource,
                              adjustedNodeResourceID,
                            });
                          }}
                        >
                          {Array.from(values[1].availNodeResources).map(
                            (item, index) => (
                              <SelectOption
                                key={index}
                                value={item.logicNodePoolID}
                              >
                                <Tag color="blue">{item.description}</Tag>
                                {item.nodeSelectorTerms.map((term, index) => (
                                  <Tag key={index} color="green">
                                    {term.constraintKey}={term.constraintValue}
                                  </Tag>
                                ))}
                              </SelectOption>
                            )
                          )}
                        </Select>
                      </FormItem>

                      <FormItem
                        name="adjustedResource"
                        label={"CPU Request"}
                        rules={[{ required: true, message: "没选资源" }]}
                      >
                        <Select
                          disabled={this.props.forbitEdit}
                          dropdownMatchSelectWidth={false}
                          optionLabelProp="label"
                          value={this.state.adjustedResource || null}
                          onChange={(adjustedResource) => {
                            this.setState({ adjustedResource });
                            if (form.current)
                              form.current.setFieldsValue({ adjustedResource });
                          }}
                        >
                          {Array.from(values[1].availNodeRequests)
                            .filter((item) => {
                              if (!this.state.adjustedNodeResourceID)
                                return true;
                              return (
                                item.logicNodePoolIDs.indexOf(
                                  this.state.adjustedNodeResourceID
                                ) !== -1
                              );
                            })
                            .map((item, index) => (
                              <SelectOption
                                key={index}
                                value={
                                  item.cpu.toString() +
                                  "x" +
                                  item.mem.toString()
                                }
                                label={<>{item.cpu / 1000}核</>}
                              >
                                <span style={{ marginLeft: 5 }}>
                                  {item.cpu / 1000}核
                                </span>
                              </SelectOption>
                            ))}
                        </Select>
                      </FormItem>
                      <FormItem
                        // name="adjustedResource"
                        label={"Memory Limit"}
                        // rules={[{ required: true, message: "没选资源" }]}
                      >
                        <Select
                          disabled
                          dropdownMatchSelectWidth={false}
                          optionLabelProp="label"
                          value={this.state.adjustedResource || null}
                          className="select-mem"
                        >
                          {Array.from(values[1].availNodeRequests)
                            .filter((item) => {
                              if (!this.state.adjustedNodeResourceID)
                                return true;
                              return (
                                item.logicNodePoolIDs.indexOf(
                                  this.state.adjustedNodeResourceID
                                ) !== -1
                              );
                            })
                            .map((item, index) => (
                              <SelectOption
                                key={index}
                                value={
                                  item.cpu.toString() +
                                  "x" +
                                  item.mem.toString()
                                }
                                label={<>{item.mem}Mi</>}
                              >
                                <span style={{ marginLeft: 5 }}>
                                  {item.mem}Mi
                                </span>
                              </SelectOption>
                            ))}
                        </Select>
                      </FormItem>

                      <FormItem
                        name="adjustedPodQOSClass"
                        label={
                          podQOSClassChanged ? "调整后服务优先级" : "服务优先级"
                        }
                        rules={[{ required: true, message: "没选服务优先级" }]}
                        tooltip={<PodQosClassTooltip />}
                      >
                        <Select
                          disabled={this.props.forbitEdit}
                          dropdownMatchSelectWidth={false}
                          value={this.state.adjustedPodQOSClass || null}
                          onChange={(adjustedPodQOSClass) => {
                            this.setState({ adjustedPodQOSClass });
                            if (form.current)
                              form.current.setFieldsValue({
                                adjustedPodQOSClass,
                              });
                          }}
                        >
                          {Array.from(values[1].availPodQOSClasses).map(
                            (podQOSClass, index) => (
                              <SelectOption key={index} value={podQOSClass}>
                                {podQOSClass}
                              </SelectOption>
                            )
                          )}
                        </Select>
                      </FormItem>

                      <FormItem
                        name="adjustedSelfAntiAffinity"
                        label="自身打散"
                        valuePropName="checked"
                        tooltip="一台机器最多部署一个节点"
                      >
                        <Switch
                          disabled={this.props.forbitEdit}
                          size="small"
                          onChange={(adjustedSelfAntiAffinity) =>
                            this.setState({ adjustedSelfAntiAffinity })
                          }
                        />
                      </FormItem>

                      <FormItem
                        name="adjustedAntiAffinities"
                        label="打散服务"
                        tooltip="不与选中服务部署同一台机器"
                      >
                        <TreeSelect
                          disabled={this.props.forbitEdit}
                          multiple
                          treeCheckable
                          treeDefaultExpandAll
                          size="small"
                          treeData={this.state.treeData}
                          value={this.state.selectedTreeData}
                          style={{ width: "100%" }}
                          onSelect={(value: any, node: any) =>
                            this.onAntiAffinitiesSelect(value, node)
                          }
                          onDeselect={(value: any, node: any) =>
                            this.onAntiAffinitiesDeselect(value, node)
                          }
                        />
                      </FormItem>

                      <FormItem
                        name="adjustedUnScheduleAble"
                        label={
                          unScheduleAbleChanged ? "调整后禁止调度" : "禁止调度"
                        }
                        valuePropName="checked"
                      >
                        <Switch
                          disabled={this.props.forbitEdit}
                          size="small"
                          onChange={(adjustedUnScheduleAble) => {
                            this.setState({ adjustedUnScheduleAble });
                            if (adjustedUnScheduleAble) {
                              notification.destroy();
                              notification.warning({
                                message:
                                  "打开后，集群将不再主动调度该服务所有节点，请谨慎打开！！！",
                                duration: 15,
                              });
                            }
                          }}
                        />
                      </FormItem>
                      <FormItem
                        name="adjustedEnvs"
                        label={envsChanged ? "调整后环境变量" : "环境变量"}
                        valuePropName="checked"
                      >
                        <TweenOneGroup
                          disabled={this.props.forbitEdit}
                          enter={{
                            scale: 0.8,
                            opacity: 0,
                            type: "from",
                            duration: 100,
                          }}
                          onEnd={(e) => {
                            if (e.type === "appear" || e.type === "enter") {
                              (e.target as any).style = "display: inline-block";
                            }
                          }}
                          leave={{
                            opacity: 0,
                            width: 0,
                            scale: 0,
                            duration: 200,
                          }}
                          appear={false}
                        >
                          {Array.from(this.state.adjustedEnvs).map(
                            (value, index) => (
                              <Tooltip title={value[1]} arrowPointAtCenter>
                                <span
                                  key={index}
                                  style={{ display: "inline-block" }}
                                >
                                  <Tag
                                    closable
                                    onClose={(e) => {
                                      e.preventDefault();
                                      let adjustedEnvs =
                                        this.state.adjustedEnvs;
                                      adjustedEnvs.delete(value[0]);
                                      this.setState({ adjustedEnvs });
                                    }}
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      width:
                                        value[1].length >= 20 ? 150 : "100%",
                                    }}
                                  >
                                    {value[0]}={value[1]}
                                  </Tag>
                                </span>
                              </Tooltip>
                            )
                          )}

                          {this.state.inputVisible && (
                            <>
                              <span>
                                <Input
                                  type="text"
                                  size="small"
                                  value={this.state.inputEnvName}
                                  onChange={(e) =>
                                    this.setState({
                                      inputEnvName: e.target.value,
                                    })
                                  }
                                  style={{ width: 78 }}
                                />
                                =
                                <TextArea
                                  rows={1}
                                  size="small"
                                  value={this.state.inputEnvValue}
                                  onChange={(e) =>
                                    this.setState({
                                      inputEnvValue: e.target.value,
                                    })
                                  }
                                  style={{ width: 200 }}
                                />
                              </span>
                              <span style={{ marginLeft: 10 }}>
                                <Button
                                  className="tool-btn"
                                  type="primary"
                                  size="small"
                                  ghost
                                  style={{ marginLeft: 10 }}
                                  onClick={() => this.onInputEnvConfirm()}
                                >
                                  确认
                                </Button>
                                <Button
                                  className="tool-btn"
                                  type="default"
                                  danger
                                  size="small"
                                  ghost
                                  style={{ marginLeft: 10 }}
                                  onClick={() => this.onInputEnvCancel()}
                                >
                                  取消
                                </Button>
                              </span>
                            </>
                          )}

                          {!this.state.inputVisible &&
                            !this.props.forbitEdit && (
                              <Tag
                                onClick={() =>
                                  this.setState({ inputVisible: true })
                                }
                                className="site-tag-plus"
                                style={{
                                  display: "inline-block",
                                  marginLeft: 10,
                                }}
                              >
                                <PlusOutlined /> 添加
                              </Tag>
                            )}
                        </TweenOneGroup>
                      </FormItem>

                      {this.props.admin && (
                        <FormItem
                          name="serviceAccount"
                          label={
                            serverAccountChanged
                              ? "调整后k8s Acount"
                              : "k8s Acount"
                          }
                        >
                          <Select
                            allowClear
                            disabled={this.props.forbitEdit}
                            options={resource?.availServiceAccount?.map(
                              (item) => {
                                return { label: item, value: item };
                              }
                            )}
                            onChange={(value) => {
                              this.setState({ adjustedServiceAccount: value });
                            }}
                          />
                        </FormItem>
                      )}

                      <FormItem name="sharedStorageSubPaths" label="共享存储">
                        <Input
                          placeholder="dir子路径.."
                          addonBefore={
                            <Switch
                              size="small"
                              disabled={this.props.forbitEdit}
                              onChange={(checked) => {
                                if (checked) {
                                  Modal.destroyAll();
                                  Modal.error({
                                    icon: (
                                      <ExclamationCircleOutlined
                                        style={{ color: "red" }}
                                      />
                                    ),
                                    title: "确认修改挂载共享存储?",
                                    content: (
                                      <div>
                                        <div>
                                          服务共享目录将挂载至/data/${"{dir}"}
                                        </div>
                                        <div
                                          style={{
                                            color: "red",
                                            fontWeight: "bold",
                                            fontSize: "20px",
                                          }}
                                        >
                                          历史存储卷数据将会永久丢失，请谨慎！！！！！！
                                        </div>
                                      </div>
                                    ),
                                    okText: "确认",
                                    okButtonProps: {
                                      danger: true,
                                    },
                                  });
                                }
                                this.setState({ sharedStorage: checked });
                              }}
                            />
                          }
                          onChange={(e) => {
                            this.setState({
                              adjustedSharedPath: e.target.value,
                            });
                          }}
                          value={this.state.adjustedSharedPath ?? ""}
                          style={{ width: 200 }}
                          disabled={this.state.sharedStorage ? false : true}
                        />
                      </FormItem>

                      {(nodeResourceChanged ||
                        resourceChanged ||
                        podQOSClassChanged ||
                        envsChanged ||
                        serverAccountChanged ||
                        sharedPathChanged ||
                        this.state.adjustedSelfAntiAffinity !==
                          this.state.selfAntiAffinity ||
                        !this.equalAntiAffinity(
                          this.state.adjustedAntiAffinities,
                          this.state.antiAffinities
                        )) && (
                        <FormItem
                          name="reason"
                          label="调整原因"
                          rules={[
                            { required: true, min: 6, message: "原因过于简单" },
                          ]}
                        >
                          <Input
                            disabled={this.props.forbitEdit}
                            onChange={(e) => {
                              if (form.current)
                                form.current.setFieldsValue({
                                  reason: e.target.value,
                                });
                            }}
                          />
                        </FormItem>
                      )}
                    </Form>
                  )}
                </TabPane>
              ))}
            </Tabs>
          )}

          {this.state.resources.size === 0 && <Empty />}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  admin: state.tarsReducer.admin,
});

export default connect(mapStateToProps, null)(AdjustResource);
