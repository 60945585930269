import React, { useEffect } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import { ThunkDispatch } from "redux-thunk";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { webApi } from "src/fetch";

import Tools from "../pages/Tools";
import DevOps from "../pages/DevOps";
import DCache from "../pages/DCache";
import CreateServer from "src/pages/CreateServer";
import Login from "src/pages/Login";
import Login4Test from "../pages/Login/login4Test";
import ApproveCenter from "src/pages/ApproveCenter";
import ServerStatus from "src/pages/ServerStatus";
import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";
import "./index.less";
import { Spin } from "antd";

interface IUserInfo {
  username: string;
  avatar: string;
  rootPerm: boolean;
  useRootPerm: boolean;
}

interface IProps {
  admin?: boolean;
  globalLoading: boolean;
  login: (param: IUserInfo) => void;
  toggleLoading: () => void;
}

const App: React.FC<IProps> = ({
  login,
  admin,
  globalLoading,
  toggleLoading,
}) => {
  const location = useLocation();
  const navigator = useNavigate();
  useEffect(() => {
    if (!["/sign_in", "/login_4_test", "/login"].includes(location.pathname)) {
      fetchUserInfo();
    }
  }, []);

  function fetchUserInfo() {
    toggleLoading();
    webApi
      .get<{
        userInfo: IUserInfo;
      }>("user/userInfo")
      .then((res) => {
        login(res.userInfo);
      })
      .finally(() => {
        toggleLoading();
      });
  }

  return (
    <Spin spinning={globalLoading}>
      <Header />
      <div className="tars-layout-content">
        <Routes>
          <Route path="/home" element={<DevOps />} />
          {admin ? (
            <Route path="/tools" element={<Tools navigator={navigator} />} />
          ) : null}
          <Route path="/dcache" element={<DCache />} />
          <Route path="/login_4_test" element={<Login4Test />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/createServer"
            element={<CreateServer navigator={navigator} admin={admin} />}
          />
          <Route path="/serverStatus" element={<ServerStatus />} />
          <Route path="/approveCenter" element={<ApproveCenter />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </div>
      <Footer />
    </Spin>
  );
};

const mapStateToProps = (state: any) => ({
  admin: state.tarsReducer.admin,
  globalLoading: state.tarsReducer.globalLoading,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  login: (param: IUserInfo) => dispatch(Actions.login(param)),
  toggleLoading: () => dispatch(Actions.toggleLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
