import React from 'react';
import { Button, Input, InputNumber, Form, FormInstance, Popconfirm, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import * as fetch from 'src/fetch';

const FormItem = Form.Item;

interface IProps {
  currentUser?: string;
  clusterName: string;
  nodePoolName: string;
}

interface IState {
  visible: boolean;
}

export default class ExpandNodePoolButton extends React.Component<IProps, IState> {
  private expandForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.state = {visible: false};
    this.expandForm = React.createRef<FormInstance>();
  }

  fetchExpandNodePool(nodePoolName: string, count: number, reason: string) {
    fetch
    .fetchExpandNodePool({
      clusterName: this.props.clusterName,
      nodePoolName,
      count,
      reason,
      userName: this.props.currentUser || ''
    })
    .then(() => {
      notification.success({message: '扩容成功, 稍后再来查看结果'});
    })
    .catch(_ => {});
  }

  render() {
    return (
      <Popconfirm
        icon={null}
        placement='left'
        title={
          <Form
            name='expandForm'
            ref={this.expandForm}
            validateTrigger='onBlur'
            initialValues={{ count: 1, nodePoolName: this.props.nodePoolName, reason: '' }}
          >
            <FormItem name='nodePoolName' valuePropName='children' label='节点池扩容'>
              <div />
            </FormItem>
            <FormItem name='count'>
              <InputNumber addonBefore='数量' />
            </FormItem>

            <FormItem name='reason' rules={[{ required: true, min: 6, message: '原因过于简单' }]}>
              <Input addonBefore='原因' placeholder='eg: 服务资源不足'/>
            </FormItem>
          </Form>
        }
        trigger='click'
        okText='扩容'
        cancelText='取消'
        visible={this.state.visible}
        okButtonProps={{className: 'tool-btn', ghost: true, size: 'small', type: 'primary'}}
        cancelButtonProps={{className: 'tool-btn', ghost: true, size: 'small', danger: true}}
        onConfirm={async () => {
          try {
            if (this.expandForm.current) {
              const { nodePoolName, count, reason } = await this.expandForm.current.validateFields();
              this.setState({visible: false})
              this.fetchExpandNodePool(nodePoolName, count, reason);
            }
          } catch {}
        }}
        onCancel={() => this.setState({visible: false})}
        disabled={this.props.nodePoolName.indexOf('PayAsYouGo') !== -1}
      >
        <Button size='small' className='tool-btn' disabled={this.props.nodePoolName.indexOf('PayAsYouGo') !== -1} onClick={() => this.setState({visible: true})}>
          <PlusOutlined />扩容
        </Button>
      </Popconfirm>
    );
  }
};
