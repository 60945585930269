import React, { useEffect, useState } from "react";
import {
  Form,
  Checkbox,
  InputNumber,
  Button,
  Spin,
  Tabs,
  Card,
  message,
  Select,
  Switch,
  Input,
  Row,
  Tag,
  Table,
  AutoComplete,
  Modal
} from "antd";
import { useSelector } from "react-redux";
import "./style/common.less";
import * as fetch from "src/fetch";
import { set } from "lodash";
import { Tab } from "@material-ui/core";

const originColumns = (dealHandle: any) => [
  {
    title: '规则名称',
    dataIndex: 'alertRuleName',
    key: 'alertRuleName',
  },
  {
    title: '特性名称',
    dataIndex: 'propertyName',
    key: 'propertyName',
  },
  {
    title: 'reducer',
    dataIndex: 'reducer',
    key: 'reducer',
  },
  {
    title: 'operateType',
    dataIndex: 'operateType',
    key: 'operateType',
    render: (text: any, record: { [key: string]: any }) => {
      const options: { [key: string]: string } = { gt: "大于", lt: "小于", outside_range: "在范围外", within_range: "在范围内" };
      return options[text] || "";
    }
  },
  {
    title: 'timeRange',
    dataIndex: 'timeRange',
    key: 'timeRange',
    render: (text: any, record: any) => {
      //分钟转换
      return modifyTime(text)
    }
  },
  {
    title: 'operateParams',
    dataIndex: 'operateParams',
    key: 'operateParams',
    render: (text: any, record: any) => {
      return text
    }
  },
  {
    title: '告警ID',
    dataIndex: 'alarmID',
    key: 'alarmID',
  },

  ...[dealHandle]
];



//把分钟转换成小时，小于60分钟的不转换，输出文案
const modifyTime = (time: number) => {
  if (time < 60) {
    return time + "分钟"
  } else {
    return time / 60 + "小时"
  }
}




const ServerPropertyRule = () => {
  const [form] = Form.useForm();
  const [loading, setIsLoading] = useState(false);

  const [columns, setColumns] = useState<any[]>([]);

  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [setList, setSetList] = useState<any[]>([]);
  const [currentCluster, setCurrentCluster] = useState("");

  const [dataSource, setDataSource] = useState<any[]>([]);


  const [currCondition, setCurrCondition] = useState<any>('');

  const [rangeList, setRangeList] = useState<any[]>([]);
  const [reducers, setReducers] = useState<any[]>([]);
  const [propertyNames, setPropertyNames] = useState<any[]>([]);



  const {
    selectedTarsApplication,
    selectedTarsSetName,
    selectedTarsServer,
    currentUser,
    tarsServerItems,
    newRSItems,
  } = useSelector((state: any) => ({
    selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
    selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
    selectedTarsServer: state.tarsReducer.selectedTarsServer,
    currentUser: state.tarsReducer.currentUser,
    tarsServerItems: state.tarsReducer.tarsServerItems,
    newRSItems: state.tarsReducer.newRSItems,

  }));



  useEffect(() => {
    const columns = originColumns({
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: { [key: string]: any }) => {
        return (<>
          <Button type="link" onClick={() => {
            setIsEdit(true);
            setEditId(record.id);
            setCurrCondition(record?.evaluator?.operType);


            form.setFieldsValue({
              alertRuleName: record.alertRuleName,
              propertyName: record.propertyName,
              reducer: record.reducer,
              condition: record?.evaluator?.operType,
              min: record?.evaluator?.params[0] || "",
              max: record?.evaluator?.params[1] || "",
              timeRange: record.timeRange,
              alarmId: record.alarmID,

            });
          }}>
            编辑
          </Button>
          <Button type="link" onClick={() => {
            deleteAlertCondition(record.id);
          }}>删除</Button>
        </>
        )
      }
    })


    setColumns(columns);

    let newSetList: any[] = [];
    if (!newRSItems) return;
    //newSetList = [...(newRSItems).keys()]
    (newRSItems as Map<string, number>).forEach((element, key) => {
      if (element > 0) {
        newSetList.push(key)
      }
    });
    setSetList(newSetList);
    setCurrentCluster(newSetList[0]);
    getAlertCondition(newSetList[0]);
    getAlertList(newSetList[0]);

  }, [
    selectedTarsApplication,
    selectedTarsSetName,
    selectedTarsServer,
    currentUser,
    tarsServerItems,
    newRSItems
  ]);



  const deleteAlertCondition = async (id: string,) => {
    Modal.confirm({
      title: "删除",
      content: "确定删除吗？",
      onOk: async () => {
        setIsLoading(true);
        try {
          await fetch.deleteAlertRule({
            tarsApplication: selectedTarsApplication,
            tarsServerName: selectedTarsServer,
            tarsSetName: selectedTarsSetName,
            clusterName: currentCluster,
            userName: currentUser,
            id: id
          });
          await getAlertList(currentCluster);
        } catch (error) {
          message.error("删除失败");
        } finally {
          setIsLoading(false);
        }
      }
    });

  };





  const getAlertList = async (currentCluster: string) => {
    setIsLoading(true);
    try {
      const result = await fetch.getPropertyAlertList({
        tarsApplication: selectedTarsApplication,
        tarsServerName: selectedTarsServer,
        tarsSetName: selectedTarsSetName,
        clusterName: currentCluster
      });

      const { propertyAlertList } = result.items || []
      const modifyList = propertyAlertList?.map((item: { alertRuleName: any; propertyName: any; reducer: any; evaluator: { operType: any; params: any[]; }; timeRange: any; alarmID: any; id: any }) => {
        return {
          ...item,
          alertRuleName: item.alertRuleName,
          propertyName: item.propertyName,
          reducer: item.reducer,
          operateType: item.evaluator.operType,
          operateParams: item.evaluator.params?.join(","),
          timeRange: item.timeRange,
          alarmID: item.alarmID,
          id: item.id
        }
      })

      setDataSource(modifyList || [])

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }


  const getAlertCondition = async (currentCluster: string) => {
    setIsLoading(true);
    try {
      const result = await fetch.getAlertPresetCondition({
        tarsApplication: selectedTarsApplication,
        tarsServerName: selectedTarsServer,
        tarsSetName: selectedTarsSetName,
        clusterName: currentCluster
      });

      const { reducers, propertyNames, timeRanges } = result?.item || {}

      setRangeList(timeRanges || [])
      setReducers(reducers
        || [])
      setPropertyNames(propertyNames || [])

    } catch (error) {
      setIsLoading(false);
    }
  }





  const addOrModifyAlertRule = async (values: any) => {
    setIsLoading(true);
    try {
      await fetch.addOrModifyAlertRule({
        tarsApplication: selectedTarsApplication,
        tarsServerName: selectedTarsServer,
        tarsSetName: selectedTarsSetName,
        clusterName: currentCluster,
        userName: currentUser,
        param: {
          ...values,
        }
      });
      setIsEdit(false);
      setIsLoading(false);
      form.resetFields();
    } catch (error) {
      setIsLoading(false);
    }
  }


  const onSubmit = async () => {
    await form.validateFields()

    const values = form.getFieldsValue();
    const { alertRuleName, propertyName, reducer, timeRange, condition, min, max, alarmId } = values;

    await addOrModifyAlertRule({
      alertRuleName,
      propertyName,
      reducer,
      timeRange,
      evaluator: {
        operType: condition,
        params: max !== undefined ? [Number(min), Number(max)] : [Number(min)]
      },
      alarmId,
      id: editId || undefined
    })

    getAlertList(currentCluster);
  }


  return (
    <>
      <Spin spinning={loading}>
        <Tabs
          size="large"
          type="card"
          activeKey={currentCluster}
          style={{ marginLeft: 8 }}
          onChange={(clusterName) => {
            setCurrentCluster(clusterName);
          }}
        >

          {
            setList.map((clusterName: string) => {
              return <Tabs.TabPane tab={clusterName} key={clusterName}>
                {!isEdit && <Card className="cardBox">
                  <Row justify="space-between">
                    <div className="title">服务特性规则</div>
                    <Button type="primary" onClick={() => setIsEdit(true)}>新增</Button>
                  </Row>
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                  />
                </Card>}

                {isEdit &&
                  <Card>

                    <Form form={form} className="formEditBox" onFinish={() => {
                      onSubmit()
                    }}>
                      <Form.Item label="规则名称" name="alertRuleName" rules={
                        [
                          {
                            required: true,
                            message: "请输入规则名称",
                          },
                        ]
                      }>
                        <Input />
                      </Form.Item>

                      <div className="subTitle">查询条件</div>
                      <Form.Item label="特性名称" name="propertyName" rules={
                        [
                          {
                            required: true,
                            message: "请输入特性名称",
                          },
                        ]
                      }>
                        <AutoComplete options={propertyNames.map((item) => ({ label: item, value: item }))} />
                      </Form.Item>
                      <Form.Item label="时间周期" name="timeRange" rules={
                        [
                          {
                            required: true,
                            message: "请选择时间周期",
                          },
                        ]
                      }>
                        <Select options={rangeList.map((item) => ({ label: modifyTime(item), value: item }))} />
                      </Form.Item>

                      <Form.Item label="告警条件"
                        extra="在时间周期内，通过告警条件判断是否触发告警到指定的告警id"
                        required
                        rules={
                          [
                            {
                              required: true,
                              message: "请输入告警条件",
                            },
                          ]
                        }>
                        <div className="conditionFun">
                          <span className="noMargin">WHEN</span>
                          <Form.Item name="reducer" rules={
                            [
                              {
                                required: true,
                                message: "请选择聚合函数",
                              },
                            ]
                          } noStyle>
                            <Select
                              options={reducers.map((item) => ({ label: item, value: item }))}
                              className="inlineSelect"
                            />
                          </Form.Item>
                          <span className="noMargin">OF A</span>
                          <Form.Item name="condition"
                            rules={
                              [
                                {
                                  required: true,
                                  message: "请选择条件",
                                },
                              ]
                            }
                            noStyle>
                            <Select
                              options={[
                                { label: "IS ABOVE", value: "gt" },
                                { label: "IS BELOW", value: "lt" },
                                { label: "IS OUTSIDE RANGE", value: "outside_range" },
                                { label: "IS WITHIN RANGE", value: "within_range" },
                              ]}
                              className="inlineSelect"
                              onChange={(value) => {
                                setCurrCondition(value);
                                form.setFieldsValue({
                                  conditionValue: undefined,
                                });
                              }}
                            />
                          </Form.Item>
                          <div className="conditionFun noWarp conditionMore">

                            {currCondition === "outside_range" || currCondition === "within_range" ? <>
                              {" "}
                              <Form.Item name="min" rules={
                                [
                                  {
                                    required: true,
                                    message: "请输入边界值",
                                  },
                                ]
                              }>
                                <Input />
                              </Form.Item>
                              <span className="tag">TO</span>
                              <Form.Item name="max" rules={
                                [
                                  {
                                    required: true,
                                    message: "请输入边界值",
                                  },
                                ]
                              }>
                                <Input />
                              </Form.Item>
                            </> : <Form.Item name="min" rules={
                              [
                                {
                                  required: true,
                                  message: "请输入边界值",
                                },
                              ]
                            }>
                              <Input />
                            </Form.Item>
                            }

                          </div>
                        </div>
                      </Form.Item>
                      <Form.Item label="告警ID" name="alarmId" rules={
                        [
                          {
                            required: true,
                            message: "请选择告警条件",
                          },
                        ]
                      }>
                        <Input />
                      </Form.Item>
                      <Row justify="center" className="submitBox">
                        <Button type="primary" htmlType="submit" >提交</Button>
                        <Button type="ghost" onClick={() => setIsEdit(false)}>返回</Button>
                      </Row>
                    </Form>
                  </Card>}
              </Tabs.TabPane>
            })
          }

        </Tabs>
      </Spin>
    </>
  );
};

export default ServerPropertyRule;
