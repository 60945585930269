import React from "react";
import { connect } from "react-redux";

import { Layout, Spin } from "antd";
import { ThunkDispatch } from "redux-thunk";
import LeftSideBar from "src/pages/DevOps/LeftSideBar";
import RightContent from "src/pages/DevOps/RightContent";
import RightToolsBar from "src/pages/DevOps/RightToolsBar";

import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";

import SplitPane from "react-split-pane";
// @ts-ignore
import Pane from "react-split-pane/lib/Pane";
import "./style/common.less";

const { Content, Sider } = Layout;
interface IState {
  selectedKey?: string;
  isRollout?: boolean;
}
interface IProps {
  tab?: string;
  loadDone?: boolean;
  selectedTarsServer?: string;
  treeNodes: Array<ActionType.ITreeItem>; // 原始响应

  changeSelectServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string
  ) => void;

  listServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string
  ) => void;
}

class DevOps extends React.Component<IProps, IState> {
  componentDidMount(): void {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const selectKey = params.get("key");
    const isRollout = params.get("isRollout");
    const parts = selectKey?.split(".");
    // 进入的时候有对应的key，直接定位到具体的服务页面
    // console.log("parts", parts);
    // todo 刷新页面后侧边栏重新定位
    if (selectKey && this.props.treeNodes) {
      const hasKey = this.props.treeNodes.some((node: ActionType.ITreeItem) => {
        return (
          `${node.tarsApplication}${node.tarsSetName === ".." ? `.${node.tarsSetName}` : ""
          }.${node.tarsServerName}` === selectKey
        );
      });
      if (!hasKey) {
        return;
      }
    }

    if (selectKey) {
      this.setState({
        selectedKey: selectKey,
      });
    }


    this.setState({
      isRollout: !!isRollout
    });

    setTimeout(() => {
      if (parts?.length === 2) {
        this.props.listServer(parts[0], "", parts[1]);
        this.props.changeSelectServer(parts[0], "", parts[1]);
      } else if (parts?.length === 5) {
        const setName = parts.slice(1, 4).join(".");
        this.props.listServer(parts[0], setName, parts[4]);
        this.props.changeSelectServer(parts[0], setName, parts[4]);
      }
    }, 3000);
  }
  render() {
    const tabs = new Set<string>([
      "machine-info",
      "container-monitoring",
      "slave-stat",
      "property",
      "master-stat",
      "dcache-stat",
      "property-plus",
      "debugInterface",
      "server-info",
    ]);
    return (
      <Layout
        className="tars-devops-layout"
        hasSider={true}
        style={{ backgroundColor: "white" }}
      >
        <SplitPane className="split-pane" split="vertical">
          <Pane initialSize="240px">
            <Spin spinning={!this.props.loadDone}>
              <Sider className="tars-left-sider" theme="light" width="100%">
                <div
                  style={{ paddingTop: 12, paddingLeft: 10, paddingRight: 10 }}
                >
                  <LeftSideBar selectedKey={this.state?.selectedKey} />
                </div>
              </Sider>
            </Spin>
          </Pane>

          {this.props.selectedTarsServer && (
            <Pane initialSize="70vw" className="mainContainer">
              <Content style={{ margin: 12, marginTop: 0 }}>
                <RightContent isRollout={!!this.state.isRollout} />
              </Content>
            </Pane>
          )}

          {this.props.selectedTarsServer &&
            this.props.tab &&
            !tabs.has(this.props.tab) && (
              <Pane className="rightSider">
                <Sider className="tars-right-sider" theme="light" width="140">
                  <RightToolsBar />
                </Sider>
              </Pane>
            )}
        </SplitPane>
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  tab: state.tarsReducer.tab,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  treeNodes: state.tarsReducer.treeNodes,
  loadDone: state.tarsReducer.loadDone,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  changeSelectServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string
  ) =>
    dispatch(
      Actions.changeSelectServer(
        selectedTarsApplication,
        selectedTarsSetName,
        selectedTarsServer
      )
    ),

  listServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string
  ) => {
    dispatch(Actions.listServerClear());
    dispatch(
      Actions.listServer(
        selectedTarsApplication,
        selectedTarsSetName,
        selectedTarsServer
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DevOps);
