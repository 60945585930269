import { AutoSizer, Table, Column, SortDirection } from "react-virtualized";
import { isNumber } from "lodash";

export const getSortMap = (sortDirection: any) => {
  const sortFnMap = {
    podIP: (a: any, b: any) => {
      if (sortDirection === SortDirection.ASC) {
        return a.podIP.localeCompare(b.podIP);
      } else {
        return b.podIP.localeCompare(a.podIP);
      }
    },
    idcName: (a: any, b: any) => {
      if (sortDirection === SortDirection.ASC) {
        return a.idcName.localeCompare(b.idcName);
      } else {
        return b.idcName.localeCompare(a.idcName);
      }
    },
    zone: (a: any, b: any) => {
      if (sortDirection === SortDirection.ASC) {
        return a.zone.localeCompare(b.zone);
      } else {
        return b.zone.localeCompare(a.zone);
      }
    },
    netstat: (a: any, b: any) => {
      if (sortDirection === SortDirection.ASC) {
        return a.netstat.localeCompare(b.netstat);
      } else {
        return b.netstat.localeCompare(a.netstat);
      }
    },
    settingState: (a: any, b: any) => {
      if (sortDirection === SortDirection.ASC) {
        return a.settingState.localeCompare(b.settingState);
      } else {
        return b.settingState.localeCompare(a.settingState);
      }
    },
    currentState: (a: any, b: any) => {
      if (sortDirection === SortDirection.ASC) {
        return a.currentState.localeCompare(b.currentState);
      } else {
        return b.currentState.localeCompare(a.currentState);
      }
    },
    publishVersion: (a: any, b: any) => {
      if (sortDirection === SortDirection.ASC) {
        return a.publishVersion.localeCompare(b.publishVersion);
      } else {
        return b.publishVersion.localeCompare(a.publishVersion);
      }
    },
    whoPublished: (a: any, b: any) => {
      if (sortDirection === SortDirection.ASC) {
        return a.whoPublished.localeCompare(b.whoPublished);
      } else {
        return b.whoPublished.localeCompare(a.whoPublished);
      }
    },
    publishTimestamp: (a: any, b: any) => {
      const x = parseInt(a.publishTimestamp) || 0;
      const y = parseInt(b.publishTimestamp) || 0;
      //       return x - y;
      if (sortDirection === SortDirection.ASC) {
        return x - y;
      } else {
        return y - x;
      }
    },
    startTimestamp: (a: any, b: any) => {
      const x = parseInt(a.startTimestamp) || 0;
      const y = parseInt(b.startTimestamp) || 0;
      if (sortDirection === SortDirection.ASC) {
        return x - y;
      } else {
        return y - x;
      }
    },
    clusterName: (a: any, b: any) => {
      if (sortDirection === SortDirection.ASC) {
        return a.clusterName.localeCompare(b.clusterName);
      } else {
        return b.clusterName.localeCompare(a.clusterName);
      }
    },
  };
  return sortFnMap;
};
