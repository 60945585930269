import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { pickClusterName } from "src/utils/Picker";
import {
  Button,
  Card,
  Form,
  FormInstance,
  Result,
  List,
  Input,
  InputNumber,
  Space,
  Select,
  Switch,
  PageHeader,
  Tag,
  Tabs,
  Popconfirm,
  Modal,
  message,
  notification,
  Popover,
} from "antd";
import {
  GlobalOutlined,
  QuestionCircleOutlined,
  PlusCircleOutlined,
  EditOutlined,
  EyeTwoTone,
} from "@ant-design/icons";

import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";

import ApproveButton from "./ApproveButton";
import { ApproveKindKeyEnum } from "src/utils/constant";

const { TabPane } = Tabs;
const FormItem = Form.Item;
const FormList = Form.List;
const ListItem = List.Item;
const SelectOption = Select.Option;

interface IProps {
  disabled: boolean;
  forbitEdit: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  httpItems?: Map<string, number>;
  approveStatus: Number;
  updateApproveStatus: () => void;
  pendingApproveUser: string;
}

interface IIngressRule extends FetchType.IIngressRule {
  targetPort: number;
}

interface IIngressConfig extends FetchType.IIngressConfig {
  ingressName: string;
  ingressClassName: string;
  kind: string;
  rules: Array<IIngressRule>;
  tlsHost: Array<string>;
  hostnamesOrIPs: Array<string>;
}

interface IIngressClass {
  name: string;
  description: string;
}

interface IIngressHostname {
  hostname: string;
  resourceNames: Array<string>;
}

interface IIngressPriority {
  priority: number;
  resourceName: string;
}

interface IIngress {
  errCode: number;
  errMsg: string;
  httpConfig: IIngressConfig;
  httpsConfig: IIngressConfig;
  ingressClasses: Array<IIngressClass>;
  ingressHostnames: Array<IIngressHostname>;
  ingressPriorities: Array<IIngressPriority>;
}

interface IState {
  tooltips: boolean;
  adjusted: boolean;
  visible: boolean;
  which: string;
  clusterName: string;
  items: Map<string, IIngress>;
  editConfig: IIngressConfig;
  allowEdit: boolean;
}

class Ingress extends React.Component<IProps, IState> {
  private httpForm: React.RefObject<FormInstance>;
  private httpsForm: React.RefObject<FormInstance>;

  defaultIngressConfig(): IIngressConfig {
    return {
      ingressName: "<未配置>",
      ingressClassName: "",
      kind: "<未配置>",
      sslRedirect: 0,
      keepAlive: 1,

      cors: 0,
      corsHeaders: "",
      corsMethods: "",
      corsExposeHeaders: "",
      corsCredentials: 0,
      corsMaxAge: 0,

      rewrite: "",
      priority: 0,
      trafficLimitQPS: 0,

      rules: [],
      tlsHost: [],
      hostnamesOrIPs: ["<未配置>"],
    };
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      tooltips: false,
      adjusted: false,
      visible: false,
      which: "http",
      clusterName: "",
      items: new Map(),
      editConfig: this.defaultIngressConfig(),
      allowEdit: false,
    };
    this.httpForm = React.createRef<FormInstance>();
    this.httpsForm = React.createRef<FormInstance>();
  }

  generateFormInitialValues(): any {
    const ingressName = this.state.editConfig.ingressName;
    const ingressClassName = this.state.editConfig.ingressClassName;
    const kind = this.state.editConfig.kind;
    const sslRedirect = this.state.editConfig.sslRedirect === 1;
    const keepAlive = this.state.editConfig.keepAlive === 1;

    const cors = this.state.editConfig.cors === 1;
    const corsHeaders = this.state.editConfig.corsHeaders;
    const corsMethods = this.state.editConfig.corsMethods;
    const corsExposeHeaders = this.state.editConfig.corsExposeHeaders;
    const corsCredentials = this.state.editConfig.corsCredentials === 1;
    const corsMaxAge = this.state.editConfig.corsMaxAge;

    const rewrite = this.state.editConfig.rewrite;
    const priority = this.state.editConfig.priority;
    const trafficLimitQPS = this.state.editConfig.trafficLimitQPS;

    const hostnamesOrIPs = this.state.editConfig.hostnamesOrIPs;

    const tlsHost = this.state.editConfig.tlsHost;

    const initialValues = {
      ingressName,
      ingressClassName,
      kind,
      sslRedirect,
      keepAlive,
      cors,
      corsHeaders,
      corsMethods,
      corsExposeHeaders,
      corsCredentials,
      corsMaxAge,
      rewrite,
      priority,
      trafficLimitQPS,
      hostnamesOrIPs,
      tlsHost,
    };
    return initialValues;
  }

  resetFormInitialValues() {
    let current: FormInstance<any> | null = null;
    if (this.state.which === "http") {
      current = this.httpForm.current;
    } else if (this.state.which === "https") {
      current = this.httpsForm.current;
    }
    if (current) current.setFieldsValue(this.generateFormInitialValues());
  }

  fetchData() {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer) {
      message.error("系统错误");
      return;
    }

    fetch
      .fetchListIngress({
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
      })
      .then((data) => {
        let clusterName = this.state.clusterName;
        const items = new Map<string, IIngress>(Object.entries(data.items));
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(items.keys()));
        }
        let which = this.state.which;

        let editConfig: IIngressConfig = this.defaultIngressConfig();
        const item = items.get(clusterName);
        if (item) {
          if (which === "http") editConfig = item.httpConfig;
          else if (which === "https") editConfig = item.httpsConfig;
        }
        if (editConfig.ingressName.length === 0) {
          editConfig.ingressName = "<未配置>";
          editConfig.kind = "<未配置>";
        }

        this.setState({ visible: true, items, editConfig, clusterName });
      })
      .catch((_) => {});
  }

  fetchAdjustIngress(fields: any) {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer)
      return;
    const kind = this.state.which;

    if (!fields.rules || isEmpty(fields.rules)) {
      notification.error({ message: "系统错误:规则未配置" });
      return;
    }

    let conflictResourceName = "";
    const item = this.state.items.get(this.state.clusterName);
    if (
      item &&
      item.ingressPriorities.some((x) => {
        if (x.priority === fields.priority) {
          conflictResourceName = x.resourceName;
          return true;
        }
        return false;
      })
    ) {
      notification.error({
        message: `优先级已冲突,冲突资源名:${conflictResourceName}`,
      });
      return;
    }

    let rules: Array<FetchType.IIngressRule> = [];
    for (const rule of fields.rules) {
      rules.push({
        host: rule.host,
        path: rule.path,
        pattern: rule.pattern,
      });
    }

    fetch
      .fetchAdjustIngress({
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        kind,
        userName: this.props.currentUser || "",
        clusterName: this.state.clusterName,
        config: {
          ingressClassName: fields.ingressClassName,
          sslRedirect: fields.sslRedirect ? 1 : 0,
          keepAlive: fields.keepAlive ? 1 : 0,
          cors: fields.cors ? 1 : 0,
          corsHeaders: fields.corsHeaders ? fields.corsHeaders : "",
          corsMethods: fields.corsMethods ? fields.corsMethods : "",
          corsExposeHeaders: fields.corsExposeHeaders
            ? fields.corsExposeHeaders
            : "",
          corsCredentials: fields.corsCredentials ? 1 : 0,
          corsMaxAge:
            typeof fields.corsMaxAge === "number" ? fields.corsMaxAge : -1,
          rewrite: fields.rewrite ? fields.rewrite : "",
          priority: typeof fields.priority === "number" ? fields.priority : 0,
          trafficLimitQPS:
            typeof fields.trafficLimitQPS === "number"
              ? fields.trafficLimitQPS
              : 0,
          rules,
        },
      })
      .then(() =>
        this.setState({
          adjusted: false,
          visible: false,
          which: "http",
          clusterName: "",
          items: new Map(),
        })
      )
      .finally(() => {
        this.props.updateApproveStatus();
      });
  }

  setRuleValue(index: number, value: string, kind: string) {
    let editConfig = { ...this.state.editConfig };
    let target = editConfig.rules[index];
    if (kind === "host") {
      target.host = value;
    } else if (kind === "path") {
      target.path = value;
    } else if (kind === "pattern") {
      target.pattern = value;
    }
    this.setState({ editConfig });
  }

  render() {
    const initialValues = this.generateFormInitialValues();

    const validateRules = (index: number, kind: string): Promise<any> => {
      const value = this.state.editConfig.rules[index];
      if (kind === "host") {
        try {
          let u = new URL("http://" + value.host);
          if (u.pathname !== "/") return Promise.reject("域名不能写路径");
        } catch {
          return Promise.reject("域名不合法");
        }
      } else if (
        kind === "path" &&
        (isEmpty(value.path) || !value.path.startsWith("/"))
      ) {
        return Promise.reject("path不合法");
      }

      const item = this.state.items.get(this.state.clusterName);
      if (item) {
        for (const h of item.ingressHostnames) {
          if (h.hostname === value.host) {
            notification.warning({
              message: (
                <span>
                  <p>域名已被使用, 已使用资源:</p>
                  {Array.from(new Set(h.resourceNames)).map((x, index) => (
                    <p key={index}> {x} </p>
                  ))}
                </span>
              ),
            });
            break;
          }
        }
      }

      const current = value.host + "|" + value.path;
      for (let i = 0; i < this.state.editConfig.rules.length; ++i) {
        if (i === index) continue;
        const key =
          this.state.editConfig.rules[i].host +
          "|" +
          this.state.editConfig.rules[i].path;
        if (key === current) {
          return Promise.reject(`与规则(${i + 1})重复`);
        }
      }
      return Promise.resolve();
    };

    return (
      <>
        <Popover
          placement="left"
          content={(
            this.props.disabled ||
              Array.from((this.props.httpItems || new Map()).values()).every(
                (x) => x === 0
              )
            ? "非http服务无法配置域名"
            : (
              <div>
                <Button
                  ghost
                  type="primary"
                  disabled={this.props.disabled}
                  size="small"
                  className="tool-btn"
                  onClick={() => {
                    this.setState({allowEdit: false});
                    this.fetchData();
                  }}
                  style={{marginBottom: '10px'}}
                >
                  <Space>
                    <EyeTwoTone />
                    查看配置
                  </Space>
                </Button>
                <ApproveButton
                  kind={ApproveKindKeyEnum.alterDomain}
                  approveStatus={this.props.approveStatus}
                  updateApproveStatus={this.props.updateApproveStatus}
                  pendingApproveUser={this.props.pendingApproveUser}
                >
                  <Button
                    disabled={
                      this.props.disabled ||
                      Array.from((this.props.httpItems || new Map()).values()).every(
                        (x) => x === 0
                      )
                    }
                    className="tool-btn"
                    size="small"
                    onClick={() => {
                      if (
                        this.props.httpItems &&
                        Array.from(this.props.httpItems.values()).some((x) => x === 1)
                      ) {
                        this.setState({allowEdit: true});
                        this.fetchData();
                      }
                    }}
                  >
                    <Space>
                      <EditOutlined />
                      编辑配置
                    </Space>
                  </Button>
                </ApproveButton>
              </div>
            )
          )}
        >
          <Button
            disabled={
              this.props.disabled ||
              Array.from((this.props.httpItems || new Map()).values()).every(
                (x) => x === 0
              )
            }
            className="tool-btn"
            size="small"
          >
            <Space>
              <GlobalOutlined />
              域名配置
            </Space>
          </Button>
        </Popover>

        <Modal
          closable={false}
          width="70%"
          destroyOnClose
          visible={this.state.visible}
          title={
            <PageHeader
              title="域名转发规则配置"
              subTitle="(域名需要到云或CDN产商配置回源解析)"
              footer={
                <span>
                  <span style={{ color: "red" }}>注意</span>
                  :HTTP和HTTPS需要分开配置
                </span>
              }
              extra={
                <Button
                  size="large"
                  className="tool-btn"
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  高危页面,谨慎操作
                </Button>
              }
            />
          }
          footer={[
            <Button
              key="cancel-adjust-ingress"
              type="primary"
              ghost
              className="tool-btn"
              onClick={() => {
                this.setState({
                  adjusted: false,
                  visible: false,
                  which: "http",
                  items: new Map(),
                });
                if (this.httpForm.current) this.httpForm.current.resetFields();
                if (this.httpsForm.current)
                  this.httpsForm.current.resetFields();
              }}
            >
              取消
            </Button>,

            this.state.adjusted ? (
              <Popconfirm
                key="submit-adjust-ingress"
                title={"确定要修改" + this.state.which.toUpperCase() + "配置?"}
                placement="top"
                okText="确定"
                cancelText="取消"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                trigger="click"
                cancelButtonProps={{
                  className: "tool-btn",
                  ghost: true,
                  size: "small",
                  type: "primary",
                }}
                okButtonProps={{
                  className: "tool-btn",
                  ghost: true,
                  size: "small",
                  danger: true,
                }}
                onConfirm={async () => {
                  let current: FormInstance<any> | null = null;
                  if (this.state.which === "http") {
                    current = this.httpForm.current;
                  } else if (this.state.which === "https") {
                    current = this.httpsForm.current;
                  }
                  if (!current) {
                    notification.error({ message: "系统错误" });
                    return;
                  }

                  try {
                    const fields = await current.validateFields();
                    this.fetchAdjustIngress(fields);
                  } catch {}
                }}
              >
                <Button
                  disabled={this.props.forbitEdit || !this.state.allowEdit || !this.state.allowEdit}
                  danger
                  ghost
                  className="tool-btn"
                >
                  提交
                </Button>
              </Popconfirm>
            ) : null,
          ]}
        >
          <Tabs
            size="small"
            activeKey={this.state.clusterName}
            onChange={(clusterName) => {
              let editConfig: IIngressConfig = this.defaultIngressConfig();
              const item = this.state.items.get(clusterName);
              if (item && item.errCode === 0) {
                if (this.state.which === "http") editConfig = item.httpConfig;
                else if (this.state.which === "https")
                  editConfig = item.httpsConfig;
              }
              this.setState({ clusterName, adjusted: false, editConfig }, () =>
                this.resetFormInitialValues()
              );
            }}
            tabPosition="top"
            type="card"
          >
            {Array.from(this.state.items).map((values) => (
              <TabPane key={values[0]} tab={values[0]}>
                {values[1].errCode !== 0 && (
                  <Result
                    status="error"
                    title="无法配置规则"
                    subTitle={
                      "errCode:" +
                      values[1].errCode +
                      " errMsg:" +
                      values[1].errMsg
                    }
                  />
                )}

                {values[1].errCode === 0 && (
                  <Tabs
                    size="small"
                    activeKey={this.state.which}
                    onChange={(which) => {
                      let editConfig = this.defaultIngressConfig();
                      const item = this.state.items.get(this.state.clusterName);
                      if (item) {
                        if (which === "http")
                          editConfig = { ...item.httpConfig };
                        else if (which === "https")
                          editConfig = { ...item.httpsConfig };
                      }
                      if (editConfig.ingressName.length === 0) {
                        editConfig.ingressName = "<未配置>";
                        editConfig.kind = "<未配置>";
                      }
                      this.setState({ which, editConfig }, () =>
                        this.resetFormInitialValues()
                      );
                    }}
                    tabPosition="left"
                  >
                    <TabPane key="http" tab="http规则">
                      <Form
                        name="httpForm"
                        ref={this.httpForm}
                        initialValues={initialValues}
                        labelCol={{ span: 8 }}
                        validateTrigger="onBlur"
                        onValuesChange={(changedValues, allValues) => {
                          let editConfig = { ...this.state.editConfig };
                          if (typeof changedValues.cors === "boolean") {
                            editConfig.cors = changedValues.cors ? 1 : 0;
                          } else if (
                            typeof changedValues.priority === "number"
                          ) {
                            const item = this.state.items.get(
                              this.state.clusterName
                            );
                            let conflictResourceName = "";
                            if (
                              item &&
                              item.ingressPriorities.some((x) => {
                                if (x.priority === changedValues.priority) {
                                  conflictResourceName = x.resourceName;
                                  return true;
                                }
                                return false;
                              })
                            ) {
                              message.error(
                                `优先级已冲突,冲突资源名:${conflictResourceName}`
                              );
                              return;
                            }
                          }
                          this.setState({ editConfig, adjusted: true });
                        }}
                      >
                        <FormItem label="资源名">
                          {" "}
                          {this.state.editConfig.ingressName}{" "}
                        </FormItem>

                        {this.state.editConfig.ingressClassName.length > 0 && (
                          <FormItem label="className">
                            {this.state.editConfig.ingressClassName}
                            <FormItem name="ingressClassName" hidden>
                              <Input />
                            </FormItem>
                          </FormItem>
                        )}

                        {this.state.editConfig.ingressClassName.length ===
                          0 && (
                          <FormItem
                            hasFeedback
                            label="className"
                            name="ingressClassName"
                            rules={[
                              { required: true, message: "必须选ingressClass" },
                            ]}
                          >
                            {
                              <Select
                                disabled={this.props.forbitEdit || !this.state.allowEdit || !this.state.allowEdit}
                                size="small"
                                style={{ width: "50%" }}
                                dropdownMatchSelectWidth={false}
                              >
                                {values[1].ingressClasses.map((x, index) => (
                                  <SelectOption key={index} value={x.name}>
                                    <span>
                                      <Tag color="processing">{x.name}</Tag>{" "}
                                      <Tag color="success">{x.description}</Tag>
                                    </span>
                                  </SelectOption>
                                ))}
                              </Select>
                            }
                          </FormItem>
                        )}

                        <FormItem label="网络类型">
                          {" "}
                          {this.state.editConfig.kind}{" "}
                        </FormItem>

                        <FormItem label="源站地址" tooltip="回源地址">
                          {this.state.editConfig.hostnamesOrIPs.map(
                            (x, index) => (
                              <Tag key={index}>{x}</Tag>
                            )
                          )}
                        </FormItem>

                        <FormItem
                          hasFeedback
                          name="priority"
                          label="优先级"
                          tooltip="值越小优先级越高"
                        >
                          <InputNumber
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            min={0}
                            max={1000}
                            size="small"
                          />
                        </FormItem>

                        <FormItem
                          hasFeedback
                          name="trafficLimitQPS"
                          label="QPS限速"
                          tooltip={
                            <span>
                              <p>触发限速后,将返回503</p>
                              <p>生产环境慎用!!</p>
                            </span>
                          }
                        >
                          <InputNumber
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            size="small"
                          />
                        </FormItem>

                        <FormItem
                          hasFeedback
                          name="sslRedirect"
                          valuePropName="checked"
                          label="HTTPS重定向"
                          tooltip="HTTP重定向HTTPS"
                        >
                          <Switch
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            size="small"
                          />
                        </FormItem>

                        <FormItem
                          hasFeedback
                          name="keepAlive"
                          valuePropName="checked"
                          label="长连接"
                          tooltip={
                            <span>
                              <p>Connection: Keep-Alive</p>{" "}
                              <p>(建议开启,减小服务器开销)</p>
                            </span>
                          }
                        >
                          <Switch
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            size="small"
                          />
                        </FormItem>

                        <FormItem
                          hasFeedback
                          name="cors"
                          valuePropName="checked"
                          label="允许跨域"
                        >
                          <Switch
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            size="small"
                          />
                        </FormItem>

                        {this.state.editConfig.cors === 1 && (
                          <>
                            <FormItem
                              hasFeedback
                              name="corsHeaders"
                              label="CORS-Allow-Headers"
                              tooltip={
                                <span>
                                  <p>Access-Control-Allow-Headers</p>
                                  <p>
                                    默认值:DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Authorization
                                  </p>
                                </span>
                              }
                            >
                              <Input
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                size="small"
                                style={{ width: "50%" }}
                              />
                            </FormItem>

                            <FormItem
                              hasFeedback
                              name="corsExposeHeaders"
                              label="CORS-Expose-Headers"
                              tooltip={
                                <span>
                                  <p>Access-Control-Expose-Headers</p>
                                  <p>默认值:empty</p>
                                </span>
                              }
                            >
                              <Input
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                size="small"
                                style={{ width: "50%" }}
                              />
                            </FormItem>

                            <FormItem
                              hasFeedback
                              name="corsMethods"
                              label="CORS-Allow-Methods"
                              tooltip={
                                <span>
                                  <p>Access-Control-Allow-Methods</p>
                                  <p>
                                    默认值:GET, PUT, POST, DELETE, PATCH,
                                    OPTIONS
                                  </p>
                                </span>
                              }
                            >
                              <Input
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                size="small"
                                style={{ width: "50%" }}
                              />
                            </FormItem>

                            <FormItem
                              hasFeedback
                              name="corsCredentials"
                              valuePropName="checked"
                              label="CORS-Allow-Credentials"
                              tooltip="Access-Control-Allow-Credentials 默认值:false"
                            >
                              <Switch
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                size="small"
                              />
                            </FormItem>

                            <FormItem
                              hasFeedback
                              name="corsMaxAge"
                              label="CORS-Max-Age"
                              tooltip={
                                <span style={{ width: 600 }}>
                                  <p>Access-Control-Max-Age</p>
                                  <p>默认值:172800 取值范围[-1, 172800]</p>
                                </span>
                              }
                            >
                              <InputNumber
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                size="small"
                                min={-1}
                                max={172800}
                                style={{ width: "50%" }}
                              />
                            </FormItem>
                          </>
                        )}

                        <FormItem hasFeedback name="rewrite" label="路径重写">
                          <Input
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            size="small"
                            style={{ width: "50%" }}
                            placeholder={`/path/\${2}`}
                          />
                        </FormItem>

                        <FormList
                          name="rules"
                          initialValue={this.state.editConfig.rules}
                          children={(fields) => (
                            <List
                              grid={{
                                gutter: 12,
                                xs: 1,
                                sm: 2,
                                md: 4,
                                lg: 4,
                                xl: 6,
                                xxl: 3,
                                column: 3,
                              }}
                              header={
                                <span style={{ textAlign: "center" }}>
                                  转发规则
                                </span>
                              }
                              dataSource={fields}
                              renderItem={(item, index) => (
                                <ListItem key={index}>
                                  <Card
                                    bordered
                                    title={
                                      "规则:(" + (index + 1).toString() + ")"
                                    }
                                    extra={
                                      <Popconfirm
                                        title={
                                          "确定要删除规则(" +
                                          (index + 1).toString() +
                                          ")?"
                                        }
                                        placement="left"
                                        trigger="click"
                                        okText="删除"
                                        cancelText="取消"
                                        okButtonProps={{
                                          danger: true,
                                          className: "tool-btn",
                                        }}
                                        cancelButtonProps={{
                                          className: "tool-btn",
                                        }}
                                        onConfirm={() => {
                                          let editConfig = {
                                            ...this.state.editConfig,
                                          };
                                          editConfig.rules.splice(index, 1);
                                          const rules = editConfig.rules;
                                          if (this.httpForm.current)
                                            this.httpForm.current.setFieldsValue(
                                              { rules }
                                            );
                                          this.setState({
                                            editConfig,
                                            adjusted: true,
                                          });
                                        }}
                                      >
                                        <Button
                                          type="link"
                                          danger
                                          size="small"
                                          disabled={this.props.forbitEdit || !this.state.allowEdit}
                                          className="tool-btn"
                                        >
                                          删除
                                        </Button>
                                      </Popconfirm>
                                    }
                                  >
                                    <FormItem label="域名">
                                      <FormItem
                                        name={[item.name, "host"]}
                                        noStyle
                                        rules={[
                                          {
                                            required: true,
                                            validator: validateRules.bind(
                                              this,
                                              index,
                                              "host"
                                            ),
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={this.props.forbitEdit || !this.state.allowEdit}
                                          size="small"
                                          placeholder="eg: kingo.cloudview.me"
                                          onChange={(e) => {
                                            this.setRuleValue(
                                              index,
                                              e.target.value,
                                              "host"
                                            );
                                          }}
                                        />
                                      </FormItem>
                                    </FormItem>

                                    <FormItem label="路径">
                                      <FormItem
                                        name={[item.name, "path"]}
                                        noStyle
                                        rules={[
                                          {
                                            required: true,
                                            validator: validateRules.bind(
                                              this,
                                              index,
                                              "path"
                                            ),
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={this.props.forbitEdit || !this.state.allowEdit}
                                          size="small"
                                          placeholder="eg: /test"
                                          onChange={(e) => {
                                            this.setRuleValue(
                                              index,
                                              e.target.value,
                                              "path"
                                            );
                                          }}
                                        />
                                      </FormItem>
                                    </FormItem>

                                    <FormItem label="匹配规则">
                                      <FormItem
                                        name={[item.name, "pattern"]}
                                        noStyle
                                        rules={[{ required: true }]}
                                        tooltip={
                                          <span>
                                            <p>Exact:完全匹配</p>{" "}
                                            <p>Prefix:前缀匹配</p>
                                          </span>
                                        }
                                      >
                                        <Select
                                          disabled={this.props.forbitEdit || !this.state.allowEdit}
                                          size="small"
                                          onChange={(e) => {
                                            this.setRuleValue(
                                              index,
                                              e,
                                              "pattern"
                                            );
                                          }}
                                        >
                                          <SelectOption
                                            key="Exact"
                                            value="Exact"
                                          >
                                            Exact
                                          </SelectOption>
                                          <SelectOption
                                            key="Prefix"
                                            value="Prefix"
                                          >
                                            Prefix
                                          </SelectOption>
                                        </Select>
                                      </FormItem>
                                    </FormItem>
                                  </Card>
                                </ListItem>
                              )}
                            />
                          )}
                        />

                        <FormItem>
                          <Button
                            type="dashed"
                            size="small"
                            className="tool-btn"
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            onClick={() => {
                              let editConfig = { ...this.state.editConfig };
                              let rules = editConfig.rules;
                              rules.push({
                                host: "",
                                path: "/",
                                pattern: "Prefix",
                                targetPort: 0,
                              });
                              this.setState({ editConfig, adjusted: true });
                              if (this.httpForm.current)
                                this.httpForm.current.setFieldsValue({ rules });
                            }}
                          >
                            <PlusCircleOutlined /> 新增规则
                          </Button>
                        </FormItem>
                      </Form>
                    </TabPane>

                    <TabPane key="https" tab="https规则">
                      <Form
                        name="httpsForm"
                        ref={this.httpsForm}
                        labelCol={{ span: 8 }}
                        validateTrigger="onBlur"
                        initialValues={initialValues}
                        onValuesChange={(changedValues, allValues) => {
                          let editConfig = { ...this.state.editConfig };
                          if (typeof changedValues.cors === "boolean") {
                            editConfig.cors = changedValues.cors ? 1 : 0;
                          } else if (
                            typeof changedValues.priority === "number"
                          ) {
                            const item = this.state.items.get(
                              this.state.clusterName
                            );
                            let conflictResourceName = "";
                            if (
                              item &&
                              item.ingressPriorities.some((x) => {
                                if (x.priority === changedValues.priority) {
                                  conflictResourceName = x.resourceName;
                                  return true;
                                }
                                return false;
                              })
                            ) {
                              message.error(
                                `优先级已冲突,冲突资源名:${conflictResourceName}`
                              );
                              return;
                            }
                          }
                          this.setState({ editConfig, adjusted: true });
                        }}
                      >
                        <FormItem label="资源名">
                          {" "}
                          {this.state.editConfig.ingressName}{" "}
                        </FormItem>

                        {this.state.editConfig.ingressClassName.length > 0 && (
                          <FormItem label="className">
                            {this.state.editConfig.ingressClassName}
                            <FormItem name="ingressClassName" hidden>
                              <Input disabled={this.props.forbitEdit || !this.state.allowEdit} />
                            </FormItem>
                          </FormItem>
                        )}

                        {this.state.editConfig.ingressClassName.length ===
                          0 && (
                          <FormItem
                            hasFeedback
                            label="className"
                            name="ingressClassName"
                            rules={[
                              { required: true, message: "必须选ingressClass" },
                            ]}
                          >
                            {
                              <Select
                                size="small"
                                style={{ width: "50%" }}
                                dropdownMatchSelectWidth={false}
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                              >
                                {values[1].ingressClasses.map((x, index) => (
                                  <SelectOption key={index} value={x.name}>
                                    <span>
                                      <Tag color="processing">{x.name}</Tag>{" "}
                                      <Tag color="success">{x.description}</Tag>
                                    </span>
                                  </SelectOption>
                                ))}
                              </Select>
                            }
                          </FormItem>
                        )}

                        <FormItem label="网络类型">
                          {this.state.editConfig.kind}
                        </FormItem>

                        <FormItem label="源站地址" tooltip="回源地址">
                          {this.state.editConfig.hostnamesOrIPs.map(
                            (x, index) => (
                              <Tag key={index}>{x}</Tag>
                            )
                          )}
                        </FormItem>

                        <FormItem label="HTTPS域名">
                          {this.state.editConfig.tlsHost.map((x, index) => (
                            <Tag key={index}>{x}</Tag>
                          ))}
                        </FormItem>

                        <FormItem
                          hasFeedback
                          name="priority"
                          label="优先级"
                          tooltip="值越小优先级越高"
                        >
                          <InputNumber
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            min={0}
                            max={1000}
                            size="small"
                          />
                        </FormItem>

                        <FormItem
                          hasFeedback
                          name="trafficLimitQPS"
                          label="QPS限速"
                          tooltip={
                            <span>
                              <p>触发限速后,将返回503</p>
                              <p>生产环境慎用!!</p>
                            </span>
                          }
                        >
                          <InputNumber
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            size="small"
                          />
                        </FormItem>

                        <FormItem
                          hasFeedback
                          name="keepAlive"
                          valuePropName="checked"
                          label="长连接"
                          tooltip={
                            <span>
                              <p>Connection: Keep-Alive</p>{" "}
                              <p>(建议开启,减小服务器开销)</p>
                            </span>
                          }
                        >
                          <Switch
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            size="small"
                          />
                        </FormItem>

                        <FormItem
                          hasFeedback
                          name="cors"
                          valuePropName="checked"
                          label="允许跨域"
                        >
                          <Switch
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            size="small"
                          />
                        </FormItem>

                        {this.state.editConfig.cors === 1 && (
                          <>
                            <FormItem
                              hasFeedback
                              name="corsHeaders"
                              label="CORS-Allow-Headers"
                              tooltip={
                                <span>
                                  <p>Access-Control-Allow-Headers</p>
                                  <p>
                                    默认值:DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Authorization
                                  </p>
                                </span>
                              }
                            >
                              <Input
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                size="small"
                                style={{ width: "50%" }}
                              />
                            </FormItem>

                            <FormItem
                              hasFeedback
                              name="corsExposeHeaders"
                              label="CORS-Expose-Headers"
                              tooltip={
                                <span>
                                  <p>Access-Control-Expose-Headers</p>
                                  <p>默认值:empty</p>
                                </span>
                              }
                            >
                              <Input
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                size="small"
                                style={{ width: "50%" }}
                              />
                            </FormItem>

                            <FormItem
                              hasFeedback
                              name="corsMethods"
                              label="CORS-Allow-Methods"
                              tooltip={
                                <span>
                                  <p>Access-Control-Allow-Methods</p>
                                  <p>
                                    默认值:GET, PUT, POST, DELETE, PATCH,
                                    OPTIONS
                                  </p>
                                </span>
                              }
                            >
                              <Input
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                size="small"
                                style={{ width: "50%" }}
                              />
                            </FormItem>

                            <FormItem
                              hasFeedback
                              name="corsCredentials"
                              valuePropName="checked"
                              label="CORS-Allow-Credentials"
                              tooltip="Access-Control-Allow-Credentials 默认值:false"
                            >
                              <Switch
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                size="small"
                              />
                            </FormItem>

                            <FormItem
                              hasFeedback
                              name="corsMaxAge"
                              label="CORS-Max-Age"
                              tooltip={
                                <span>
                                  <p>Access-Control-Max-Age</p>
                                  <p>默认值:172800 取值范围[-1, 172800]</p>
                                </span>
                              }
                            >
                              <InputNumber
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                size="small"
                                min={-1}
                                max={172800}
                                style={{ width: "50%" }}
                              />
                            </FormItem>
                          </>
                        )}

                        <FormItem hasFeedback name="rewrite" label="路径重写">
                          <Input
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            size="small"
                            style={{ width: "50%" }}
                            placeholder={`/path/\${2}`}
                          />
                        </FormItem>

                        <FormList
                          name="rules"
                          initialValue={this.state.editConfig.rules}
                          children={(fields) => (
                            <List
                              grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 2,
                                md: 4,
                                lg: 4,
                                xl: 6,
                                xxl: 3,
                                column: 3,
                              }}
                              header={
                                <span
                                  style={{
                                    textAlign: "center",
                                    marginBottom: 10,
                                  }}
                                >
                                  转发规则
                                </span>
                              }
                              dataSource={fields}
                              renderItem={(item, index) => (
                                <ListItem key={index}>
                                  <Card
                                    size="small"
                                    bordered
                                    title={
                                      "规则:(" + (index + 1).toString() + ")"
                                    }
                                    extra={
                                      <Popconfirm
                                        title={
                                          "确定要删除规则(" +
                                          (index + 1).toString() +
                                          ")?"
                                        }
                                        placement="left"
                                        trigger="click"
                                        okText="确定"
                                        okButtonProps={{
                                          danger: true,
                                          className: "tool-btn",
                                        }}
                                        cancelText="取消"
                                        onConfirm={() => {
                                          let editConfig = {
                                            ...this.state.editConfig,
                                          };
                                          editConfig.rules.splice(index, 1);
                                          const rules = editConfig.rules;
                                          if (this.httpsForm.current)
                                            this.httpsForm.current.setFieldsValue(
                                              { rules }
                                            );
                                          this.setState({
                                            editConfig,
                                            adjusted: true,
                                          });
                                        }}
                                      >
                                        <Button
                                          disabled={this.props.forbitEdit || !this.state.allowEdit}
                                          type="link"
                                          danger
                                          size="small"
                                          className="tool-btn"
                                        >
                                          删除
                                        </Button>
                                      </Popconfirm>
                                    }
                                  >
                                    <FormItem label="域名">
                                      <FormItem
                                        name={[item.name, "host"]}
                                        noStyle
                                        rules={[
                                          {
                                            required: true,
                                            validator: validateRules.bind(
                                              this,
                                              index,
                                              "host"
                                            ),
                                          },
                                        ]}
                                      >
                                        <Input
                                          disabled={this.props.forbitEdit || !this.state.allowEdit}
                                          size="small"
                                          placeholder="eg: kingo.cloudview.me"
                                          onChange={(e) => {
                                            this.setRuleValue(
                                              index,
                                              e.target.value,
                                              "host"
                                            );
                                          }}
                                        />
                                      </FormItem>
                                    </FormItem>

                                    <FormItem label="路径">
                                      <FormItem
                                        name={[item.name, "path"]}
                                        noStyle
                                        rules={[
                                          {
                                            required: true,
                                            validator: validateRules.bind(
                                              this,
                                              index,
                                              "path"
                                            ),
                                          },
                                        ]}
                                      >
                                        <Input
                                          size="small"
                                          placeholder="eg: /test"
                                          disabled={this.props.forbitEdit || !this.state.allowEdit}
                                          onChange={(e) => {
                                            this.setRuleValue(
                                              index,
                                              e.target.value,
                                              "path"
                                            );
                                          }}
                                        />
                                      </FormItem>
                                    </FormItem>

                                    <FormItem label="匹配规则">
                                      <FormItem
                                        name={[item.name, "pattern"]}
                                        noStyle
                                        rules={[{ required: true }]}
                                        tooltip={
                                          <span>
                                            <p>Exact:完全匹配</p>{" "}
                                            <p>Prefix:前缀匹配</p>
                                          </span>
                                        }
                                      >
                                        <Select
                                          disabled={this.props.forbitEdit || !this.state.allowEdit}
                                          size="small"
                                          onChange={(e) => {
                                            this.setRuleValue(
                                              index,
                                              e,
                                              "pattern"
                                            );
                                          }}
                                        >
                                          <SelectOption
                                            key="Exact"
                                            value="Exact"
                                          >
                                            Exact
                                          </SelectOption>
                                          <SelectOption
                                            key="Prefix"
                                            value="Prefix"
                                          >
                                            Prefix
                                          </SelectOption>
                                        </Select>
                                      </FormItem>
                                    </FormItem>
                                  </Card>
                                </ListItem>
                              )}
                            />
                          )}
                        />

                        <FormItem>
                          <Button
                            disabled={this.props.forbitEdit || !this.state.allowEdit}
                            type="dashed"
                            size="small"
                            className="tool-btn"
                            onClick={() => {
                              let editConfig = { ...this.state.editConfig };
                              let rules = editConfig.rules;
                              rules.push({
                                host: "",
                                path: "/",
                                pattern: "Prefix",
                                targetPort: 0,
                              });
                              this.setState({ editConfig, adjusted: true });
                              if (this.httpsForm.current)
                                this.httpsForm.current.setFieldsValue({
                                  rules,
                                });
                            }}
                          >
                            <PlusCircleOutlined /> 新增规则
                          </Button>
                        </FormItem>
                      </Form>
                    </TabPane>
                  </Tabs>
                )}
              </TabPane>
            ))}
          </Tabs>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  httpItems: state.tarsReducer.httpItems,
});

export default connect(mapStateToProps, null)(Ingress);
