
import React from 'react';
import { Button, Modal, Popconfirm } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';

import * as fetch from 'src/fetch';

interface IProps {
    currentUser?: string;
    info: any;
    clusterName: string;
    tarsServerName: string;
    tarsApplication: string;
    tarsSetName: string;
    onOK: () => void;
}

export default class DeleteMachineButton extends React.Component<IProps> {
    state = {
        visible: false,
    }
    fetchDeleteMachine() {
        console.log(this.props.info);
        fetch
            .offlineNode({
                userName: this.props.currentUser || '',
                tarsApplication: this.props.tarsApplication,
                tarsSetName: this.props.tarsSetName,
                tarsServerName: this.props.tarsServerName,
                clusterName: this.props.clusterName,
                nodePool: this.props.info.nodePool,
                instanceID: this.props.info.instanceID,
                ip: this.props.info.ip,
            })
            .then(() => {
                this.setState({ visible: false });

                this.props.onOK();
            })
            .catch(_ => {

            });
    }

    render() {
        return (
            <>
                <Button size='small' className='tool-btn' danger onClick={() => this.setState(
                    { visible: true }
                )}>
                    <DeleteTwoTone twoToneColor='#fff' />下线机器
                </Button>
                <Modal
                    title='确认下线容器所属机器？'
                    okText='下线机器'
                    okType='danger'
                    visible={this.state.visible}
                    okButtonProps={{ className: 'tool-btn', danger: true, ghost: false }}
                    cancelButtonProps={{ className: 'tool-btn', type: 'primary', ghost: true }}
                    onCancel={() => this.setState({ visible: false })}
                    onOk={() => this.fetchDeleteMachine()}
                >
                    <div>
                        <div>下线机器会驱逐当前宿主机的全部容器，触发容器重新调度</div>
                        <div style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "20px"
                        }}>非机器跑飞，宿主机异常等特殊情况，不要执行此操作!!!</div>
                    </div>
                </Modal>

            </>



        );
    }
}
