import React from "react";
import { Button, Result, Space, Modal } from "antd";
import { PlayCircleFilled } from "@ant-design/icons";
import * as ActionType from "src/stores/types";

interface IProps {
  disabled: boolean;
  jobIDs?: Array<ActionType.IJobItem>;
}

interface IState {
  visible: boolean;
}

export default class SimpleCodingBuild extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false };
  }

  render() {
    return (
      <>
        <Button
          size="small"
          disabled={this.props.disabled}
          className="tool-btn"
          // style={
          //   !this.props.disabled
          //     ? { backgroundColor: "black", color: "white" }
          //     : {}
          // }
          onClick={() => {
            if (this.props.jobIDs && this.props.jobIDs.length !== 0) {
              const jobID = this.props.jobIDs[0].jobID;
              const projectName = this.props.jobIDs[0].projectName;
              window.open(
                `https://verizontal.coding.net/p/${projectName}/ci/job?id=${jobID}`
              );
            } else {
              this.setState({ visible: true });
            }
          }}
        >
          <Space>
            <PlayCircleFilled />
            立即构建
          </Space>
        </Button>

        <Modal
          width="50vw"
          visible={this.state.visible}
          title={null}
          footer={null}
          onCancel={() => this.setState({ visible: false })}
        >
          <Result
            status="500"
            title="无法构建"
            subTitle="项目未设置Coding关联,先到【发布】页面进行Coding关联"
          />
        </Modal>
      </>
    );
  }
}
