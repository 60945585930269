/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
// 渲染查询之后渲染table和header的情况

import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Select,
  DatePicker,
  Row,
  Col,
  Button,
  Form,
  Input,
  Spin,
  Modal,
} from "antd";
import type { PaginationProps } from "antd";
import MomentFormat from "src/utils/MomentFormat";
import { formatValueToOptions } from "./until";

import "./index.less";
interface IProps {
  disabled?: boolean;
  onEdit: (record: any) => void;
  onAdd: (record: any) => void;
  onSearch: (params: any) => void;
  onDel: (record: any) => void;
  renderConfig: Record<string, string | any>;
  enumMap?: Record<string, any>;
  loading: boolean;
  dataSource: Record<string, any>;
}

enum InputType {
  SELECT = "select",
  DATE = "date",
  INPUT = "text",
}
enum OperateEnum {
  ADD = "add",
  EDIT = "edit",
  DEL = "remove",
  SWITCH = "switch",
}
const { RangePicker } = DatePicker;

export default function tableRender(props: IProps) {
  const [form] = Form.useForm();
  const {
    onEdit,
    onAdd,
    onSearch,
    renderConfig,
    enumMap = {},
    loading,
    dataSource,
    onDel,
  } = props;
  const { inputConfig = [] } = renderConfig;
  const [current, setCurrent] = useState(1);
  const [isSetValue, setIsSetValue] = useState(false);

  useEffect(() => {
    setIsSetValue(false);
  }, [renderConfig]);
  const InputField = (
    type: InputType,
    field: string,
    optionItems?: any[],
    allowClear?: boolean
  ) => {
    switch (type) {
      case InputType.SELECT:
        const options =
          optionItems && optionItems.length
            ? optionItems
            : formatValueToOptions(enumMap[field] || []);
        return (
          <Select
            style={{
              width: 180,
            }}
            dropdownMatchSelectWidth={false}
            optionFilterProp="children"
            filterOption={(input, option) => {
              console.log(option, input, "option?.label, input");
              return ((option?.children ?? "") as string)
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            showSearch
            allowClear={allowClear}
          >
            {options.map((item: any) => {
              return (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              );
            })}
          </Select>
        );
      case InputType.DATE:
        return (
          <RangePicker
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
            allowClear={allowClear}
          />
        );
      case InputType.INPUT:
        return <Input allowClear={allowClear} />;
      default:
        return <Input />;
    }
  };

  const buildSearch = (values: any, page: number) => {
    const { baseConfig = {} } = renderConfig?.outputConfig?.config || {};
    const inputConfig = renderConfig?.inputConfig || [];
    const { paginActionSize } = baseConfig;

    const inputFilterMap = inputConfig.reduce((acc: any, item: any) => {
      acc[item.field] = item;
      return acc;
    }, {});

    const filterCond = Object.keys(values)
      .filter((item) => {
        return (
          values[item] !== undefined &&
          values[item] !== null &&
          values[item] !== ""
        );
      })
      .map((item) => {
        const { operation, type } = inputFilterMap[item];

        if (type === InputType.DATE) {
          return {
            name: item,
            operation: operation,
            value: values[item]
              .map((date: any) => new Date(date).getTime())
              .join(","),
          };
        }

        return {
          name: item,
          operation: operation,
          value: values[item],
        };
      });
    return { filterCond, paginActionSize, page };
  };
  const searchSubmit = () => {
    const values = form.getFieldsValue();
    // 构建查询数据，调用查询数据的方法

    onSearch(buildSearch(values, current));
  };

  const toSureDel = (record: any) => {
    // 确定是否删除
    Modal.confirm({
      title: "注意⚠️",
      content: "确定删除这条配置吗？",
      onOk: () => {
        onDel(record);
      },
    });
  };

  const renderTableFun = useCallback(() => {
    const { baseConfig = {}, columnList = [] } =
      renderConfig?.outputConfig?.config || {};
    const { operateTypes = [], paginActionSize = 10 } = baseConfig;
    const hasAdd = operateTypes.includes(OperateEnum.ADD);
    const hasEdit = operateTypes.includes(OperateEnum.EDIT);
    const hasDel = operateTypes.includes(OperateEnum.DEL);
    const columns = columnList
      .map((item: any) => {
        const { field, fieldDisplayName, hide, editType, options, type } = item;
        return {
          title: fieldDisplayName,
          dataIndex: field,
          key: field,
          hide,
          width: 200,
          render: (text: any, record: any) => {
            if (editType === "select") {
              const optionMap = options.reduce((acc: any, item: any) => {
                acc[item.value] = item;
                return acc;
              }, {});

              const split1Arr = text?.split(";") || [];
              const split2Arr = text?.split(",") || [];
              const split3Arr = text?.split("/") || [];

              // 返回长度最大的数组
              const textArr = [split1Arr, split2Arr, split3Arr].sort((a, b) => {
                return b.length - a.length;
              })[0];

              return (
                textArr
                  ?.filter(
                    (item: any) => item !== ";" && item !== "," && item !== ""
                  )
                  ?.map((item: any) => {
                    return optionMap[item]?.label;
                  })
                  ?.join(";") || text
              );
            }
            if (type === "timeStamp") {
              return MomentFormat(text);
            }
            return (
              <p
                style={{
                  wordBreak: "break-all",
                  wordWrap: "break-word",
                }}
              >
                {text || "-"}
              </p>
            );
          },
        };
      })
      .filter((item: any) => !item.hide);
    if ((hasEdit || hasDel) && !props.disabled) {
      columns.push({
        title: "操作",
        dataIndex: "operate",
        key: "operate",
        fixed: "right",
        width: 130,
        render: (text: any, record: any) => {
          return (
            <div>
              {hasEdit ? (
                <a
                  onClick={() => {
                    onEdit(record);
                  }}
                  className="btn_edit  fun_btn"
                >
                  编辑
                </a>
              ) : null}
              {hasDel ? (
                <a
                  onClick={() => {
                    toSureDel(record);
                  }}
                  className="btn_del fun_btn"
                >
                  删除
                </a>
              ) : null}
            </div>
          );
        },
      });
    }

    const total = dataSource?.count || 20;
    // 双层数组
    const dataSourceList = (dataSource?.data || []).map((row: any) => {
      return row.reduce((pre: { [x: string]: any }, item: any) => {
        pre[item.field] = item.formatValue || item.value;
        return pre;
      }, {});
    });

    const onChange: PaginationProps["onChange"] = (page, paginActionSize) => {
      setCurrent(page);

      //   TODO:查询条件处理
      onSearch(buildSearch(form.getFieldsValue(), page));
    };

    return (
      <div
        className={inputConfig?.length ? "table_box top_border" : "table_box"}
      >
        {hasAdd && !props.disabled ? (
          <Row className="tableFn">
            <Col span={24}>
              <Button type="primary" onClick={onAdd}>
                新增
              </Button>
            </Col>
          </Row>
        ) : null}
        <div className="tars_table">
          <Table
            dataSource={dataSourceList}
            columns={columns}
            scroll={{
              x: columns.length * 200,
            }}
            pagination={{
              showTotal: (total) => `总共 ${total} 条`,
              current: current,
              size: "small",
              showSizeChanger: false,
              pageSize: paginActionSize,
              onChange: (page) => onChange(page, paginActionSize),
              total: total,
            }}
          />
        </div>
      </div>
    );
  }, [renderConfig, dataSource, current]);

  const renderSearchField = useCallback(() => {
    if (!isSetValue) {
      const inputConfig = renderConfig?.inputConfig || [];
      const inputFilterMap = inputConfig.reduce((acc: any, item: any) => {
        if (item.type === InputType.SELECT) {
          // 如果是下拉框，取第一个值，如果有默认值，取默认值
          const defaultOptionItem = item.optionItems.find(
            (item: any) => item.defaultOption
          );
          // ||item.optionItems[0];
          acc[item.field] = defaultOptionItem?.value;
        }
        return acc;
      }, {});
      if (renderConfig.baseConfig) {
        form.setFieldsValue(inputFilterMap || {});
        onSearch(buildSearch(inputFilterMap, current));
        setIsSetValue(true);
      }
    }
    const inputConfig = renderConfig?.inputConfig || [];
    return (
      <div>
        {" "}
        {inputConfig?.length ? (
          <Row className="table_common_search">
            <Col span={20}>
              <Form form={form} layout="inline">
                {inputConfig?.map((item: any) => {
                  const {
                    type,
                    field,
                    displayName,
                    optionItems = [],
                    allowClear = false,
                  } = item;
                  return (
                    <Form.Item
                      className="search_item"
                      name={field}
                      label={displayName}
                    >
                      {InputField(type, field, optionItems, allowClear)}
                    </Form.Item>
                  );
                })}
              </Form>
            </Col>
            <Col span={4} className="search">
              <Button type="primary" onClick={searchSubmit}>
                查询
              </Button>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }, [renderConfig, isSetValue, current, enumMap]);

  return (
    <div className="common_table">
      <Spin spinning={loading}>
        {renderSearchField()}
        {renderTableFun()}
      </Spin>
    </div>
  );
}
