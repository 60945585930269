import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import { connect } from "react-redux";
import CommonReporter from "./../../../components/CommonReporter";
interface IProps {
  currentUser?: string;
}
const GlobalAlarmHistory = forwardRef(
  (props: IProps, ref: React.LegacyRef<HTMLDivElement> | undefined) => {
    const fetchData = () => {
      console.log("fetchData");
      // 触发这个组件的focusUpdate
    };

    useEffect(() => {
      fetchData();
    }, []);
    useImperativeHandle(ref as React.Ref<unknown> | undefined, () => ({
      fetchData,
    }));
    return (
      <div ref={ref}>
        <CommonReporter
          tableId="db_resource_{cluster_name}.t_alarm_history.global"
          userName={props.currentUser}
        />
      </div>
    );
  }
);

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  admin: state.tarsReducer.admin,
});

export default connect(mapStateToProps, null)(GlobalAlarmHistory);

// export default GlobalAlarmHistory;
