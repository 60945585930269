import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import MomentFormat from "src/utils/MomentFormat";
import { isEmpty } from "lodash";
import {
  Tag,
  Button,
  Checkbox,
  Drawer,
  Modal,
  Form,
  FormInstance,
  Input,
  Tabs,
  List,
  Divider,
  Row,
  Col,
  Select,
  Result,
  Popconfirm,
  Space,
  Card,
  Tooltip,
  Alert,
} from "antd";
import {
  CloudUploadOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  RollbackOutlined,
  WarningFilled,
} from "@ant-design/icons";
import Marquee from "react-fast-marquee";

import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";

import * as fetch from "src/fetch";
import { pickClusterName } from "src/utils/Picker";

import PatchPreCheckModal, { PreCheckModalRef } from "./PatchPreCheckModal";

import ApproveButton from "./ApproveButton";
import { ApproveKindKeyEnum } from "src/utils/constant";

const pubServerTemp = `【发布目的】
说清楚此次发布的目的，比如：修复问题、新功能、tapd单

【放量情况】
全量/部分

【上线步骤】
说清楚上线步骤，先发哪个服务，后发哪个服务，需要改哪些配置

【影响范围】
说清楚可能对线上产生的影响，以及需要观察哪些指标、数据

【成本变化】
发布导致成本上涨、下跌情况。

【checklist】
灰度测试已通过：yes/no
代码合入已CR：CR人；cr链接
发布版本已确认：yes/no
配置、DB变更已操作：yes/无
接口测试已通过：yes/无
UI测试已通过：yes/无
服务自身、下游资源已检查：yes/no`;

const { TabPane } = Tabs;
const FormItem = Form.Item;
const SelectOption = Select.Option;
const ListItem = List.Item;
const ListItemMeta = ListItem.Meta;

interface IProps {
  disabled: boolean;
  depotID?: number;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  pausedItems?: Map<string, number>;
  replicasItems: Map<string, number>;
  approveStatus: Number;
  updateApproveStatus: () => void;
  pendingApproveUser: string;

  listServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string
  ) => void;
  getServerStatus: (
    userName: string,
    clusterName: string,
    tarsApplication: string,
    tarsSetName: string,
    tarsServerName: string
  ) => void;
}

interface IRspMeta {
  errCode: number;
  errMsg: string;
}

interface IGitCommiter {
  email: string;
  name: string;
}

interface IRepo {
  gitCommit: string;
  shortMessage: string;
  gitTags: string;
  gitBranches: string;
  commiter: IGitCommiter;
  commitTimestamp: string;
  buildTimestamp: string;
}

export interface IRepoItem {
  repo: IRepo;
  revisionIDs: Array<number>;
}

interface IRevisionItem extends IRepoItem {
  disabled?: boolean;
  revisionID: number;
  changeCause: string;
}

interface IDepotItem {
  repoName: string;
  depotID: number;
}

interface IProjectItem {
  projectID: number;
  depotItems: Array<IDepotItem>;
}

interface IState {
  visible: boolean;
  associateVisible: boolean;

  selectedProjectID?: number;
  selectedDepotID?: number;

  clusterName?: string;
  rspItems?: Map<string, IRspMeta>;
  repoItems?: Map<string, Array<IRepoItem>>;
  revisionItems?: Map<string, Array<IRevisionItem>>;
  projItems?: Map<string, IProjectItem>;

  noTagPatch: boolean;
  patchVisible?: boolean;
}

class Patch extends React.Component<IProps, IState> {
  private depotForm: React.RefObject<FormInstance>;
  private patchForm: React.RefObject<FormInstance>;
  private reasonForm: React.RefObject<FormInstance>;
  private patchImgForm: React.RefObject<FormInstance>;
  private preCheckModal: React.RefObject<any>;

  initialState(): IState {
    return {
      visible: false,
      associateVisible: false,
      selectedProjectID: undefined,
      selectedDepotID: undefined,

      clusterName: undefined,
      rspItems: undefined,
      repoItems: undefined,
      revisionItems: undefined,
      projItems: undefined,

      noTagPatch: false,
      patchVisible: undefined,
    };
  }

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState();
    this.depotForm = React.createRef<FormInstance>();
    this.patchForm = React.createRef<FormInstance>();
    this.reasonForm = React.createRef<FormInstance>();
    this.patchImgForm = React.createRef<FormInstance>();
    this.preCheckModal = React.createRef<PreCheckModalRef>();
  }

  fetchData() {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer)
      return;
    const depotID = this.props.depotID || 0;
    fetch
      .fetchListServerRepo({
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        depotID,
      })
      .then((data) => {
        let rspItems = new Map<string, IRspMeta>(Object.entries(data.rspItems));
        let repoItems = new Map<string, Array<IRepoItem>>();
        let revisionItems = new Map<string, Array<IRevisionItem>>();
        for (const i in data.repoItems) {
          let repoItem = data.repoItems[i];
          repoItems.set(i, repoItem);
        }

        let clusterName = this.state.clusterName;
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(repoItems.keys()));
        }
        for (const i in data.revisionItems) {
          let revisionItem = data.revisionItems[i];
          if (revisionItem && revisionItem.length > 0)
            revisionItem[0].disabled = true;
          revisionItems.set(i, revisionItem);
        }

        this.setState({
          clusterName,
          visible: true,
          rspItems,
          repoItems,
          revisionItems,
        });
      })
      .catch((_) => {});
  }

  fetchProjectData() {
    const clusterName = this.state.clusterName;
    if (!clusterName) return;
    fetch
      .fetchListCodingProject({ clusterName })
      .then((data) => {
        let projItems = new Map<string, IProjectItem>(
          Object.entries(data.projItems)
        );
        const depotID = this.props.depotID || 0;
        let selectedProjectID: number | undefined;
        let selectedDepotID: number | undefined;
        if (depotID > 0 && projItems.size > 0) {
          let found = false;
          const entries = Array.from(projItems.entries());
          for (const projEntry of entries) {
            for (const depotItem of projEntry[1].depotItems) {
              if (depotItem.depotID === depotID) {
                selectedProjectID = projEntry[1].projectID;
                selectedDepotID = depotItem.depotID;
                found = true;
                break;
              }
            }
            if (found) break;
          }
        }

        this.setState({
          projItems,
          selectedProjectID,
          selectedDepotID,
          associateVisible: true,
        });
      })
      .catch((_) => {});
  }

  fetchAssociateRepo() {
    const clusterName = this.state.clusterName;
    const projectID = this.state.selectedProjectID;
    const depotID = this.state.selectedDepotID;
    if (
      !clusterName ||
      !projectID ||
      !depotID ||
      !this.props.selectedTarsApplication ||
      !this.props.selectedTarsServer
    )
      return;
    this.setState(this.initialState());
    fetch
      .fetchAssociateServerRepo({
        userName: this.props.currentUser || "",
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        clusterName,
        projectID,
        depotID,
      })
      .then(() => {
        setTimeout(() => {
          if (
            this.props.selectedTarsApplication &&
            this.props.selectedTarsServer
          ) {
            this.props.listServer(
              this.props.selectedTarsApplication,
              this.props.selectedTarsSetName || "",
              this.props.selectedTarsServer
            );
          }
        }, 500);
        this.fetchServerStatus(clusterName);
        this.setState({ associateVisible: false });
      })
      .catch((_) => {});
  }

  async fetchRolloutSetImage(
    changeCause: string,
    podPhase: string,
    maxSurge: number
  ) {
    const clusterName = this.state.clusterName;
    if (
      !clusterName ||
      !this.props.selectedTarsApplication ||
      !this.props.selectedTarsServer
    )
      return;
    try {
      if (this.patchImgForm.current) {
        const { patchImage } = await this.patchImgForm.current.validateFields();
        if (!patchImage) return;
        fetch
          .fetchRolloutSetImage({
            userName: this.props.currentUser || "",
            clusterName,
            tarsApplication: this.props.selectedTarsApplication,
            tarsSetName: this.props.selectedTarsSetName || "",
            tarsServerName: this.props.selectedTarsServer,
            tag: patchImage,
            reason: changeCause,
            podPhase,
            maxSurge: Number(maxSurge),
          })
          .then(() => {
            setTimeout(() => {
              if (
                this.props.selectedTarsApplication &&
                this.props.selectedTarsServer
              ) {
                this.props.listServer(
                  this.props.selectedTarsApplication,
                  this.props.selectedTarsSetName || "",
                  this.props.selectedTarsServer
                );
              }
            }, 500);
            this.fetchServerStatus(clusterName);
            this.setState(this.initialState());
          })
          .catch((_) => {})
          .finally(() => {
            this.props.updateApproveStatus();
          });
      }
    } catch {}
  }

  fetchRolloutUndo(toRevision: number, changeCause: string) {
    if (
      !this.state.clusterName ||
      !this.props.selectedTarsApplication ||
      !this.props.selectedTarsServer
    )
      return;
    fetch
      .fetchRolloutUndo({
        userName: this.props.currentUser || "",
        clusterName: this.state.clusterName,
        tarsApplication: this.props.selectedTarsApplication,
        tarsSetName: this.props.selectedTarsSetName || "",
        tarsServerName: this.props.selectedTarsServer,
        toRevision,
        reason: changeCause,
      })
      .then(() => {
        this.fetchServerStatus(this.state.clusterName as string);
        this.setState(this.initialState());
      })
      .catch((_) => {});
  }

  fetchServerStatus(clusterName: string) {
    if (!this.props.selectedTarsApplication || !this.props.selectedTarsServer) {
      return;
    }
    setTimeout(() => {
      this.props.getServerStatus(
        this.props.currentUser || "",
        clusterName || "",
        this.props.selectedTarsApplication || "",
        this.props.selectedTarsSetName || "",
        this.props.selectedTarsServer || ""
      );
    }, 1000 * 5);
  }

  render() {
    const { approveStatus, updateApproveStatus } = this.props;

    const paused: boolean = this.props.pausedItems
      ? Array.from(this.props.pausedItems).some(
          (value: [string, number]) => value.length === 2 && value[1] > 0
        )
      : false;

    let rspItem: IRspMeta | undefined = undefined;
    if (this.state.rspItems) {
      rspItem = this.state.rspItems.get(this.state.clusterName || "");
    }

    // let rollingUpdate = false;
    let dueTo = (
      <>
        【<WarningFilled style={{ color: "#faad14" }} />
        滚动发布】或【
        <CloseCircleOutlined style={{ color: "red" }} />
        回滚发布】完成后才能继续进行【发布】
      </>
    );
    /**
    if (!paused && this.props.tarsServerItems) {
      const versionReduced = this.props.tarsServerItems.reduce((prev, cur) => {
        if (cur.publishVersion.indexOf("(") === -1) {
          return prev;
        }
        const ver = prev.get(cur.clusterName);
        if (typeof ver === "boolean" && !ver) {
          return prev;
        } else if (typeof ver === "string") {
          if (cur.publishVersion !== ver) {
            prev.set(cur.clusterName, false);
          }
          return prev;
        }
        prev.set(cur.clusterName, cur.publishVersion);
        return prev;
      }, new Map<string, string | boolean>());
      if (Array.from(versionReduced.values()).some((x) => x === false)) {
        rollingUpdate = true;
        dueTo = <>正在【滚动发布】中，滚动发布完成后再继续操作</>;
      }
      console.log(versionReduced);
    }
    **/
    console.log(this.props.disabled, paused);
    return (
      <>
        <Space style={{ marginLeft: paused ? "-25px" : 0 }}>
          {paused && (
            <Tooltip
              placement="left"
              overlayStyle={{ minWidth: 420 }}
              title={
                <>
                  <span>
                    发布处于<span style={{ color: "red" }}>(暂停状态)</span>
                  </span>
                  <br />
                  <span>{dueTo}</span>
                </>
              }
            >
              <QuestionCircleOutlined style={{ marginLeft: 3 }} />
            </Tooltip>
          )}
          <ApproveButton
            kind={ApproveKindKeyEnum.publish}
            approveStatus={approveStatus}
            updateApproveStatus={updateApproveStatus}
            pendingApproveUser={this.props.pendingApproveUser}
          >
            <Button
              disabled={this.props.disabled || paused}
              className="tool-btn"
              type="primary"
              ghost
              size="small"
              onClick={() => this.fetchData()}
            >
              <Space>
                <CloudUploadOutlined
                  style={{
                    color: "#1890ff",
                  }}
                />
                发布服务
              </Space>
            </Button>
          </ApproveButton>
        </Space>
        <Drawer
          title={
            <div slot="title" style={{ display: "inline-block" }}>
              <div style={{ float: "left" }}>选择发布镜像</div>
              <div style={{ position: "absolute", left: "40%" }}>
                <Button
                  type="primary"
                  ghost
                  danger={
                    this.props.depotID && this.props.depotID > 0 ? false : true
                  }
                  className="tool-btn"
                  onClick={() => this.fetchProjectData()}
                >
                  Coding关联
                </Button>
              </div>
              <div style={{ position: "absolute", right: 10 }}>
                <Checkbox
                  checked={this.state.noTagPatch}
                  onChange={({ target: { checked } }) =>
                    this.setState({ noTagPatch: checked })
                  }
                >
                  无TAG发布
                </Checkbox>
              </div>
            </div>
          }
          closable={false}
          placement="right"
          width="60vw"
          visible={this.state.visible}
          onClose={() => this.setState(this.initialState())}
        >
          <Tabs
            activeKey={this.state.clusterName}
            onChange={(clusterName) => this.setState({ clusterName })}
          >
            {this.state.repoItems &&
              this.state.clusterName &&
              Array.from(this.state.repoItems).map((value) => (
                <TabPane key={value[0]} tab={value[0]}>
                  {!isEmpty(value[1]) && rspItem && rspItem.errCode === 0 && (
                    <>
                      <Row justify="end">
                        <Col flex="1 1 auto" style={{ width: "88%" }}>
                          <Form ref={this.patchImgForm}>
                            <FormItem
                              name="patchImage"
                              rules={[
                                { required: true, message: "未选发布镜像" },
                              ]}
                            >
                              <Select
                                size="large"
                                placeholder="选择发布镜像"
                                optionLabelProp="label"
                              >
                                {Array.from(value[1] || [])
                                  .sort(
                                    (a, b) =>
                                      parseInt(b.repo.buildTimestamp) -
                                      parseInt(a.repo.buildTimestamp)
                                  )
                                  .filter((item) =>
                                    this.state.noTagPatch
                                      ? true
                                      : !isEmpty(item.repo.gitTags)
                                  )
                                  .map((item) => (
                                    <SelectOption
                                      key={item.repo.gitCommit}
                                      value={item.repo.gitCommit}
                                      label={
                                        <Row justify="start">
                                          <Col>
                                            镜像Tag:{" "}
                                            <Tag>{item.repo.gitCommit}</Tag>
                                          </Col>
                                          <Col>
                                            构建时间:{" "}
                                            <Tag color="green">
                                              {MomentFormat(
                                                parseInt(
                                                  item.repo.buildTimestamp
                                                )
                                              )}
                                            </Tag>{" "}
                                          </Col>
                                          <Col>
                                            分支:{" "}
                                            <Tag color="blue">
                                              {item.repo.gitTags}
                                            </Tag>
                                          </Col>
                                        </Row>
                                      }
                                    >
                                      <Card
                                        size="small"
                                        style={{ whiteSpace: "nowrap" }}
                                        title={
                                          <span>
                                            镜像Tag:
                                            <Tag>{item.repo.gitCommit}</Tag>
                                            构建时间:
                                            <Tag color="green">
                                              {MomentFormat(
                                                parseInt(
                                                  item.repo.buildTimestamp
                                                )
                                              )}
                                            </Tag>
                                            {!isEmpty(item.repo.gitBranches)
                                              ? "代码分支"
                                              : null}
                                            {!isEmpty(
                                              item.repo.gitBranches
                                            ) && (
                                              <Tag style={{ marginLeft: 3 }}>
                                                {item.repo.gitBranches}
                                              </Tag>
                                            )}
                                            {!isEmpty(item.repo.gitTags)
                                              ? "Tag"
                                              : null}
                                            {!isEmpty(item.repo.gitTags) && (
                                              <Tag style={{ marginLeft: 3 }}>
                                                {item.repo.gitTags}
                                              </Tag>
                                            )}
                                          </span>
                                        }
                                        actions={[
                                          <div
                                            style={{
                                              height: 10,
                                              textAlign: "left",
                                              marginLeft: 10,
                                            }}
                                          >
                                            REVISIONS:
                                            {item.revisionIDs.map(
                                              (revision) => (
                                                <Tag
                                                  key={revision}
                                                  color="#108ee9"
                                                >
                                                  {revision}
                                                </Tag>
                                              )
                                            )}
                                          </div>,
                                        ]}
                                      >
                                        <>
                                          <p>
                                            {" "}
                                            <div
                                              style={{ width: "100%" }}
                                              dangerouslySetInnerHTML={{
                                                __html: item.repo.shortMessage,
                                              }}
                                            />{" "}
                                          </p>
                                          <p>
                                            {item.repo.commiter.name +
                                              "提交于" +
                                              MomentFormat(
                                                parseInt(
                                                  item.repo.commitTimestamp
                                                )
                                              )}
                                          </p>
                                        </>
                                      </Card>
                                    </SelectOption>
                                  ))}
                              </Select>
                            </FormItem>
                          </Form>
                        </Col>
                        <Col flex="1 1 auto">
                          <Button
                            type="default"
                            size="large"
                            className="tool-btn"
                            style={{ float: "right" }}
                            onClick={async () => {
                              try {
                                if (this.patchImgForm.current) {
                                  const imgSelected =
                                    await this.patchImgForm.current.validateFields();
                                  this.preCheckModal.current?.show({
                                    imgList: value[1],
                                    imgSelected,
                                  });
                                }
                              } catch {}
                            }}
                          >
                            <CloudUploadOutlined />
                            发布服务
                          </Button>
                        </Col>
                      </Row>
                      <Divider type="horizontal" />
                      <List
                        pagination={{
                          pageSize: 4,
                          simple: true,
                          hideOnSinglePage: true,
                          defaultCurrent: 1,
                        }}
                        itemLayout="horizontal"
                        size="small"
                        dataSource={Array.from(
                          (
                            this.state.revisionItems ||
                            new Map<string, IRevisionItem[]>()
                          ).get(this.state.clusterName || "") || []
                        )}
                        renderItem={(item, index) => (
                          <ListItem key={index}>
                            <ListItemMeta
                              description={
                                <span>
                                  构建时间:{" "}
                                  {MomentFormat(
                                    parseInt(item.repo.buildTimestamp)
                                  )}
                                </span>
                              }
                              title={
                                <span
                                  style={{
                                    width: 1000,
                                    float: "left",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Row>
                                    <Row style={{ height: 36 }}>
                                      <Col
                                        flex="80px"
                                        style={{ textAlign: "left" }}
                                      >
                                        REVISION
                                      </Col>
                                      <Col
                                        flex="auto"
                                        style={{ textAlign: "left" }}
                                      >
                                        CHANGE-CAUSE
                                      </Col>
                                    </Row>
                                    <Row style={{ height: 36 }}>
                                      <Col
                                        flex="80px"
                                        style={{
                                          textAlign: "left",
                                          color: "rgba(0,0,0,.65)",
                                        }}
                                      >
                                        {item.revisionID}
                                      </Col>
                                      <Col
                                        flex="auto"
                                        style={{
                                          textAlign: "left",
                                          color: "rgba(0,0,0,.65)",
                                        }}
                                      >
                                        {!isEmpty(item.changeCause)
                                          ? item.changeCause
                                          : "<none>"}
                                      </Col>
                                    </Row>
                                  </Row>
                                  <p
                                    style={{
                                      color: "rgba(0,0,0,.65)",
                                      marginTop: 26,
                                    }}
                                  >
                                    Commit: {item.repo.gitCommit}{" "}
                                    {item.repo.gitTags.length > 0
                                      ? "(" + item.repo.gitTags + ")"
                                      : ""}
                                  </p>
                                  <p style={{ color: "rgba(0,0,0,.65)" }}>
                                    Author: {item.repo.commiter.name} &lt;
                                    {item.repo.commiter.email}&gt;
                                  </p>
                                  <p
                                    style={{
                                      whiteSpace: "pre",
                                      color: "rgba(0,0,0,.65)",
                                    }}
                                  >
                                    Date:{" "}
                                    {MomentFormat(
                                      parseInt(item.repo.buildTimestamp)
                                    ).toString()}
                                  </p>
                                  <br />
                                  <p
                                    style={{
                                      whiteSpace: "pre",
                                      color: "rgba(0,0,0,.65)",
                                    }}
                                  >
                                    {" "}
                                    <div
                                      style={{ width: "100%" }}
                                      dangerouslySetInnerHTML={{
                                        __html: item.repo.shortMessage,
                                      }}
                                    />
                                  </p>
                                  <br />
                                </span>
                              }
                            />

                            <Popconfirm
                              placement="left"
                              title={
                                <>
                                  <span>新节点将被全部下线</span>
                                  <Form
                                    name="reasonForm"
                                    ref={this.reasonForm}
                                    validateTrigger="onBlur"
                                    initialValues={{ reason: "" }}
                                  >
                                    <FormItem
                                      name="reason"
                                      rules={[
                                        {
                                          required: true,
                                          min: 6,
                                          message: "原因过于简单",
                                        },
                                      ]}
                                    >
                                      <Input
                                        size="small"
                                        placeholder="回滚原因"
                                      />
                                    </FormItem>
                                  </Form>
                                </>
                              }
                              onConfirm={async () => {
                                try {
                                  if (this.reasonForm.current) {
                                    await this.reasonForm.current.validateFields();
                                    const { reason } =
                                      this.reasonForm.current.getFieldsValue();
                                    this.fetchRolloutUndo(
                                      item.revisionID,
                                      reason
                                    );
                                  }
                                } catch {}
                              }}
                              disabled={item.disabled ? true : false}
                            >
                              <Button
                                size="small"
                                danger
                                disabled={item.disabled ? true : false}
                              >
                                <RollbackOutlined />
                                回滚
                              </Button>
                            </Popconfirm>
                          </ListItem>
                        )}
                      />
                    </>
                  )}

                  {(!rspItem || rspItem.errCode !== 0) && (
                    <Result
                      status="403"
                      title={`请求失败: errCode: ${
                        rspItem ? rspItem.errCode : "0"
                      }`}
                      subTitle={`errMsg:${rspItem ? rspItem.errMsg : ""}`}
                    />
                  )}

                  {isEmpty(value[1]) && rspItem && rspItem.errCode === 0 && (
                    <Result
                      status="403"
                      title="Coding项目未正确关联"
                      subTitle="重新检查Coding关联"
                    >
                      <div>
                        <p>
                          <CloseCircleOutlined style={{ color: "red" }} />
                          未设置关联
                        </p>
                        <p>
                          <CloseCircleOutlined style={{ color: "red" }} />
                          仓库已关联，但容器镜像未构建, 镜像名:
                          {this.props.selectedTarsApplication
                            ? this.props.selectedTarsApplication.toLowerCase()
                            : ""}
                          -
                          {this.props.selectedTarsServer
                            ? this.props.selectedTarsServer.toLowerCase()
                            : ""}
                        </p>
                      </div>
                    </Result>
                  )}
                </TabPane>
              ))}
          </Tabs>
        </Drawer>

        {/* coding关联 */}
        <Modal
          title={<div style={{ textAlign: "center" }}>Coding关联</div>}
          visible={this.state.associateVisible}
          closable={false}
          okText="关联"
          cancelText="取消"
          okButtonProps={{ className: "tool-btn" }}
          cancelButtonProps={{ className: "tool-btn" }}
          onCancel={() => this.setState({ associateVisible: false })}
          onOk={async () => {
            try {
              if (this.depotForm.current)
                await this.depotForm.current.validateFields();
              this.fetchAssociateRepo();
            } catch {}
          }}
        >
          <Form
            ref={this.depotForm}
            validateTrigger="onBlur"
            initialValues={{
              clusterName: this.state.clusterName,
              proj: this.state.selectedProjectID,
              depotID: this.state.selectedDepotID,
            }}
          >
            <FormItem
              name="proj"
              label="项目"
              rules={[{ required: true, message: "没选关联项目" }]}
            >
              <Select
                placeholder="Coding项目"
                value={this.state.selectedProjectID}
                onChange={(selectedProjectID: number) => {
                  this.setState({
                    selectedProjectID,
                    selectedDepotID: undefined,
                  });
                  if (this.depotForm.current)
                    this.depotForm.current.setFieldsValue({
                      depotID: undefined,
                    });
                }}
              >
                {this.state.projItems &&
                  Array.from(this.state.projItems).map((value, index) => (
                    <SelectOption key={index} value={value[1].projectID}>
                      {value[0]}
                    </SelectOption>
                  ))}
              </Select>
            </FormItem>
            <FormItem
              name="depotID"
              label="仓库"
              rules={[{ required: true, message: "没选关联仓库" }]}
            >
              <Select
                showSearch
                optionFilterProp="label"
                placeholder="Coding仓库"
                value={this.state.selectedDepotID}
                onChange={(selectedDepotID: number) =>
                  this.setState({ selectedDepotID })
                }
              >
                {this.state.projItems &&
                  Array.from(this.state.projItems)
                    .filter(
                      (x) => x[1].projectID === this.state.selectedProjectID
                    )
                    .reduce((prev, cur) => {
                      prev = prev.concat(cur[1].depotItems);
                      return prev;
                    }, Array<IDepotItem>())
                    .map((value, index) => (
                      <SelectOption
                        key={index}
                        value={value.depotID}
                        label={value.repoName}
                      >
                        {value.repoName}
                      </SelectOption>
                    ))}
              </Select>
            </FormItem>
          </Form>
        </Modal>

        {/* 发布原因 */}
        <Modal
          width={600}
          centered
          title="发布说明"
          closable={false}
          okText="发布"
          cancelText="取消"
          visible={this.state.patchVisible}
          onCancel={() => this.setState({ patchVisible: false })}
          okButtonProps={{ className: "tool-btn", ghost: true, size: "small" }}
          cancelButtonProps={{
            className: "tool-btn",
            danger: true,
            size: "small",
          }}
          onOk={async () => {
            try {
              if (this.patchForm.current) {
                const fields = await this.patchForm.current.validateFields();
                this.fetchRolloutSetImage(
                  fields.changeCause,
                  fields.podPhase,
                  fields.maxSurge
                );
              }
            } catch {}
          }}
        >
          <Form
            ref={this.patchForm}
            validateTrigger="onBlur"
            initialValues={{
              changeCause: pubServerTemp,
              podPhase: "Ready",
              maxSurge: "1",
            }}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <Alert
              banner
              style={{ marginBottom: 24 }}
              message={
                <Marquee pauseOnHover gradient={false}>
                  当前为：
                  <Tag color="#f50">
                    {this.props.selectedTarsApplication}.
                    {this.props.selectedTarsServer}
                    {this.props.selectedTarsSetName
                      ? `【${this.props.selectedTarsSetName}】`
                      : ""}
                  </Tag>
                  , 请注意区分环境
                </Marquee>
              }
            />
            <FormItem
              label="CHANGE-CAUSE"
              name="changeCause"
              rules={[
                {
                  required: true,
                  validator: (_, val) => {
                    if (!val || !val.trim()) {
                      return Promise.reject(new Error("CHANGE-CAUSE 为必填"));
                    }
                    if (
                      val === pubServerTemp &&
                      this.props.selectedTarsSetName !== "phx.gray.1"
                    ) {
                      return Promise.reject(
                        new Error("CHANGE-CAUSE 未改动，请认真填写后再发布")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 12 }}
                placeholder="发布说明"
                size="small"
              />
            </FormItem>

            <FormItem
              label="灰度节点状态"
              name="podPhase"
              rules={[{ required: true }]}
            >
              <Select size="small">
                <SelectOption value="Ready">正常</SelectOption>
                <SelectOption value="NotReady">无流量</SelectOption>
              </Select>
            </FormItem>
            <FormItem
              label="预发布节点数"
              name="maxSurge"
              rules={[
                { required: true, message: "预发布节点数需要填写" },
                {
                  validator: (rule, value) => {
                    let replicasItemNum = 0;
                    this.props.replicasItems.forEach((item) => {
                      if (item > 0) {
                        replicasItemNum = item;
                      }
                    });
                    if (value <= 0) {
                      return Promise.reject("节点数不正确");
                    }
                    if (replicasItemNum <= 10 && value > 1) {
                      return Promise.reject("节点数不正确,只能为所有节点的1");
                    }

                    if (replicasItemNum > 10 && value > replicasItemNum / 10) {
                      return Promise.reject(
                        "节点数不正确,不能大于所有节点的1/10"
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="预发布的节点数" size="small" type="number" />
            </FormItem>
          </Form>
        </Modal>

        <PatchPreCheckModal
          ref={this.preCheckModal}
          onConfirm={() => this.setState({ patchVisible: true })}
          clusterName={this.state.clusterName || ""}
          tarsApplication={this.props.selectedTarsApplication || ""}
          tarsSetName={this.props.selectedTarsSetName || ""}
          tarsServerName={this.props.selectedTarsServer || ""}
          userName={this.props.currentUser || ""}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  depotID: state.tarsReducer.depotID,
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  pausedItems: state.tarsReducer.pausedItems,
  replicasItems: state.tarsReducer.replicasItems,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  listServer: (
    selectedTarsApplication: string,
    selectedTarsSetName: string,
    selectedTarsServer: string
  ) => {
    dispatch(
      Actions.listServer(
        selectedTarsApplication,
        selectedTarsSetName,
        selectedTarsServer
      )
    );
  },
  getServerStatus: (
    userName: string,
    clusterName: string,
    tarsApplication: string,
    tarsSetName: string,
    tarsServerName: string
  ) =>
    dispatch(
      Actions.getServerStatus(
        userName,
        clusterName,
        tarsApplication,
        tarsSetName,
        tarsServerName
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Patch);
