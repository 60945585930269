import React from 'react';
import { isEmpty } from 'lodash';
import { Button, Space, Form, Modal, Select, Tag, message } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';
import * as ActionType from 'src/stores/types';

import * as fetch from 'src/fetch';
import * as util from 'src/pages/DevOps/ToolsBar/util';
import NotificationResult from 'src/pages/DevOps/ToolsBar/NotificationResult';

const FormItem = Form.Item;
const SelectOption = Select.Option;

interface IProps {
  disabled: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  selectedItems?: Array<ActionType.ITarsServerItem>;
}

interface ILogLevelItem {
  podIP: string;
  logLevel: number;
};

interface IState {
  confirmVisible: boolean;
  resultVisible: boolean;
  notificationResult: Array<any>;
  items?: Array<ILogLevelItem>;
}

const EditLogLevel : React.FC<IProps> = props => {
  const [form] = Form.useForm();
  const [state, setState] = React.useState<IState>({confirmVisible: false, resultVisible: false, notificationResult: []});

  const fetchData = () => {
    const { selectedTarsApplication, selectedTarsServer, selectedItems } = props;
    if (selectedTarsApplication && selectedTarsServer && selectedItems && !isEmpty(selectedItems)) {
      fetch
      .fetchGetServerLogLevel({
        tarsApplication: selectedTarsApplication,
        tarsSetName: '',
        tarsServerName: selectedTarsServer,
        podNetworkInterfaces: selectedItems.map(item => ({podIP: item.podIP, clusterName: item.clusterName}))
      })
      .then(data => {
        setState({ confirmVisible: true, resultVisible: false, notificationResult: [], items: data.items });
      })
      .catch(() => {});
    }
  };

  const levelToString = (level: number) => {
    switch (level) {
      case 0:
        return 'NONE';
      case 1:
        return 'INFO';
      case 2:
        return 'DEBUG';
      case 3:
        return 'WARN';
      case 4:
        return 'ERROR';
    }
    return 'ERROR';
  };

  const levelToColor = (level: number) => {
    switch (level) {
      case 0:
        return 'default';
      case 1:
        return 'success';
      case 2:
        return 'processing';
      case 3:
        return 'warning';
      case 4:
        return 'error';
    }
    return 'error';
  };

  return (
    <>
      <Button
        size='small'
        disabled={props.disabled}
        className='tool-btn'
        onClick={() => {
          if (util.disableSelectNull(props.selectedItems)) {
            return;
          }

          if (util.has('inactive', props.selectedItems)) {
            message.destroy();
            message.error('选中的节点中有inactive状态的节点');
            return;
          }

          fetchData();
        }}
      >
        <Space><DashboardOutlined />日志等级</Space>
      </Button>

      <Modal
        title='设置日志等级'
        closable={false}
        visible={state.confirmVisible}
        okText='设置'
        cancelText='取消'
        width={380}
        onOk={async () => {
          try {
            const values = await form.validateFields();
            const { currentUser, selectedTarsApplication, selectedTarsServer, selectedItems } = props;
            if (selectedTarsApplication && selectedTarsServer && selectedItems && !isEmpty(selectedItems)) {
              const { logLevel } = values;
              fetch
              .fetchSetServerLogLevel({
                userName: currentUser || '',
                logLevel,
                tarsApplication: selectedTarsApplication,
                tarsSetName: '',
                tarsServerName: selectedTarsServer,
                podNetworkInterfaces: selectedItems.map(item => ({podIP: item.podIP, clusterName: item.clusterName}))
              })
              .then(data => {
                setState({ confirmVisible: false, resultVisible: true, notificationResult: data.items });
              })
              .catch(_ => {});
            }
          } catch {}
        }}
        onCancel={() => {
          setState({ confirmVisible: false, resultVisible: false, notificationResult: [], items: undefined });
          form.resetFields();
        }}
      >
        <span>
          <p>
            {
              state.items && isEmpty(!state.items) &&
              state.items
              .map((x, index) => (
                <Tag key={index} color={levelToColor(x.logLevel)}>{x.podIP}<span style={{fontSize: 5}}>({levelToString(x.logLevel)})</span></Tag>
              ))
            }
          </p>
          <p>
            <Form
              name='logLevelForm'
              form={form}
              initialValues={{logLevel: 4}}
              autoComplete='off'
              validateTrigger='onBlur'
            >
              <FormItem
                name='logLevel'
              >
                <Select
                  size='large'
                  style={{width: '100%'}}
                >
                  {[{
                    logLevel: 'NONE',
                    value: 0,
                  }, {
                    logLevel: 'INFO',
                    value: 1,
                  }, {
                    logLevel: 'DEBUG',
                    value: 2,
                  }, {
                    logLevel: 'WARN',
                    value: 3,
                  }, {
                    logLevel: 'ERROR',
                    value: 4,
                  }].map((option, index) => (
                    <SelectOption key={index} value={option.value}> <p style={{width:200}}>{option.logLevel}</p> </SelectOption>
                  ))}
                </Select>
              </FormItem>
            </Form>
          </p>
        </span>
      </Modal>

      <NotificationResult visible={state.resultVisible} dataSource={state.notificationResult}
        onClose={() => setState({ confirmVisible: false, resultVisible: false, notificationResult: [] })}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  selectedItems: state.tarsReducer.selectedItems,
});

export default connect(mapStateToProps, null)(EditLogLevel);
