import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Modal, Tooltip, notification, Input } from "antd";

import * as fetch from "../../../fetch";
import * as Actions from "../../../stores/actions";
import * as ActionType from "../../../stores/types";

import {
  ApproveStatusEnum,
  getStaticClusterName,
} from "../../../utils/constant";

const TextArea = Input.TextArea;

const Pending_Timer_Interval = 3; // 单位秒

interface IProps {
  children: any;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  approveStatus: Number;
  kind: Number;
  updateApproveStatus: () => void;
  pendingApproveUser: string;
}

let remark = "";

const ApproveButton: React.FC<IProps> = (props) => {
  const {
    children,
    currentUser,
    selectedTarsApplication,
    selectedTarsSetName,
    selectedTarsServer,
    approveStatus,
    kind,
    updateApproveStatus,
    pendingApproveUser,
  } = props;

  const [hover, setHover] = useState(false);
  const [clientShowPending, setClientShowPending] = useState(
    approveStatus === ApproveStatusEnum.Pending
  );
  const [rollbackBtnLoading, setRollbackBtnLoading] = useState(false);

  const acquireApprove = () => {
    fetch
      .fetchAcquireApprove({
        userName: currentUser || "",
        clusterName: getStaticClusterName(),
        tarsApplication: selectedTarsApplication,
        tarsSetName: selectedTarsSetName || "",
        tarsServerName: selectedTarsServer,
        kind,
        reason: remark || "",
      })
      .then((data) => {
        if (data && data.errCode === 0) {
          notification.success({
            message: "审批申请提交成功",
            placement: "topRight",
          });
          setClientShowPending(true);
          updateApproveStatus();
        } else {
          if (data && data.errCode === 1001) {
            Modal.error({
              title: "审批申请提交失败",
              content: (
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    @{pendingApproveUser}
                  </span>
                  占用了当前服务的审批和操作流程，请联系
                  <span style={{ fontWeight: "bold" }}>
                    @{pendingApproveUser}
                  </span>
                  释放操作
                </p>
              ),
            });
          } else if (data && data.errCode === 2) {
            Modal.error({
              title: "审批异常",
              content: "请填写申请理由",
            });
          } else {
            Modal.error({
              title: "审批异常",
              content: "提交审批请求失败，请稍后重试",
            });
          }
        }
      });
  };

  const handleClick = () => {
    remark = "";
    Modal.confirm({
      title: "请填写申请说明",
      content: (
        <TextArea
          onChange={(e) => {
            remark = e.currentTarget.value;
          }}
        />
      ),
      okText: "确认提交",
      cancelText: "取消",
      closable: true,

      onOk: () => {
        acquireApprove();
      },
      onCancel: () => {},
    });
  };

  const handleRollback = () => {
    setRollbackBtnLoading(true);
    fetch
      .fetchSubmitApproveStatus({
        userName: currentUser || "",
        clusterName: getStaticClusterName(),
        tarsApplication: selectedTarsApplication,
        tarsSetName: selectedTarsSetName || "",
        tarsServerName: selectedTarsServer,
        kind,
        action: 3,
        reason: "撤回",
      })
      .then((data) => {
        setRollbackBtnLoading(false);
        if (data && data.errCode === 0) {
          notification.success({
            message: "操作成功",
            placement: "topRight",
          });
          updateApproveStatus();
        } else {
          Modal.error({
            title: "接口异常",
            content: "撤回审批失败",
          });
        }
      });
  };

  useEffect(() => {
    let timer: any;
    if (approveStatus === ApproveStatusEnum.Pending) {
      setClientShowPending(false);
      timer = setInterval(() => {
        updateApproveStatus();
      }, Pending_Timer_Interval * 1000);
    } else {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [approveStatus]);

  const showOriginButton =
    approveStatus === ApproveStatusEnum.Approved ||
    approveStatus === ApproveStatusEnum.NoNeed ||
    (approveStatus === ApproveStatusEnum.Required && !hover);
  const acquireApprovePending =
    approveStatus === ApproveStatusEnum.Pending || clientShowPending;
  const acquireApproveSuccess = approveStatus === ApproveStatusEnum.Approved;

  return (
    <>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {showOriginButton ? (
          <div className="suc-btn">
            {children}
            {acquireApproveSuccess ? (
              <Tooltip
                color="#ffffff"
                title={
                  <div className="approve-pending-tip">
                    <div className="rollback-tip">
                      <p className="rollback-title">撤回申请</p>
                      <p className="rollback-desc">
                        如暂时无需操作，可撤回申请，释放审批通道。
                      </p>
                      <Button
                        type="primary"
                        size="small"
                        danger
                        loading={rollbackBtnLoading}
                        onClick={handleRollback}
                      >
                        撤回申请
                      </Button>
                    </div>
                  </div>
                }
                placement="leftTop"
              >
                <CheckCircleTwoTone
                  className="suc-icon"
                  twoToneColor="#52c41a"
                />
              </Tooltip>
            ) : null}
          </div>
        ) : (
          <Tooltip
            color="#ffffff"
            title={
              <div className="approve-pending-tip">
                <span>{selectedTarsServer}</span>当前由
                <span>@{pendingApproveUser}</span>占用审批和操作流程
              </div>
            }
            placement="leftTop"
          >
            <Button
              size="small"
              type="primary"
              className="approve-btn"
              loading={acquireApprovePending}
              onClick={handleClick}
            >
              {acquireApprovePending ? "审批中..." : "提交审批申请"}
            </Button>
          </Tooltip>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  selectedItems: state.tarsReducer.selectedItems,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  setTaskManagerDot: (taskManagerDot: boolean) =>
    dispatch(Actions.setTaskManagerDot(taskManagerDot)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveButton);
