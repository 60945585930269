import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, PageHeader, Popover, Tag } from "antd";
import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";
import MoreOption from "./MoreOption";
import UserInfo from "./UserInfo";
import "./index.less";

interface IProps {
  currentUser?: string;
  admin?: boolean;
  resetStore: () => void;
}

interface IState {
  moreOptionVisible: boolean;
}

const Header: React.FC<IProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = React.useState<IState>({
    moreOptionVisible: false,
  });
  useEffect(() => { }, [props.currentUser, location]);
  let pathname = location.pathname;
  const { currentUser } = props;
  let user = currentUser || "";
  if (!isEmpty(user) && user.length > 1) {
    user = user[0].toUpperCase() + user.substring(1);
  }
  if (pathname === "/") pathname = "/home";
  const avatar = isEmpty(user)
    ? 0
    : (new Date().getDay() +
      new TextEncoder().encode(user).reduce((prev, next) => prev + next)) %
    25;
  useEffect(() => { }, [avatar]);
  return (
    <div style={{ width: "100vw" }}>
      <PageHeader
        style={{ padding: 0 }}
        ghost={false}
        backIcon={false}
        className="tars_header"
        title={
          <>
            <a
              href="/home"
              className={`${pathname === "/home" || pathname === "/tools" ? "active" : ""
                }`}
            >
              <img
                src={require("src/assets/image/logo.png")}
                style={{ height: 72 }}
                alt="home"
              />
            </a>
          </>
        }
        tags={
          currentUser ? (
            <Tag color="error">正式环境，谨慎操作！！</Tag>
          ) : undefined
        }
        extra={
          currentUser ? (
            <div className="header-right">
              {props.admin ? (
                <>
                  <Button onClick={() => navigate("/tools")} type="link">
                    服务运维
                  </Button>
                  <Divider
                    type="vertical"
                    style={{ height: 24, margin: "0" }}
                  />
                </>
              ) : null}
              <Button onClick={() => (navigate("/serverStatus"))} type="link">
                服务动态
              </Button>
              <Divider type="vertical" style={{ height: 24, margin: "0" }} />
              <Button onClick={() => (window.open("/approveCenter"))} type="link">
                审批中心
              </Button>
              <Divider type="vertical" style={{ height: 24, margin: "0" }} />
              <Button onClick={() => navigate("/createServer")} type="link">
                创建服务
              </Button>
              <Divider type="vertical" style={{ height: 24, margin: "0" }} />
              <Popover
                content={
                  <MoreOption
                    onClick={() => setState({ moreOptionVisible: false })}
                  />
                }
                trigger="click"
                placement="bottomRight"
                arrowPointAtCenter
                visible={state.moreOptionVisible}
                onVisibleChange={(moreOptionVisible) =>
                  setState({ moreOptionVisible })
                }
                getPopupContainer={(trigger) =>
                  trigger.parentElement || document.body
                }
              >
                <Button
                  onClick={() => setState({ moreOptionVisible: true })}
                  type="link"
                >
                  服务授权
                </Button>
              </Popover>
              <Divider
                type="vertical"
                style={{ height: 24, margin: "0 16px 0 0" }}
              />
              <UserInfo />
            </div>
          ) : null
        }
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  admin: state.tarsReducer.admin,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  resetStore: () => dispatch(Actions.resetStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
