import { DataType } from './type';

export const getServerKey = (data: DataType) => {
  const { tarsApplication, tarsServerName } = data;

  let serverKey = '';

  if (tarsApplication) {
    serverKey += `${tarsApplication}.`;
  }
  
  serverKey += tarsServerName;
  
  return serverKey;
}
