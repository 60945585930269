import React from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { Modal, Select, Button, message } from "antd";

import * as fetch from "src/fetch";
import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";

const { webApi } = fetch;
const SelectOption = Select.Option;

interface IProps {
  currentUser?: string;
  onClick: () => void;
  listServerTree: () => void;
}

interface IState {
  loading: boolean;
  addAuthVisible: boolean;
  removeAuthVisible: boolean;
  serverItems: Set<string>;
  selectedAuthServer?: string;
  authItems?: Set<string>;
}

class MoreOption extends React.Component<IProps, IState> {
  initialState(): IState {
    return {
      loading: false,
      addAuthVisible: false,
      removeAuthVisible: false,
      serverItems: new Set(),
      selectedAuthServer: undefined,
      authItems: undefined,
    };
  }

  constructor(props: IProps) {
    super(props);
    this.state = this.initialState();
  }

  fetchData = () => {
    this.setState({
      ...this.initialState(),
    });
    const hide = message.loading("加载中..", 0);
    fetch
      .fetchListServerTree({ userName: "" })
      .then((data) => {
        let serverItems = new Set<string>();
        data.treeNodes.forEach((item: ActionType.ITreeItem) => {
          if (item.tarsApplication !== "DCache")
            serverItems.add(item.tarsApplication + "." + item.tarsServerName);
        });
        this.setState({ serverItems, addAuthVisible: true });
      })
      .catch((_) => {})
      .finally(() => {
        hide();
      });
  };

  fetchListAuth = () => {
    this.setState(this.initialState());
    fetch
      .fetchListAuth({ userName: this.props.currentUser || "" })
      .then((data) => {
        let authItems = new Set<string>();
        data.items.forEach((item: string) => {
          authItems.add(item);
        });
        this.setState({ authItems, removeAuthVisible: true });
      })
      .catch((_) => {});
  };

  fetchAddAuth = () => {
    if (isEmpty(this.state.selectedAuthServer)) {
      message.error("未选服务");
      return;
    }
    if (!this.state.selectedAuthServer) return;
    this.setState({
      loading: true,
    });
    webApi
      .post("server/person/visitor/add", {
        server: this.state.selectedAuthServer,
      })
      .then(() => {
        message.success("添加权限成功");
        setTimeout(this.props.listServerTree.bind(this), 500);
        this.setState(this.initialState());
      })
      .catch((_) => {
        message.error("添加失败");
        this.setState(this.initialState());
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  fetchDeleteAuth = () => {
    if (isEmpty(this.state.selectedAuthServer)) {
      message.error("未选服务");
      return;
    }
    if (!this.state.selectedAuthServer) return;
    this.setState({
      loading: true,
    });
    webApi
      .post("server/person/visitor/delete", {
        server: this.state.selectedAuthServer,
      })
      .then(() => {
        message.success("删除权限成功");
        setTimeout(
          this.props.listServerTree.bind(this, this.props.currentUser || ""),
          500
        );
        this.setState(this.initialState());
      })
      .catch((_) => {
        message.error("删除失败");
        this.setState(this.initialState());
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <>
        <span>
          <p>
            <Button
              size="small"
              type="primary"
              ghost
              className="tool-btn"
              onClick={() => {
                this.props.onClick();
                this.fetchData();
              }}
            >
              添加授权
            </Button>
          </p>
          <p>
            <Button
              size="small"
              danger
              className="tool-btn"
              onClick={() => {
                this.props.onClick();
                this.fetchListAuth();
              }}
            >
              移除授权
            </Button>
          </p>
        </span>

        {/* 添加授权 */}
        <Modal
          closable={false}
          visible={this.state.addAuthVisible}
          title="添加授权"
          okText="添加"
          cancelText="取消"
          okButtonProps={{
            type: "primary",
            ghost: true,
            className: "tool-btn",
            loading: this.state.loading,
          }}
          cancelButtonProps={{
            danger: true,
            ghost: true,
            className: "tool-btn",
          }}
          onOk={() => this.fetchAddAuth()}
          onCancel={() =>
            this.setState({
              addAuthVisible: false,
              selectedAuthServer: undefined,
            })
          }
        >
          <Select
            showSearch={true}
            style={{ width: "100%" }}
            dropdownMatchSelectWidth={false}
            value={this.state.selectedAuthServer}
            onChange={(selectedAuthServer) =>
              this.setState({ selectedAuthServer })
            }
          >
            {this.state.serverItems &&
              Array.from(this.state.serverItems).map((item, index) => (
                <SelectOption value={item} key={index}>
                  {item}
                </SelectOption>
              ))}
          </Select>
        </Modal>

        {/* 移除授权 */}
        <Modal
          closable={false}
          visible={this.state.removeAuthVisible}
          title="移除授权"
          okText="移除"
          cancelText="取消"
          okButtonProps={{
            type: "default",
            danger: true,
            className: "tool-btn",
          }}
          cancelButtonProps={{
            type: "primary",
            ghost: true,
            className: "tool-btn",
            loading: this.state.loading,
          }}
          onOk={() => this.fetchDeleteAuth()}
          onCancel={() =>
            this.setState({
              removeAuthVisible: false,
              selectedAuthServer: undefined,
            })
          }
        >
          <Select
            showSearch={true}
            style={{ width: "100%" }}
            dropdownMatchSelectWidth={false}
            value={this.state.selectedAuthServer}
            onChange={(selectedAuthServer) =>
              this.setState({ selectedAuthServer })
            }
          >
            {this.state.authItems &&
              Array.from(this.state.authItems).map((item, index) => (
                <SelectOption value={item} key={index}>
                  {item}
                </SelectOption>
              ))}
          </Select>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  listServerTree: () => dispatch(Actions.listServerTree(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoreOption);
