import React from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { Button, Spin, Result, Tabs, Popconfirm, message } from "antd";
import { StopOutlined } from "@ant-design/icons";
import * as fetch from "src/fetch";

import { pickClusterName } from "src/utils/Picker";

const { TabPane } = Tabs;

interface IProps {
  kind: string;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
}

interface IMonitoringItem {
  loading?: boolean;
  errCode: number;
  errMsg: string;
  firstCreated: number;
  grafanaURL: string;
}

interface IState {
  loading: boolean;
  errMsg: string;
  clusterName: string;
  items: Map<string, IMonitoringItem>;
}

const countdownKey = "countdown";
let messageKeys: Array<string> = [];
let tickers: Array<NodeJS.Timer> = [];

class Monitoring extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      errMsg: "",
      clusterName: "",
      items: new Map<string, IMonitoringItem>(),
    };
  }

  fetchData(reload?: boolean) {
    const kind = this.props.kind;
    const userName = this.props.currentUser || "";
    const tarsApplication = this.props.selectedTarsApplication;
    const tarsSetName = this.props.selectedTarsSetName || "";
    const tarsServerName = this.props.selectedTarsServer;
    if (!tarsApplication || !tarsServerName) {
      this.setState({ loading: false, errMsg: "应用或服务名为空!" });
      return;
    }

    this.setState({ loading: true });
    if (!isEmpty(tarsApplication) && !isEmpty(tarsServerName)) {
      fetch
        .fetchMonitoringGrafanaDashboard({
          userName,
          kind,
          tarsApplication,
          tarsSetName,
          tarsServerName,
          reload: reload ? 1 : 0,
        })
        .then((data) => {
          let clusterName = this.state.clusterName;
          let items = new Map<string, IMonitoringItem>(
            Object.entries(data.items)
          );

          if (isEmpty(clusterName)) {
            clusterName = pickClusterName(Array.from(items.keys()));
          }

          items.forEach((item, key) => {
            if (item.firstCreated === 1) {
              item.loading = true;
              let countdown = 15;
              const messageKey = key + countdownKey;
              messageKeys.push(messageKey);
              tickers.push(
                setInterval(() => {
                  --countdown;
                  const content = `(${key})自动创建监控成功, 等待grafana加载页面(${
                    countdown + 1
                  })..`;
                  message.success({ content, key: messageKey, duration: 15 });
                }, 1000)
              );
            }
          });

          if (!isEmpty(tickers)) {
            setTimeout(() => {
              this.clearData();
              items.forEach((item) => (item.loading = undefined));
              this.setState({ items });
            }, 15000);
          }

          this.setState({ loading: false, clusterName, items });
        })
        .catch((errMsg) =>
          this.setState({
            loading: false,
            items: new Map<string, IMonitoringItem>(),
            errMsg,
          })
        );
    }
  }

  clearData() {
    tickers.forEach((ticker) => clearInterval(ticker));
    tickers = [];

    messageKeys.forEach((key) => message.destroy(key));
    messageKeys = [];
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevProps.selectedTarsApplication !==
        this.props.selectedTarsApplication ||
      prevProps.selectedTarsSetName !== this.props.selectedTarsSetName ||
      prevProps.selectedTarsServer !== this.props.selectedTarsServer
    ) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.clearData();
  }

  render() {
    return (
      <>
        {!this.state.loading && this.state.items.size > 0 && (
          <Tabs
            size="small"
            activeKey={this.state.clusterName}
            onChange={(clusterName) => {
              if (clusterName !== "reload-btn") this.setState({ clusterName });
            }}
            tabPosition="right"
          >
            {Array.from(this.state.items).map((values) => (
              <TabPane tab={values[0]} key={values[0]}>
                {values[1].loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <Spin size="large" tip="加载中..." />
                  </div>
                )}

                {!values[1].loading && values[1].errCode === 0 && (
                  <iframe
                    style={{ width: "100%", height: 800 }}
                    src={values[1].grafanaURL}
                    frameBorder={0}
                    title={this.props.kind}
                  />
                )}

                {!values[1].loading && values[1].errCode !== 0 && (
                  <Result
                    status="500"
                    title="加载失败"
                    subTitle={values[1].errMsg}
                  />
                )}
              </TabPane>
            ))}

            <TabPane
              key="reload-btn"
              tab={
                <Popconfirm
                  icon={null}
                  trigger="click"
                  okText="修改"
                  cancelText="取消"
                  title="重新创建?"
                  placement="bottom"
                  cancelButtonProps={{
                    className: "tool-btn",
                    ghost: true,
                    size: "small",
                    type: "primary",
                  }}
                  okButtonProps={{
                    className: "tool-btn",
                    ghost: true,
                    size: "small",
                    danger: true,
                  }}
                  onConfirm={() => this.fetchData(true)}
                >
                  <Button type="dashed" size="small" className="tool-btn">
                    <StopOutlined style={{ color: "red", fontSize: 15 }} />
                    重新创建
                  </Button>
                </Popconfirm>
              }
            />
          </Tabs>
        )}

        {this.state.loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" tip="加载中..." />
          </div>
        )}

        {!this.state.loading && !isEmpty(this.state.errMsg) && (
          <Result status="404" title="加载失败" subTitle={this.state.errMsg} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
});

export default connect(mapStateToProps, null)(Monitoring);
