import React from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Spin,
  Select,
  Result,
  Row,
  Input,
  InputNumber,
  Menu,
  Modal,
  Dropdown,
  Tabs,
  Tag,
  Popover,
  message,
  Divider,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";
import { pickClusterName } from "src/utils/Picker";

const { TabPane } = Tabs;
const { Option } = Select;
const MenuItem = Menu.Item;
const FormItem = Form.Item;

interface IProps {
  currentUser?: string;
  haveOperatePerm?: boolean;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
}

interface IPropertyPlusItem {
  errCode: number;
  errMsg: string;
  items: Array<FetchType.IPropertyPlusDashboardItem>;
}

interface IState {
  loading: boolean;
  errMsg: string;
  clusterName: string;
  items: Map<string, IPropertyPlusItem>;

  showLeftRight: boolean;
  selectedDashboardItem?: FetchType.IPropertyPlusDashboardItem;
  editingDashboardItem?: FetchType.IPropertyPlusDashboardItem;
  addDashboardItem?: FetchType.IPropertyPlusDashboardItem;
  editVisible: boolean;
  addVisible: boolean;
  addLabelVisible: boolean;
  addMetricVisible: boolean;
}

class PropertyPlus extends React.Component<IProps, IState> {
  private addLabelForm: React.RefObject<FormInstance>;
  private addMetricForm: React.RefObject<FormInstance>;
  private editForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      errMsg: "",
      clusterName: "",
      items: new Map<string, IPropertyPlusItem>(),
      selectedDashboardItem: undefined,
      editingDashboardItem: undefined,
      addDashboardItem: undefined,
      editVisible: false,
      addVisible: false,
      addLabelVisible: false,
      addMetricVisible: false,
      showLeftRight: false,
    };
    this.addLabelForm = React.createRef<FormInstance>();
    this.addMetricForm = React.createRef<FormInstance>();
    this.editForm = React.createRef<FormInstance>();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const userName = this.props.currentUser || "";
    const tarsApplication = this.props.selectedTarsApplication;
    const tarsSetName = this.props.selectedTarsSetName || "";
    const tarsServerName = this.props.selectedTarsServer;
    if (!tarsApplication || !tarsServerName) {
      this.setState({ loading: false, errMsg: "应用或服务名为空!" });
      return;
    }

    this.setState({ loading: true });
    if (!isEmpty(tarsApplication) && !isEmpty(tarsServerName)) {
      fetch
        .fetchPropertyPlusDashboard({
          userName,
          tarsApplication,
          tarsSetName,
          tarsServerName,
        })
        .then((data) => {
          let selectedDashboardItem = this.state.selectedDashboardItem;
          let editingDashboardItem = this.state.editingDashboardItem;
          let clusterName = this.state.clusterName;

          let items = new Map<string, IPropertyPlusItem>(
            Object.entries(data.items)
          );
          if (items.size > 0) {
            const item = Array.from(items);
            if (!selectedDashboardItem) {
              let found = false;
              for (const i of item) {
                if (i.length !== 2) continue;
                if (found) break;

                for (const j of i[1].items) {
                  if (!isEmpty(j.dashboardName) && !isEmpty(j.grafanaURL)) {
                    selectedDashboardItem = {
                      dashboardName: j.dashboardName,
                      grafanaURL: j.grafanaURL,
                      labels: j.labels,
                      metrics: j.metrics,
                    };
                    editingDashboardItem = { ...selectedDashboardItem };
                    clusterName = i[0];
                    found = true;
                    break;
                  }
                }
              }
            }
          }

          if (isEmpty(clusterName)) {
            clusterName = pickClusterName(Array.from(items.keys()));
          }
          this.setState({
            loading: false,
            clusterName,
            items,
            editingDashboardItem,
            addDashboardItem: undefined,
            selectedDashboardItem: selectedDashboardItem,
          });
        })
        .catch((errMsg) =>
          this.setState({
            loading: false,
            items: new Map<string, IPropertyPlusItem>(),
            errMsg,
          })
        );
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevProps.selectedTarsApplication !==
        this.props.selectedTarsApplication ||
      prevProps.selectedTarsSetName !== this.props.selectedTarsSetName ||
      prevProps.selectedTarsServer !== this.props.selectedTarsServer
    ) {
      this.fetchData();
    }
  }

  changeSelectedDashboard(
    selectedDashboardItem: FetchType.IPropertyPlusDashboardItem
  ) {
    let editingDashboardItem = { ...selectedDashboardItem };
    this.setState({ editingDashboardItem, selectedDashboardItem });
  }

  removeLabel(
    item: FetchType.IPropertyPlusDashboardItem,
    labelName: string
  ): FetchType.IGrafanaLabel[] {
    const labels = item.labels.filter((x) => x.labelName !== labelName);
    return labels;
  }

  addLabel(
    item: FetchType.IPropertyPlusDashboardItem,
    labelName: string,
    dimension: string
  ): FetchType.IGrafanaLabel[] {
    const labels = item.labels.filter((x) => x.labelName !== labelName);
    labels.push({ labelName, dimension });
    return labels;
  }

  addMetric(
    item: FetchType.IPropertyPlusDashboardItem,
    metricName: string,
    policy: string,
    left?: number,
    right?: number
  ) {
    const metrics = item.metrics.filter((x) => x.metricName !== metricName);
    metrics.push({
      metricName,
      policy,
      left: left ? left : 0,
      right: right ? right : 0,
    });
    return metrics;
  }

  removeMetric(item: FetchType.IPropertyPlusDashboardItem, metricName: string) {
    const labels = item.metrics.filter((x) => x.metricName !== metricName);
    return labels;
  }

  fetchAddOrModifyPropertyPlusDashboard(
    item: FetchType.IPropertyPlusDashboardItem
  ) {
    const userName = this.props.currentUser || "";
    const tarsApplication = this.props.selectedTarsApplication;
    const tarsSetName = this.props.selectedTarsSetName || "";
    const tarsServerName = this.props.selectedTarsServer;
    if (!tarsApplication || !tarsServerName) {
      this.setState({ loading: false, errMsg: "应用或服务名为空!" });
      return;
    }

    fetch
      .fetchAddOrModifyPropertyPlusDashboard({
        userName,
        clusterName: this.state.clusterName,
        tarsApplication,
        tarsSetName,
        tarsServerName,
        item,
      })
      .then(() => {
        message.success("更新成功");
        this.setState({ editVisible: false, addVisible: false });
        this.fetchData();
      })
      .catch(() => this.setState({ editVisible: false, addVisible: false }));
  }

  render() {
    return (
      <>
        {!this.state.loading && this.state.items.size > 0 && (
          <Tabs
            size="small"
            activeKey={this.state.clusterName}
            onChange={(clusterName) => {
              if (clusterName !== "edit") this.setState({ clusterName });
            }}
            tabPosition="right"
          >
            {Array.from(this.state.items).map((values) => (
              <TabPane tab={values[0]} key={values[0]}>
                {values[1].errCode !== 0 && (
                  <Result
                    status="404"
                    title="加载失败"
                    subTitle={values[1].errMsg}
                  />
                )}

                {this.state.selectedDashboardItem && (
                  <>
                    <Dropdown
                      overlay={
                        <Menu>
                          {Array.from(values[1].items).map((x, index) => (
                            <MenuItem
                              key={index}
                              onClick={() => this.changeSelectedDashboard(x)}
                            >
                              {x.dashboardName}
                            </MenuItem>
                          ))}
                        </Menu>
                      }
                    >
                      <span>
                        Dashboard:
                        <Button size="small" type="link">
                          {this.state.selectedDashboardItem.dashboardName}{" "}
                          <DownOutlined />
                        </Button>
                      </span>
                    </Dropdown>

                    <Button
                      disabled={!this.props.haveOperatePerm}
                      size="small"
                      type="dashed"
                      className="tool-btn"
                      onClick={() => this.setState({ editVisible: true })}
                    >
                      配置
                    </Button>

                    {!isEmpty(this.state.selectedDashboardItem.grafanaURL) && (
                      <iframe
                        frameBorder={0}
                        style={{ width: "100%", height: 800 }}
                        src={this.state.selectedDashboardItem.grafanaURL}
                        title={this.state.selectedDashboardItem.dashboardName}
                      />
                    )}
                  </>
                )}

                {!this.state.selectedDashboardItem && (
                  <Result
                    status="500"
                    title="找不到配置"
                    subTitle={
                      <>
                        <Button
                          disabled={!this.props.haveOperatePerm}
                          size="small"
                          type="link"
                          onClick={() => {
                            let addDashboardItem: FetchType.IPropertyPlusDashboardItem =
                              {
                                grafanaURL: "",
                                dashboardName: "",
                                labels: [],
                                metrics: [],
                              };
                            this.setState({
                              addDashboardItem,
                              addVisible: true,
                            });
                          }}
                        >
                          新增
                        </Button>
                        PP监控
                      </>
                    }
                  />
                )}
              </TabPane>
            ))}

            <TabPane
              key="edit"
              tab={
                <Button
                  disabled={!this.props.haveOperatePerm}
                  size="small"
                  type="dashed"
                  className="tool-btn"
                  onClick={() => {
                    let addDashboardItem: FetchType.IPropertyPlusDashboardItem =
                      {
                        grafanaURL: "",
                        dashboardName: "",
                        labels: [],
                        metrics: [],
                      };
                    this.setState({ addDashboardItem, addVisible: true });
                  }}
                >
                  新增
                </Button>
              }
            />
          </Tabs>
        )}

        {this.state.loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spin size="large" tip="加载中..." />
          </div>
        )}

        {!this.state.loading && !isEmpty(this.state.errMsg) && (
          <Result status="404" title="加载失败" subTitle={this.state.errMsg} />
        )}

        <Modal
          centered
          maskClosable={false}
          title={
            <>
              Dashboard:{" "}
              <span style={{ textAlign: "center" }}>
                {this.state.selectedDashboardItem
                  ? this.state.selectedDashboardItem.dashboardName
                  : ""}
              </span>{" "}
            </>
          }
          closable={false}
          visible={this.state.editVisible}
          onCancel={() => this.setState({ editVisible: false })}
          okButtonProps={{ danger: true, ghost: true, className: "tool-btn" }}
          cancelButtonProps={{
            type: "primary",
            ghost: true,
            className: "tool-btn",
          }}
          okText="更新"
          onOk={() => {
            if (this.state.editingDashboardItem)
              this.fetchAddOrModifyPropertyPlusDashboard(
                this.state.editingDashboardItem
              );
          }}
        >
          <span>
            <p>
              {this.state.editingDashboardItem &&
                Array.from(this.state.editingDashboardItem.labels).map(
                  (x, index) => (
                    <Tag
                      closable
                      key={index}
                      onClose={() => {
                        if (this.state.editingDashboardItem) {
                          const labels = this.removeLabel(
                            this.state.editingDashboardItem,
                            x.labelName
                          );
                          this.setState({
                            editingDashboardItem: {
                              ...this.state.editingDashboardItem,
                              labels,
                            },
                          });
                        }
                      }}
                    >
                      {x.labelName}={x.dimension}
                    </Tag>
                  )
                )}
            </p>

            <p>
              <Popover
                placement="right"
                content={
                  <span>
                    <Form
                      name="editForm"
                      ref={this.editForm}
                      autoComplete="off"
                      validateTrigger="onBlur"
                    >
                      <p>
                        <FormItem
                          name="labelName"
                          noStyle
                          rules={[{ required: true, message: "标签不能为空" }]}
                        >
                          <Input
                            size="small"
                            addonBefore="标签"
                            placeholder="展示标签 eg:国家"
                          />
                        </FormItem>
                      </p>
                      <p>
                        <FormItem
                          name="dimension"
                          noStyle
                          rules={[{ required: true, message: "维度不能为空" }]}
                        >
                          <Input
                            size="small"
                            addonBefore="维度"
                            placeholder="上报key eg:country"
                          />
                        </FormItem>
                      </p>
                      <p>
                        <Button
                          ghost
                          type="primary"
                          size="small"
                          className="tool-btn"
                          style={{ marginLeft: 10 }}
                          onClick={async () => {
                            try {
                              if (
                                this.editForm.current &&
                                this.state.editingDashboardItem
                              ) {
                                const fields =
                                  await this.editForm.current.validateFields();
                                const labels = this.addLabel(
                                  this.state.editingDashboardItem,
                                  fields.labelName,
                                  fields.dimension
                                );
                                this.setState({
                                  editingDashboardItem: {
                                    ...this.state.editingDashboardItem,
                                    labels,
                                  },
                                  addLabelVisible: false,
                                });
                              }
                            } catch {}
                          }}
                        >
                          添加
                        </Button>
                      </p>
                    </Form>
                  </span>
                }
                trigger="click"
                overlayStyle={{ width: 270 }}
                visible={this.state.addLabelVisible}
                onVisibleChange={(addLabelVisible) =>
                  this.setState({ addLabelVisible })
                }
              >
                <Button
                  disabled={!this.props.haveOperatePerm}
                  size="small"
                  type="dashed"
                  onClick={() => this.setState({ addLabelVisible: true })}
                >
                  新增
                </Button>
              </Popover>
            </p>
          </span>
        </Modal>

        <Modal
          centered
          maskClosable={false}
          title="新建Dashboard"
          closable={false}
          visible={this.state.addVisible}
          onCancel={() => this.setState({ addVisible: false })}
          okButtonProps={{ danger: true, ghost: true, className: "tool-btn" }}
          cancelButtonProps={{
            type: "primary",
            ghost: true,
            className: "tool-btn",
          }}
          okText="添加"
          onOk={() => {
            if (!this.state.addDashboardItem) {
              message.error("系统错误");
              return;
            }
            if (isEmpty(this.state.addDashboardItem.dashboardName)) {
              message.error("Dashboard为空");
              return;
            }
            if (this.state.addDashboardItem) {
              this.fetchAddOrModifyPropertyPlusDashboard(
                this.state.addDashboardItem
              );
            }
          }}
        >
          <span>
            <p>
              <Input
                size="small"
                addonBefore="Dashboard"
                onChange={(e) => {
                  if (this.state.addDashboardItem) {
                    const item = { ...this.state.addDashboardItem };
                    item.dashboardName = e.target.value;
                    this.setState({ addDashboardItem: item });
                  }
                }}
              />
            </p>

            <p>
              {this.state.addDashboardItem &&
                Array.from(this.state.addDashboardItem.labels).map(
                  (x, index) => (
                    <Tag
                      closable
                      key={index}
                      onClose={() => {
                        if (this.state.addDashboardItem) {
                          const labels = this.removeLabel(
                            this.state.addDashboardItem,
                            x.labelName
                          );
                          this.setState({
                            addDashboardItem: {
                              ...this.state.addDashboardItem,
                              labels,
                            },
                          });
                        }
                      }}
                    >
                      {x.labelName}={x.dimension}
                    </Tag>
                  )
                )}

              <Divider />

              {this.state.addDashboardItem &&
                Array.from(this.state.addDashboardItem.metrics).map(
                  (x, index) => (
                    <Tag
                      closable
                      key={index}
                      onClose={() => {
                        if (this.state.addDashboardItem) {
                          const metrics = this.removeMetric(
                            this.state.addDashboardItem,
                            x.metricName
                          );
                          this.setState({
                            addDashboardItem: {
                              ...this.state.addDashboardItem,
                              metrics,
                            },
                          });
                        }
                      }}
                    >
                      {x.metricName}({x.policy})
                      {x.policy === "Add" ||
                      x.policy === "Sub" ||
                      x.policy === "Divide" ||
                      x.policy === "Multiply"
                        ? " [left:" +
                          x.left.toString() +
                          ", right:" +
                          x.right.toString() +
                          "]"
                        : null}
                    </Tag>
                  )
                )}
            </p>

            <Divider />

            <Row justify="space-around">
              <Col>
                <Form
                  name="addLabelForm"
                  ref={this.addLabelForm}
                  autoComplete="off"
                  validateTrigger="onBlur"
                >
                  <Popover
                    placement="right"
                    content={
                      <span>
                        <p>
                          <FormItem
                            name="labelName"
                            noStyle
                            rules={[
                              { required: true, message: "标签名不能为空" },
                            ]}
                          >
                            <Input
                              size="small"
                              addonBefore="标签名"
                              placeholder="展示标签 eg:国家"
                            />
                          </FormItem>
                        </p>
                        <p>
                          <FormItem
                            name="dimension"
                            noStyle
                            rules={[
                              { required: true, message: "标签value不能为空" },
                            ]}
                          >
                            <Input
                              size="small"
                              addonBefore="标签value"
                              placeholder="上报key eg:country"
                            />
                          </FormItem>
                        </p>
                        <p>
                          <Button
                            ghost
                            type="primary"
                            size="small"
                            className="tool-btn"
                            style={{ marginLeft: 10 }}
                            onClick={async () => {
                              try {
                                if (
                                  this.addLabelForm.current &&
                                  this.state.addDashboardItem
                                ) {
                                  const fields =
                                    await this.addLabelForm.current.validateFields();
                                  const labels = this.addLabel(
                                    this.state.addDashboardItem,
                                    fields.labelName,
                                    fields.dimension
                                  );
                                  this.setState({
                                    addDashboardItem: {
                                      ...this.state.addDashboardItem,
                                      labels,
                                    },
                                    addLabelVisible: false,
                                  });
                                }
                              } catch {}
                            }}
                          >
                            添加
                          </Button>
                        </p>
                      </span>
                    }
                    trigger="click"
                    overlayStyle={{ width: 270 }}
                    visible={this.state.addLabelVisible}
                    onVisibleChange={(addLabelVisible) =>
                      this.setState({ addLabelVisible })
                    }
                  >
                    <Button
                      size="small"
                      type="dashed"
                      onClick={() => this.setState({ addLabelVisible: true })}
                    >
                      新增Label
                    </Button>
                  </Popover>
                </Form>
              </Col>
              <Col>
                <Form
                  name="addMetricForm"
                  ref={this.addMetricForm}
                  autoComplete="off"
                  validateTrigger="onBlur"
                  initialValues={{ policy: "Max", left: 0, right: 1 }}
                >
                  <Popover
                    placement="right"
                    content={
                      <span>
                        <p>
                          <FormItem
                            name="metricName"
                            label="metric"
                            rules={[
                              { required: true, message: "metricName不能为空" },
                            ]}
                          >
                            <Input
                              size="small"
                              placeholder="上报维度 eg:country"
                              style={{ width: 148 }}
                            />
                          </FormItem>
                        </p>
                        <p>
                          <FormItem
                            name="policy"
                            label="policy"
                            rules={[
                              { required: true, message: "聚合策略不能为空" },
                            ]}
                          >
                            <Select
                              size="small"
                              onChange={(value) => {
                                const showLeftRight =
                                  value === "Add" ||
                                  value === "Sub" ||
                                  value === "Divide" ||
                                  value === "Multiply";
                                this.setState({ showLeftRight });
                                if (this.addMetricForm.current)
                                  this.addMetricForm.current.setFieldsValue({
                                    left: 0,
                                    right: 1,
                                  });
                              }}
                            >
                              <Option value="Sum">Sum</Option>
                              <Option value="Avg">Avg</Option>
                              <Option value="Count">Count</Option>
                              <Option value="Max">Max</Option>
                              <Option value="Min">Min</Option>
                              <Option value="Add">Add</Option>
                              <Option value="Sub">Sub</Option>
                              <Option value="Divide">Divide</Option>
                              <Option value="Multiply">Multiply</Option>
                            </Select>
                          </FormItem>
                        </p>

                        <p>
                          <Row justify="space-around">
                            <Col>
                              <FormItem name="left" noStyle>
                                <InputNumber
                                  size="small"
                                  addonBefore="left"
                                  disabled={!this.state.showLeftRight}
                                  style={{ width: 100 }}
                                  min={0}
                                />
                              </FormItem>
                            </Col>
                            <Col>
                              <FormItem name="right" noStyle>
                                <InputNumber
                                  size="small"
                                  addonBefore="right"
                                  disabled={!this.state.showLeftRight}
                                  style={{ width: 100 }}
                                  min={0}
                                />
                              </FormItem>
                            </Col>
                          </Row>
                        </p>

                        <p>
                          <Button
                            ghost
                            type="primary"
                            size="small"
                            className="tool-btn"
                            style={{ marginLeft: 10 }}
                            onClick={async () => {
                              try {
                                if (
                                  this.addMetricForm.current &&
                                  this.state.addDashboardItem
                                ) {
                                  const fields =
                                    await this.addMetricForm.current.validateFields();
                                  const metrics = this.addMetric(
                                    this.state.addDashboardItem,
                                    fields.metricName,
                                    fields.policy,
                                    fields.left,
                                    fields.right
                                  );
                                  this.setState({
                                    addDashboardItem: {
                                      ...this.state.addDashboardItem,
                                      metrics,
                                    },
                                    addMetricVisible: false,
                                  });
                                }
                              } catch {}
                            }}
                          >
                            添加
                          </Button>
                        </p>
                      </span>
                    }
                    trigger="click"
                    overlayStyle={{ width: 270 }}
                    visible={this.state.addMetricVisible}
                    onVisibleChange={(addMetricVisible) =>
                      this.setState({ addMetricVisible })
                    }
                  >
                    <Button
                      size="small"
                      type="dashed"
                      onClick={() => this.setState({ addMetricVisible: true })}
                    >
                      新增Metric
                    </Button>
                  </Popover>
                </Form>
              </Col>
            </Row>
          </span>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  haveOperatePerm: state.tarsReducer.selectedTarsPerm === "W",
});

export default connect(mapStateToProps, null)(PropertyPlus);
