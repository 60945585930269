import React, { forwardRef, useEffect, useImperativeHandle } from "react";

import CommonReporter from "./../../../components/CommonReporter";

// TODO: 需要注意在pre测试，要把t_alarm_conf ==> t_alarm_conf_v1
// 其他情况修改回来

const serverAlarmTag = `db_resource_{cluster_name}.t_alarm_conf.global`;

interface IProps {
  currentUser?: string;
}
const GlobalAlarmConfig = forwardRef(
  (props: IProps, ref: React.LegacyRef<HTMLDivElement> | undefined) => {
    const fetchData = () => {
      console.log("fetchData1");
    };

    useEffect(() => {
      fetchData();
    }, []);

    useImperativeHandle(ref as React.Ref<unknown> | undefined, () => ({
      fetchData,
    }));

    return (
      <div ref={ref}>
        <CommonReporter tableId={serverAlarmTag} userName={props.currentUser} />
      </div>
    );
  }
);

export default GlobalAlarmConfig;
