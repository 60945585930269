import React from "react";
import { isEmpty } from "lodash";
import { Anchor, Modal } from "antd";
import { connect } from "react-redux";

import * as fetch from "src/fetch";
import { ApproveKindEnum, getStaticClusterName } from 'src/utils/constant';

import Restart from "src/pages/DevOps/ToolsBar/Restart";
import Stop from "src/pages/DevOps/ToolsBar/Stop";
import Patch from "src/pages/DevOps/ToolsBar/Patch";
import Notify from "src/pages/DevOps/ToolsBar/Notify";
import EditConfig from "src/pages/DevOps/ToolsBar/EditConfig";
import EditTemplate from "src/pages/DevOps/ToolsBar/EditTemplate";
import EditAlarm from "src/pages/DevOps/ToolsBar/EditAlarm";
import EditLogLevel from "src/pages/DevOps/ToolsBar/EditLogLevel";
import EditPerson from "src/pages/DevOps/ToolsBar/EditPerson";
import Ingress from "src/pages/DevOps/ToolsBar/Ingress";
import SimpleCodingBuild from "src/pages/DevOps/ToolsBar/SimpleCodingBuild";
import AdjustResource from "src/pages/DevOps/ToolsBar/AdjustResource";
import ExpandNode from "src/pages/DevOps/ToolsBar/ExpandNode";
import AutoScaling from "src/pages/DevOps/ToolsBar/AutoScaling";
import CronHPA from "src/pages/DevOps/ToolsBar/CronHPA";
import ReduceNode from "src/pages/DevOps/ToolsBar/ReduceNode";
import Undeploy from "src/pages/DevOps/ToolsBar/Undeploy";
import { SERVER_STATUS } from "./utils";
import * as ActionType from "src/stores/types";
import { pickClusterName } from "src/utils/Picker";

const { Link } = Anchor;

interface IProps {
  tab?: string;
  haveOperatePerm?: boolean;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  pausedItems?: Map<string, number>;
  jobItems?: Map<string, ActionType.IJobItem>;
  httpItems?: Map<string, number>;
  serverStatus?: SERVER_STATUS;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  admin?: boolean;
}

interface IState {
  tab?: string;
  selectedTarsServer?: string;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  approveStatus: {
    stop: number,
    publish: number,
    delete: number,
    alterDomain: number,
    editConfig: Number;
    autoScaling: Number;
  }
  pendingApproveUser: string;
}

interface IApproveStatus {
  kind: Number;
  stage: Number;
  createdBy: string;
}

class RightToolsBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      approveStatus: {
        stop: 1,
        publish: 1,
        delete: 1,
        alterDomain: 1,
        editConfig: 1,
        autoScaling: 1
      },
      pendingApproveUser: '',
    };

    this.refreshServerApproveStatus = this.refreshServerApproveStatus.bind(this);
  }

  refreshServerApproveStatus() {
    fetch.fetchServerApproveStatus({
      userName: this.props.currentUser || "",
      clusterName: getStaticClusterName(),
      tarsApplication: this.props.selectedTarsApplication,
      tarsSetName: this.props.selectedTarsSetName || "",
      tarsServerName: this.props.selectedTarsServer,
    }).then((data) => {
      if (data && data.errCode === 0 && data.items.length > 0) {
        const newApproveStatus: any = {};
        let currentPendingApproveUser = '';
        data.items.forEach(({ kind, stage, createdBy }: IApproveStatus) => {
          const kindKey = ApproveKindEnum[String(kind)];
          if (kindKey) {
            newApproveStatus[kindKey] = stage;
          }
          if (createdBy) {
            currentPendingApproveUser = createdBy;
          }
        });
        this.setState({
          approveStatus: newApproveStatus,
          pendingApproveUser: currentPendingApproveUser,
        })
      } else {
        Modal.error({
          title: '审批异常',
          content: '获取审批状态错误',
        });
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    const changeServer = (
      this.props.selectedTarsApplication !== prevProps.selectedTarsApplication ||
      this.props.selectedTarsSetName !== prevProps.selectedTarsSetName ||
      this.props.selectedTarsServer !== prevProps.selectedTarsServer
    );

    if (changeServer) {
      this.refreshServerApproveStatus()
    }
  }

  componentDidMount(): void {
    this.refreshServerApproveStatus()
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (
      props.tab !== state.tab ||
      props.selectedTarsServer !== state.selectedTarsServer ||
      props.tarsServerItems !== state.tarsServerItems
    ) {
      return {
        tab: props.tab,
        selectedTarsServer: props.selectedTarsServer,
        tarsServerItems: props.tarsServerItems,
      };
    }
    return null;
  }

  render() {
    const disabled =
      !this.state.tarsServerItems || isEmpty(this.state.tarsServerItems);
    let jobIDs: Array<ActionType.IJobItem> = [];
    if (this.props.jobItems && this.props.jobItems.size > 0) {
      const clusterName = pickClusterName(
        Array.from(this.props.jobItems.keys())
      );
      const jobID = this.props.jobItems.get(clusterName);
      if (jobID && jobID.jobID !== 0) jobIDs.push(jobID);

      if (isEmpty(jobIDs)) {
        jobIDs =
          this.props.jobItems && this.props.jobItems.size > 0
            ? Array.from(this.props.jobItems.values()).filter(
              (x) => x.jobID !== 0
            )
            : [];
      }
    }

    let isPaused = false;
    if (this.props.pausedItems) {
      isPaused = Array.from(this.props.pausedItems).some(
        (value: [string, number]) => value.length === 2 && value[1] > 0
      );
    }

    const isPublishing =
      this.props.serverStatus !== SERVER_STATUS.RolloutStatusFinished;
    const { haveOperatePerm } = this.props;
    return (
      <>
        {this.state.tab === "devops" &&
          this.state.selectedTarsServer &&
          !isEmpty(this.state.selectedTarsServer) && (
            <Anchor
              showInkInFixed={false}
              bounds={10}
              getCurrentAnchor={() => ""}
            >
              <Link href="/home" title="服务操作">
                <Link
                  title={
                    <Stop
                      disabled={disabled || !haveOperatePerm}
                      gracefully={false}
                      approveStatus={this.state.approveStatus.stop}
                      updateApproveStatus={this.refreshServerApproveStatus}
                      pendingApproveUser={this.state.pendingApproveUser}
                    />
                  }
                />
                <Link
                  title={
                    <Restart
                      disabled={disabled || !haveOperatePerm}
                      gracefully={false}
                    />
                  }
                />
                <Link
                  title={
                    <Patch
                      disabled={isPublishing || disabled || !haveOperatePerm}
                      approveStatus={this.state.approveStatus.publish}
                      updateApproveStatus={this.refreshServerApproveStatus}
                      pendingApproveUser={this.state.pendingApproveUser}
                    />
                  }
                />
                <Link
                  title={
                    <Stop
                      disabled={disabled || !haveOperatePerm}
                      gracefully={true}
                      approveStatus={this.state.approveStatus.stop}
                      updateApproveStatus={this.refreshServerApproveStatus}
                      pendingApproveUser={this.state.pendingApproveUser}
                    />
                  }
                />
                <Link
                  title={
                    <Restart
                      disabled={disabled || !haveOperatePerm}
                      gracefully={true}
                    />
                  }
                />
                <Link
                  title={
                    <Notify
                      disabled={disabled || !haveOperatePerm}
                    />
                  }
                />
              </Link>

              <Link href="/home" title="服务运维">
                <Link
                  title={
                    <SimpleCodingBuild
                      disabled={disabled || !haveOperatePerm}
                      jobIDs={jobIDs}
                    />
                  }
                />
                <Link
                  title={
                    <EditConfig
                      disabled={disabled}
                      forbitEdit={!haveOperatePerm}
                      approveStatus={this.state.approveStatus.editConfig}
                      updateApproveStatus={this.refreshServerApproveStatus}
                      pendingApproveUser={this.state.pendingApproveUser}
                    />
                  }
                />
                <Link
                  title={
                    <EditTemplate
                      forbitEdit={!haveOperatePerm}
                      disabled={disabled}
                    />
                  }
                />
                <Link
                  title={
                    <EditAlarm
                      forbitEdit={!haveOperatePerm}
                      disabled={disabled}
                    />
                  }
                />
                <Link
                  title={
                    <EditLogLevel disabled={disabled || !haveOperatePerm} />
                  }
                />
                <Link
                  title={
                    <Ingress
                      forbitEdit={!haveOperatePerm}
                      disabled={
                        disabled ||
                        Array.from(
                          (this.props.httpItems || new Map()).values()
                        ).every((x) => x === 0)
                      }
                      approveStatus={this.state.approveStatus.alterDomain}
                      updateApproveStatus={this.refreshServerApproveStatus}
                      pendingApproveUser={this.state.pendingApproveUser}
                    />
                  }
                />
                <Link
                  title={
                    <EditPerson
                      forbitEdit={!haveOperatePerm}
                      disabled={disabled}
                    />
                  }
                />
              </Link>

              <Link href="/home" title="服务调整">
                <Link
                  title={
                    <AdjustResource
                      forbitEdit={!haveOperatePerm}
                      disabled={isPublishing || disabled || isPaused}
                      admin={this.props.admin}
                    />
                  }
                />
                <Link
                  title={
                    <ExpandNode
                      disabled={
                        isPublishing || disabled || isPaused || !haveOperatePerm
                      }
                    />
                  }
                />
                <Link
                  title={
                    <AutoScaling
                      disabled={
                        isPublishing || disabled || isPaused || !haveOperatePerm
                      }
                      approveStatus={this.state.approveStatus.autoScaling}
                      updateApproveStatus={this.refreshServerApproveStatus}
                      pendingApproveUser={this.state.pendingApproveUser}
                    />
                  }
                />
                <Link
                  title={
                    <CronHPA
                      forbitEdit={!haveOperatePerm}
                      disabled={isPublishing || disabled || isPaused}
                    />
                  }
                />
                <Link
                  title={
                    <ReduceNode
                      disabled={
                        isPublishing || disabled || isPaused || !haveOperatePerm
                      }
                    />
                  }
                />
                <Link
                  title={
                    <Undeploy
                      disabled={
                        isPublishing || disabled || isPaused || !haveOperatePerm
                      }
                      approveStatus={this.state.approveStatus.delete}
                      updateApproveStatus={this.refreshServerApproveStatus}
                      pendingApproveUser={this.state.pendingApproveUser}
                    />
                  }
                />
              </Link>
            </Anchor>
          )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  tab: state.tarsReducer.tab,
  haveOperatePerm: state.tarsReducer.selectedTarsPerm === "W",
  tarsServerItems: state.tarsReducer.tarsServerItems,
  pausedItems: state.tarsReducer.pausedItems,
  jobItems: state.tarsReducer.jobItems,
  httpItems: state.tarsReducer.httpItems,
  serverStatus: state.tarsReducer.serverStatus,
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  admin: state.tarsReducer.admin,
});

export default connect(mapStateToProps, null)(RightToolsBar);
