/* eslint-disable @typescript-eslint/no-unused-vars */
import sha1 from "sha1";
import { post } from "./baseApi";
import webApi from "./webApi";
import { message, notification } from "antd";
import { MessageType } from "antd/lib/message";
import { cacheFetchAndSave } from "./util";
import { getIsDevEnv } from "src/utils/constant";

import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";
import * as FetchType from "src/fetch/types";
export * from "./baseApi";

const ErrCodeOK = 0;
const ErrCodeParseTarsFileFailed = 12;
const ErrCodeTarsFileNotFound = 13;

function tarsApi<T = any>(
  path: string,
  payload: any,
  errorNeedReture = false
): Promise<T> {
  return cacheFetchAndSave(path, payload, () => {
    return post<
      {
        errCode: number;
        errMsg: string;
        stack?: string;
      } & T
    >(`server/api/v2/${path}`, payload).then((res) => {
      if (
        (res && res.errCode === ErrCodeOK) ||
        res.errCode === ErrCodeParseTarsFileFailed ||
        res.errCode === ErrCodeTarsFileNotFound
      ) {
        return res;
      } else {
        const errCode = res && res.errCode === undefined ? -1 : res.errCode;
        const errMsg =
          res && res.errMsg && typeof res.errMsg === "string"
            ? res.errMsg
            : res && typeof res.stack === "string"
            ? res.stack
            : JSON.stringify(res);
        notification.error({
          message: `${path}请求失败, 稍后重试`,
          description: `errCode:${errCode}\nerrMsg:${errMsg}`,
          style: { width: 500, whiteSpace: "pre-line" },
        });
        if (errorNeedReture) {
          return res;
        } else {
          Promise.reject("error");
        }
      }
    });
  });
}

function dcacheApi<T = any>(path: string, payload: any): Promise<T> {
  return post(`dcache/api/v2/${path}`, payload);
}

export function fetchLogin(obj: FetchType.ILoginReq): Promise<any> {
  const path: string = "login";
  const { userName, password } = obj;
  const shaPassword = sha1(password);
  return new Promise((resolve) => {
    tarsApi<{ admin: number }>(path, { userName, shaPassword }).then((data) => {
      resolve({ admin: data.admin });
    });
  });
}

export function fetchRegister(obj: FetchType.IRegisterReq): Promise<any> {
  const path: string = "register";
  const { userName, password, phoneNumber, email } = obj;
  const shaPassword = sha1(password);
  return new Promise((resolve) => {
    tarsApi<{ admin: number }>(path, {
      userName,
      shaPassword,
      phoneNumber,
      email,
    }).then((data) => {
      resolve({ admin: data.admin });
    });
  });
}

export function fetchCreateServer(
  obj: FetchType.ICreateServerReq
): Promise<any> {
  const path: string = "create_server";
  return new Promise((resolve) => {
    const hide = message.loading("服务创建中..", 0);
    tarsApi<{ resourceName: string }>(path, obj)
      .then((data) => {
        resolve({ resourceName: data.resourceName });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchDeleteServer(
  obj: FetchType.IDeleteServerReq
): Promise<any> {
  const path: string = "delete_server";
  return new Promise((resolve) => {
    const hide = message.loading("服务删除中..", 0);
    tarsApi(path, obj)
      .then(() => {
        message.success("下线成功, 节点将自动下线");
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchGetDeployOptionSet(): Promise<any> {
  const path: string = "get_deploy_option_set";
  return new Promise((resolve) => {
    tarsApi(path, {}).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchAddAuth(obj: FetchType.IAddAuthReq): Promise<any> {
  const path: string = "add_auth";
  return new Promise((resolve) => {
    const hide = message.loading("添加中..", 0);
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListAuth(obj: FetchType.IListAuthReq): Promise<any> {
  const path: string = "list_auth";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("准备中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ items: data.items });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchDeleteAuth(obj: FetchType.IDeleteAuthReq): Promise<any> {
  const path: string = "delete_auth";
  return new Promise((resolve) => {
    const hide = message.loading("删除中..", 0);
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListServerTree(
  obj: FetchType.IListServerTreeReq
): Promise<any> {
  const path: string = "list_server_tree";
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      let treeNodes = new Array<ActionType.ITreeItem>();
      for (const item of data.items) {
        treeNodes.push(item);
      }
      resolve({ treeNodes });
    });
  });
}

export function fetchUpdateMachineUsage(
  obj: FetchType.IUpdateMachineUsageReq
): Promise<any> {
  const path: string = "update_machine_usage";
  return new Promise((resolve) => {
    const hide = message.loading("更新中..", 0);
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListServer(obj: FetchType.IListServerReq): Promise<any> {
  const path: string = "list_server";
  const { tarsApplication, tarsSetName, tarsServerName, withLoading } = obj;
  let hide: MessageType | null = null;
  if (withLoading) {
    message.destroy();
    hide = message.loading("加载中..", 0);
  }
  return new Promise((resolve) => {
    tarsApi(path, {
      tarsApplication,
      tarsSetName,
      tarsServerName,
    })
      .then((data) => {
        const pausedItems = new Map<string, number>(
          Object.entries(data.pausedItems)
        );
        const canResumeItems = new Map<string, number>(
          Object.entries(data.canResumeItems)
        );
        const newRSCreationTimestampItems = new Map<string, string>(
          Object.entries(data.newRSCreationTimestampItems)
        );
        const jobItems = new Map<string, ActionType.IJobItem>(
          Object.entries(data.jobItems)
        );
        const statefulsetItems = new Map<string, number>(
          Object.entries(data.statefulsetItems)
        );
        const replicasItems = new Map<string, number>(
          Object.entries(data.replicasItems)
        );
        const newRSItems = new Map<string, number>(
          Object.entries(data.newRSItems)
        );
        const commentsItems = new Map<string, string>(
          Object.entries(data.commentsItems)
        );
        const operationItems = new Map<
          string,
          Array<ActionType.IOperationItem>
        >(Object.entries(data.operationItems));
        const operationLenItems = new Map<string, number>(
          Object.entries(data.operationLenItems)
        );
        const systemOperationLenItems = new Map<string, number>(
          Object.entries(data.systemOperationLenItems)
        );
        const httpItems = new Map<string, number>(
          Object.entries(data.httpItems)
        );
        const result: Actions.ListServerPayLoad = {
          tarsApplication,
          tarsSetName,
          tarsServer: tarsServerName,
          pausedItems,
          canResumeItems,
          jobItems,
          statefulsetItems,
          newRSCreationTimestampItems,
          replicasItems,
          newRSItems,
          depotID: data.depotID,
          tarsServerItems: data.items,
          operationItems,
          operationLenItems,
          systemOperationLenItems,
          commentsItems,
          httpItems,
        };
        resolve(result);
      })
      .finally(() => {
        if (hide) hide();
      });
  });
}

export function fetchMonitoringGrafanaDashboard(
  obj: FetchType.IGetOrCreateMonitoringReq
): Promise<any> {
  const path: string = "get_or_create_monitoring";
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchPropertyPlusDashboard(
  obj: FetchType.IGetPropertyPlusDashboard
): Promise<any> {
  const path: string = "get_property_plus_dashboard";
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchAddOrModifyPropertyPlusDashboard(
  obj: FetchType.IAddOrModifyPropertyPlusDashboard
): Promise<any> {
  const path: string = "add_or_modify_property_plus_dashboard";
  return new Promise((resolve) => {
    const hide = message.loading("更新中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ items: data.items });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchSwitchNetstat(
  obj: FetchType.ISwitchNetstatReq
): Promise<any> {
  const path: string = "switch_server_netstat";
  const {
    userName,
    tarsApplication,
    tarsServerName,
    podNetworkInterfaces,
    netstat,
  } = obj;
  return new Promise((resolve) => {
    const hide = message.loading("切换中..", 0);
    const toNetstat = netstat === "正常" ? 2 : 0;
    const toNetstatDesc = netstat === "正常" ? "无流量" : "正常";
    tarsApi(path, {
      tarsApplication,
      tarsServerName,
      podNetworkInterfaces,
      userName,
      netstat: toNetstat,
    })
      .then(() => {
        message.success("切换成功");
        resolve({ podNetworkInterfaces, netstat: toNetstatDesc, hide });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchRestartServer(
  obj: FetchType.IRestartServerReq
): Promise<any> {
  const path: string = "restart_server";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("重启中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ taskID: data.taskID });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchStopServer(obj: FetchType.IStopServerReq): Promise<any> {
  const path: string = "stop_server";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("停止中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ taskID: data.taskID });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchQueryOperationLogList(
  obj: FetchType.IQueryOperationLogListReq
): Promise<any> {
  const path: string = "query_operation_log_list";
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve({ logItems: data.logItems });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchSendNotification(
  obj: FetchType.ISendServerNotificationReq
): Promise<any> {
  const path: string = "send_server_notification";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("发送中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ items: data.items });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchSetServerLogLevel(
  obj: FetchType.ISetServerLogLevelReq
): Promise<any> {
  const path: string = "set_server_log_level";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("设置中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ items: data.items });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchGetServerLogLevel(
  obj: FetchType.IGetServerLogLevelReq
): Promise<any> {
  const path: string = "get_server_loglevel";
  return new Promise((resolve) => {
    const hide = message.loading("加载中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ items: data.items });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListServerAlarmConfig(
  obj: FetchType.IListServerAlarmConfigReq
): Promise<any> {
  const path: string = "list_server_alarm_config";
  return new Promise((resolve) => {
    const hide = message.loading("加载中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ items: data.items });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchAddOrModifyServerAlarmConfig(
  obj: FetchType.AddOrModifyServerAlarmConfigReq
): Promise<any> {
  const path: string = "add_or_modify_server_alarm_config";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("修改中..", 0);
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchDeleteServerAlarmConfig(
  obj: FetchType.DeleteServerAlarmConfigReq
): Promise<any> {
  const path: string = "delete_server_alarm_config";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("删除中..", 0);
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchConfigs(obj: FetchType.IListServerConfig): Promise<any> {
  const path: string = "list_server_config";
  return new Promise((resolve) => {
    message.destroy();
    tarsApi(path, obj).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchHistoryConfigs(
  obj: FetchType.IListServerHistoryConfig
): Promise<any> {
  const path: string = "list_server_config_history";
  return new Promise((resolve) => {
    message.destroy();
    tarsApi(path, obj).then((data) => resolve(data));
  });
}

export function fetchAddConfig(
  obj: FetchType.IAddServerConfigReq
): Promise<any> {
  const path: string = "add_server_config";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("添加中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ confID: data.confID, confIDCName: data.confIDCName });
      })
      .finally(() => {
        if (hide) hide();
      });
  });
}

export function fetchEditConfig(
  obj: FetchType.IEditServerConfigReq
): Promise<any> {
  const path: string = "edit_server_config";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("修改中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ id: data.id });
      })
      .finally(() => {
        if (hide) hide();
      });
  });
}

export function fetchDeleteConfig(
  obj: FetchType.IDeleteServerConfigReq
): Promise<any> {
  const path: string = "delete_server_config";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("删除中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({});
      })
      .finally(() => {
        if (hide) hide();
      });
  });
}

export function fetchTemplates(
  obj: FetchType.IListServerTemplateReq
): Promise<any> {
  const path: string = "list_server_template";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("加载中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({
          editServantItems: data.items,
          availTemplateName: data.availTemplateName,
        });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchEditTemplate(
  obj: FetchType.IEditServerTemplateReq
): Promise<any> {
  const path: string = "edit_server_template";
  return new Promise((resolve) => {
    const hide = message.loading("设置中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        message.success("设置成功");
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

/**
 * 继续发布/滚动发布
 * @param obj
 * @returns
 */
export function fetchRolloutResume(
  obj: FetchType.IRolloutResumeServerReq
): Promise<any> {
  const path: string = "rollout_resume_server";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("滚动发布中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        message.success("发布成功");
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchRolloutUndo(
  obj: FetchType.IRolloutUndoServerReq
): Promise<any> {
  const path: string = "rollout_undo_server";
  return new Promise((resolve, reject) => {
    message.destroy();
    let countdown = 0;
    const key = `${obj.tarsApplication}.${obj.tarsServerName}`;
    message.loading({
      content: `(${key})回滚中(${countdown + 1})，预计60s..`,
      key,
      duration: 15,
    });
    let ticker = setInterval(() => {
      ++countdown;
      const content = `(${key})回滚中(${countdown})，预计60s..`;
      message.loading({ content, key, duration: 60 });
    }, 1000);

    tarsApi(path, obj)
      .then(() => {
        setTimeout(() => {
          clearInterval(ticker);
          message.destroy(key);
          message.success("回滚成功");
          resolve({});
        }, 30 * 1000);
      })
      .catch((err) => {
        clearInterval(ticker);
        message.destroy(key);
        reject(err);
      });
  });
}

export function fetchRolloutSetImage(
  obj: FetchType.IRolloutSetImageReq
): Promise<any> {
  const path: string = "rollout_set_image";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("发布中..", 0);
    tarsApi(path, obj)
      .then(() => {
        message.success("发布成功,及时关注发布状态");
        resolve({});
        if (getIsDevEnv()) {
          return;
        }
        webApi.post("server/notify", obj);
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchScaleInServer(
  obj: FetchType.IScaleInServerReq
): Promise<any> {
  const path: string = "scale_in_server";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("缩容中..", 0);
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchScaleOutServer(
  obj: FetchType.IScaleOutServerReq
): Promise<any> {
  const path: string = "scale_out_server";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("扩容中..", 0);
    // fetchAuditData(path, obj)
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListServerResource(
  obj: FetchType.IListServerResourceReq
): Promise<any> {
  const path: string = "list_server_resource";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("获取中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ items: data.items });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchAdjustServerResource(obj: any): Promise<any> {
  const path: string = "adjust_server_resource";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("调整中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListServerRepo(
  obj: FetchType.IListServerRepoReq
): Promise<any> {
  const path: string = "list_server_repo";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("准备中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({
          rspItems: data.rspItems,
          repoItems: data.repoItems,
          revisionItems: data.revisionItems,
        });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListCodingProject(
  obj: FetchType.IListCodingProjectReq
): Promise<any> {
  const path: string = "list_coding_project";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("准备中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ projItems: data.projItems });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchAssociateServerRepo(
  obj: FetchType.IAssociateServerRepoReq
): Promise<any> {
  const path: string = "associate_server_repo";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("关联中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        notification.success({ message: "关联成功" });
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListTemplate(): Promise<any> {
  const path: string = "list_template";
  return new Promise((resolve) => {
    tarsApi(path, {}).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchDeleteTemplate(
  obj: FetchType.IDeleteTemplateReq
): Promise<any> {
  const path: string = "delete_template";
  message.destroy();
  const hide = message.loading("删除中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchAddOrModifyTemplate(
  obj: FetchType.IAddOrModifyTemplateReq
) {
  const path: string = "add_or_modify_template";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("添加中..", 0);
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListMachine(): Promise<any> {
  const path: string = "list_machine";
  return new Promise((resolve) => {
    tarsApi(path, {}).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchListMachinePods(
  obj: FetchType.IListMachinePodsReq
): Promise<any> {
  const path: string = "list_machine_pods";
  return new Promise((resolve) => {
    message.destroy();
    const hide = message.loading("获取中..", 0);
    tarsApi(path, obj)
      .then((data) => {
        resolve({ items: data.items });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchDeletePod(obj: FetchType.IDeletePodReq): Promise<any> {
  const path: string = "delete_pod";
  message.destroy();
  const hide = message.loading("删除中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        notification.success({ message: "删除成功" });
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListNodePool(): Promise<any> {
  const path: string = "list_node_pool";
  return new Promise((resolve) => {
    tarsApi(path, {}).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchListOperation(): Promise<any> {
  const path: string = "list_operation";
  return new Promise((resolve) => {
    tarsApi(path, {}).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchExpandNodePool(
  obj: FetchType.IExpandNodePoolReq
): Promise<any> {
  const path: string = "expand_node_pool";
  message.destroy();
  const hide = message.loading("扩容中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListDebugInterface(
  obj: FetchType.IListDebugInterfaceReq
): Promise<any> {
  const path: string = "list_debug_interface";
  message.destroy();
  // const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj, true)
      .then((data) => {
        if (data.errCode !== ErrCodeOK) {
          // message.error(data.errMsg);
        }
        // console.log(data, "12121");
        resolve({ items: data.items });
      })
      .catch((res) => {
        resolve({ items: res.items });
      })
      .finally(() => {
        // hide();
      });
  });
}

export function fetchDebugInterface(
  obj: FetchType.IDebugInterfaceReq
): Promise<any> {
  const path: string = "debug_interface";
  message.destroy();
  const hide = message.loading("请求中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve({ result: data.result });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchAddOrModifyDebugInterfaceTestCase(
  obj: FetchType.IAddOrModifyDebugInterfaceTestCase
): Promise<any> {
  const path: string = "add_or_modify_debug_interface_testcase";
  message.destroy();
  const hide = message.loading("添加中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchDeleteDebugInterfaceTestCase(
  obj: FetchType.IDeleteDebugInterfaceTestCase
): Promise<any> {
  const path: string = "delete_debug_interface_testcase";
  message.destroy();
  const hide = message.loading("删除中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchDeleteDebugInterfaceProtocolFile(
  obj: FetchType.IDeleteDebugInterfaceProtocolFileReq
): Promise<any> {
  const path: string = "delete_debug_interface_protocol_file";
  message.destroy();
  const hide = message.loading("删除中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListServerNodes(
  obj: FetchType.IListServerNodesReq
): Promise<any> {
  const path: string = "list_server_nodes";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchListServerAutoScaling(
  obj: FetchType.IListServerAutoScalingReq
): Promise<any> {
  const path: string = "list_server_autoscaling";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchAddOrModifyServerCronHPA(
  obj: FetchType.IAddOrModifyServerCronHPAReq
): Promise<any> {
  const path: string = "add_or_modify_server_cronhpa";
  message.destroy();
  const hide = message.loading("更新中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        message.success("更新成功");
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchAddOrModifyServerAutoScaling(
  obj: FetchType.IAddOrModifyServerAutoScalingReq
): Promise<any> {
  const path: string = "add_or_modify_server_autoscaling";
  message.destroy();
  const hide = message.loading("正在更新autoscaling..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        message.success("更新成功");
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListServerCronHPA(
  obj: FetchType.IListServerCronHPAReq
): Promise<any> {
  const path: string = "list_server_cronhpa";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchListNodePoolPlaceholder(): Promise<any> {
  const path: string = "list_nodepool_placeholder";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, {}).then((data) => {
      resolve({ items: data.items });
    });
  });
}

export function fetchAdjustNodePoolPlaceholder(
  obj: FetchType.IAdjustNodePoolPlaceholderReq
): Promise<any> {
  const path: string = "adjust_nodepool_placeholder";
  message.destroy();
  const hide = message.loading("调整中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListNodePoolPlaceholderCronHPA(
  obj: FetchType.ListNodePoolPlaceholderCronHPAReq
): Promise<any> {
  const path: string = "list_nodepool_placeholder_cronhpa";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data);
    });
  });
}

export function fetchAddOrModifyNodePoolPlaceholderCronHPA(
  obj: FetchType.IAddOrModifyNodePoolPlaceholderCronHPAReq
): Promise<any> {
  const path: string = "add_or_modify_nodepool_placeholder_cronhpa";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      message.success("更新成功");
      resolve(data);
    });
  });
}

export function fetchAddServerComments(
  obj: FetchType.IAddServerCommentsReq
): Promise<any> {
  const path: string = "add_server_comments";
  message.destroy();
  const hide = message.loading("更新服务注释中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        message.success("更新成功");
        resolve({});
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchQueryOperation(
  obj: FetchType.IQueryOperationReq
): Promise<any> {
  const path: string = "query_operation";
  // let hide: any;
  // if (obj.withLoading && !obj.withLoading) {
  //   hide = message.loading("加载中..", 0);
  // }
  // message.destroy();

  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve({ items: data.items });
    });
  });
}
export function fetchGlobalQueryOperation(obj: any): Promise<any> {
  const path: string = "query_global_operation";
  // let hide: any;
  // if (obj.withLoading && !obj.withLoading) {
  //   hide = message.loading("加载中..", 0);
  // }
  // message.destroy();

  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data.data);
    });
  });
}

export function fetchGCNodes(obj: FetchType.IGCNodesReq): Promise<any> {
  const path: string = "gc_nodes";
  message.destroy();
  const hide = message.loading("整理中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve({ errCode: data.errCode });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchListIngress(obj: FetchType.IListIngressReq): Promise<any> {
  const path: string = "list_ingress";
  message.destroy();
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve({ items: data.items });
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchAdjustIngress(
  obj: FetchType.IAdjustIngressReq
): Promise<any> {
  const path: string = "adjust_ingress";
  message.destroy();
  const hide = message.loading("调整中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        message.success("调整成功");
        resolve(null);
      })
      .finally(() => {
        hide();
      });
  });
}

// DCache
export function fetchListDCacheTree(
  obj: FetchType.IListDCacheTreeReq
): Promise<any> {
  const path: string = "list_dcache_tree";
  message.destroy();
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    dcacheApi(path, obj)
      .then((data) => {
        resolve({
          kvItems: data.kvItems,
          mkvItems: data.mkvItems,
          listItems: data.listItems,
          setItems: data.setItems,
          zsetItems: data.zsetItems,
        });
      })
      .finally(() => {
        hide();
      });
  });
}

// 获取基本配置
export function fetchBaseCommonConfig(obj: any): Promise<any> {
  const path: string = "get_config_template";
  message.destroy();
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve(data?.items);
      })
      .finally(() => {
        hide();
      });
  });
}

/**
 * 获取枚举
 * @returns
 */
export function fetchBaseCommonEnum(obj: any): Promise<any> {
  const path: string = "record_filter_condition";
  message.destroy();
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve(data?.items);
      })
      .finally(() => {
        hide();
      });
  });
}

/**
 * 编辑记录
 */
export function fetchBaseCommonEditRecord(obj: any): Promise<any> {
  const path: string = "edit_record";
  message.destroy();
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve(data);
      })
      .finally(() => {
        hide();
      });
  });
}

/**
 * 删除记录
 * @param id
 * @returns
 */
export function fetchBaseCommonRemoveRecord(obj: any): Promise<any> {
  const path: string = "remove_record";
  message.destroy();
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve(data);
      })
      .finally(() => {
        hide();
      });
  });
}

/**
 * 添加记录
 * @param obj
 * @returns
 */
export function fetchBaseCommonAddRecord(obj: any): Promise<any> {
  const path: string = "add_record";
  message.destroy();
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve(data);
      })
      .finally(() => {
        hide();
      });
  });
}

export function fetchBaseCommonGetList(obj: any): Promise<any> {
  const path: string = "search_record";
  message.destroy();
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve(data?.items);
      })
      .finally(() => {
        hide();
      });
  });
}

/**
 * 获取重启||停止的任务以及进度
 */
export function fetchOperationTaskList(obj: any): Promise<any> {
  const path: string = "get_task_progressbar";
  message.destroy();
  // const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data?.logItems);
    });
  });
}

/**
 * 终止任务
 */
export function fetchTerminateTask(obj: any): Promise<any> {
  const path: string = "terminate_task";
  message.destroy();
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve(data);
      })
      .finally(() => {
        hide();
      });
  });
}

/**
 *
 * @param obj
 * @returns
 */
export function fetchBaseServerStatus(obj: any): Promise<any> {
  const path: string = "rollout_status";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      // RolloutStatusFinished = "0" // 发布完成 RolloutStatusGreyPublishing = "1" //灰度发布中 RolloutStatusGreyPublishPaused = "2" //灰度发布暂停 RolloutStatusPublishing = "3" // 发布中 RolloutStatusPublishPaused = "4" // 发布暂停 RolloutStatusUndoing = "5" // 回滚中 rolloutStatusUndoPaused = "6" // 回滚暂停
      resolve({
        roll_out_status: data.rolloutStatus,
      });
    });
  });
}

/**
 * 暂停接口
 */
export function fetchRolloutPause(obj: any): Promise<any> {
  const path: string = "rollout_pause";
  message.destroy();
  const hide = message.loading("加载中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((data) => {
        resolve(data);
      })
      .finally(() => {
        hide();
      });
  });
}

// 获取所有服务列表
export function fetchApproveHistory(obj: any): Promise<any> {
  const path: string = "approve_history";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data);
    });
  });
}

// 获取所有服务列表
export function fetchAllServerApproveStatus(obj: any): Promise<any> {
  const path: string = "list_pending_approve";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data);
    });
  });
}

// 获取单个服务的审批状态
export function fetchServerApproveStatus(obj: any): Promise<any> {
  const path: string = "list_approve";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data);
    });
  });
}

// 提交审批申请
export function fetchAcquireApprove(obj: any): Promise<any> {
  const path: string = "acquire_approve";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj, true).then((data) => {
      resolve(data);
    });
  });
}

// 提交审批状态
export function fetchSubmitApproveStatus(obj: any): Promise<any> {
  const path: string = "submit_approve";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data);
    });
  });
}

// 获取服务信息
export function fetchServerInfo(obj: any): Promise<any> {
  const path: string = "server_info";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data.Items);
    });
  });
}

export interface RolloutPreCheckParams {
  userName: string;
  tarsApplication: string;
  tarsSetName: string;
  tarsServerName: string;
  clusterName: string;
  tag: string;
}

export interface DiffData {
  shortMessage: string;
  sha: string;
  commitDate: number;
  commitLink: string;
  commiter: {
    email: string;
    name: string;
  };
  fullMessage: string;
}

export interface RolloutPreCheckData {
  isMaster: boolean;
  diffWithMaster: {
    differentOfCommitInfo: DiffData[];
  };
  diffWithOnline: {
    differentOfCommitInfo: DiffData[];
  };
}

// 服务发布预检
export function rolloutPreCheck(
  obj: RolloutPreCheckParams
): Promise<RolloutPreCheckData> {
  const path: string = "rollout_precheck";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data?.diffResult);
    });
  });
}

export interface TarsProtocolFile {
  name: string;
  path: string;
  is_dir: boolean;
}

// 获取协议仓库目录树
export function listTarsProtocolFile(obj: any): Promise<TarsProtocolFile[]> {
  const path: string = "list_tars_protocol_file";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data?.fileItems || []);
    });
  });
}

// 获取协议仓库文件内容
export function getTarsProtocolFileContent(obj: any): Promise<string> {
  const path: string = "get_tars_protocol_file_content";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((data) => {
      resolve(data?.fileContent);
    });
  });
}

// 关联协议仓库
export function relateDebugInterfaceProtocolFile(obj: any): Promise<void> {
  const path: string = "relate_debug_interface_protocol_file";
  message.destroy();
  const hide = message.loading("关联中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        resolve();
      })
      .catch(() => {})
      .finally(hide);
  });
}

// 清空调试文件列表
export function deleteAllDebugInterfaceProtocolFile(obj: any): Promise<void> {
  const path: string = "delete_all_debug_interface_protocol_file";
  message.destroy();
  const hide = message.loading("清空中..", 0);
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then(() => {
        resolve();
      })
      .catch(() => {})
      .finally(hide);
  });
}

// 查看调试协议文件内容
export function getDebugTarsProtocolFileContent(obj: any): Promise<string> {
  const path: string = "get_debug_tars_protocol_file_content";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj)
      .then((res) => {
        resolve(res?.fileContent);
      })
      .catch(() => {})
      .finally();
  });
}

// 更新服务信息
export function updateServerInfo(obj: any): Promise<void> {
  const path: string = "update_server";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then(() => {
      resolve();
    });
  });
}

export function getAlertPresetCondition(obj: any): Promise<any> {
  const path: string = "alert_preset_condition";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((res) => {
      resolve(res);
    });
  });
}

export function addOrModifyAlertRule(obj: any): Promise<void> {
  const path: string = "add_or_modify_alert_rule";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((res) => {
      resolve(res);
    });
  });
}

export function getPropertyAlertList(obj: any): Promise<any> {
  const path: string = "property_alert_list";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((res) => {
      resolve(res);
    });
  });
}

export function deleteAlertRule(obj: any): Promise<void> {
  const path: string = "delete_alert_rule";
  message.destroy();
  return new Promise((resolve) => {
    tarsApi(path, obj).then((res) => {
      resolve(res);
    });
  });
}

export function offlineNode(obj: any): Promise<void> {
  const path: string = "offline_node";
  message.destroy();
  return new Promise((resolve, reject) => {
    tarsApi(path, obj, true).then((res) => {
      if (res.errCode > 0) {
        notification.error({
          message: "下线机器失败",
        });
        reject(res);
      } else {
        notification.success({
          message: "下线机器成功",
        });
        resolve(res);
      }
    });
  });
}
