export enum TaskCommendEnum {
  RESTART = "restart",
  STOP = "stop",
}

export const mockData = {
  "env-product": {
    errCode: 0,
    errMsg: "OK",
    items: [],
  },
  "env-product-terway": {
    errCode: 0,
    errMsg: "OK",
    items: [
      {
        taskID: "1681973243-9c044824-fe39-451a-b0b0-e257df72bfcd",
        command: "restart",
        graceful: 1,
        status: 1,
        userName: "anderson",
        nodedIds: ["10.1.21.34", "10.1.21.35"],
        progressBar: "2/2",
        creationTimestamp: "1681973413",
      },
      {
        taskID: "1681971439-5e73870e-c18e-45bd-ad5b-3876efcfe3af",
        command: "stop",
        graceful: 1,
        status: 2,
        userName: "",
        nodedIds: ["10.1.21.34", "10.1.21.35"],
        progressBar: "2/2",
        creationTimestamp: "1681971744",
      },
      {
        taskID: "1681971377-54c4023b-4229-429a-a613-ad723dd4c764",
        command: "stop",
        graceful: 1,
        status: 2,
        nodedIds: ["10.1.21.34", "10.1.21.35"],
        progressBar: "2/2",
        userName: "",
        creationTimestamp: "1681971681",
      },
      {
        taskID: "1681971403-5392d0d9-79a5-4bac-afe4-f03ac83ae2c1",
        command: "stop",
        graceful: 1,
        status: 2,
        userName: "",
        nodedIds: ["10.1.21.34", "10.1.21.35"],
        progressBar: "2/2",
        creationTimestamp: "1681971556",
      },
    ],
  },
  "env-product-us-terway": {
    errCode: 0,
    errMsg: "OK",
    items: [],
    len: 0,
  },
};

export enum TaskStatus {
  StatusPending = 0,
  StatusRunning = 1,
  StatusSuccess = 2,
  StatusFailed = 3,
  StatusBreakDown = 4,
}

enum Graceful {
  Graceful = 1,
  NotGraceful = 0,
}

export interface TaskItem {
  taskID: string;
  command: TaskCommendEnum;
  graceful: Graceful;
  status: TaskStatus;
  userName: string;
  nodeList: string;
  progressBar: string;
  creationTimestamp: string;
}

export enum SERVER_STATUS {
  RolloutStatusFinished = 0, // 发布完成
  RolloutStatusGreyPublishing = 1, //灰度发布中
  RolloutStatusGreyPublishPaused = 2, //灰度发布暂停
  RolloutStatusPublishing = 3, // 发布中
  RolloutStatusPublishPaused = 4, // 发布暂停
  RolloutStatusUndoing = 5, // 回滚中
  rolloutStatusUndoPaused = 6, // 回滚暂停
}

export const getNodesHasError = (
  nodes: any[],
  pausedItems: Map<string, number>,
  serverStatus: SERVER_STATUS
) => {
  if (!nodes || !pausedItems) {
    return false;
  }
  // 灰度暂停中的节点是否有错误
  const pausedNodeHasError = false;
  // 灰度暂停中
  if (serverStatus === SERVER_STATUS.RolloutStatusGreyPublishPaused) {
    // 暂停的节点信息
    const pausedNodeInfo =
      pausedItems &&
      Array.from(pausedItems).find(
        (value: [string, number]) => value.length === 2 && value[1] > 0
      );

    // 暂停节点的数量
    const pausedNodeNum = pausedNodeInfo ? pausedNodeInfo[1] : 0;

    // 根据节点数量和节点列表判断是否有错误
    if (pausedNodeNum > 0) {
      const replicas = [...nodes];
      const sortedReplicas = replicas?.sort(
        (a: any, b: any) =>
          Number(b.publishTimestamp) - Number(a.publishTimestamp)
      );
      const pausedNodeList = sortedReplicas?.slice(0, pausedNodeNum);
      // 判断每一个节点是否ready
      return pausedNodeList?.some((node: any) => node.podReady !== 1);
    }
    return pausedNodeHasError;

    // 灰度暂停中的节点才需要检验，其他情况直接返回
  } else {
    return pausedNodeHasError;
  }
};
