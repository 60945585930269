import React from "react";
import { omit, isEmpty, isEqual, cloneDeep } from "lodash";
import copy from 'copy-to-clipboard';
import { ColumnsType, ColumnType } from "antd/es/table";
import {
  Card,
  Tabs,
  Table,
  Input,
  InputNumber,
  Row,
  Col,
  Form,
  Tag,
  Button,
  Space,
  Modal,
  Result,
  Select,
  Popconfirm,
  message,
} from "antd";
import {
  FormOutlined,
  DeleteTwoTone,
  PlusCircleOutlined,
  CopyOutlined
} from "@ant-design/icons";

import { connect } from "react-redux";

import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";
import * as ActionType from "src/stores/types";
import * as util from "src/pages/DevOps/ToolsBar/util";

interface IProps {
  disabled: boolean;
  forbitEdit: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  selectedItems?: Array<ActionType.ITarsServerItem>;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
}

interface IAdapterItem {
  key?: string;
  tarsServantObj: string;
  protocol: string;
  threadNum: number;
  port: number | string;
  maxConn: number;
  maxQueue: number;
  maxQueueTimeout: number;
}

interface IServantItem {
  key?: string;
  id: number;
  asyncThreadNum: number;
  podIP: string;
  privateTemplate: string;
  tarsServerType: string;
  templateName: string;
  adapters: Array<IAdapterItem>;
  editingItem?: IServantItem;
  clusterName: string;
}

interface IEditServerServantItem extends IAdapterItem {
  action: number;
}

interface IState {
  tabKey: string;
  visible: boolean;
  tarsApplication: string;
  tarsSetName: string;
  tarsServer: string;

  availTemplateName?: Array<string>;

  editPod?: FetchType.IPodNetworkInterface;
  editServantItems?: Array<IServantItem>;

  batchEditItemTemplate?: IServantItem;
}

const FormItem = Form.Item;

const { TabPane } = Tabs;
const SelectOption = Select.Option;

const { TextArea } = Input;

const EditTemplate: React.FC<IProps> = (props) => {
  const [state, setState] = React.useState<IState>({
    tabKey: "single-editor",
    visible: false,
    tarsApplication: "",
    tarsSetName: "",
    tarsServer: "",
  });

  let formRefs: Array<any> = [];

  React.useEffect(() => {
    setState((s) => ({
      ...s,
      tarsApplication: props.selectedTarsApplication || "",
      tarsSetName: props.selectedTarsSetName || "",
      tarsServer: props.selectedTarsServer || "",
    }));
  }, [props]);

  const buildBatchEditServantItems = (
    editServantItems: Array<IServantItem>
  ): IServantItem | undefined => {
    if (isEmpty(editServantItems) || editServantItems.length === 1)
      return undefined;
    const one = editServantItems[0];
    const isAdapterSignatureEqual = (a: IAdapterItem, b: IAdapterItem) => {
      return (
        a.tarsServantObj === b.tarsServantObj &&
        a.protocol === b.protocol &&
        a.threadNum === b.threadNum &&
        a.maxConn === b.maxConn &&
        a.maxQueue === b.maxQueue &&
        a.maxQueueTimeout === b.maxQueueTimeout &&
        a.port === b.port
      );
    };
    const templateAndAdapterShouldSame = (val: IServantItem) => {
      if (
        val.tarsServerType !== one.tarsServerType ||
        val.privateTemplate !== one.privateTemplate ||
        val.templateName !== one.templateName ||
        val.asyncThreadNum !== one.asyncThreadNum
      ) {
        return false;
      }
      for (const a of one.adapters) {
        let found = false;
        for (const b of val.adapters) {
          if (isAdapterSignatureEqual(a, b)) {
            found = true;
          }
        }
        if (!found) return false;
      }
      for (const a of val.adapters) {
        let found = false;
        for (const b of one.adapters) {
          if (isAdapterSignatureEqual(a, b)) {
            found = true;
          }
        }
        if (!found) return false;
      }
      return true;
    };

    if (!editServantItems.every(templateAndAdapterShouldSame)) return undefined;
    let batchEditItemTemplate = cloneDeep(one);
    batchEditItemTemplate.editingItem = cloneDeep(one);
    return batchEditItemTemplate;
  };

  const fetchData = () => {
    if (isEmpty(state.tarsApplication) || isEmpty(state.tarsServer)) {
      return;
    }
    let podNetworkInterfaces: Array<FetchType.IPodNetworkInterface> = [];
    if (!props.selectedItems || util.disableSelectNull(props.selectedItems)) {
      return;
    }
    podNetworkInterfaces = props.selectedItems.map((item) => ({
      podIP: item.podIP,
      clusterName: item.clusterName,
    }));
    fetch
      .fetchTemplates({
        tarsApplication: state.tarsApplication,
        tarsSetName: state.tarsSetName,
        tarsServerName: state.tarsServer,
        podNetworkInterfaces,
      })
      .then((data) => {
        let editServantItems = data.editServantItems;
        for (let item of editServantItems) {
          for (let i = 0; i < item.adapters.length; i++) {
            item.adapters[i].key =
              item.podIP +
              "|" +
              item.clusterName +
              "|" +
              item.adapters[i].tarsServantObj;
          }
          item.key = item.podIP + "|" + item.clusterName;
        }
        const obj = buildBatchEditServantItems(editServantItems);
        setState({
          ...state,
          batchEditItemTemplate: obj,
          visible: true,
          availTemplateName: data.availTemplateName,
          editServantItems,
        });
      })
      .catch((_) => { });
  };

  const setEditable = (
    podIP: string,
    clusterName: string,
    editable: boolean
  ) => {
    if (!state.editServantItems) return;
    let editPod = undefined;
    let editServantItems = state.editServantItems;
    for (let i = 0; i < editServantItems.length; ++i) {
      let item = editServantItems[i];
      if (item.podIP === podIP) {
        if (editable) {
          editPod = { podIP, clusterName };
          item.editingItem = cloneDeep(item);
        } else {
          item.editingItem = undefined;
        }
        break;
      }
    }
    setState({ ...state, editPod, editServantItems });
  };

  const getEditingServantItem = (): IServantItem | undefined => {
    if (state.tabKey === "single-editor") {
      if (!state.editPod || isEmpty(state.editPod.podIP)) return undefined;
      if (!state.editServantItems) return undefined;
      let editingItem: IServantItem | undefined = undefined;
      for (let item of state.editServantItems) {
        if (
          item.podIP === state.editPod.podIP &&
          item.clusterName === state.editPod.clusterName
        ) {
          editingItem = item.editingItem;
          break;
        }
      }
      return editingItem;
    }

    return state.batchEditItemTemplate
      ? state.batchEditItemTemplate.editingItem
      : undefined;
  };

  const getEditingAdapter = (
    index: number,
    record: IAdapterItem
  ): IAdapterItem | undefined => {
    if (state.tabKey === "single-editor") {
      if (!state.editPod || isEmpty(state.editPod.podIP)) return undefined;
      if (!record.key) return undefined;

      const parts = record.key.split("|");
      if (
        parts[0] !== "placeholder" &&
        (parts[0] !== state.editPod.podIP ||
          parts[1] !== state.editPod.clusterName)
      )
        return undefined;

      if (state.editServantItems) {
        let editingItem = getEditingServantItem();
        if (editingItem) {
          return editingItem.adapters[index];
        }
      }
    }
    return state.batchEditItemTemplate &&
      state.batchEditItemTemplate.editingItem
      ? state.batchEditItemTemplate.editingItem.adapters[index]
      : undefined;
  };

  const onEditServantItemChanged = (
    targetValue: string | number,
    prop: string
  ) => {
    // 单编辑
    if (state.tabKey === "single-editor" && state.editPod) {
      let editServantItems = state.editServantItems;
      if (!editServantItems) return;
      for (let item of editServantItems) {
        if (
          item.podIP === state.editPod.podIP &&
          item.clusterName === state.editPod.clusterName &&
          item.editingItem
        ) {
          // no change
          if ((item.editingItem as any)[prop] === targetValue) return;
          (item.editingItem as any)[prop] = targetValue;
          break;
        }
      }
      setState({ ...state, editServantItems });
      return;
    }
    // 批量编辑
    if (state.tabKey === "batch-editor" && state.batchEditItemTemplate) {
      const editingItem = state.batchEditItemTemplate.editingItem;
      if (!editingItem) return;
      if ((editingItem as any)[prop] === targetValue) return;
      (editingItem as any)[prop] = targetValue;
      setState({ ...state });
    }
  };

  const onEditAdapterItemChanged = (
    targetValue: string | number,
    prop: string,
    index: number
  ) => {
    // 单编辑
    if (state.tabKey === "single-editor" && state.editPod) {
      let editServantItems = state.editServantItems;
      if (!editServantItems) return;
      for (let item of editServantItems) {
        if (
          item.podIP === state.editPod.podIP &&
          item.clusterName === state.editPod.clusterName &&
          item.editingItem
        ) {
          // no change
          let adapters = [...item.editingItem.adapters];
          if ((adapters[index] as any)[prop] === targetValue) return;

          if (prop === "tarsServantObj") {
            (adapters[index] as any)[prop] =
              state.tarsApplication +
              "." +
              state.tarsServer +
              "." +
              targetValue;
          } else {
            (adapters[index] as any)[prop] = targetValue;
          }
          item.editingItem.adapters = adapters;
          break;
        }
      }
      setState({ ...state, editServantItems });
      return;
    }
    // 批量编辑
    if (state.tabKey === "batch-editor" && state.batchEditItemTemplate) {
      const editingItem = state.batchEditItemTemplate.editingItem;
      if (!editingItem) return;
      let adapters = [...editingItem.adapters];
      if ((adapters[index] as any)[prop] === targetValue) return;

      if (prop === "tarsServantObj") {
        (adapters[index] as any)[prop] =
          state.tarsApplication + "." + state.tarsServer + "." + targetValue;
      } else {
        (adapters[index] as any)[prop] = targetValue;
      }
      editingItem.adapters = adapters;
      setState({ ...state });
    }
  };

  const onAddAdapterClicked = () => {
    // 单编辑
    if (state.tabKey === "single-editor" && state.editPod) {
      let editServantItems = state.editServantItems;
      if (!editServantItems) return;
      for (let item of editServantItems) {
        if (
          item.podIP === state.editPod.podIP &&
          item.clusterName === state.editPod.clusterName &&
          item.editingItem
        ) {
          let editingItem = cloneDeep(item.editingItem);
          const randomKey = Math.floor(Math.random() * 100000).toString();
          editingItem.adapters.push({
            key: "placeholder|" + randomKey,
            tarsServantObj: "",
            protocol: "tars",
            threadNum: 5,
            maxConn: 200000,
            maxQueue: 60000,
            maxQueueTimeout: 60000,
            port: "待分配",
          });
          item.editingItem = editingItem;
          break;
        }
      }
      setState({ ...state, editServantItems });
      return;
    }

    if (
      state.tabKey === "batch-editor" &&
      state.batchEditItemTemplate &&
      state.batchEditItemTemplate.editingItem
    ) {
      let batchEditItemTemplate = cloneDeep(state.batchEditItemTemplate);
      let editingItem = cloneDeep(state.batchEditItemTemplate.editingItem);
      const randomKey = Math.floor(Math.random() * 100000).toString();
      editingItem.adapters.push({
        key: "placeholder|" + randomKey,
        tarsServantObj: "",
        protocol: "tars",
        threadNum: 5,
        maxConn: 200000,
        maxQueue: 60000,
        maxQueueTimeout: 60000,
        port: "待分配",
      });
      batchEditItemTemplate.editingItem = editingItem;
      setState({ ...state, batchEditItemTemplate });
    }
  };

  const onEditServantItemSubmitClicked = async () => {
    const EditActionAdd = 1;
    const EditActionModify = 2;
    const EditActionDelete = 3;
    try {
      let podNetworkInterfaces: Array<FetchType.IPodNetworkInterface> = [];
      let editingItem: IServantItem | undefined = undefined;
      let editingItemBackup: IServantItem | undefined = undefined;
      if (state.editPod) {
        podNetworkInterfaces.push(state.editPod);
        if (state.editServantItems) {
          for (let item of state.editServantItems) {
            if (
              item.podIP === state.editPod.podIP &&
              item.clusterName === state.editPod.clusterName
            ) {
              editingItem = cloneDeep(item.editingItem);
              editingItemBackup = cloneDeep(omit(item, ["editingItem"]));
              break;
            }
          }
        }
      } else {
        if (props.selectedItems) {
          podNetworkInterfaces = props.selectedItems.map((x) => ({
            podIP: x.podIP,
            clusterName: x.clusterName,
          }));
        }
        if (
          state.batchEditItemTemplate &&
          state.batchEditItemTemplate.editingItem
        ) {
          editingItem = cloneDeep(state.batchEditItemTemplate.editingItem);
          editingItemBackup = cloneDeep(
            omit(state.batchEditItemTemplate, ["editingItem"])
          );
        }
      }
      if (!editingItem || !editingItemBackup) {
        message.error("系统内部错误");
        return;
      }
      for (const formRef of formRefs) {
        if (formRef) await formRef.validateFields();
      }

      for (let i = 0; i < editingItem.adapters.length; ++i) {
        editingItem.adapters[i] = {
          ...cloneDeep(omit(editingItem.adapters[i], ["port"])),
          port: 0,
        };
      }
      for (let i = 0; i < editingItemBackup.adapters.length; ++i) {
        editingItemBackup.adapters[i] = {
          ...cloneDeep(omit(editingItemBackup.adapters[i], ["port"])),
          port: 0,
        };
      }
      if (isEqual(editingItem, editingItemBackup)) {
        message.error("未发生变更");
        return;
      }

      let servantItems: Array<IEditServerServantItem> = [];
      for (let adapter of editingItem.adapters) {
        let found = false;
        let noChange = false;
        for (const originAdapter of editingItemBackup.adapters) {
          if (adapter.tarsServantObj === originAdapter.tarsServantObj) {
            found = true;
            if (isEqual(adapter, originAdapter)) {
              noChange = true;
            }
            break;
          }
        }
        if (!found) {
          let obj = Object.assign({ action: EditActionAdd }, adapter);
          servantItems.push(obj); // add
        } else if (!noChange) {
          let obj = Object.assign({ action: EditActionModify }, adapter);
          servantItems.push(obj); // modify
        }
      }

      for (const originAdapter of editingItemBackup.adapters) {
        let found = false;
        for (const adapter of editingItem.adapters) {
          if (originAdapter.tarsServantObj === adapter.tarsServantObj) {
            found = true;
            break;
          }
        }
        if (!found) {
          let obj = Object.assign({ action: EditActionDelete }, originAdapter);
          servantItems.push(obj); // delete
        }
      }

      if (
        !props.selectedTarsApplication ||
        !props.selectedTarsServer ||
        isEmpty(podNetworkInterfaces)
      ) {
        return;
      }

      fetch
        .fetchEditTemplate({
          userName: props.currentUser || "",
          tarsApplication: props.selectedTarsApplication,
          tarsSetName: props.selectedTarsSetName || "",
          tarsServerName: props.selectedTarsServer,
          tarsServerType: editingItem.tarsServerType,
          privateTemplate: editingItem.privateTemplate,
          templateName: editingItem.templateName,
          asyncThreadNum: editingItem.asyncThreadNum,
          servantItems,
          podNetworkInterfaces,
        })
        .then(() => {
          setState((s) => ({
            ...s,
            tabKey: "single-editor",
            visible: false,
            availTemplateName: undefined,
            editPodIP: undefined,
            editServantItems: undefined,
            batchEditItemTemplate: undefined,
            batchEditItemTemplateBackup: undefined,
          }));
        })
        .catch((_) => { });
    } catch (_) { }
  };

  const onDeleteAdapterItemClicked = (index: number) => {
    // 单编辑
    if (state.tabKey === "single-editor" && state.editPod) {
      let editServantItems = state.editServantItems;
      if (!editServantItems) return;
      for (let item of editServantItems) {
        if (
          item.podIP === state.editPod.podIP &&
          item.clusterName === state.editPod.clusterName &&
          item.editingItem
        ) {
          let editingItem = cloneDeep(item.editingItem);
          editingItem.adapters.splice(index, 1);
          item.editingItem = editingItem;
          break;
        }
      }
      setState({ ...state, editServantItems });
      return;
    }

    // 批量编辑
    if (state.tabKey === "batch-editor") {
      let batchEditItemTemplate = state.batchEditItemTemplate;
      if (!batchEditItemTemplate) return;
      let editingItem = cloneDeep(batchEditItemTemplate.editingItem);
      if (!editingItem) return;
      editingItem.adapters.splice(index, 1);
      batchEditItemTemplate.editingItem = editingItem;
      setState({ ...state, batchEditItemTemplate });
    }
  };

  const copyServant = (data: IAdapterItem) => {
    const { tarsServantObj, port, key } = data;
    const host = key?.split('|')?.[0];

    const copyText = `${tarsServantObj}@tcp -h ${host} -p ${port}`;

    copy(copyText);

    message.success('已复制 Servant 至剪贴板');
  }

  const batchEditorColumns: ColumnsType<IServantItem> = [
    {
      title: "服务类型",
      dataIndex: "tarsServerType",
      width: 100,
      align: "center",
      render: (text, record) => (
        <>
          {record.editingItem && (
            <Select
              value={record.editingItem.tarsServerType}
              style={{ margin: "-5px -5px" }}
              size="small"
              dropdownMatchSelectWidth={false}
              onChange={(value) =>
                onEditServantItemChanged(value, "tarsServerType")
              }
            >
              <SelectOption value="tars_cpp"> tars_cpp </SelectOption>
              <SelectOption value="tars_go"> tars_go </SelectOption>
              <SelectOption value="tars_nodejs"> tars_nodejs </SelectOption>
              <SelectOption value="tars_java"> tars_java </SelectOption>
            </Select>
          )}
          {!record.editingItem && <>{text}</>}
        </>
      ),
    },
    {
      title: "模板",
      dataIndex: "templateName",
      width: 100,
      align: "center",
      render: (text, record) => (
        <>
          {record.editingItem && (
            <Select
              value={record.editingItem.templateName}
              style={{ margin: "-5px -5px" }}
              size="small"
              dropdownMatchSelectWidth={false}
              onChange={(value) =>
                onEditServantItemChanged(value, "templateName")
              }
            >
              {state.availTemplateName &&
                state.availTemplateName.map((item, index) => (
                  <SelectOption value={item} key={index}>
                    {" "}
                    {item}{" "}
                  </SelectOption>
                ))}
            </Select>
          )}
          {!record.editingItem && <>{text}</>}
        </>
      ),
    },
    {
      title: "私有模板",
      dataIndex: "privateTemplate",
      ellipsis: true,
      width: 300,
      align: "center",
      render: (text, record) => (
        <>
          {record.editingItem && (
            <TextArea
              autoSize
              size="small"
              value={record.editingItem.privateTemplate}
              onChange={({ target: { value } }) =>
                onEditServantItemChanged(value, "privateTemplate")
              }
            />
          )}
          {!record.editingItem && (
            <div style={{ textAlign: "left" }}>
              <span style={{ whiteSpace: "pre" }}>{text}</span>
            </div>
          )}
        </>
      ),
    },
    {
      title: "异步线程",
      dataIndex: "asyncThreadNum",
      width: 60,
      align: "center",
      render: (text, record) => (
        <>
          {record.editingItem && (
            <InputNumber
              size="small"
              min={0}
              value={record.editingItem.asyncThreadNum}
              onChange={(value) =>
                onEditServantItemChanged(value, "asyncThreadNum")
              }
            />
          )}
          {!record.editingItem && <>{text}</>}
        </>
      ),
    },
  ];
  const singleEditorColumns: ColumnsType<IServantItem> = [
    { title: "IP", dataIndex: "podIP", width: 80, align: "center" },
    ...batchEditorColumns,
    {
      title: "操作",
      dataIndex: "operation",
      width: 60,
      align: "center",
      render: (text, record) => {
        return !record.editingItem ? (
          <span>
            <Button
              disabled={props.forbitEdit}
              type="link"
              onClick={setEditable.bind(
                this,
                record.podIP,
                record.clusterName,
                true
              )}
            >
              编辑
            </Button>
          </span>
        ) : (
          <span>
            <Popconfirm
              title="确认填写无误?"
              okText="保存"
              okType="danger"
              cancelText="取消"
              onConfirm={() => onEditServantItemSubmitClicked()}
            >
              <Button size="small" type="link" style={{ color: "red" }}>
                提交
              </Button>
            </Popconfirm>

            <Button
              type="link"
              size="small"
              onClick={setEditable.bind(
                this,
                record.podIP,
                record.clusterName,
                false
              )}
            >
              取消
            </Button>
          </span>
        );
      },
    },
  ];
  let adapterColumns: ColumnsType<IAdapterItem> = [
    {
      title: "Obj",
      dataIndex: "tarsServantObj",
      align: "center",
      render: (text, record, index) => {
        const adapter = getEditingAdapter(index, record);
        let obj: any = {};
        let parts: Array<string> = [];
        if (adapter) {
          parts = adapter.tarsServantObj.split(".");
          if (parts.length === 3) {
            obj[record.key || ""] = parts[2];
          }
        }
        return (
          <>
            {adapter && (
              <Form
                ref={(r) => formRefs.push(r)}
                name="tarsServantObjForm"
                validateTrigger="onBlur"
                initialValues={{ ...obj }}
              >
                <Row>
                  <Col>{state.tarsApplication}</Col>
                  <Col>.</Col>
                  <Col>{state.tarsServer}</Col>
                  <Col>.</Col>
                  <Col>
                    <FormItem
                      name={record.key || ""}
                      rules={[
                        {
                          required: true,
                          validator: (rule, value) => {
                            const pattern = new RegExp(
                              `^[a-zA-Z_][\\w-]*Obj$`,
                              "g"
                            );
                            if (!pattern.test(value)) {
                              return Promise.reject(
                                new Error("ServantObj格式错误，格式为xxxObj")
                              );
                            }
                            let editingItem: IServantItem | undefined =
                              undefined;
                            if (
                              state.tabKey === "single-editor" &&
                              state.editServantItems
                            ) {
                              for (const item of state.editServantItems) {
                                if (
                                  state.editPod &&
                                  item.podIP === state.editPod.podIP &&
                                  item.clusterName === state.editPod.clusterName
                                ) {
                                  editingItem = item;
                                  break;
                                }
                              }
                            } else if (
                              state.tabKey === "batch-editor" &&
                              state.batchEditItemTemplate
                            ) {
                              editingItem =
                                state.batchEditItemTemplate.editingItem;
                            }
                            if (!editingItem) {
                              return Promise.reject(
                                new Error("未找到对应的编辑项")
                              );
                            }
                            let uniqObj = new Set<string>();
                            for (let i in editingItem.adapters) {
                              const adapter = editingItem.adapters[i];
                              if (uniqObj.has(adapter.tarsServantObj)) {
                                return Promise.reject(
                                  new Error(
                                    `${adapter.tarsServantObj} 不能重复`
                                  )
                                );
                              }
                              uniqObj.add(adapter.tarsServantObj);
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        size="small"
                        onChange={({ target: { value } }) =>
                          onEditAdapterItemChanged(
                            value,
                            "tarsServantObj",
                            index
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            )}
            {!adapter && <>
              {text}
              <a style={{ marginLeft: 4 }} onClick={copyServant.bind(null, record)}>
                <CopyOutlined />
              </a>
            </>}
          </>
        );
      },
    },
    {
      title: "端口",
      dataIndex: "port",
      width: 110,
      align: "center",
      render: (text, record, index) => {
        const adapter = getEditingAdapter(index, record);
        return (
          <>
            {adapter && <>{adapter.port}</>}
            {!adapter && <>{text}</>}
          </>
        );
      },
    },
    {
      title: "协议",
      dataIndex: "protocol",
      width: 110,
      align: "center",
      render: (text, record, index) => {
        const adapter: any = getEditingAdapter(index, record);
        if (adapter && state.tabKey == "single-editor" && adapter.protocol !== "tars") {
          return <>http</>;
        }
        return (
          <>
            {adapter && (
              <Select
                value={adapter.protocol}
                style={{ margin: "-5px -5px" }}
                size="small"
                dropdownMatchSelectWidth={false}
                onChange={(value) =>
                  onEditAdapterItemChanged(value, "protocol", index)
                }
              >
                <SelectOption value="tars"> tars </SelectOption>
                <SelectOption value="not_tars"> http </SelectOption>
              </Select>
            )}
            {!adapter && <>{text === "tars" ? "tars" : "http"}</>}
          </>
        );
      },
    },
    ...[
      { title: "线程", dataIndex: "threadNum" },
      { title: "最大连接", dataIndex: "maxConn" },
      { title: "队列", dataIndex: "maxQueue" },
      { title: "队列超时ms", dataIndex: "maxQueueTimeout" },
    ].map<ColumnType<IAdapterItem>>((item) => ({
      ...item,
      width: 110,
      align: "center",
      render: (text, record, index) => {
        const adapter = getEditingAdapter(index, record);
        if (adapter && state.tabKey == "single-editor" && adapter.protocol !== "tars") {
          return <>{(adapter as any)[item.dataIndex]}</>;
        }
        return (
          <>
            {adapter && (
              <InputNumber
                size="small"
                min={1}
                value={(adapter as any)[item.dataIndex]}
                onChange={(value) =>
                  onEditAdapterItemChanged(value, item.dataIndex, index)
                }
              />
            )}
            {!adapter && <>{text}</>}
          </>
        );
      },
    })),
  ];

  return (
    <>
      <Button
        size="small"
        className="tool-btn"
        disabled={props.disabled}
        onClick={fetchData.bind(this)}
      >
        <Space>
          <FormOutlined />
          编辑服务
        </Space>
      </Button>

      <Modal
        width="85vw"
        destroyOnClose
        closable={false}
        visible={state.visible}
        footer={
          <>
            <Button
              ghost
              danger
              className="tool-btn"
              onClick={() =>
                setState({
                  ...state,
                  visible: false,
                  availTemplateName: undefined,
                  editPod: undefined,
                  editServantItems: undefined,
                  batchEditItemTemplate: undefined,
                  tabKey: "single-editor",
                })
              }
            >
              取消
            </Button>
            {state.tabKey === "batch-editor" &&
              state.batchEditItemTemplate &&
              props.selectedItems &&
              props.selectedItems.length >= 2 && (
                <Button
                  type="primary"
                  onClick={() => onEditServantItemSubmitClicked()}
                  className="tool-btn"
                  ghost
                >
                  提交
                </Button>
              )}
          </>
        }
      >
        <Tabs
          type="card"
          size="large"
          activeKey={state.tabKey}
          onChange={(tabKey) => setState({ ...state, tabKey })}
        >
          <TabPane key="single-editor" tab="详情">
            <Table
              size="small"
              pagination={false}
              columns={singleEditorColumns}
              dataSource={state.editServantItems}
              expandable={{ defaultExpandAllRows: true }}
              expandedRowRender={(record) => {
                if (
                  record.editingItem &&
                  (
                    adapterColumns[
                    adapterColumns.length - 1
                    ] as ColumnType<IAdapterItem>
                  ).dataIndex !== "adapterOperation"
                ) {
                  adapterColumns.push({
                    title: "操作",
                    dataIndex: "adapterOperation",
                    width: 100,
                    align: "center",
                    render: (text, record, index) => {
                      const item = getEditingServantItem();
                      if (record?.protocol !== "tars") {
                        return null;
                      }
                      return (
                        <>
                          {item && item.adapters.length > 1 && (
                            <Button
                              onClick={() => onDeleteAdapterItemClicked(index)}
                            >
                              <DeleteTwoTone twoToneColor="#DC143C" />
                              删除
                            </Button>
                          )}
                        </>
                      );
                    },
                  });
                }
                return (
                  <Table
                    size="small"
                    pagination={false}
                    columns={adapterColumns}
                    dataSource={
                      record.editingItem
                        ? record.editingItem.adapters
                        : record.adapters
                    }
                    footer={() => {
                      const item = getEditingServantItem();
                      return (
                        <div style={{ textAlign: "center" }}>
                          {item && item.podIP === record.podIP && (
                            <Button
                              size="small"
                              type="dashed"
                              onClick={onAddAdapterClicked}
                            >
                              <PlusCircleOutlined />
                              新增一行
                            </Button>
                          )}
                        </div>
                      );
                    }}
                  ></Table>
                );
              }}
            />
          </TabPane>

          {!props.forbitEdit ? (
            <TabPane key="batch-editor" tab="批量编辑">
              {state.batchEditItemTemplate &&
                props.selectedItems &&
                props.selectedItems.length >= 2 && (
                  <>
                    <Card>
                      <h4>待更新节点</h4>
                      {(props.selectedItems || []).map(({ podIP }, index) => (
                        <Tag color="blue" key={index}>
                          {podIP}
                        </Tag>
                      ))}
                    </Card>
                    <Table
                      size="small"
                      pagination={false}
                      columns={batchEditorColumns}
                      dataSource={[state.batchEditItemTemplate]}
                      expandable={{ defaultExpandAllRows: true }}
                      expandedRowRender={(record) => {
                        if (
                          record.editingItem &&
                          (
                            adapterColumns[
                            adapterColumns.length - 1
                            ] as ColumnType<IAdapterItem>
                          ).dataIndex !== "adapterOperation"
                        ) {
                          adapterColumns.push({
                            title: "操作",
                            dataIndex: "adapterOperation",
                            width: 100,
                            align: "center",
                            render: (text, record, index) => {
                              const item = getEditingServantItem();
                              return (
                                <>
                                  {item && item.adapters.length > 1 && (
                                    <Button
                                      onClick={() =>
                                        onDeleteAdapterItemClicked(index)
                                      }
                                    >
                                      <DeleteTwoTone twoToneColor="#DC143C" />
                                      删除
                                    </Button>
                                  )}
                                </>
                              );
                            },
                          });
                        }
                        return (
                          <Table
                            size="small"
                            pagination={false}
                            columns={adapterColumns}
                            dataSource={
                              record.editingItem
                                ? record.editingItem.adapters
                                : record.adapters
                            }
                            footer={() => {
                              return (
                                <div style={{ textAlign: "center" }}>
                                  <Button
                                    size="small"
                                    type="dashed"
                                    onClick={onAddAdapterClicked}
                                  >
                                    <PlusCircleOutlined />
                                    新增一行
                                  </Button>
                                </div>
                              );
                            }}
                          ></Table>
                        );
                      }}
                    />
                  </>
                )}

              {(!state.batchEditItemTemplate ||
                !props.selectedItems ||
                props.selectedItems.length <= 1) && (
                  <Result
                    status="500"
                    title="无法批量编辑"
                    subTitle={
                      props.selectedItems && props.selectedItems.length >= 2
                        ? "节点存在不一致Obj，重新检查各节点Obj数量、名称、协议、端口等是否一致"
                        : "选择节点数量小于2"
                    }
                  />
                )}
            </TabPane>
          ) : null}
        </Tabs>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  selectedItems: state.tarsReducer.selectedItems,
});

export default connect(mapStateToProps, null)(EditTemplate);
