import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  Button,
  Card,
  Form,
  Upload,
  Row,
  Col,
  FormInstance,
  Tabs,
  Popconfirm,
  Input,
  Select,
  message,
  Modal,
  notification,
  Cascader,
  Spin,
  Space,
  InputNumber,
} from "antd";
import {
  UploadOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";
import { pickClusterName } from "src/utils/Picker";
import MonacoEditor from "react-monaco-editor";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

import ProtocolSelect from "./ProtocolSelect";
import TarsFIleViewModal from "./TarsFIleViewModal";

import "./style/debugger.less";

import * as JSONBigInt from "json-bigint";

const { TabPane } = Tabs;
const FormItem = Form.Item;
const SelectOption = Select.Option;

interface ServantInterfaceItem {
  tarsServantObj: string;
  tarsServantFuncs: Array<string>;
}

interface IInterfaceTestCases {
  testCaseItems: Array<FetchType.IInterfaceTestCaseItem>;
}

interface IInterfaceItem {
  id: number;
  tarsFileName: string;
  interfaceItems: Array<ServantInterfaceItem>;
  savedTestCases: Map<string, IInterfaceTestCases>;
}

interface IClusterItem {
  errCode: number;
  errMsg: string;
  items: Array<IInterfaceItem>;
}

const popupNotification = (
  path: string,
  data: { errCode: number; errMsg: string } | any
): boolean => {
  notification.destroy();
  if (
    data &&
    data.errCode !== undefined &&
    (data.errCode === 0 || data.errCode === 12 || data.errCode === 13)
  ) {
    return false;
  }
  notification.destroy();
  const errCode = data && data.errCode === undefined ? -1 : data.errCode;
  const errMsg =
    data && data.errMsg && typeof data.errMsg === "string"
      ? data.errMsg
      : data && typeof data.stack === "string"
      ? data.stack
      : JSON.stringify(data);
  notification.error({
    message: `${path}请求失败, 稍后重试`,
    description: `errCode:${errCode}\nerrMsg:${errMsg}`,
    style: { width: 500, whiteSpace: "pre-line" },
  });
  return true;
};

const modifyMonacoTheme = (monaco: any) => {
  const theme = monaco.editor.defineTheme("baseTheme", {
    base: "vs",
    inherit: true,
    rules: [
      { token: "comment", foreground: "ffa500", fontStyle: "italic" },
      { token: "keyword", foreground: "aa1111" }, //关键字
      { token: "string", foreground: "000000" }, //字符串
      { token: "number", foreground: "0000ff" }, //数字
    ],
    colors: {
      "editor.foreground": "#000000", //将编辑器的整体前景颜色（即文本颜色）
      "editor.background": "#ffffff", //将编辑器的背景颜色设置为白色
      "editorCursor.foreground": "#8B0000", //编辑器中当前光标的颜色。
      "editor.lineHighlightBackground": "#e8f2ff", //当前光标所在行的背景色。
      "editorLineNumber.foreground": "#008800", //行号的颜色。
      "editorGutter.background": "#f6f6f6", //行号的背景颜色。
      "editor.selectionBackground": "#d2ceee", //选中文本的颜色。
      "editor.inactiveSelectionBackground": "#88000015", //非活动编辑器中所选文本的颜色。
    },
  });

  monaco.editor.setTheme("baseTheme", theme);
};

const DebuggerBlack: React.FC = (props: any) => {
  // 控制弹窗显示（tars协议弹窗）
  const [controlTarsModalVisible, setControlTarsModalVisible] = useState(false);

  // 所有集群map
  const [clusterItems, setClusterItems] = useState(new Map());

  // 当前选中的集群
  const [clusterName, setClusterName] = useState("");

  // 所有协议列表
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);

  // 所有添加的解析结构体
  const [tarsDecodeItems, setTarsDecodeItems] = useState<
    Array<FetchType.IDecodeStructItem>
  >([]);

  // 所有编辑器的editor实例
  const editorMapRef = useRef(new Map<string, any>());

  const selectForm = useRef<FormInstance>(null);
  const testCaseForm = useRef<FormInstance>(null);
  // const decodeForm=useRef<FormInstance>(null);

  // 选择的关联文件值ref
  const tarsFilePathRef = useRef<string[]>();

  // 查看文件内容弹窗ref
  const fileViewModalRef = useRef<any>();

  // 输入参数
  const [inputParameters, setInputParameters] = useState<string>("");

  // 输入上下文，调试
  const [inputTarsContext, setInputTarsContext] = useState<string>("");

  // 输出上下文，调试
  const [debugResponse, setDebugResponse] = useState<string>("");

  //文件
  const [selectedTarsFileName, setSelectedTarsFileName] = useState<
    string | undefined
  >("");

  // servant
  const [selectedTarsServantObj, setSelectedTarsServantObj] = useState<
    string | undefined
  >("");

  //函数方法名称
  const [selectedTarsFuncName, setSelectedTarsFuncName] = useState<
    string | undefined
  >("");

  const [selectedTarsTestCase, setSelectedTarsTestCase] = useState<
    string | undefined
  >("");

  //接口名称
  const [selectedTarsInterfaceName, setSelectedTarsInterfaceName] = useState<
    string | undefined
  >("");

  // 模块名称
  const [selectedTarsModuleName, setSelectedTarsModuleName] = useState<
    string | undefined
  >("");

  const [rpcWaitTime, setRpcWaitTime] = useState<number>(60000);

  const [selectedPodIP, setSelectedPodIP] = useState<string | undefined>("");
  const [saveVisible, setSaveVisible] = useState(false);
  const [showTestCaseManage, setShowTestCaseManage] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const {
    selectedTarsApplication,
    selectedTarsSetName,
    selectedTarsServer,
    currentUser,
    tarsServerItems,
  } = useSelector((state: any) => ({
    selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
    selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
    selectedTarsServer: state.tarsReducer.selectedTarsServer,
    currentUser: state.tarsReducer.currentUser,
    tarsServerItems: state.tarsReducer.tarsServerItems,
  }));

  // 删除协议文件
  const fetchDeleteFile = (file: UploadFile) => {
    if (!selectedTarsApplication || !selectedTarsServer) {
      message.error("没选应用和服务");
      return;
    }

    const clusterItem = clusterItems.get(clusterName);

    const interfaceItem = (clusterItem as IClusterItem).items.find(
      (item) => item.tarsFileName === file.name
    );

    fetch
      .fetchDeleteDebugInterfaceProtocolFile({
        userName: currentUser || "",
        tarsApplication: selectedTarsApplication,
        tarsSetName: selectedTarsSetName || "",
        tarsServerName: selectedTarsServer,
        tarsFileID: (interfaceItem as IInterfaceItem)?.id,
        tarsFileName: file.name,
      })
      .then(() => {
        message.success("删除成功");
        fetchData();
      })
      .catch((_) => {});
  };

  // 删除所有协议文件
  const fetchDeleteFileAll = () => {
    if (!selectedTarsApplication || !selectedTarsServer) {
      message.error("没选应用和服务");
      return;
    }

    fetch
      .deleteAllDebugInterfaceProtocolFile({
        userName: currentUser || "",
        tarsApplication: selectedTarsApplication,
        tarsSetName: selectedTarsSetName || "",
        tarsServerName: selectedTarsServer,
        clusterName: clusterName || "",
      })
      .then(() => {
        message.success("清空成功");
        fetchData();
      })
      .catch((_) => {});
  };

  // 处理初始数据
  const fetchData = (callback?: (arg: string) => void) => {
    if (!selectedTarsApplication || !selectedTarsServer) {
      return;
    }
    setIsLoading(true);
    fetch
      .fetchListDebugInterface({
        tarsApplication: selectedTarsApplication,
        tarsSetName: selectedTarsSetName || "",
        tarsServerName: selectedTarsServer,
      })
      .then((data) => {
        let fileList: Array<UploadFile> = [];
        let files = new Set<string>();
        let clusterItems = new Map<string, IClusterItem>();

        for (const i in data.items) {
          let clusterItem: IClusterItem = data.items[i];
          for (let item of clusterItem.items) {
            item.savedTestCases = new Map<string, IInterfaceTestCases>(
              Object.entries(item.savedTestCases)
            );
          }
          clusterItems.set(i, clusterItem);
        }

        let modifyClusterName = clusterName;
        if (isEmpty(modifyClusterName)) {
          modifyClusterName = pickClusterName(Array.from(clusterItems.keys()));
        }

        clusterItems.get(clusterName)?.items.forEach(({ tarsFileName }) => {
          files.add(tarsFileName);
        });

        files.forEach((file) => {
          fileList.push({
            uid: file,
            name: file,
          });
        });
        fileList.sort((a, b) => a.name.localeCompare(b.name));
        setFileList(fileList);
        setClusterItems(clusterItems);
        setClusterName(modifyClusterName);
        setIsLoading(false);
        callback && callback(modifyClusterName);
      })
      .catch((_) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  // 调试结构
  const fetchDebugInterface = (
    podIP: string,
    tarsFileName: string,
    tarsModuleName: string,
    tarsInterfaceName: string,
    tarsServantFunc: string,
    tarsServantObj: string,
    rpcWaitTime: number
  ) => {
    if (!selectedTarsApplication || !selectedTarsServer) {
      message.error("请先选择应用和服务");
      return;
    }

    if (!inputParameters || isEmpty(inputParameters)) {
      message.error("请求参数为空");
      return;
    }

    let tarsContext = {};
    if (!isEmpty(inputTarsContext)) {
      try {
        tarsContext = JSON.parse(inputTarsContext || "{}");
      } catch {}
    }

    // 保存最近localStorage中，或者可以存在indexedDB中（后续考虑）
    const lastDebugParamsString = localStorage.getItem("lastDebugParams");
    const lastDebugParamsByServerString = localStorage.getItem(
      "lastDebugParamsByServer"
    );
    const lastDebugParams = JSON.parse(lastDebugParamsString || "{}");
    const lastDebugParamsByServer = JSON.parse(
      lastDebugParamsByServerString || "{}"
    );

    const lastDebugParamsKey = `${selectedTarsApplication}_${selectedTarsSetName}_${selectedTarsServer}_${tarsModuleName}_${tarsInterfaceName}_${tarsServantFunc}`;
    const lastDebugParamsByServerKey = `${selectedTarsApplication}_${selectedTarsSetName}_${selectedTarsServer}_${clusterName}`;
    lastDebugParams[lastDebugParamsKey] = {
      context: inputTarsContext || "{}",
      params: inputParameters,
    };

    lastDebugParamsByServer[lastDebugParamsByServerKey] = {
      podIP: podIP,
      tarsFileName: tarsFileName,
      tarsServantObj: tarsServantObj,
      tarsServantFunc: tarsServantFunc,
      tarsModuleName: tarsModuleName,
      tarsInterfaceName: tarsInterfaceName,
      tarsDecodeItems: tarsDecodeItems,
      context: inputTarsContext || "{}",
      params: inputParameters,
      rpcWaitTime: rpcWaitTime,
    };

    localStorage.setItem("lastDebugParams", JSON.stringify(lastDebugParams));
    localStorage.setItem(
      "lastDebugParamsByServer",
      JSON.stringify(lastDebugParamsByServer)
    );

    fetch
      .fetchDebugInterface({
        userName: currentUser || "",
        tarsApplication: selectedTarsApplication,
        tarsSetName: selectedTarsSetName || "",
        tarsServerName: selectedTarsServer,
        podIP,
        tarsFileName,
        tarsServantObj,
        tarsServantFunc,
        tarsServantFuncParam: inputParameters,
        tarsServantFuncContext: tarsContext,
        tarsDecodeItems: tarsDecodeItems,
        clusterName: clusterName,
        timeout: rpcWaitTime,
        interfaceName: tarsInterfaceName,
      })
      .then((data) => {
        const editorMap = editorMapRef.current;
        const responseEditor = editorMap.get(`response_${clusterName}`);
        const response = JSONBigInt.parse(data.result || "{}");
        const debugResponse = JSONBigInt.stringify(response, null, 2);

        if (responseEditor) responseEditor.setValue(debugResponse);
        setDebugResponse(debugResponse);
      })
      .catch((_) => {});
  };

  // 编辑器挂载完成
  const editorDidMount = (name: string, editor: any, monaco: any) => {
    // editor.focus();
    if (!editorMapRef.current.has(name)) editorMapRef.current.set(name, editor);
  };

  // 保存测试用例
  const fetchAddTestCase = (testCaseName: string) => {
    if (!selectedTarsApplication || !selectedTarsServer) {
      message.error("没选应用和服务");
      return;
    }

    if (
      !selectedTarsFileName ||
      !selectedTarsServantObj ||
      !selectedTarsFuncName
    ) {
      message.error("没选tars接口");
      return;
    }

    let parameters = "";
    const parametersEditor = editorMapRef.current.get(
      `parameters_${clusterName}`
    );
    if (parametersEditor)
      parameters = parametersEditor.getValue() || inputParameters;

    let tarsContext = "";
    const contextEditor = editorMapRef.current.get(`context_${clusterName}`);
    if (contextEditor)
      tarsContext = contextEditor.getValue() || inputTarsContext;

    fetch
      .fetchAddOrModifyDebugInterfaceTestCase({
        userName: currentUser || "",
        clusterName: clusterName,
        tarsApplication: selectedTarsApplication,
        tarsSetName: selectedTarsSetName || "",
        tarsServerName: selectedTarsServer,
        tarsFileName: selectedTarsFileName,
        tarsServantObj: selectedTarsServantObj,
        item: {
          name: testCaseName,
          funcName: selectedTarsFuncName,
          parameters,
          tarsContext,
        },
      })
      .then(() => {
        message.success("保存成功");
        if (selectForm.current)
          selectForm.current.setFieldsValue({ testCase: testCaseName });
        setTimeout(() => fetchData(), 1000);
      })
      .catch((_) => {});
  };

  // 删除测试用例
  const fetchDeleteTestCase = () => {
    if (!selectedTarsApplication || !selectedTarsServer) {
      message.error("没选应用和服务");
      return;
    }

    if (
      !selectedTarsFileName ||
      !selectedTarsServantObj ||
      !selectedTarsFuncName
    ) {
      message.error("没选tars接口");
      return;
    }

    if (!selectedTarsTestCase) {
      message.error("没选测试用例");
      return;
    }

    fetch
      .fetchDeleteDebugInterfaceTestCase({
        userName: currentUser || "",
        clusterName: clusterName,
        tarsApplication: selectedTarsApplication,
        tarsSetName: selectedTarsSetName || "",
        tarsServerName: selectedTarsServer,
        tarsFileName: selectedTarsFileName,
        tarsServantObj: selectedTarsServantObj,
        item: {
          name: selectedTarsTestCase,
          funcName: selectedTarsFuncName,
          parameters: "",
          tarsContext: "",
        },
      })
      .then(() => {
        message.success("删除成功");
        if (selectForm.current)
          selectForm.current.setFieldsValue({ testCase: "default" });
        setTimeout(() => fetchData(), 1000);
      })
      .catch((_) => {});
  };

  const setContextAndParamsToEditor = (
    inputParameters: string,
    inputTarsContext: string
  ) => {
    const parametersEditor = editorMapRef.current.get(
      `parameters_${clusterName}`
    );
    if (parametersEditor) {
      parametersEditor.setValue(inputParameters);
      parametersEditor.setSelection(new monaco.Selection(0, 0, 0, 0));
    }

    const contextEditor = editorMapRef.current.get(`context_${clusterName}`);
    if (contextEditor) {
      contextEditor.setValue(inputTarsContext);
      parametersEditor.setSelection(new monaco.Selection(0, 0, 0, 0));
    }
  };

  // 接口的选择变动之后
  const getFuncInterface = (
    funcInterface: any,
    selectedTarsFileName: string | undefined,
    selectedTarsServantObj: string | undefined,
    selectedTarsModuleName?: string | undefined,
    selectedTarsInterfaceName?: string | undefined
  ) => {
    let inputParameters = undefined;
    let inputTarsContext = undefined;
    if (
      selectedTarsApplication &&
      selectedTarsServer &&
      selectedTarsFileName &&
      selectedTarsServantObj
    ) {
      const lastDebugParamsKey = `${selectedTarsApplication}_${selectedTarsSetName}_${selectedTarsServer}_${selectedTarsModuleName}_${selectedTarsInterfaceName}_${funcInterface}`;
      const lastDebugParamsString = localStorage.getItem("lastDebugParams");
      if (lastDebugParamsString) {
        const lastDebugParams = JSON.parse(lastDebugParamsString);
        const lastDebugParamsItem = lastDebugParams[lastDebugParamsKey];
        if (lastDebugParamsItem) {
          inputParameters = lastDebugParamsItem.params || "{}";
          inputTarsContext = lastDebugParamsItem.context || "{}";
          // 走缓存
          return {
            inputParameters,
            inputTarsContext,
          };
        }
      }
    }

    console.log("走default");
    const clusterItem = clusterItems.get(clusterName);
    //TODO
    let testCases = getTestCaseByServant(
      clusterItem,
      selectedTarsFileName,
      selectedTarsInterfaceName
    );

    // 获取defualt的测试用例，填充进去

    const defaultTestCase = testCases.find(
      (item) => item.name === "default" && item.funcName === funcInterface
    );

    return {
      inputParameters: defaultTestCase?.parameters || "{}",
      inputTarsContext: defaultTestCase?.tarsContext || "{}",
    };
  };

  // 整个大表单发生值的变化
  const formValuesChange = (
    changedValues: any,
    allValues: any,
    testCases: Array<FetchType.IInterfaceTestCaseItem>
  ) => {
    let isFuncChanged = false;
    let isTestCaseChanged = false;

    if (allValues?.structAndVariable && allValues?.structName) {
      setTarsDecodeItems([
        {
          variableName: `${allValues.structAndVariable[0]}::${allValues.structAndVariable[1]}`,
          structName: allValues?.structName,
        },
      ]);
    } else {
      setTarsDecodeItems([]);
    }

    // 如果修改的值，不属于这几项的变更，直接返回
    if (
      !(
        changedValues.ModuleAndInterfaceAndFunc ||
        changedValues.testCase ||
        changedValues.podIP ||
        changedValues.tarsServantServerObj ||
        changedValues.rpcWaitTime
      )
    ) {
      return;
    }

    if (changedValues.ModuleAndInterfaceAndFunc) {
      isFuncChanged = true;
    }

    if (changedValues.testCase) {
      isTestCaseChanged = true;
    }
    let selectedPodIP = allValues.podIP;
    let selectedTarsServantObj: string | undefined =
      allValues.tarsServantServerObj;
    let selectedTarsFileName: string | undefined = undefined;
    let selectedTarsFuncName: string | undefined = undefined;
    let selectedTarsModuleName: string | undefined = undefined;
    let selectedTarsInterfaceName: string | undefined = undefined;
    let selectedTarsTestCase = allValues.testCase;

    if (allValues.ModuleAndInterfaceAndFunc) {
      const parts = allValues.ModuleAndInterfaceAndFunc[0]?.split("|") || [];
      if (parts.length === 2) {
        selectedTarsFileName = parts[0];
        selectedTarsModuleName = parts[1];
      }
      selectedTarsInterfaceName = allValues.ModuleAndInterfaceAndFunc[1];
      selectedTarsFuncName = allValues.ModuleAndInterfaceAndFunc[2];
    }

    let inputParameters: string = "";
    let inputTarsContext: string = "";

    // 选择了测试用例和函数名称，就把测试用例的参数和上下文填充到编辑器中
    // 没有选择测试用例，就把函数的参数和上下文填充到编辑器中（基于localstorage）
    if (selectedTarsFuncName && selectedTarsTestCase) {
      const testCaseItem = testCases.find(
        (item) =>
          item.funcName === selectedTarsFuncName &&
          item.name === selectedTarsTestCase
      );
      if (testCaseItem) {
        inputParameters = testCaseItem.parameters;
        inputTarsContext = testCaseItem.tarsContext;
        setContextAndParamsToEditor(inputParameters, inputTarsContext);
      }
    } else if (selectedTarsFuncName) {
      const {
        inputParameters: getInputParameters,
        inputTarsContext: getInputTarsContext,
      } = getFuncInterface(
        selectedTarsFuncName,
        selectedTarsFileName,
        selectedTarsServantObj,
        selectedTarsModuleName,
        selectedTarsInterfaceName
      );
      inputParameters = getInputParameters;
      inputTarsContext = getInputTarsContext;
    }

    // servant发生变化
    if (isFuncChanged || isTestCaseChanged) {
      setSelectedTarsFileName(selectedTarsFileName);
      setSelectedTarsServantObj(selectedTarsServantObj);
      setSelectedTarsFuncName(selectedTarsFuncName);
      setInputParameters(inputParameters);
      setInputTarsContext(inputTarsContext);
      setSelectedTarsTestCase(selectedTarsTestCase);
      setSelectedTarsModuleName(selectedTarsModuleName);
      setSelectedTarsInterfaceName(selectedTarsInterfaceName);
    }
    setSelectedPodIP(selectedPodIP);
  };

  // 选择了podIP之后，需要重新拉取数据
  const refreshByCache = (clusterName: string) => {
    try {
      const lastDebugParamsByServerKey = `${selectedTarsApplication}_${selectedTarsSetName}_${selectedTarsServer}_${clusterName}`;
      const lastDebugParamsString = localStorage.getItem(
        "lastDebugParamsByServer"
      );

      const lastDebugParamsByServer = JSON.parse(lastDebugParamsString || "{}");

      const lastDebugParamsItem =
        lastDebugParamsByServer[lastDebugParamsByServerKey];

      // 上一次有使用的情况
      if (
        lastDebugParamsItem &&
        lastDebugParamsItem.tarsModuleName &&
        lastDebugParamsItem.tarsInterfaceName &&
        lastDebugParamsItem.tarsServantFunc
      ) {
        setTarsDecodeItems(lastDebugParamsItem.tarsDecodeItems);
        setSelectedTarsFileName(lastDebugParamsItem.tarsFileName);
        setSelectedTarsServantObj(lastDebugParamsItem.tarsServantObj);
        setSelectedTarsFuncName(lastDebugParamsItem.tarsServantFunc);
        setSelectedTarsModuleName(lastDebugParamsItem.tarsModuleName);
        setSelectedTarsInterfaceName(lastDebugParamsItem.tarsInterfaceName);
        setInputParameters(lastDebugParamsItem.params);
        setInputTarsContext(lastDebugParamsItem.context);
        setRpcWaitTime(lastDebugParamsItem.rpcWaitTime);

        // 查找是否还有podIP
        const validPodIPs = (tarsServerItems ? tarsServerItems : []).filter(
          (item: {
            clusterName: any;
            currentState: string;
            podReady: number;
          }) =>
            item.clusterName === clusterName && item.currentState == "active"
        );
        const filterPodIP = validPodIPs.find(
          (item: { podIP: any }) => item.podIP === lastDebugParamsItem.podIP
        );

        setSelectedPodIP(
          filterPodIP ? lastDebugParamsItem.podIP : validPodIPs[0]?.podIP
        );

        setTimeout(() => {
          const tarsDecodeItems = lastDebugParamsItem?.tarsDecodeItems || [];
          selectForm.current?.setFieldsValue({
            ModuleAndInterfaceAndFunc: [
              `${lastDebugParamsItem.tarsFileName}|${lastDebugParamsItem.tarsModuleName}`,
              lastDebugParamsItem.tarsInterfaceName,
              lastDebugParamsItem.tarsServantFunc,
            ],
            tarsServantServerObj: lastDebugParamsItem.tarsServantObj,

            podIP: filterPodIP
              ? lastDebugParamsItem.podIP
              : validPodIPs[0]?.podIP,
            structAndVariable: tarsDecodeItems?.length
              ? [
                  tarsDecodeItems[0].variableName?.split("::")[0],
                  tarsDecodeItems[0].variableName?.split("::")[1],
                ]
              : [],
            structName: tarsDecodeItems[0]?.structName || "",
            rpcWaitTime: lastDebugParamsItem.rpcWaitTime || 60000,
          });
        });
      }
    } catch (error) {
      //反解数据出现问题
      console.log(error);
    }

    // 基于当前情况，获取上一次的调试参数的默认配置
  };

  const resetDefault = (isRestCluster = true) => {
    // 首先是清除当前服务的所有的已有状态
    setControlTarsModalVisible(false);

    if (isRestCluster) {
      setClusterItems(new Map());
      setClusterName("");
    }
    setFileList([]);
    setTarsDecodeItems([]);
    setSelectedTarsFileName(undefined);
    setSelectedTarsServantObj(undefined);
    setSelectedTarsFuncName(undefined);
    setInputParameters("");
    setInputTarsContext("");
    setSelectedTarsTestCase(undefined);
    setSaveVisible(false);
    setShowTestCaseManage(true);
    setSelectedPodIP("");
    setDebugResponse("");
    setSelectedTarsModuleName("");
    setSelectedTarsInterfaceName("");
    selectForm.current?.resetFields();
    testCaseForm.current?.resetFields();
  };
  //TODO 切换服务或者切换set之后，都应该重新拉取数据，对于没有发生改变，那就不用重新拉取数据
  useEffect(() => {
    // 其中一个值发生改变之后，都应该重新拉数据
    message.destroy();
    resetDefault();

    fetchData(refreshByCache);

    // 重新查找上次记录的选择的信息 TODO: 其他服务切换之后，上一次选择需要捞出来
  }, [selectedTarsApplication, selectedTarsServer, selectedTarsSetName]);

  useEffect(() => {
    resetDefault(false);
    let fileList: Array<UploadFile> = [];
    let files = new Set<string>();
    clusterItems.get(clusterName)?.items.forEach(({ tarsFileName }: any) => {
      files.add(tarsFileName);
    });

    files.forEach((file) => {
      fileList.push({
        uid: file,
        name: file,
      });
    });
    fileList.sort((a, b) => a.name.localeCompare(b.name));
    setFileList(fileList);
    refreshByCache(clusterName);
  }, [clusterName]);

  useEffect(() => {
    modifyMonacoTheme(monaco);
  }, []);

  const fileNameListRef = useRef<string[]>([]);

  useEffect(() => {
    const fileNameList = fileList.map((item) => item.name);

    const curKey = (fileNameList || [])
      .sort((a, b) => a.localeCompare(b))
      .toString();
    const preKey = (fileNameListRef.current || [])
      .sort((a, b) => a.localeCompare(b))
      .toString();

    if (preKey && preKey !== curKey) {
      // 发生了变化，直接清除localStorage相关调试信息
      const lsDebugStr = localStorage.getItem("lastDebugParams") || "{}";
      let debugObj: any = {};

      try {
        debugObj = JSON.parse(lsDebugStr);
      } catch (e) {}

      const newDebugObj = Object.fromEntries(
        Object.entries(debugObj).filter(
          // ${selectedTarsApplication}_${selectedTarsSetName}_${selectedTarsServer}_${tarsFileName}
          (item) => !fileNameList.some((i) => item[0].includes(`_${i}_`))
        )
      );

      localStorage.setItem("lastDebugParams", JSON.stringify(newDebugObj));
    }

    fileNameListRef.current = fileNameList;
  }, [fileList]);

  // 级联的选项
  let cascaderOptions: any[] = [];

  let cascaderStructOptions: any[] = [];

  let structsOptions: any[] = [];

  // 获取当前选中的servant对应的所有的用例,
  const getTestCaseByServant = (
    clusterItem: IClusterItem,
    selectedTarsFileName: string | undefined,
    selectedTarsInterfaceName: string | undefined
  ) => {
    let testCases: Array<FetchType.IInterfaceTestCaseItem> = [];
    // selectedTarsFileName  当前选中的tars文件名
    if (clusterItem && selectedTarsFileName) {
      // 对应当前选中的servantObj （tarsFile与servant对应）
      if (selectedTarsInterfaceName) {
        // 找出对应servantObj的所有接口
        const interfaceItem = clusterItem.items.find(
          (item: { tarsFileName: string }) =>
            item.tarsFileName === selectedTarsFileName
        );
        // 判断是否有接口
        if (interfaceItem) {
          // 找到这个servant对应的所有的用例（用例包含了对应的函数名称对应的用例名称）
          const savedTestCase = interfaceItem.savedTestCases.get(
            selectedTarsInterfaceName
          );
          if (savedTestCase) {
            testCases = savedTestCase.testCaseItems;
          }
        }
      }
    }
    return testCases;
  };

  // 所有上传的tars case包含了对应的每一个接口的用例
  const clusterItem = clusterItems.get(clusterName);
  // 左后选中的服务的用例
  let testCases: Array<FetchType.IInterfaceTestCaseItem> = getTestCaseByServant(
    clusterItem,
    selectedTarsFileName,
    selectedTarsInterfaceName
  );

  if (clusterItem) {
    cascaderOptions = clusterItem.items.flatMap(
      (item: {
        interfaceFunc: any[];
        tarsFileName: string;
        moduleName: string;
      }) => {
        return {
          value: `${item.tarsFileName}|${item.moduleName}`,
          label: item.moduleName,
          children: item.interfaceFunc.map((interfaceItem) => {
            return {
              value: interfaceItem.interfaceName,
              label: interfaceItem.interfaceName,
              children: interfaceItem.tarsServantFuncs.map((func: any) => {
                return {
                  value: func,
                  label: func,
                };
              }),
            };
          }),
        };
      }
    );
    cascaderStructOptions = Object.values(clusterItem?.structs || {})
      .map((item: any) => {
        return {
          value: item?.name,
          label: item?.name,
          children: (item?.fields || []).map((field: any) => {
            return {
              value: field,
              label: field,
            };
          }),
        };
      })
      .filter((item) => item.children.length > 0);
    structsOptions = Object.values(clusterItem?.structs || {}).map(
      (item: any) => {
        return {
          value: item?.name,
          label: item?.name,
        };
      }
    );
  }

  const onProtocolRelateClick = () => {
    if (!tarsFilePathRef.current?.length) {
      message.warn("请选择协议文件");

      return;
    }

    fetch
      .relateDebugInterfaceProtocolFile({
        tarsApplication: selectedTarsApplication || "",
        tarsSetName: selectedTarsSetName || "",
        tarsServerName: selectedTarsServer || "",
        clusterName: clusterName || "",
        userName: currentUser,
        files: tarsFilePathRef.current,
      })
      .then(() => fetchData());
  };

  return (
    <Spin spinning={isLoading}>
      {isLoading && <div style={{ minHeight: "50vh" }}></div>}
      <Tabs
        size="small"
        activeKey={clusterName}
        onChange={(clusterName) => {
          setClusterName(clusterName);
        }}
        tabPosition="right"
      >
        {Array.from(clusterItems.keys()).map((clusterName) => (
          <TabPane tab={clusterName} key={clusterName}>
            {/* 判断是否上传了文件 */}
            {fileList && fileList.length > 0 ? (
              <Card className="debugCodeCard">
                <Form
                  name="selectForm"
                  className="selectForm"
                  ref={selectForm}
                  layout="inline"
                  validateTrigger="onBlur"
                  onValuesChange={(changedValues, allValues) => {
                    formValuesChange(changedValues, allValues, testCases);
                  }}
                  initialValues={{
                    rpcWaitTime: 60000,
                  }}
                >
                  <Row gutter={16} justify="end" className="inlineFull topBox">
                    <Col>
                      <div className={`${showTestCaseManage ? "hide" : ""}`}>
                        <Row>
                          <Col>
                            <FormItem name="testCase" label="测试用例">
                              <Select
                                dropdownMatchSelectWidth={false}
                                style={{ width: 100 }}
                                allowClear
                              >
                                {testCases
                                  .filter(
                                    (item) =>
                                      item.funcName === selectedTarsFuncName
                                  )
                                  .map((item, index) => (
                                    <SelectOption key={index} value={item.name}>
                                      {item.name}
                                    </SelectOption>
                                  ))}
                              </Select>
                            </FormItem>
                          </Col>
                          {/* 删除用例按钮 */}
                          {selectedTarsTestCase && (
                            <Col className="ml-16">
                              <Popconfirm
                                title={`删除测试用例${selectedTarsTestCase}?`}
                                icon={
                                  <QuestionCircleOutlined
                                    style={{ color: "red" }}
                                  />
                                }
                                okText="删除"
                                cancelText="取消"
                                okButtonProps={{
                                  className: "tool-btn",
                                  danger: true,
                                  type: "primary",
                                  ghost: true,
                                }}
                                cancelButtonProps={{
                                  className: "tool-btn",
                                  type: "primary",
                                  ghost: true,
                                }}
                                onConfirm={() => fetchDeleteTestCase()}
                              >
                                <Button className="tool-btn" danger>
                                  删除用例
                                </Button>
                              </Popconfirm>
                            </Col>
                          )}

                          <Col>
                            <Button
                              type="link"
                              onClick={() => setShowTestCaseManage(true)}
                            >
                              收起
                              <DoubleRightOutlined
                                style={{ color: "#3273f5" }}
                              />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <div className={`${showTestCaseManage ? "" : "hide"}`}>
                        <Button
                          type="link"
                          onClick={() => setShowTestCaseManage(false)}
                        >
                          管理用例
                          <DoubleLeftOutlined style={{ color: "#3273f5" }} />
                        </Button>
                      </div>
                    </Col>

                    <Col>
                      <span>
                        <div>
                          <Button
                            className="tool-btn"
                            onClick={() => {
                              // 管理tars文件
                              setControlTarsModalVisible(true);
                            }}
                          >
                            <UnorderedListOutlined />
                            管理tars协议
                          </Button>
                        </div>
                      </span>
                    </Col>
                  </Row>
                  <Row className="inlineFull bottomBox">
                    <Col className="pdr-30" span={12}>
                      <h2 className="title">调试基本信息</h2>
                      <Row className="baseInfo">
                        {/* 选择servant和接口名称 */}
                        <Col span={14}>
                          <Form.Item
                            name="ModuleAndInterfaceAndFunc"
                            label="模块/接口/方法"
                            rules={[
                              {
                                required: true,
                                message: "请选择模块/接口/方法",
                              },
                            ]}
                            wrapperCol={{ span: 16 }}
                            labelCol={{ span: 8 }}
                          >
                            <Cascader
                              options={cascaderOptions}
                              onChange={() => {
                                selectForm.current?.resetFields(["testCase"]);
                                setSelectedTarsTestCase(undefined);
                                // 这里你可能需要添加你自己的处理逻辑，比如设置一些状态值
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={10}>
                          <Form.Item
                            name="tarsServantServerObj"
                            label="Servant"
                            rules={[
                              { required: true, message: "请选择ServantObj" },
                            ]}
                            wrapperCol={{ span: 16 }}
                            labelCol={{ span: 8 }}
                          >
                            <Select
                              dropdownMatchSelectWidth={false}
                              options={(clusterItem?.tarsServants || []).map(
                                (item: any) => ({
                                  label: item,
                                  value: item,
                                })
                              )}
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="baseInfo">
                        <Col span={12}>
                          {/* 选择服务和IP */}
                          <Form.Item
                            name="podIP"
                            label="IP"
                            rules={[{ required: true, message: "没选IP" }]}
                            labelCol={{ span: 8 }}
                          >
                            <Select
                              dropdownMatchSelectWidth={false}
                              placeholder="请选择IP"
                              showSearch
                              allowClear
                            >
                              {(tarsServerItems ? tarsServerItems : [])
                                .filter(
                                  (item: {
                                    clusterName: any;
                                    currentState: string;
                                    podReady: number;
                                  }) =>
                                    item.clusterName === clusterName &&
                                    item.currentState == "active"
                                )
                                .map(
                                  (
                                    item: {
                                      podIP:
                                        | boolean
                                        | React.ReactChild
                                        | React.ReactFragment
                                        | React.ReactPortal
                                        | null
                                        | undefined;
                                    },
                                    index: React.Key | null | undefined
                                  ) => (
                                    <SelectOption
                                      key={index}
                                      value={item.podIP}
                                    >
                                      {item.podIP}
                                    </SelectOption>
                                  )
                                )}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name="rpcWaitTime"
                            label="调用等待时间"
                            labelCol={{ span: 12 }}
                          >
                            <InputNumber
                              min={1000}
                              max={10000000}
                              step={1000}
                              value={rpcWaitTime || 60000}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <h2 className="title">Context参数</h2>
                      <Col span={24}>
                        <Card className="codeCard" bordered size="small">
                          <div style={{ textAlign: "left" }}>
                            <MonacoEditor
                              height="150px"
                              language="json"
                              value={inputTarsContext || "{}"}
                              options={{
                                minimap: { enabled: false },
                                occurrencesHighlight: false,
                                selectOnLineNumbers: true,
                                automaticLayout: true,
                                scrollBeyondLastLine: false,
                                selectionHighlight: true,
                                lineNumbersMinChars: 3,
                                wordWrap: "on",
                                theme: "baseTheme",
                              }}
                              editorDidMount={(editor: any, monaco: any) =>
                                editorDidMount(
                                  `context_${clusterName}`,
                                  editor,
                                  monaco
                                )
                              }
                              onChange={(inputTarsContext) =>
                                setInputTarsContext(inputTarsContext)
                              }
                            />
                          </div>
                        </Card>
                      </Col>

                      <h2 className="title">入参</h2>
                      <Col span={24}>
                        <Card className="codeCard" bordered size="small">
                          <div style={{ textAlign: "left" }}>
                            <MonacoEditor
                              height="320px"
                              language="json"
                              value={inputParameters || "{}"}
                              options={{
                                minimap: { enabled: false },
                                occurrencesHighlight: false,
                                selectOnLineNumbers: true,
                                automaticLayout: true,
                                scrollBeyondLastLine: false,
                                selectionHighlight: true,
                                roundedSelection: true,
                                lineNumbersMinChars: 3,
                                wordWrap: "on",
                                theme: "baseTheme",
                              }}
                              editorDidMount={(editor: any, monaco: any) =>
                                editorDidMount(
                                  `parameters_${clusterName}`,
                                  editor,
                                  monaco
                                )
                              }
                              onChange={(inputParameters) =>
                                setInputParameters(inputParameters)
                              }
                            />
                          </div>
                        </Card>
                      </Col>

                      {/* 接口调试的按钮 */}
                      {
                        <Col span={24} className="submitBtn">
                          <Button
                            disabled={!(selectedTarsFuncName && selectedPodIP)}
                            className="tool-btn"
                            type="primary"
                            onClick={async () => {
                              try {
                                if (selectForm.current) {
                                  const fields =
                                    await selectForm.current.validateFields();
                                  const parts =
                                    fields.ModuleAndInterfaceAndFunc[0].split(
                                      "|"
                                    );
                                  if (parts.length === 2) {
                                    fetchDebugInterface(
                                      fields.podIP,
                                      parts[0],
                                      parts[1],
                                      fields.ModuleAndInterfaceAndFunc[1],
                                      fields.ModuleAndInterfaceAndFunc[2],
                                      fields.tarsServantServerObj,
                                      fields.rpcWaitTime
                                    );
                                  } else {
                                    message.error("未知错误");
                                  }
                                }
                              } catch {}
                            }}
                          >
                            接口调试
                          </Button>
                          {/*保存用例按钮 */}
                          {
                            <Popconfirm
                              title={
                                <Form
                                  name="testCaseForm"
                                  ref={testCaseForm}
                                  validateTrigger="onBlur"
                                >
                                  <FormItem
                                    name="testCaseName"
                                    label="用例名"
                                    rules={[{ required: true }]}
                                  >
                                    <Input />
                                  </FormItem>
                                </Form>
                              }
                              icon={null}
                              okText="保存"
                              cancelText="取消"
                              okButtonProps={{
                                className: "tool-btn",
                                type: "primary",
                                ghost: true,
                              }}
                              cancelButtonProps={{
                                className: "tool-btn",
                                danger: true,
                                ghost: true,
                              }}
                              placement="top"
                              overlayStyle={{ minWidth: 300 }}
                              visible={saveVisible}
                              getPopupContainer={(trigger) => document.body}
                              onConfirm={async () => {
                                try {
                                  if (testCaseForm.current) {
                                    const fields =
                                      await testCaseForm.current.validateFields();

                                    setSaveVisible(false);
                                    fetchAddTestCase(fields.testCaseName);
                                  } else {
                                    message.error("未知错误");
                                  }
                                } catch {}
                              }}
                              onCancel={() => setSaveVisible(false)}
                            >
                              <Button
                                ghost
                                type="primary"
                                className="tool-btn ml-10"
                                disabled={!selectedTarsFuncName}
                                onClick={async () => {
                                  await selectForm.current?.validateFields();
                                  setSaveVisible(true);
                                }}
                              >
                                保存用例
                              </Button>
                            </Popconfirm>
                          }
                        </Col>
                      }
                    </Col>
                    <Col className="pdl-30" span={11}>
                      <h2 className="title">结构解析</h2>
                      <Row gutter={16}>
                        <Col span="14">
                          <Form.Item
                            name="structAndVariable"
                            label="变量名"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 18 }}
                          >
                            <Cascader
                              options={cascaderStructOptions}
                              dropdownMatchSelectWidth={false}
                            ></Cascader>
                          </Form.Item>
                        </Col>
                        <Col span="10">
                          <Form.Item
                            name="structName"
                            label="结构体"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Select
                              options={structsOptions}
                              dropdownMatchSelectWidth={false}
                              allowClear
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <div className="ant-form-item-extra">
                        非必填，用于解析结构体数据（结构体/变量名）
                      </div>
                      <h2 className="title">响应信息</h2>
                      <Card className="codeCard" bordered size="small">
                        <div style={{ textAlign: "left" }}>
                          <MonacoEditor
                            language="json"
                            value={debugResponse}
                            onChange={(debugResponse) =>
                              setDebugResponse(debugResponse)
                            }
                            height="600px"
                            options={{
                              minimap: { enabled: false },
                              occurrencesHighlight: false,
                              selectOnLineNumbers: true,
                              automaticLayout: true,
                              scrollBeyondLastLine: false,
                              selectionHighlight: true,
                              roundedSelection: false,
                              lineNumbersMinChars: 3,
                              theme: "baseTheme",
                            }}
                            editorDidMount={(editor: any, monaco: any) =>
                              editorDidMount(
                                `response_${clusterName}`,
                                editor,
                                monaco
                              )
                            }
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              </Card>
            ) : (
              <Card
                className="debugTarsCard"
                title={<div className="tlcenter">暂无Tars协议文件</div>}
              >
                <div className="tarsCardCenter">
                  <p>
                    请通过管理tars协议，上传tars文件后，再进行接口调试！！！
                  </p>
                  <Col>
                    <span>
                      <div>
                        <Button
                          className="tool-btn"
                          onClick={() => {
                            // 管理tars文件
                            setControlTarsModalVisible(true);
                          }}
                        >
                          <UnorderedListOutlined />
                          管理tars协议
                        </Button>
                      </div>
                    </span>
                  </Col>
                </div>
              </Card>
            )}
          </TabPane>
        ))}
      </Tabs>
      <Modal
        visible={controlTarsModalVisible}
        onCancel={() => {
          setControlTarsModalVisible(false);
        }}
        onOk={() => {
          setControlTarsModalVisible(false);
        }}
        title="tars文件列表"
      >
        <div>
          <Space>
            <div style={{ width: 100 }}>从协议仓库上传</div>
            <div>
              <ProtocolSelect
                onChange={(val) => {
                  tarsFilePathRef.current = val;
                }}
                clusterName={clusterName || ""}
                tarsApplication={selectedTarsApplication || ""}
                tarsSetName={selectedTarsSetName || ""}
                tarsServerName={selectedTarsServer || ""}
              />
            </div>
            <a onClick={onProtocolRelateClick}>上传</a>
          </Space>

          <div style={{ fontWeight: "bold", fontSize: 16, padding: "8px 0" }}>
            或
          </div>

          <Space align={"baseline"} className={"protocol-file-row"}>
            <div style={{ width: 100 }}>从本地上传</div>
            <div className={"protocol-uploader-wrapper"}>
              {fileList?.length ? (
                <>
                  <div className={"protocol-file-list-label"}>文件列表</div>
                </>
              ) : null}
              <Upload
                className={"protocol-uploader"}
                fileList={fileList}
                iconRender={(file: UploadFile) => (
                  <EyeOutlined
                    className={"protocol-view-icon"}
                    onClick={() => {
                      const clusterItem = clusterItems.get(clusterName);

                      const interfaceItem = (
                        clusterItem as IClusterItem
                      ).items.find((item) => item.tarsFileName === file.name);

                      fileViewModalRef.current?.show(file.name, () =>
                        fetch.getDebugTarsProtocolFileContent({
                          userName: currentUser || "",
                          tarsApplication: selectedTarsApplication,
                          tarsSetName: selectedTarsSetName || "",
                          tarsServerName: selectedTarsServer,
                          tarsFileID: (interfaceItem as IInterfaceItem)?.id,
                          fileName: file.name,
                          clusterName: clusterName || "",
                        })
                      );
                    }}
                  />
                )}
                showUploadList={{
                  showDownloadIcon: true,
                  showRemoveIcon: true,
                  removeIcon: (file: UploadFile) => (
                    <Popconfirm
                      title={`删除协议文件${file.name}?`}
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okText="删除"
                      cancelText="取消"
                      placement="bottom"
                      okButtonProps={{
                        className: "tool-btn",
                        danger: true,
                        type: "primary",
                        ghost: true,
                      }}
                      cancelButtonProps={{
                        className: "tool-btn",
                        type: "primary",
                        ghost: true,
                      }}
                      onConfirm={() => fetchDeleteFile(file)}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  ),
                }}
                data={{
                  tarsApplication: selectedTarsApplication,
                  tarsServerName: selectedTarsServer,
                  userName: currentUser,
                }}
                action={`${fetch.APIBaseUrl}/server/api/v2/upload_debug_interface_protocol_file`}
                headers={{
                  "tars-server": `${selectedTarsApplication}.${selectedTarsServer}`,
                }}
                onChange={(info: UploadChangeParam<UploadFile>) => {
                  const file = info.file;
                  if (file.status === "removed") return;
                  let fileList = info.fileList;
                  if (file.response) {
                    if (
                      popupNotification(
                        "/upload_debug_interface_protocol_file",
                        file.response
                      )
                    ) {
                      fileList = fileList.filter(
                        (item) => item.uid !== file.uid
                      );
                      setFileList([...fileList]);
                    } else {
                      message.success(`上传协议文件${file.name}成功`);
                      fetchData();
                    }
                  } else {
                    setFileList(
                      [...fileList].sort((a, b) => a.name.localeCompare(b.name))
                    );
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>上传tars文件</Button>
              </Upload>

              <TarsFIleViewModal
                clusterName={clusterName || ""}
                tarsApplication={selectedTarsApplication || ""}
                tarsSetName={selectedTarsSetName || ""}
                tarsServerName={selectedTarsServer || ""}
                ref={fileViewModalRef}
              />

              {fileList?.length ? (
                <Popconfirm
                  title={`确定清空文件?`}
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                  okText="清空"
                  cancelText="取消"
                  okButtonProps={{
                    className: "tool-btn",
                    danger: true,
                    type: "primary",
                    ghost: true,
                  }}
                  cancelButtonProps={{
                    className: "tool-btn",
                    type: "primary",
                    ghost: true,
                  }}
                  onConfirm={() => fetchDeleteFileAll()}
                >
                  <div style={{ margin: "8px 0 0 4px", width: "fit-content" }}>
                    <a>清空文件</a>
                  </div>
                </Popconfirm>
              ) : null}
            </div>
            {fileList?.length ? <div className={"protocol-file-line"} /> : null}
          </Space>
        </div>
      </Modal>
    </Spin>
  );
};

export default DebuggerBlack;
