import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import CommonReporter from "./../../../components/CommonReporter";

interface IProps {
  currentUser?: string;
}
const ThirdAlarmConfig = forwardRef(
  (props: IProps, ref: React.LegacyRef<HTMLDivElement> | undefined) => {
    const fetchData = () => {
      console.log("fetchData");
    };

    useEffect(() => {
      fetchData();
    }, []);
    useImperativeHandle(ref as React.Ref<unknown> | undefined, () => ({
      fetchData,
    }));
    return (
      <div ref={ref}>
        <CommonReporter
          tableId="db_tars_web.t_alarm_webaccess_config.global"
          userName={props.currentUser}
        />
      </div>
    );
  }
);

export default ThirdAlarmConfig;
