import React from "react";
import { Buffer } from "buffer";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { SearchAddon } from "xterm-addon-search";
import { WebLinksAddon } from "xterm-addon-web-links";

// @ts-ignore

// 在终端上传文件发送文件的逻辑
import Zmodem from "nora-zmodemjs/src/zmodem_browser";

import xtermTheme from "xterm-theme";

import "./ToolsBar/style/xterm.less";
import { message } from "antd";
interface IProps {}

const terminalID = "terminal";
let terminal: Terminal | undefined = undefined;

export default class XTerm extends React.Component<IProps> {
  private ws?: WebSocket;
  private fitAddon: FitAddon;
  private terminalRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    // 自动适配窗口
    this.fitAddon = new FitAddon();
    this.terminalRef = React.createRef<HTMLDivElement>();
  }

  // 点击登录的时候，建立连接，以及后续控制指令
  exec(podIP: string, clusterName: string) {
    if (!terminal) return;

    const term = terminal;
    // 清空终端ws
    if (this.ws) {
      console.log("close ws");
      this.ws.close();
      this.ws = undefined;
    }

    const cols = term.cols;
    const rows = term.rows;
    // this.ws = new WebSocket(`wss://${window.location.host}/server/api/v2/exec?ip=${podIP}&cols=${cols}&rows=${rows}`);
    // debug

    const isHttp = window.location.protocol === "http:";
    this.ws = new WebSocket(
      `${isHttp ? "ws" : "wss"}://${
        window.location.host
      }/server/api/v2/exec?ip=${podIP}&cols=${cols}&rows=${rows}&clusterName=${clusterName}`
    );
    // 传递终端的输入type
    this.ws.binaryType = "arraybuffer";
    message.loading("正在连接中...", 0);

    // 发送数据
    Zmodem.Browser.send_block_files = function (
      session: any,
      files: any,
      options: any
    ) {
      return send_block_files(session, files, options);
    };

    // 接入下载数据和发送数据的初始化
    const zsentry = new Zmodem.Sentry({
      // 接收数据到终端
      to_terminal: function (octets: any) {
        if (!zsentry.get_confirmed_session()) {
          term.write(decodeToStr(octets));
        }
      },
      // 发送数据到服务端
      sender: function (octets: any) {
        if (this.ws) {
          return this.ws.send(new Uint8Array(octets));
        }
      },
      on_retract: function () {
        console.log("zmodem Retract");
      },
      on_detect: function (detection: any) {
        var promise;
        let file_input_el: any;
        var zsession = detection.confirm();
        term.write("\r\n");
        // 发送文件
        if (zsession.type === "send") {
          // 动态创建 input 标签，否则选择相同的文件，不会触发 onchang 事件
          file_input_el = document.createElement("input");
          file_input_el.type = "file";
          file_input_el.style.display = "none"; //隐藏
          document.body.appendChild(file_input_el);
          document.body.onfocus = function () {
            document.body.onfocus = null;
            setTimeout(function () {
              // 如果未选择任何文件，则代表取消上传。主动取消
              if (file_input_el.files.length === 0) {
                console.log("Cancel file clicked");
                if (!zsession.aborted()) {
                  zsession.abort();
                }
              }
            }, 1000);
          };
          // 处理发送
          promise = _handle_send_session(file_input_el, zsession);
        } else {
          // 接受文件
          promise = _handle_receive_session(zsession);
        }
        promise
          .catch(console.error.bind(console))
          .then(() => {
            console.log("zmodem Detect promise finished");
          })
          .finally(() => {
            if (file_input_el != null) {
              document.body.removeChild(file_input_el);
            }
          });
      },
    });

    // 监听链接
    this.ws.onopen = (e) => {
      message.destroy();
      term.writeln(`\n\r\x1b[1;32mConnecting to ${podIP}\x1b[0m.\n\r`);
      setTimeout(() => (terminal as Terminal)?.focus(), 200);
    };

    // 监听错误
    this.ws.onerror = (e) => {
      term.writeln("\nConnection error");
      console.log(e);
      this.ws = undefined;
    };

    // 监听断开
    this.ws.onclose = (e: CloseEvent) => {
      // 一样的时候，才关闭，否则就是挂载最新的ws
      if ((e.currentTarget as WebSocket)?.url === this.ws?.url) {
        term.writeln(
          "\n\x1B[1;3;31m===========Connection closed===========\x1B"
        );
        this.ws = undefined;
      }
    };

    // 监听数据接受信息
    let buffer = "";
    this.ws.onmessage = (e) => {
      buffer += e.data;
      setTimeout(() => {
        zsentry.consume(Buffer.from(buffer));
        buffer = "";
      }, 50);
    };
  }

  // 窗口大小改变的时候，重新设置窗口大小
  fit() {
    this.fitAddon.fit();
  }

  // 组件挂载的时候，初始化终端
  componentDidMount() {
    // 首先销毁
    if (terminal) {
      terminal.dispose();
      terminal = undefined;
    }
    // 初始化终端
    terminal = new Terminal({
      lineHeight: 1,
      cursorBlink: true,
      cursorStyle: "bar",
      cursorWidth: 2,
      windowOptions: { setWinSizePixels: true },
      windowsMode: true,
      fontSize: 11,
      fontFamily: 'monaco, Consolas, "Lucida Console", monospace',
      rightClickSelectsWord: true,
      theme: xtermTheme.Molokai,
    });

    // 打开终端，并且设置焦点，以及处理键盘事件
    if (this.terminalRef.current) {
      terminal.open(this.terminalRef.current);

      terminal.attachCustomKeyEventHandler((e) => {
        if (e.ctrlKey && e.key === "c" && terminal && terminal.hasSelection()) {
          return false;
        }
        return !(e.ctrlKey && e.key === "v");
      });
    }
    // 添加插件
    terminal.loadAddon(new SearchAddon());
    terminal.loadAddon(this.fitAddon);
    terminal.loadAddon(new WebLinksAddon());

    setTimeout(() => {
      this.fitAddon.fit();
      (terminal as Terminal)?.focus();
    }, 100);
    // 监听命令的输入，发送到后端
    terminal.onData((data) => {
      if (!this.ws) return;
      this.ws.send(data);
    });
  }

  // 组件卸载的时候，销毁终端
  componentWillUnmount() {
    if (terminal) {
      terminal.dispose();
      terminal = undefined;
    }
  }

  render() {
    return (
      <div
        id={terminalID}
        ref={this.terminalRef}
        style={{ height: 595, width: "100%" }}
      />
    );
  }
}

// 通过终端发送文件
function send_block_files(session: any, files: any, options: any) {
  if (!options) options = {};

  //Populate the batch in reverse order to simplify sending
  //the remaining files/bytes components.
  var batch: Array<any> = [];
  var total_size = 0;
  for (var f = files.length - 1; f >= 0; f--) {
    var fobj = files[f];
    total_size += fobj.size;
    batch[f] = {
      obj: fobj,
      name: fobj.name,
      size: fobj.size,
      mtime: new Date(fobj.lastModified),
      files_remaining: files.length - f,
      bytes_remaining: total_size,
    };
  }

  var file_idx = 0;

  function _check_aborted(session: any) {
    if (session.aborted()) {
      throw new Zmodem.Error("aborted");
    }
  }

  function promise_callback() {
    var cur_b = batch[file_idx];

    if (!cur_b) {
      return Promise.resolve(); //batch done!
    }

    file_idx++;

    return session.send_offer(cur_b).then(function after_send_offer(xfer: any) {
      if (options.on_offer_response) {
        options.on_offer_response(cur_b.obj, xfer);
      }

      if (xfer === undefined) {
        return promise_callback(); //skipped
      }

      return new Promise(function (res) {
        var block = 1024 * 1024;
        var fileSize = cur_b.size;
        var fileLoaded = 0;
        var reader = new FileReader();
        reader.onerror = function reader_onerror(e) {
          console.error("file read error", e);
          throw Error("File read error: " + e);
        };

        function readBlob() {
          var blob;
          if (cur_b.obj.slice) {
            blob = cur_b.obj.slice(fileLoaded, fileLoaded + block + 1);
          } else if (cur_b.obj.mozSlice) {
            blob = cur_b.obj.mozSlice(fileLoaded, fileLoaded + block + 1);
          } else if (cur_b.obj.webkitSlice) {
            blob = cur_b.obj.webkitSlice(fileLoaded, fileLoaded + block + 1);
          } else {
            blob = cur_b.obj;
          }
          reader.readAsArrayBuffer(blob);
        }

        var piece: any;
        reader.onload = function reader_onload(e: any) {
          fileLoaded += e.total;
          if (fileLoaded < fileSize) {
            if (e.target.result) {
              piece = new Uint8Array(e.target.result);
              _check_aborted(session);
              xfer.send(piece);
              if (options.on_progress) {
                options.on_progress(cur_b.obj, xfer, piece);
              }
            }
            readBlob();
          } else {
            //
            if (e.target.result) {
              piece = new Uint8Array(e.target.result);
              _check_aborted(session);
              xfer.end(piece).then(function () {
                if (options.on_progress && piece.length) {
                  options.on_progress(cur_b.obj, xfer, piece);
                }
                if (options.on_file_complete) {
                  options.on_file_complete(cur_b.obj, xfer);
                }
                res(promise_callback());
              });
            }
          }
        };
        readBlob();
      });
    });
  }

  return promise_callback();
}

function bytesHuman(bytes: any, precision?: any) {
  if (!/^([-+])?|(\.\d+)(\d+(\.\d+)?|(\d+\.)|Infinity)$/.test(bytes)) {
    return "-";
  }
  if (bytes === 0) return "0";
  if (typeof precision === "undefined") precision = 1;
  const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB", "BB"];
  const num = Math.floor(Math.log(bytes) / Math.log(1024));
  const value = (bytes / Math.pow(1024, Math.floor(num))).toFixed(precision);
  return `${value} ${units[num]}`;
}

function decodeToStr(octets: any) {
  if (typeof TextEncoder == "function") {
    return new TextDecoder("utf-8").decode(new Uint8Array(octets));
  }
  return decodeURIComponent(escape(String.fromCharCode.apply(null, octets)));
}

function alert_message(name: any, size: any, action: any = "upload") {
  let msg_array = [
    name,
    "大小",
    bytesHuman(size),
    "超过最大传输大小",
    bytesHuman(MAX_TRANSFER_SIZE),
  ];
  let action_name = action;
  switch (action) {
    case "upload":
      action_name = "上传";
      break;
    default:
      action_name = "下载";
  }
  msg_array.unshift(action_name);
  return msg_array.join(" ");
}

function _update_progress(xfer: any, action = "upload") {
  let detail = xfer.get_details();
  let name = detail.name;
  let total = detail.size;
  let offset = xfer.get_offset();
  var percent;
  if (total === 0 || total === offset) {
    percent = 100;
  } else {
    percent = Math.round((offset / total) * 100);
  }
  let msg =
    action + " " + name + ": " + bytesHuman(total) + " " + percent + "%";
  if (terminal) terminal.write("\r" + msg);
}

function _save_to_disk(xfer: any, buffer: any) {
  return Zmodem.Browser.save_to_disk(buffer, xfer.get_details().name);
}

function _validate_transfer_file_size(xfer: any) {
  let detail = xfer.get_details();
  return detail.size < MAX_TRANSFER_SIZE;
}

// 处理接受文件
function _handle_receive_session(zsession: any) {
  zsession.on("offer", function (xfer: any) {
    if (!_validate_transfer_file_size(xfer)) {
      let detail = xfer.get_details();
      let msg = alert_message(detail.name, detail.size, "download");
      alert(msg);
      xfer.skip();
      return;
    }

    function on_form_submit() {
      var FILE_BUFFER: Array<any> = [];
      xfer.on("input", (payload: any) => {
        _update_progress(xfer, "download");
        FILE_BUFFER.push(new Uint8Array(payload));
      });
      xfer.accept().then(() => {
        _save_to_disk(xfer, FILE_BUFFER);
      }, console.error.bind(console));
    }

    on_form_submit();
  });

  var promise = new Promise((res: any) => {
    zsession.on("session_end", () => {
      if (!terminal) return;
      terminal.write("\r\n");
      res();
      console.log("finished ");
    });
  });

  zsession.start();

  return promise;
}

let MAX_TRANSFER_SIZE = 1024 * 1024 * 500; // 默认最大上传下载500M
// 发送文件
function _handle_send_session(file_el: any, zsession: any) {
  let promise = new Promise((res: any) => {
    file_el.onchange = function (e: any) {
      console.log("file input on change", file_el.files);
      let files_obj = file_el.files;
      for (let i = 0; i < files_obj.length; i++) {
        if (files_obj[i].size > MAX_TRANSFER_SIZE) {
          alert(alert_message(files_obj[i].name, files_obj[i].size));
          zsession.abort();
          return;
        }
      }
      Zmodem.Browser.send_block_files(zsession, files_obj, {
        on_offer_response(obj: any, xfer: any) {
          if (xfer) {
            console.log("on_offer_response ", xfer);
            _update_progress(xfer);
          }
        },
        on_progress(obj: any, xfer: any, piece: any) {
          _update_progress(xfer);
        },
        on_file_complete(obj: any) {
          console.log("COMPLETE", obj);
        },
      })
        .then(zsession.close.bind(zsession), console.error.bind(console))
        .then(() => {
          if (!terminal) return;
          res();
          terminal.write("\r\n");
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    file_el.click();
  });

  return promise;
}
