import React from "react";
import { isEmpty } from "lodash";
import MomentFormat from "src/utils/MomentFormat";
import {
  Spin,
  Input,
  Row,
  Col,
  Select,
  FormInstance,
  Badge,
  Button,
  Space,
  Drawer,
  Popover,
  Modal,
  Form,
  Result,
  Popconfirm,
  Tabs,
  Card,
  Radio,
  List,
  notification,
} from "antd";
import {
  EditOutlined,
  SwitcherOutlined,
  EyeTwoTone,
  DeleteTwoTone,
  QuestionCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import MonacoEditor, { MonacoDiffEditor } from "react-monaco-editor";
import * as monaco from "monaco-editor";

import { connect } from "react-redux";

import * as fetch from "src/fetch";
import * as ActionType from "src/stores/types";
import { pickClusterName } from "src/utils/Picker";
import { getLanguageAndTheme, checkLanguageIsCorrect } from "./util";
import { config, language } from "./util/tomlLanguage";

import ApproveButton from './ApproveButton';
import { ApproveKindKeyEnum } from 'src/utils/constant';

import "./style/editconfig.less";

const { TextArea } = Input;

const { TabPane } = Tabs;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const SelectOption = Select.Option;

const ListItem = List.Item;
const ListItemMeta = List.Item.Meta;

const FormItem = Form.Item;

interface IConfigItem {
  badge?: boolean;
  confID: number;
  confName: string;
  confIDCName: string;
  confContent: string;
  creationTimestamp: string;
}

interface IConfig {
  errCode: number;
  errMsg: string;
  cfgItems: Array<IConfigItem>;
}

interface IConfigHistoryItem extends IConfigItem {
  id: number;
  updateConfContent: string;
  userName: string;
  reason: string;
  updateTimestamp: string;
}

interface IConfigHistory {
  errCode: number;
  errMsg: string;
  totalPage: number;
  cfgHistoryItems: Array<IConfigHistoryItem>;
}

interface IProps {
  disabled: boolean;
  forbitEdit: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  approveStatus: Number;
  updateApproveStatus: () => void
  pendingApproveUser: string;
}

interface IState {
  loading: boolean;
  visible: boolean;
  tarsApplication: string;
  tarsSetName: string;
  tarsServer: string;

  language: string;
  languages?: Array<string>;

  loadingHistory: boolean;
  addEditorVisible: boolean;
  confContent: string;
  clusterName: string;
  currentPage: number;
  confItems: Map<string, IConfig>;
  confHistoryItem: IConfigHistory;

  modifyConfItem?: IConfigItem;
  modifiedConfContent?: string;
  modifyReason?: string;
  modifyReasonVisible: boolean;
  modifyEditorVisible: boolean;

  diff1: string;
  diff2: string;
  diffConfVisible: boolean;
  editMonacoInstance: any;

  allowEdit: boolean;
}

class EditConfig extends React.Component<IProps, IState> {
  private addForm: React.RefObject<FormInstance>;
  private reasonForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      tarsApplication: "",
      tarsSetName: "",
      tarsServer: "",
      confContent: "",
      addEditorVisible: false,
      clusterName: "",
      currentPage: 1,
      confItems: new Map(),
      loadingHistory: false,
      confHistoryItem: {
        errCode: 0,
        errMsg: "Not Found",
        totalPage: 0,
        cfgHistoryItems: [],
      },
      language: "xml",
      modifyReasonVisible: false,
      modifyEditorVisible: false,
      diff1: "",
      diff2: "",
      diffConfVisible: false,

      editMonacoInstance: null,

      allowEdit: false,
    };
    this.addForm = React.createRef<FormInstance>();
    this.reasonForm = React.createRef<FormInstance>();
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (
      props.selectedTarsApplication !== state.tarsApplication ||
      props.selectedTarsSetName !== state.tarsSetName ||
      props.selectedTarsServer !== state.tarsServer
    ) {
      return {
        tarsApplication: props.selectedTarsApplication,
        tarsSetName: props.selectedTarsSetName,
        tarsServer: props.selectedTarsServer,
        clusterName: "",
        confItems: new Map(),
        confHistoryItems: new Map(),
      };
    }
    return null;
  }

  componentDidMount(): void {
    // 获取当前monaco.languages是否已经注册了toml语言
    if (monaco && monaco.languages) {
      const languages = monaco.languages.getLanguages();
      const hasRegister = languages.some(
        (language: any) => language.id === "toml"
      );
      if (!hasRegister) {
        monaco.languages.register({ id: "toml" });
        monaco.languages.setLanguageConfiguration("toml", config as any);
        monaco.languages.setMonarchTokensProvider("toml", language as any);
      }
    }
  }
  editorDidMount(editor: any, monaco: any) {
    if (isEmpty(this.state.languages) && monaco && monaco.languages) {
      const languages = monaco.languages.getLanguages();
      if (!isEmpty(languages)) {
        this.setState({
          languages: languages.map((language: any) => language.id),
        });
      }
    }
    editor.focus();
    this.setState({
      editMonacoInstance: editor,
    });
  }

  fetchData() {
    if (isEmpty(this.state.tarsApplication) || isEmpty(this.state.tarsServer)) {
      return;
    }
    this.setState({ loading: true });
    fetch
      .fetchConfigs({
        tarsApplication: this.state.tarsApplication,
        tarsSetName: this.state.tarsSetName,
        tarsServerName: this.state.tarsServer,
      })
      .then((data) => {
        let confItems = new Map<string, IConfig>(Object.entries(data.items));
        let clusterName = this.state.clusterName;
        if (isEmpty(clusterName)) {
          clusterName = pickClusterName(Array.from(confItems.keys()));
        }
        this.setState({
          loading: false,
          clusterName,
          confItems,
        });
      })
      .catch((_) => this.setState({ loading: false }));
  }

  fetchHistoryData() {
    if (isEmpty(this.state.tarsApplication) || isEmpty(this.state.tarsServer)) {
      return;
    }

    this.setState({ loadingHistory: true }, () => {
      fetch
        .fetchHistoryConfigs({
          tarsApplication: this.state.tarsApplication,
          tarsSetName: this.state.tarsSetName,
          tarsServerName: this.state.tarsServer,
          currentPage: this.state.currentPage,
          clusterName: this.state.clusterName,
        })
        .then((confHistoryItem) =>
          this.setState({ confHistoryItem, loadingHistory: false })
        )
        .catch((_) => this.setState({ loading: false }));
    });
  }

  fetchAddConfig(confName: string, confContent: string) {
    const clusterName = this.state.clusterName;
    fetch
      .fetchAddConfig({
        userName: this.props.currentUser || "",
        clusterName,
        tarsApplication: this.state.tarsApplication,
        tarsSetName: this.state.tarsSetName,
        tarsServerName: this.state.tarsServer,
        confName,
        confContent,
      })
      .then((data) => {
        notification.success({
          message: "添加配置成功",
          description: confName,
        });
        let confItems = this.state.confItems;
        let item = confItems.get(clusterName);
        if (item) {
          item.cfgItems.unshift({
            badge: true,
            confID: data.confID,
            confName,
            confIDCName: data.confIDCName,
            confContent,
            creationTimestamp: Math.floor(
              new Date().getTime() / 1000
            ).toString(),
          });
          confItems.set(this.state.clusterName, item);
        }
        this.setState({ confItems, addEditorVisible: false, confContent: "" });
      })
      .catch((_) => {})
      .finally(() => {
        this.props.updateApproveStatus()
      });
  }

  fetchEditConfig() {
    if (!this.state.modifyConfItem) return;
    const reason = this.state.modifyReason || "";
    const confID = this.state.modifyConfItem.confID;
    const confName = this.state.modifyConfItem.confName;
    const confContent = this.state.modifiedConfContent || "";
    const confIDCName = this.state.modifyConfItem.confIDCName;
    fetch
      .fetchEditConfig({
        userName: this.props.currentUser || "",
        tarsApplication: this.state.tarsApplication,
        tarsSetName: this.state.tarsSetName,
        tarsServerName: this.state.tarsServer,
        clusterName: this.state.clusterName,
        confID,
        confName,
        confContent,
        confIDCName,
        reason,
      })
      .then((data) => {
        notification.success({
          message: "修改配置成功",
          description: confName,
        });
        let confItems = this.state.confItems;
        let item = confItems.get(this.state.clusterName);
        if (item) {
          for (let conf of item.cfgItems) {
            if (conf.confID === confID) {
              conf.confContent = confContent;
              break;
            }
          }
          confItems.set(this.state.clusterName, item);
        }

        let confHistoryItem = this.state.confHistoryItem;
        const lastInsertID = data.id;
        confHistoryItem.cfgHistoryItems.unshift({
          id: lastInsertID,
          confID,
          confName,
          confIDCName,
          confContent: this.state.modifyConfItem
            ? this.state.modifyConfItem.confContent
            : "",
          updateTimestamp: Math.floor(new Date().getTime() / 1000).toString(),
          reason,
          updateConfContent: confContent,
          userName: this.props.currentUser || "",
          creationTimestamp: this.state.modifyConfItem?.creationTimestamp || "",
        });

        this.setState({
          confItems,
          confHistoryItem,
          modifyEditorVisible: false,
          modifyConfItem: undefined,
          modifiedConfContent: undefined,
          modifyReasonVisible: false,
        });
      })
      .catch((_) => {
        this.setState({
          modifyEditorVisible: false,
          modifyConfItem: undefined,
          modifiedConfContent: undefined,
          modifyReasonVisible: false,
        });
      })
      .finally(() => {
        this.props.updateApproveStatus()
      });
  }

  fetchDeleteConfig(confID: number, confName: string) {
    if (isEmpty(this.state.tarsApplication) || isEmpty(this.state.tarsServer)) {
      notification["error"]({
        message: "删除失败",
        description: "tarsApplication或tarsServer为空",
      });
      return;
    }
    fetch
      .fetchDeleteConfig({
        userName: this.props.currentUser || "",
        tarsApplication: this.state.tarsApplication,
        tarsSetName: this.state.tarsSetName,
        tarsServerName: this.state.tarsServer,
        clusterName: this.state.clusterName,
        confID,
        confName,
      })
      .then(() => {
        notification["success"]({
          message: "删除成功",
          description: `${confName}已删除`,
        });
        let confItems = this.state.confItems;
        let item = confItems.get(this.state.clusterName);
        if (item) {
          item.cfgItems = item.cfgItems.filter((x) => x.confID !== confID);
          confItems.set(this.state.clusterName, item);
        }
        this.setState({ confItems });
      })
      .catch((_) => {})
      .finally(() => {
        this.props.updateApproveStatus()
      });
  }

  render() {
    const confItem = this.state.confItems.get(this.state.clusterName);
    return (
      <>
        <Popover
          placement="left"
          content={(
            <div>
              <Button
                ghost
                type="primary"
                disabled={this.props.disabled}
                size="small"
                className="tool-btn"
                onClick={() => {
                  this.setState({ 
                    visible: true, 
                    allowEdit: false });
                  this.fetchData();
                }}
                style={{marginBottom: '10px'}}
              >
                <Space>
                  <EyeTwoTone />
                  查看配置
                </Space>
              </Button>
              <ApproveButton 
                kind={ApproveKindKeyEnum.editConfig} 
                approveStatus={this.props.approveStatus} 
                updateApproveStatus={this.props.updateApproveStatus}
                pendingApproveUser={this.props.pendingApproveUser}
              >
                <Button
                  disabled={this.props.disabled}
                  size="small"
                  className="tool-btn"
                  onClick={() => {
                    this.setState({ 
                      visible: true,
                      allowEdit: true,
                    });
                    this.fetchData();
                  }}
                >
                  <Space>
                    <EditOutlined />
                    编辑配置
                  </Space>
                </Button>
              </ApproveButton>
            </div>
          )}
        >
          <Button
            disabled={this.props.disabled}
            size="small"
            className="tool-btn"
          >
            <Space>
              <EditOutlined />
              服务配置
            </Space>
          </Button>
        </Popover>

        <Drawer
          title={<h2 style={{ textAlign: "center" }}>服务配置</h2>}
          closable={false}
          placement="right"
          width="50vw"
          visible={this.state.visible}
          onClose={() => this.setState({ visible: false })}
        >
          {!this.state.loading && (
            <Tabs
              size="large"
              tabPosition="left"
              tabBarGutter={50}
              defaultActiveKey="edit"
              onChange={(activeKey) => {
                if (activeKey === "edit-history") {
                  this.setState({ currentPage: 1 }, () =>
                    this.fetchHistoryData()
                  );
                } else {
                  this.fetchData();
                }
              }}
            >
              <TabPane
                key="edit"
                tab={
                  <span>
                    <EditOutlined />
                    编辑配置
                  </span>
                }
              >
                <div style={{ textAlign: "center", paddingRight: 20 }}>
                  <Card
                    title={
                      <RadioGroup
                        size="large"
                        value={this.state.clusterName}
                        onChange={(e) =>
                          this.setState({ clusterName: e.target.value })
                        }
                      >
                        {Array.from(this.state.confItems.keys()).map(
                          (clusterName, index) => (
                            <RadioButton key={index} value={clusterName}>
                              {clusterName}
                            </RadioButton>
                          )
                        )}
                      </RadioGroup>
                    }
                  >
                    <List
                      size="small"
                      itemLayout="horizontal"
                      dataSource={confItem ? confItem.cfgItems : []}
                      renderItem={(item) => (
                        <ListItem
                          key={item.confID}
                          actions={[
                            <Popover
                              title={item.confName}
                              trigger="click"
                              placement="left"
                              arrowPointAtCenter
                              openClassName="confPopover"
                              overlayStyle={{
                                marginTop: 30,
                                maxHeight: "95vh",
                                maxWidth: "70vw",
                                overflowY: "scroll",
                                borderRadius: "10px",
                                padding: "0",
                                border: "8px solid #f0f0f0",
                              }}
                              content={
                                <code style={{ whiteSpace: "pre-wrap" }}>
                                  {item.confContent}
                                </code>
                              }
                            >
                              <Button
                                className="tool-btn"
                                onClick={() => {
                                  let confItems = this.state.confItems;
                                  const clusterName = this.state.clusterName;
                                  let confItem = confItems.get(clusterName);
                                  if (confItem) {
                                    for (let conf of confItem.cfgItems) {
                                      if (conf.confID === item.confID) {
                                        conf.badge = false;
                                        break;
                                      }
                                    }
                                    confItems.set(
                                      this.state.clusterName,
                                      confItem
                                    );
                                  }
                                  this.setState({ confItems });
                                }}
                              >
                                <EyeTwoTone />
                                查看
                              </Button>
                            </Popover>,
                            <Button
                              disabled={this.props.forbitEdit || !this.state.allowEdit}
                              className="tool-btn"
                              onClick={() => {
                                const { language } = getLanguageAndTheme(
                                  item.confName
                                );

                                this.setState({
                                  modifyEditorVisible: true,
                                  modifyConfItem: { ...item },
                                  modifiedConfContent: item.confContent,
                                  language: language,
                                });
                              }}
                            >
                              <EditOutlined />
                              编辑
                            </Button>,
                            <Popconfirm
                              title="删除配置无法再恢复"
                              okText="删除"
                              okType="danger"
                              cancelText="取消"
                              disabled={this.props.forbitEdit || !this.state.allowEdit}
                              icon={
                                <QuestionCircleOutlined
                                  style={{ color: "red" }}
                                />
                              }
                              onConfirm={() =>
                                this.fetchDeleteConfig(
                                  item.confID,
                                  item.confName
                                )
                              }
                            >
                              <Button
                                disabled={this.props.forbitEdit || !this.state.allowEdit}
                                className="tool-btn"
                              >
                                <DeleteTwoTone twoToneColor="#DC143C" />
                                删除
                              </Button>
                            </Popconfirm>,
                          ]}
                        >
                          <ListItemMeta
                            title={
                              <Badge dot={item.badge}>{item.confName}</Badge>
                            }
                            description={item.confIDCName}
                          />
                        </ListItem>
                      )}
                      footer={
                        <Button
                          disabled={this.props.forbitEdit || !this.state.allowEdit}
                          size="large"
                          type="dashed"
                          className="tool-btn"
                          onClick={() =>
                            this.setState({ addEditorVisible: true })
                          }
                        >
                          <PlusCircleOutlined />
                          添加配置
                        </Button>
                      }
                    />
                  </Card>
                </div>
              </TabPane>

              <TabPane
                key="edit-history"
                tab={
                  <span>
                    <SwitcherOutlined />
                    编辑历史
                  </span>
                }
              >
                <div style={{ textAlign: "center", paddingRight: 20 }}>
                  <Card
                    title={
                      <RadioGroup
                        size="large"
                        value={this.state.clusterName}
                        onChange={(e) => {
                          this.setState(
                            { clusterName: e.target.value, currentPage: 1 },
                            () => this.fetchHistoryData()
                          );
                        }}
                      >
                        {Array.from(this.state.confItems.keys()).map(
                          (clusterName, index) => (
                            <RadioButton key={index} value={clusterName}>
                              {clusterName}
                            </RadioButton>
                          )
                        )}
                      </RadioGroup>
                    }
                  >
                    {this.state.loadingHistory && (
                      <Spin
                        size="large"
                        tip="加载中..."
                        style={{ left: "50%", top: "50%" }}
                      />
                    )}

                    {!this.state.loadingHistory &&
                      this.state.confHistoryItem.errCode === 0 && (
                        <List
                          size="small"
                          itemLayout="horizontal"
                          dataSource={
                            this.state.confHistoryItem.cfgHistoryItems
                          }
                          pagination={{
                            current: this.state.currentPage,
                            pageSize: 6,
                            total: this.state.confHistoryItem.totalPage,
                            simple: true,
                            onChange: (currentPage) => {
                              this.setState({ currentPage }, () =>
                                this.fetchHistoryData()
                              );
                            },
                          }}
                          renderItem={(item, index) => (
                            <ListItem
                              key={index}
                              actions={[
                                <Button
                                  className="tool-btn"
                                  onClick={() => {
                                    const { language } = getLanguageAndTheme(
                                      item.confName
                                    );
                                    this.setState({
                                      diff1: item.confContent,
                                      diff2: item.updateConfContent,
                                      diffConfVisible: true,
                                      language: language,
                                    });
                                  }}
                                >
                                  <EyeTwoTone />
                                  查看
                                </Button>,
                              ]}
                            >
                              <ListItemMeta
                                title={item.confName}
                                description={
                                  <span>
                                    <div>
                                      修改时间:
                                      {MomentFormat(item.updateTimestamp)}
                                    </div>
                                    <div>修改原因: {item.reason}</div>
                                    <div>编辑人: {item.userName}</div>
                                  </span>
                                }
                              />
                            </ListItem>
                          )}
                        />
                      )}

                    {!this.state.loadingHistory &&
                      this.state.confHistoryItem.errCode !== 0 && (
                        <Result
                          status="error"
                          title="请求失败"
                          subTitle={
                            "errCode:" +
                            this.state.confHistoryItem.errCode +
                            " errMsg:" +
                            this.state.confHistoryItem.errMsg
                          }
                        />
                      )}
                  </Card>
                </div>
              </TabPane>
            </Tabs>
          )}
          {this.state.loading && (
            <Spin
              size="large"
              tip="加载中..."
              style={{ position: "relative", left: "50%", top: "50%" }}
            />
          )}
        </Drawer>

        {/* 添加配置 */}
        <Modal
          centered
          visible={this.state.addEditorVisible}
          title={
            <Form
              name="addForm"
              ref={this.addForm}
              validateTrigger="onBlur"
              initialValues={{ confName: "", confContent: "" }}
            >
              <Row justify="space-between">
                <Col>
                  <h3>添加配置</h3>
                </Col>
                <Col>
                  <FormItem
                    name="confName"
                    rules={[
                      {
                        required: true,
                        validator: (rule, value) => {
                          if (isEmpty(value)) {
                            return Promise.reject(new Error("文件名不能为空"));
                          }
                          const confItem = this.state.confItems.get(
                            this.state.clusterName
                          );
                          if (
                            confItem &&
                            confItem.cfgItems.find(
                              (item) => item.confName === value
                            ) !== undefined
                          ) {
                            return Promise.reject(new Error("文件名重复"));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      allowClear
                      addonBefore="文件名"
                      style={{ width: 400 }}
                      placeholder={
                        "eg:" + this.props.selectedTarsServer + ".conf"
                      }
                    />
                  </FormItem>
                </Col>
                {!isEmpty(this.state.languages) && (
                  <Col>
                    <FormItem label="语法高亮">
                      <Select
                        showSearch={true}
                        style={{ width: 100 }}
                        defaultValue={this.state.language}
                        dropdownMatchSelectWidth={false}
                        onChange={(language) => this.setState({ language })}
                      >
                        {this.state.languages &&
                          this.state.languages.map((item, index) => (
                            <SelectOption value={item} key={index}>
                              {item}
                            </SelectOption>
                          ))}
                      </Select>
                    </FormItem>
                  </Col>
                )}
              </Row>
            </Form>
          }
          closable={false}
          width="50vw"
          bodyStyle={{
            width: "50vw",
            height: "70vh",
          }}
          okText="添加"
          cancelText="关闭"
          okButtonProps={{
            type: "primary",
            ghost: true,
            className: "tool-btn",
          }}
          cancelButtonProps={{ danger: true, className: "tool-btn" }}
          onCancel={() => this.setState({ addEditorVisible: false })}
          onOk={async () => {
            try {
              if (this.addForm.current) {
                await this.addForm.current.validateFields();
                const values = this.addForm.current.getFieldsValue();
                const confName = values.confName;
                const confContent = this.state.confContent;
                this.fetchAddConfig(confName, confContent);
              }
            } catch {}
          }}
        >
          <MonacoEditor
            theme="vs-dark"
            language={this.state.language}
            options={{ minimap: { enabled: false } }}
            editorDidMount={this.editorDidMount.bind(this)}
            onChange={(value) => this.setState({ confContent: value })}
          />
        </Modal>

        {/* 配置编辑 */}
        <Modal
          centered
          visible={this.state.modifyEditorVisible}
          title={
            <Row justify="space-between">
              <Col>配置变更</Col>
              <Col>
                {this.state.modifyConfItem
                  ? this.state.modifyConfItem.confName
                  : ""}
              </Col>
              {!isEmpty(this.state.languages) && (
                <Col>
                  <FormItem label="语法高亮">
                    <Select
                      showSearch={true}
                      style={{ width: 100 }}
                      defaultValue={this.state.language}
                      dropdownMatchSelectWidth={false}
                      onChange={(language) => this.setState({ language })}
                    >
                      {this.state.languages &&
                        this.state.languages.map((item, index) => (
                          <SelectOption value={item} key={index}>
                            {item}
                          </SelectOption>
                        ))}
                    </Select>
                  </FormItem>
                </Col>
              )}
            </Row>
          }
          closable={false}
          width="80vw"
          bodyStyle={{ width: "80vw", height: "75vh" }}
          footer={
            <Row justify="end">
              <Col>
                <Button
                  type="ghost"
                  danger
                  ghost
                  className="tool-btn"
                  onClick={() =>
                    this.setState({
                      modifyEditorVisible: false,
                      modifiedConfContent: undefined,
                      modifyConfItem: undefined,
                    })
                  }
                >
                  关闭
                </Button>
              </Col>
              <Col>
                <Popconfirm
                  title={
                    <Form
                      name="reasonForm"
                      ref={this.reasonForm}
                      validateTrigger="onBlur"
                      initialValues={{ reason: "" }}
                    >
                      <FormItem
                        name="reason"
                        rules={[
                          { required: true, message: "内容过少", min: 6 },
                        ]}
                      >
                        <TextArea
                          placeholder="修改原因"
                          autoSize={{ minRows: 3, maxRows: 5 }}
                          onChange={({ target: { value } }) =>
                            this.setState({ modifyReason: value })
                          }
                        />
                      </FormItem>
                    </Form>
                  }
                  okText="提交"
                  cancelText="取消"
                  okButtonProps={{ type: "primary", className: "tool-btn" }}
                  cancelButtonProps={{ danger: true, className: "tool-btn" }}
                  visible={this.state.modifyReasonVisible}
                  onConfirm={async () => {
                    try {
                      if (this.reasonForm.current)
                        await this.reasonForm.current?.validateFields();
                      this.setState({ modifyReasonVisible: false });
                      this.fetchEditConfig();
                    } catch {}
                  }}
                  onCancel={() => this.setState({ modifyReasonVisible: false })}
                >
                  <Button
                    type="primary"
                    ghost
                    className="tool-btn"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      if (!this.state.modifyConfItem) {
                        notification.destroy();
                        notification["error"]({ message: "系统错误" });
                        return;
                      }
                      if (
                        this.state.modifiedConfContent ===
                        this.state.modifyConfItem.confContent
                      ) {
                        notification.destroy();
                        notification["error"]({ message: "内容无变更" });
                        return;
                      }
                      console.log(
                        this.state.modifiedConfContent,
                        "变更之后的内容校验"
                      );

                      const { isCorrect } = checkLanguageIsCorrect(
                        this.state.modifiedConfContent as string,
                        this.state.modifyConfItem.confName
                      );

                      if (isCorrect) {
                        this.setState({ modifyReasonVisible: true });
                      } else {
                        notification.destroy();
                        notification["error"]({
                          message: "编辑后的内容不符合语法规范",
                        });
                      }
                    }}
                  >
                    修改
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          }
        >
          <Row>
            <Col flex={1}>
              <div style={{ textAlign: "center" }}>
                <h4>原配置</h4>
              </div>
            </Col>
            <Col flex={1}>
              <div style={{ textAlign: "center" }}>
                <h4>待编辑内容</h4>
              </div>
            </Col>
          </Row>
          <MonacoDiffEditor
            theme="vs-dark"
            height="67vh"
            language={this.state.language}
            options={{
              minimap: { enabled: false },
              originalEditable: false,
              automaticLayout: true,
            }}
            editorDidMount={this.editorDidMount.bind(this)}
            original={
              this.state.modifyConfItem
                ? this.state.modifyConfItem.confContent
                : ""
            }
            value={this.state.modifiedConfContent}
            onChange={(value) => {
              this.setState({ modifiedConfContent: value });
            }}
          />
        </Modal>

        {/* 配置历史 */}
        <Modal
          centered
          visible={this.state.diffConfVisible}
          title={
            <Row justify="space-between">
              <Col>变更历史</Col>
              {!isEmpty(this.state.languages) && (
                <Col style={{ marginRight: 30 }}>
                  <FormItem label="语法高亮">
                    <Select
                      showSearch={true}
                      style={{ width: 100 }}
                      defaultValue={this.state.language}
                      dropdownMatchSelectWidth={false}
                      onChange={(language) => this.setState({ language })}
                    >
                      {this.state.languages &&
                        this.state.languages.map((item, index) => (
                          <SelectOption value={item} key={index}>
                            {item}
                          </SelectOption>
                        ))}
                    </Select>
                  </FormItem>
                </Col>
              )}
            </Row>
          }
          width="80vw"
          bodyStyle={{ width: "80vw", height: "75vh" }}
          footer={null}
          onCancel={() =>
            this.setState({ diff1: "", diff2: "", diffConfVisible: false })
          }
        >
          <Row>
            <Col flex={1}>
              <div style={{ textAlign: "center" }}>
                <h4>修改前</h4>
              </div>
            </Col>
            <Col flex={1}>
              <div style={{ textAlign: "center" }}>
                <h4>修改后</h4>
              </div>
            </Col>
          </Row>
          <MonacoDiffEditor
            theme="vs-dark"
            height="67vh"
            language={this.state.language}
            options={{
              minimap: { enabled: false },
              automaticLayout: true,
              readOnly: true,
            }}
            editorDidMount={this.editorDidMount.bind(this)}
            original={this.state.diff1}
            value={this.state.diff2}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
});

export default connect(mapStateToProps, null)(EditConfig);
