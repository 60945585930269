import React, { useEffect } from "react";
import loadScript from "load-script";
import { parseUrlQuery } from "src/utils";
import { APP_IP, AGENT_ID, REDIECT_URL } from "./constant";
import "./index.less";

export default function WorkWeChatLogin() {
  useEffect(() => {
    // @ts-ignore
    if (!window.WwLogin) {
      loadScript(
        "//rescdn.qqmail.com/node/ww/wwopenmng/js/sso/wwLogin-1.0.0.js",
        (err) => {
          if (!err) {
            initWorkWeChatLogin();
          }
        }
      );
    } else {
      initWorkWeChatLogin();
    }
  });

  function initWorkWeChatLogin() {
    const { redirect } = parseUrlQuery();
    const redirectUrl = encodeURIComponent(
      redirect || `${window.location.origin}/`
    );
    // @ts-ignore
    window.WwLogin({
      id: "weChat-qr",
      appid: APP_IP,
      agentid: AGENT_ID,
      redirect_uri: `${REDIECT_URL}?host=${encodeURIComponent(
        window.location.host
      )}&redirect=${redirectUrl}`,
      state: "",
      href: "",
    });
  }

  return (
    <div className="weChatLogin-container">
      <div id="weChat-qr" />
      <h3>请使用企业微信扫码登录 Kingo 平台</h3>
    </div>
  );
}
