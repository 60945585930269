import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ConfigProvider, Button, message } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";

import store from "src/stores";
import App from "src/layout";
import reportWebVitals from "src/reportWebVitals";
import "xterm/css/xterm.css";
import "antd/dist/antd.min.css";
import "./index.less";
import "animate.css/animate.min.css";
import "./global.less";
import "src/pages/Tools/Tools.css";
import "src/pages/DevOps/ToolsBar/Tool.css";

ReactDOM.render(
  <>
    <React.StrictMode>
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </React.StrictMode>
  </>,
  document.getElementById("root")
);

window.addEventListener(
  "error",
  (event) => {
    console.log("window error event :", JSON.stringify(event));
    const target = event.target;
    let isElementTarget =
      target instanceof HTMLScriptElement ||
      target instanceof HTMLStyleElement ||
      target instanceof HTMLSourceElement ||
      target instanceof HTMLLinkElement;
    if (!isElementTarget) return false;
    message.error({
      key: "pageReload",
      duration: 0,
      content: (
        <>
          检测到js、css文件已更新，请重新
          <Button
            type="link"
            onClick={() => {
              window.location.reload();
            }}
          >
            刷新
          </Button>
          页面
        </>
      ),
      style: {
        marginTop: "20vh",
      },
    });
  },
  true
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
