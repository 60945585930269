import React from "react";
import { connect } from "react-redux";

import { isEmpty } from "lodash";
import {
  Button,
  Form,
  FormInstance,
  Space,
  Select,
  InputNumber,
  Popconfirm,
  Modal,
  message,
  notification,
} from "antd";
import { DeleteTwoTone, QuestionCircleOutlined } from "@ant-design/icons";

import * as ActionType from "src/stores/types";
import * as fetch from "src/fetch";
import * as FetchType from "src/fetch/types";
import { pickClusterName } from "src/utils/Picker";

const FormItem = Form.Item;
const SelectOption = Select.Option;

interface IProps {
  disabled: boolean;
  currentUser?: string;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  selectedItems?: Array<ActionType.ITarsServerItem>;
  statefulsetItems?: Map<string, number>;
  replicasItems?: Map<string, number>;
}

interface IState {
  modalVisible: boolean;
  confirmVisible: boolean;
  clusterName?: string;
}

class ReduceNode extends React.Component<IProps, IState> {
  private reduceForm: React.RefObject<FormInstance>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      modalVisible: false,
      confirmVisible: false,
      clusterName: undefined,
    };
    this.reduceForm = React.createRef<FormInstance>();
  }

  fetchScaleInServer(clusterName?: string, shrinkCount?: number) {
    if (
      !this.props.selectedTarsApplication ||
      !this.props.selectedTarsServer ||
      !this.props.selectedItems
    )
      return;
    let obj: FetchType.IScaleInServerReq = {
      userName: this.props.currentUser || "",
      tarsApplication: this.props.selectedTarsApplication,
      tarsSetName: this.props.selectedTarsSetName || "",
      tarsServerName: this.props.selectedTarsServer,
    };

    if (this.props.selectedItems && !isEmpty(this.props.selectedItems)) {
      obj.podNetworkInterfaces = this.props.selectedItems.map((item) => ({
        podIP: item.podIP,
        clusterName: item.clusterName,
      }));
    } else {
      if (
        !clusterName ||
        !shrinkCount ||
        clusterName.length === 0 ||
        shrinkCount <= 0
      ) {
        message.error("系统错误");
        return;
      }
      obj.clusterName = clusterName;
      obj.shrinkCount = shrinkCount;
    }

    fetch
      .fetchScaleInServer(obj)
      .then(() =>
        notification.success({
          message: "下线成功",
          description: "节点将被自动下线, 稍后刷新页面查看",
        })
      )
      .catch((_) => {})
      .finally(() => {
        this.setState({
          confirmVisible: false,
          modalVisible: false,
          clusterName: undefined,
        });
      });
  }

  render() {
    let clusterName: string | undefined = undefined;
    if (this.props.tarsServerItems) {
      clusterName = pickClusterName(
        this.props.tarsServerItems.map((item) => item.clusterName)
      );
    }

    return (
      <>
        <Popconfirm
            placement="left"
            title={
              <>
                下线
                {this.props.selectedItems ? this.props.selectedItems.length : 0}
                个节点?
              </>
            }
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => this.fetchScaleInServer()}
            visible={this.state.confirmVisible}
            okText="下线"
            cancelText="取消"
            okButtonProps={{ className: "tool-btn", danger: true, ghost: true }}
            cancelButtonProps={{
              className: "tool-btn",
              type: "primary",
              ghost: true,
            }}
            onVisibleChange={(visible) => {
              if (!visible) {
                this.setState({
                  confirmVisible: visible,
                  modalVisible: visible,
                  clusterName: undefined,
                });
                if (this.reduceForm.current)
                  this.reduceForm.current.resetFields();
              }
            }}
          >
            <Button
              disabled={this.props.disabled}
              className="tool-btn"
              size="small"
              onClick={() => {
                if (
                  isEmpty(this.props.selectedItems) ||
                  (this.props.statefulsetItems &&
                    Array.from(this.props.statefulsetItems.values()).some(
                      (x) => x === 1
                    ))
                ) {
                  this.setState({ modalVisible: true });
                } else {
                  this.setState({ confirmVisible: true });
                }
              }}
            >
              <Space>
                <DeleteTwoTone twoToneColor="#DC143C" />
                缩容节点
              </Space>
            </Button>
          </Popconfirm>

        <Modal
          centered
          closable={false}
          visible={this.state.modalVisible}
          okText="下线"
          cancelText="取消"
          title={
            this.props.statefulsetItems &&
            Array.from(this.props.statefulsetItems.values()).some(
              (x) => x === 1
            )
              ? "StatefulSet只能指定数量下线"
              : null
          }
          onCancel={() => {
            this.setState({
              confirmVisible: false,
              modalVisible: false,
              clusterName: undefined,
            });
            if (this.reduceForm.current) this.reduceForm.current.resetFields();
          }}
          okButtonProps={{ danger: true, ghost: true, className: "tool-btn" }}
          cancelButtonProps={{
            type: "primary",
            ghost: true,
            className: "tool-btn",
          }}
          onOk={async () => {
            try {
              if (this.reduceForm.current) {
                const fields = await this.reduceForm.current.validateFields();
                this.fetchScaleInServer(fields.clusterName, fields.replicas);
              }
            } catch {}
          }}
        >
          <Form
            name="reduceForm"
            ref={this.reduceForm}
            validateTrigger="onBlur"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{
              clusterName,
              replicas: 1,
            }}
          >
            <FormItem
              name="clusterName"
              label="下线集群"
              rules={[{ required: true, message: "没选集群" }]}
            >
              <Select
                placeholder="集群"
                dropdownMatchSelectWidth={false}
                style={{ width: "100%" }}
                onChange={(clusterName) => this.setState({ clusterName })}
              >
                {(this.props.tarsServerItems || [])
                  .reduce((prev, cur) => {
                    if (-1 === prev.indexOf(cur.clusterName))
                      prev.push(cur.clusterName);
                    return prev;
                  }, Array<string>())
                  .map((value, index) => (
                    <SelectOption key={index} value={value}>
                      {value}
                    </SelectOption>
                  ))}
              </Select>
            </FormItem>
            <FormItem
              name="replicas"
              label="下线数量"
              rules={[
                {
                  required: true,
                  message: "没填下线数量",
                },
                {
                  validator: async (_, value) => {
                    if (
                      value >=
                      ((this.props.replicasItems as any).get(
                        clusterName as string
                      ) as number)
                    ) {
                      throw new Error("不能全部下线节点，至少保留一个节点");
                    }
                  },
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
                max={
                  !isEmpty(clusterName) && this.props.tarsServerItems
                    ? this.props.tarsServerItems.filter(
                        (item) => item.clusterName === clusterName
                      ).length
                    : 1
                }
              />
            </FormItem>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  selectedItems: state.tarsReducer.selectedItems,
  statefulsetItems: state.tarsReducer.statefulsetItems,
  replicasItems: state.tarsReducer.replicasItems,
});

export default connect(mapStateToProps, null)(ReduceNode);
