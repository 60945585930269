import { Input, Button} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps, FilterConfirmProps } from 'antd/es/table/interface';

const columnProps = (
    dataIndex: string,
    dataIndexName: string,
    searchInput: Input | null,
    onSearch: (selectedKeys : React.Key[], confirm : (param?: FilterConfirmProps | undefined) => void) => void,
    onSearchReset: (clearFilters: (() => void) | undefined, confirm : (param?: FilterConfirmProps | undefined) => void) => void,
  ) => ({
  sorter: (a: any, b: any): number => a[dataIndex] && b[dataIndex] ? a[dataIndex].toString().localeCompare(b[dataIndex]).toString() : 0,
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters } : FilterDropdownProps) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => searchInput = node}
        placeholder={`根据${dataIndexName}模糊查找`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        style={{ marginBottom: 8, display: 'block' }}
      />
        <Button className='tool-btn' onClick={() => onSearchReset(clearFilters, confirm)} size='small' danger style={{ width: 90 }}>
          重置
        </Button>
        <Button
          type='primary'
          ghost
          className='tool-btn'
          onClick={() => onSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginLeft: 3 }}
        >
          搜索
      </Button>
    </div>
  ),
  onFilterDropdownVisibleChange: (visible: boolean) => {
    if (visible) setTimeout(() => {if(searchInput) searchInput.select()}, 100);
  },
  onFilter: (value: string | number | boolean, record: any) => record[dataIndex] ? record[dataIndex].toLowerCase().indexOf((value as string).toLowerCase()) !== -1 : false,
  filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
});

const CommonTableProps = (
    dataIndex: string,
    dataIndexName: string,
    onSearch: (selectedKeys : React.Key[], confirm : (param?: FilterConfirmProps | undefined) => void) => void,
    onSearchReset: (clearFilters: (() => void) | undefined, confirm : (param?: FilterConfirmProps | undefined) => void) => void) => {
  let searchInput : Input | null = null;
  return columnProps(dataIndex, dataIndexName, searchInput, onSearch, onSearchReset);
};

export default CommonTableProps;