import React from 'react';
import { Button, Space } from 'antd';
import { BellFilled } from '@ant-design/icons';


import { connect } from 'react-redux';

interface IProps {
  currentUser?: string;
};

interface IState {
};

class Notify extends React.Component<IProps, IState> {
  render() {
    return (
      <>
        <Button size='small' className='tool-btn'>
          <Space><BellFilled style={{color: 'orange'}} />发送命令</Space>
        </Button>
      </>
    );
  };
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
});

export default connect(mapStateToProps, null)(Notify);
