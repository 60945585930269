import React from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { DataNode } from 'rc-tree/lib/interface';
import { Button, Input, Tree } from 'antd';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';

import * as Actions from 'src/stores/actions';
import * as ActionType from 'src/stores/types';

const { DirectoryTree } = Tree;

interface IProps {
  currentUser?: string;
  dcacheDataNodes: Array<DataNode>;                   // 搜索 展开之后tree结构
  dcacheTreeNodes: Array<ActionType.IDcacheTreeItem>; // 原始响应
  listDCacheTree: (currentUser : string, withLoading?: boolean) => void;
};

interface IState {
};

class LeftSideBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.listDCacheTree(this.props.currentUser || '');
  }

  render(){
    return (
      <>
        <Input
          placeholder='模块搜索(最少3字符)..'
          prefix={<SearchOutlined />}
          addonAfter={
            <Button
              type='link' size='small' icon={
                <ReloadOutlined style={{ color: 'green', fontSize: '15px' }} />
              }
              onClick={() => {}}
            />
          }
        />

        {
          !isEmpty(this.props.dcacheTreeNodes) &&
          <DirectoryTree
            style={{
              overflow: 'auto',
              display: 'flex',
              marginTop: 2,
              flexDirection: 'column',
            }}
            treeData={this.props.dcacheDataNodes}
          />
        }
      </>
    );
  }
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  dcacheDataNodes: state.tarsReducer.dcacheDataNodes || [],
  dcacheTreeNodes: state.tarsReducer.dcacheTreeNodes || [],
  treeNodes: state.tarsReducer.treeNodes || [],
});

const mapDispatchToProps = (dispatch: ThunkDispatch<ActionType.IStoreState, void, any>) => ({
  listDCacheTree: (currentUser: string, withLoading?: boolean) => dispatch(Actions.listDCacheTree(currentUser, withLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar);
