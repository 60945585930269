import { message } from "antd";
import { isEmpty } from "lodash";
import * as ActionType from "src/stores/types";

export const disableSelectNull = (
  selectedItems?: Array<ActionType.ITarsServerItem>
): boolean => {
  if (!selectedItems || isEmpty(selectedItems)) {
    message.destroy();
    message.error("选择节点为空");
    return true;
  }
  return false;
};

export const has = (
  phrase: string,
  selectedItems?: Array<ActionType.ITarsServerItem>
): boolean => {
  if (selectedItems && !isEmpty(selectedItems)) {
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].currentState === phrase) {
        return true;
      }
    }
  }
  return false;
};

export const allInactive = (
  selectedItems?: Array<ActionType.ITarsServerItem>
): boolean => {
  return selectedItems
    ? selectedItems.every((item) => item.currentState === "inactive")
    : false;
};

// 定义不同文件类型对应的语言 ID 和高亮主题名
const languageMap: any = {
  ".js": { language: "javascript", theme: "vs" },
  ".json": { language: "json", theme: "vs" },
  ".html": { language: "html", theme: "vs" },
  ".css": { language: "css", theme: "vs" },
  ".md": { language: "markdown", theme: "vs" },
  ".conf": { language: "xml", theme: "hc-black" },
  ".xml": { language: "xml", theme: "hc-black" },
  ".sh": { language: "shell", theme: "vs" },
  ".toml": { language: "toml", theme: "vs" },
};

export const getLanguageAndTheme = (fileName: string) => {
  const ext = fileName.substr(fileName.lastIndexOf("."));
  return languageMap[ext] || { language: "xml", theme: "hc-black" };
};

export const updateCache = (
  value: any,
  CACHE_SIZE: number,
  CACHE_KEY: string,
  cache: string[]
) => {
  const index = cache.indexOf(value);
  if (index !== -1) {
    cache.splice(index, 1);
  }
  cache.unshift(value);
  if (cache.length > CACHE_SIZE) {
    cache.pop();
  }
  localStorage.setItem(CACHE_KEY, JSON.stringify(cache));
};

export const checkLanguageIsCorrect = (
  languageContent: string,
  fileName: string
) => {
  const type = fileName.substr(fileName.lastIndexOf("."));
  // 根据传入的语言类型，判断是否是正确的语言类型，内容语法是否有问题，包括toml、json、xml、yaml、conf文件
  let isCorrect = true;
  let errorInfo: any;
  try {
    if (type === ".toml") {
      const toml = require("toml");
      toml.parse(languageContent);
    } else if (type === ".json") {
      JSON.parse(languageContent);
    } else if (type === ".xml") {
      const xml2js = require("xml2js");
      const result = xml2js.parseString(languageContent);
      if (!result) {
        isCorrect = false;
        errorInfo = "xml格式不正确";
      }
    } else if (type === ".yaml") {
      const yaml = require("js-yaml");
      yaml.load(languageContent);
    } else if (type === ".conf") {
      const parser = getTarsConfParser();
      const result = parser(languageContent);
      console.log("result", result);
      if (!result) {
        isCorrect = false;
        errorInfo = "conf格式不正确";
      }
    }
  } catch (error) {
    isCorrect = false;
    errorInfo = error;
  }
  return { isCorrect, errorInfo };
};
const getTarsConfParser = function () {
  let _data = {};

  const parseText = function (sText: string) {
    let arr = sText.split(/\r\n|\r|\n/); //行划分

    let stack: any[] = [_data];
    let key = "",
      value: any = "";

    for (let i = 0, len = arr.length; i < len; i++) {
      let line = arr[i].replace(/^[\s\t ]+|[\s\t ]+$/g, "");
      if (line.length == 0 || line[0] == "#") {
        continue;
      }

      // 当前域下面的值,例如：格式为a="b=2"或者a='b=2'或者a=b=2应该解析为key为a，value为b=2
      if (line[0] != "<") {
        let index = line.indexOf("=");
        if (index == -1) {
          key = line;
          value = undefined;
        } else {
          key = line.slice(0, index);
          value = line.slice(index + 1);
          value = value.replace(/^[\s\t ]+|[\s\t ]+$/g, "");
        }

        key = key.replace(/^[\s\t ]+|[\s\t ]+$/g, "");

        stack[stack.length - 1][key] = value;
        if (!stack[stack.length - 1]["tars_config_line_vector_value"]) {
          stack[stack.length - 1]["tars_config_line_vector_value"] = [];
        }
        stack[stack.length - 1]["tars_config_line_vector_value"].push(line);

        continue;
      }

      //当前行为域的标识
      if (line[line.length - 1] != ">") {
        //域标识符有开头但没有结尾，则分析错误
        _data = {};
        console.log("endTag", line);
        return false;
      }

      //当前行为域的结束
      if (line.startsWith("</")) {
        let endTag = line.substring(2, line.length - 1);
        if (
          stack.length > 1 &&
          Object.keys(stack[stack.length - 2]).includes(endTag)
        ) {
          stack.pop();
          continue;
        } else {
          _data = {};
          console.log("endTag", line);
          return false;
        }
      }

      //当前行为域的开始
      key = line.substring(1, line.length - 1);
      let parent = stack[stack.length - 1];

      if (parent.hasOwnProperty(key)) {
        //在当前域中已经有相同名字的域
        stack.push(parent[key]);
      } else {
        parent[key] = {};
        stack.push(parent[key]);
      }

      //检查是否为有效的结束标记
    }

    //检查结束标记是否正确
    if (stack.length === 1 && Object.keys(stack[0]).length > 0) {
      return true;
    } else {
      _data = {};
      console.log("endTag", stack);
      return false;
    }
  };
  return parseText;
};
