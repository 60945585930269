import React from "react";
import { isEmpty } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { Button, Space, Popconfirm, notification } from "antd";
import { PauseCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as Actions from "src/stores/actions";
import * as ActionType from "src/stores/types";

import * as fetch from "src/fetch";
import * as util from "src/pages/DevOps/ToolsBar/util";

import ApproveButton from './ApproveButton';
import { ApproveKindKeyEnum } from 'src/utils/constant';

interface IProps {
  disabled: boolean;
  currentUser?: string;
  gracefully: boolean;
  selectedTarsApplication?: string;
  selectedTarsSetName?: string;
  selectedTarsServer?: string;
  selectedItems?: Array<ActionType.ITarsServerItem>;
  tarsServerItems?: Array<ActionType.ITarsServerItem>;
  story_fetch: Record<string, any>;
  approveStatus: Number;
  updateApproveStatus: () => void
  pendingApproveUser: string;

  setTaskManagerDot: (taskManagerDot: boolean) => void;
}

interface IState {
  confirmVisible: boolean;
}

const Stop: React.FC<IProps> = (props) => {
  const {
    currentUser,
    selectedTarsApplication,
    selectedTarsSetName,
    selectedTarsServer,
    selectedItems,
    approveStatus,
    updateApproveStatus,
  } = props;

  const [state, setState] = React.useState<IState>({ confirmVisible: false });
  const handleStop = () => {
    if (
      selectedTarsApplication &&
      selectedTarsServer &&
      selectedItems &&
      !isEmpty(selectedItems)
    ) {
      fetch
        .fetchStopServer({
          userName: currentUser || "",
          graceful: props.gracefully ? 1 : 0,
          tarsApplication: selectedTarsApplication,
          tarsSetName: selectedTarsSetName || "",
          tarsServerName: selectedTarsServer,
          podNetworkInterfaces: selectedItems.map((item) => ({
            podIP: item.podIP,
            clusterName: item.clusterName,
          })),
        })
        .then((data) => {
          notification.destroy();
          notification.success({
            message:
              (props.gracefully ? "无损" : "") +
              "停止成功, 左侧【任务】中查看任务进度",
            description: `taskID:${data.taskID}`,
            duration: 15,
            style: { width: 600, whiteSpace: "pre-line" },
          });

          setTimeout(() => {
            props?.story_fetch?.taskRefresh();
          }, 3000);
          props.setTaskManagerDot(true);
          console.log("stop server success:story_fetch", props.story_fetch);
        }).finally(() => {
          updateApproveStatus()
        });
    }
  };

  return (
    <>
      <ApproveButton 
        kind={ApproveKindKeyEnum.stop} 
        approveStatus={approveStatus} 
        updateApproveStatus={updateApproveStatus}
        pendingApproveUser={props.pendingApproveUser}
      >
        <Popconfirm
          title={
            props.gracefully
              ? `确定【无损停止】${
                  props.selectedItems ? props.selectedItems.length : "0"
                }个节点?`
              : "直接【停止】没有流量切换过程,继续?"
          }
          visible={state.confirmVisible}
          placement="left"
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onCancel={() => setState({ ...state, confirmVisible: false })}
          onConfirm={() => handleStop()}
          disabled={props.disabled}
          onVisibleChange={(confirmVisible) =>
            setState({ ...state, confirmVisible })
          }
        >
          <Button
            ghost
            disabled={props.disabled}
            size="small"
            danger={!props.gracefully}
            className="tool-btn"
            type={props.gracefully ? "primary" : "default"}
            onClick={() => {
              if (util.disableSelectNull(props.selectedItems)) {
                return;
              }

              setState({ confirmVisible: true });
            }}
          >
            <Space>
              <PauseCircleOutlined
                style={{
                  color: props.gracefully ? "#1890ff" : "#ff4d4f",
                }}
              />
              {props.gracefully ? "无损停止" : "停止服务"}
            </Space>
          </Button>
        </Popconfirm>
      </ApproveButton>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentUser: state.tarsReducer.currentUser,
  selectedTarsApplication: state.tarsReducer.selectedTarsApplication,
  selectedTarsSetName: state.tarsReducer.selectedTarsSetName,
  selectedTarsServer: state.tarsReducer.selectedTarsServer,
  tarsServerItems: state.tarsReducer.tarsServerItems,
  selectedItems: state.tarsReducer.selectedItems,
  story_fetch: state.tarsReducer.story_fetch,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ActionType.IStoreState, void, any>
) => ({
  setTaskManagerDot: (taskManagerDot: boolean) =>
    dispatch(Actions.setTaskManagerDot(taskManagerDot)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stop);
